"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box } from "@mui/material";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ApproversList } from "@v2/feature/approval-rule/components/approvers-list.component";
import { ExpenseApprovalButtons } from "@v2/feature/payments/pages/components/expense-approval-buttons.component";
import { DEFAULT_CURRENCY, ExpenseStatus } from "@v2/feature/payments/payments.interface";
import { getContractorInvoiceStatusComponent } from "@v2/feature/payments/utils/get-contractor-invoice-status.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import moment from "moment/moment";
import { generatePath, useHistory } from "react-router-dom";
import { ExpenseAPI } from "@/api-client/expense.api";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE,
  EXPENSE_ME_EDIT_EXPENSE_ROUTE,
  EXPENSE_TEAM_EDIT_EXPENSE_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { PublicImageViewer } from "@/v2/components/upload/public-image-viewer.component";
import { LineItemInDrawerComponent } from "@/v2/feature/payments/components/line-item-in-drawer.component";
import { ViewerItem } from "@/v2/feature/payments/components/payment-details-drawer.component";
import { getExpenseTotalsBasedOnLineItems, getTaxRateForTotalSection } from "@/v2/feature/payments/expenses.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { truncateWithEllipses } from "@/v2/util/string.util";
export const ExpenseModal = ({
  isOpen,
  setIsOpen,
  selectedExpense,
  onClose,
  afterClose,
  onActionPerformed,
  currentUserIsAdmin,
  reach = "me"
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(
    ExpenseModalContent,
    {
      selectedExpense,
      currentUserIsAdmin,
      onActionPerformed,
      onClose,
      reach
    }
  ) });
};
const ExpenseModalContent = ({
  selectedExpense,
  currentUserIsAdmin,
  onActionPerformed,
  onClose,
  reach
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const routerHistory = useHistory();
  const { getScopesContext, hasScopes } = useScopes();
  const { polyglot } = usePolyglot();
  const [isUpdatingApproval, setIsUpdatingApproval] = useState(false);
  const [showMessage] = useMessage();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const currentUserIsExpenseAdmin = hasScopes(
    ["expenses:all", "expenses:manager"],
    getScopesContext({ userId: user == null ? void 0 : user.userId })
  );
  const currentUserIsManagerOfExpenseOwner = (selectedExpense == null ? void 0 : selectedExpense.from) ? checkScopes(user, ["expenses:manager"], { userId: selectedExpense.from }) : false;
  const hasRights = currentUserIsManagerOfExpenseOwner || currentUserIsExpenseAdmin;
  const draftExpense = (selectedExpense == null ? void 0 : selectedExpense.status) === ExpenseStatus.Draft;
  const expensePending = (selectedExpense == null ? void 0 : selectedExpense.status) === ExpenseStatus.Pending;
  const canEditExpense = hasRights && (draftExpense || expensePending) ? true : selectedExpense && selectedExpense.createdBy && user.userId === selectedExpense.createdBy && draftExpense ? true : selectedExpense && selectedExpense.from ? hasScopes(["expenses"], getScopesContext({ userId: selectedExpense.from })) && user.userId === (selectedExpense == null ? void 0 : selectedExpense.from) : false;
  const [loading, setLoading] = useState(false);
  const currentUserIsBeneficiaryOfExpense = user.userId === (selectedExpense == null ? void 0 : selectedExpense.from);
  const deleteExpense = useCallback(
    (expenseId) => __async(void 0, null, function* () {
      try {
        setIsUpdatingApproval(true);
        yield ExpenseAPI.deleteExpense(expenseId);
        showMessage(polyglot.t("ExpenseModal.successMessages.delete"), "success");
        setIsUpdatingApproval(false);
        yield onActionPerformed();
        onClose();
      } catch (e) {
        showMessage(polyglot.t("ExpenseModal.errorMessages.delete", { errorMessage: nestErrorMessage(e) }), "error");
        setIsUpdatingApproval(false);
      }
    }),
    [showMessage, polyglot, onClose, onActionPerformed]
  );
  const syncExpenseWithAccountingProvider = (expenseId) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield ExpenseAPI.syncExpenseWithExternalProvider(expenseId);
      showMessage(polyglot.t("ExpenseModal.successMessages.sync"), "success");
    } catch (error) {
      showMessage(polyglot.t("ExpenseModal.errorMessages.sync", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoading(false);
    }
  });
  const navigateToExpensePageToEdit = useCallback(() => {
    if (!selectedExpense || !(selectedExpense == null ? void 0 : selectedExpense.id) || (selectedExpense == null ? void 0 : selectedExpense.status) !== ExpenseStatus.Draft && (selectedExpense == null ? void 0 : selectedExpense.status) !== ExpenseStatus.Pending)
      return;
    if (reach === "me") {
      routerHistory.push(generatePath(EXPENSE_ME_EDIT_EXPENSE_ROUTE, { id: selectedExpense == null ? void 0 : selectedExpense.id }), {
        expenseToEdit: selectedExpense
      });
    } else if (reach === "team") {
      routerHistory.push(generatePath(EXPENSE_TEAM_EDIT_EXPENSE_ROUTE, { id: selectedExpense == null ? void 0 : selectedExpense.id }), {
        expenseToEdit: selectedExpense
      });
    } else if (reach === "company") {
      routerHistory.push(generatePath(EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE, { id: selectedExpense == null ? void 0 : selectedExpense.id }), {
        expenseToEdit: selectedExpense
      });
    }
  }, [reach, routerHistory, selectedExpense]);
  const { totalGross, totalAmount, totalTaxAmount } = selectedExpense && selectedExpense.lineItems ? getExpenseTotalsBasedOnLineItems(selectedExpense.lineItems) : { totalGross: 0, totalAmount: 0, totalTaxAmount: 0 };
  const hasMoreThanOneLineItem = ((_a = selectedExpense == null ? void 0 : selectedExpense.lineItems) == null ? void 0 : _a.length) && ((_b = selectedExpense == null ? void 0 : selectedExpense.lineItems) == null ? void 0 : _b.length) > 1 ? true : false;
  const canDeleteOrEditExpense = (selectedExpense == null ? void 0 : selectedExpense.status) && [ExpenseStatus.Draft, ExpenseStatus.Pending, ExpenseStatus.Rejected, ExpenseStatus.Voided].includes(
    selectedExpense == null ? void 0 : selectedExpense.status
  );
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between"
        },
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ExpenseModal.title") }),
          (canDeleteOrEditExpense && currentUserIsAdmin || expensePending && currentUserIsBeneficiaryOfExpense || draftExpense && canEditExpense) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: spacing.g10 }, children: [
            (expensePending || draftExpense) && canEditExpense && /* @__PURE__ */ jsx(
              IconButton,
              {
                disabled: isUpdatingApproval,
                fullWidth: true,
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => {
                  navigateToExpensePageToEdit();
                },
                name: polyglot.t("General.edit"),
                children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, iconSize))
              }
            ),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                disabled: isUpdatingApproval,
                fullWidth: true,
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: (event) => {
                  setAnchorEl(event.currentTarget);
                  setIsRemovalModalOpen(true);
                },
                name: polyglot.t("General.delete"),
                children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
              }
            )
          ] })
        ]
      }
    ),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        children: [
          /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ExpenseModal.paidTo") }) }),
          (selectedExpense == null ? void 0 : selectedExpense.from) && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(UserCell, { userId: selectedExpense.from, nameVariant: "title4" }) })
        ]
      }
    ),
    selectedExpense && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g15, mb: spacing.mb20 }, children: [
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ExpenseModal.policy"),
          value: ((_c = selectedExpense == null ? void 0 : selectedExpense.lineItems) == null ? void 0 : _c.length) === 1 ? "Single" : "Multiple"
        }
      ),
      selectedExpense.notes && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.description"),
          value: (selectedExpense == null ? void 0 : selectedExpense.notes) && selectedExpense.notes.length > 40 ? /* @__PURE__ */ jsx(StyledTooltip, { title: selectedExpense.notes, placement: "top", children: /* @__PURE__ */ jsx("span", { children: truncateWithEllipses(selectedExpense.notes, 40) }) }) : /* @__PURE__ */ jsx("div", { children: truncateWithEllipses(selectedExpense.notes, 40) })
        }
      ),
      selectedExpense.date && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ExpenseModal.dateSpent"),
          value: moment(selectedExpense.date).format("DD MMM YYYY")
        }
      ),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ExpenseModal.type"),
          value: ((_d = selectedExpense == null ? void 0 : selectedExpense.lineItems) == null ? void 0 : _d.length) === 1 ? "Single" : "Multiple"
        }
      ),
      selectedExpense.updatedBy && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ExpenseModal.lastUpdatedBy"),
          value: /* @__PURE__ */ jsx(UserCell, { userId: selectedExpense.updatedBy, nameVariant: "title4" })
        }
      ),
      (_e = selectedExpense == null ? void 0 : selectedExpense.lineItems) == null ? void 0 : _e.map((item, index) => /* @__PURE__ */ jsx(LineItemInDrawerComponent, { item, index, currency: selectedExpense.currency }, item.id)),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ExpenseModal.status"),
          value: getContractorInvoiceStatusComponent(selectedExpense.status, themeFonts.title4)
        }
      ),
      !selectedExpense.amount ? /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.amount"),
          value: formatCurrency(selectedExpense.amount, void 0, selectedExpense.currency)
        }
      ) : null,
      selectedExpense.gross && selectedExpense.amount && totalAmount ? /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("NewInvoicePage.amount"),
          value: formatCurrency(totalAmount != null ? totalAmount : 0, void 0, (_f = selectedExpense.currency) != null ? _f : DEFAULT_CURRENCY)
        }
      ) : null,
      selectedExpense.taxRate || selectedExpense.taxRate === 0 ? /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: `${polyglot.t("NewInvoicePage.taxAmount")} ${!hasMoreThanOneLineItem ? getTaxRateForTotalSection(selectedExpense.taxRate) : ""}`,
          value: formatCurrency(totalTaxAmount, void 0, selectedExpense.currency)
        }
      ) : null,
      !selectedExpense.taxRate && (selectedExpense.taxAmount || totalTaxAmount) ? /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: `${polyglot.t("NewInvoicePage.taxAmount")}`,
          value: formatCurrency(
            totalTaxAmount != null ? totalTaxAmount : selectedExpense.taxAmount,
            void 0,
            selectedExpense.currency
          )
        }
      ) : null,
      selectedExpense.amount && selectedExpense.taxRate !== null && selectedExpense.taxRate >= 0 ? /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("NewInvoicePage.gross"),
          value: formatCurrency(totalGross != null ? totalGross : 0, void 0, (_g = selectedExpense.currency) != null ? _g : DEFAULT_CURRENCY)
        }
      ) : null,
      selectedExpense.amount && !selectedExpense.taxRate && selectedExpense.taxAmount && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("NewInvoicePage.gross"),
          value: formatCurrency(totalGross != null ? totalGross : 0, void 0, (_h = selectedExpense.currency) != null ? _h : DEFAULT_CURRENCY)
        }
      ),
      !selectedExpense.approvedOnTimestamp && /* @__PURE__ */ jsx(ApproversList, { approverSteps: selectedExpense.approverSteps, layout: "horizontal", rowAvatarsLimit: 4 }),
      selectedExpense.approvedByIds && (selectedExpense == null ? void 0 : selectedExpense.approvedByIds.length) > 0 && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ExpenseModal.approvedBy"),
          value: /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: selectedExpense.approvedByIds })
        }
      ),
      selectedExpense.rejectedByIds && (selectedExpense == null ? void 0 : selectedExpense.rejectedByIds.length) > 0 && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ExpenseModal.rejectedBy"),
          value: /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: selectedExpense.rejectedByIds })
        }
      ),
      selectedExpense.approvedOnTimestamp && selectedExpense.status === ExpenseStatus.Approved ? /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ExpenseModal.approvedOn"),
          value: new Date(selectedExpense.approvedOnTimestamp).toLocaleString()
        }
      ) : selectedExpense.approvedOnTimestamp && selectedExpense.status === ExpenseStatus.Approved ? /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ExpenseModal.rejectedOn"),
          value: new Date(selectedExpense.approvedOnTimestamp).toLocaleString()
        }
      ) : null,
      selectedExpense.status === ExpenseStatus.Rejected && selectedExpense.approvalNotes && ((_i = selectedExpense.approvalNotes) == null ? void 0 : _i.length) > 0 && /* @__PURE__ */ jsx(ViewerItem, { title: polyglot.t("ContractorInvoiceModal.notes"), value: selectedExpense.approvalNotes }),
      selectedExpense.attachment && /* @__PURE__ */ jsx(
        PublicImageViewer,
        {
          fileName: selectedExpense.attachment,
          uploadName: "Uploaded receipt",
          hasView: true,
          hasDownload: true
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      AccountingIntegrationSection,
      {
        selectedExpense,
        syncExpenseWithAccountingProvider,
        loading,
        user
      }
    ),
    selectedExpense && /* @__PURE__ */ jsx(
      ExpenseApprovalButtons,
      {
        selectedExpense,
        onClose,
        onActionPerformed
      }
    ),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => setIsRemovalModalOpen(false),
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (selectedExpense) yield deleteExpense(selectedExpense.id);
        }),
        message: `Are you sure you want to delete this expense?`,
        callToAction: "Yes"
      }
    )
  ] });
};
const AccountingIntegrationSection = ({
  selectedExpense,
  syncExpenseWithAccountingProvider,
  loading,
  user
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g15 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mbb: spacing.s2 }, children: polyglot.t("ContractorInvoiceModal.accountingIntegration") }),
    (selectedExpense == null ? void 0 : selectedExpense.externalId) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "External invoice" }),
      /* @__PURE__ */ jsx(
        "a",
        {
          href: `https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${selectedExpense.externalId}`,
          target: "_blank",
          rel: "noreferrer",
          children: /* @__PURE__ */ jsx(Box, { component: "span", sx: themeFonts.caption, children: "See here" })
        }
      )
    ] }),
    selectedExpense && selectedExpense.lineItems && ((_a = selectedExpense.lineItems) == null ? void 0 : _a.some((item) => item.accountingCode !== null)) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: Array.from(new Set(selectedExpense.lineItems.map((item) => item.accountingCode).filter(Boolean))).map(
        (accountingCode) => {
          const item = selectedExpense.lineItems.find((i) => i.accountingCode === accountingCode);
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: `${polyglot.t("PaymentSettingsPage.tableColumns.accountingCode")} (${accountingCode}${(item == null ? void 0 : item.accountingCodeDescription) ? truncateWithEllipses(" - ".concat(item == null ? void 0 : item.accountingCodeDescription), 15) : ""})` }, accountingCode);
        }
      ) }),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["expenses:all"], context: { userId: user.userId }, children: /* @__PURE__ */ jsxs(
        ButtonComponent,
        {
          colorVariant: "secondary",
          sizeVariant: "small",
          disabled: loading,
          onClick: () => __async(void 0, null, function* () {
            yield syncExpenseWithAccountingProvider(selectedExpense.id);
          }),
          children: [
            /* @__PURE__ */ jsx(RefreshIcon, { sx: __spreadValues({}, iconSize) }),
            polyglot.t(
              (selectedExpense == null ? void 0 : selectedExpense.externalId) ? "ContractorInvoiceModal.syncAgain" : "ContractorInvoiceModal.syncExternally"
            )
          ]
        }
      ) })
    ] }) : /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ExpenseModal.noAccountingCode") }) })
  ] });
};
