"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AttendanceShiftAPI } from "@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { buttonBoxSx, fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { isoDateAndTimeFormat, isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DrawerViewerItem } from "@/v2/feature/absence/components/drawer-viewer-item.component";
export const UnfinishedAttendanceShiftDrawer = ({
  isOpen,
  setIsOpen,
  shift,
  refresh,
  onClose,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(UnfinishedAttendanceShiftDrawerContent, { refresh, setIsOpen, shift }) });
};
const UnfinishedAttendanceShiftDrawerContent = ({
  shift,
  setIsOpen,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const user = getCachedUserById(shift.userId);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield AttendanceShiftAPI.endUnfinishedAttendanceShiftById(
          shift.userId,
          shift.logDate,
          values.endHour,
          values.endHourTimestamp,
          // for now if admin ends the shift, don't save location
          null,
          null
        );
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.badRequest", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, shift, refresh, setIsOpen, showMessage]
  );
  const minEndTime = useMemo(() => {
    var _a;
    if (!shift.shiftEntries || !((_a = shift.shiftEntries[0]) == null ? void 0 : _a.startHourTimestamp)) {
      const minTime = new LocalDate(shift.logDate);
      minTime.getDate().setHours(0, 0, 0, 0);
      return minTime.getDate();
    }
    const lastEntryStart = new Date(shift.shiftEntries[shift.shiftEntries.length - 1].startHourTimestamp);
    lastEntryStart.setMinutes(lastEntryStart.getMinutes() + 5, 0, 0);
    return lastEntryStart;
  }, [shift]);
  const formik = useFormik({
    initialValues: {
      endHour: "",
      endHourTimestamp: null
    },
    validationSchema: Yup.object({
      endHour: Yup.string().matches(isoDateAndTimeFormat, polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.valueInvalid")).required(polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.endHourRequired")),
      endHourTimestamp: Yup.date().min(
        minEndTime,
        `Min time: ${minEndTime.toLocaleDateString(void 0, {
          hour: "2-digit",
          minute: "2-digit"
        }).split(", ")[1]}`
      ).typeError(polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.valueInvalid")).required(polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.endHourTimeStampRequired"))
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("UnfinishedAttendanceShiftDrawer.finishShift") }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("UnfinishedAttendanceShiftDrawer.employee"),
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId: shift.userId, size: "xxsmall" }),
          user && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: user.displayName || `${user.firstName} ${user.lastName}` })
        ] })
      },
      "employee"
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { display: "flex" }), children: [
      /* @__PURE__ */ jsxs(Box, { sx: { mb: spacing.m30, display: "flex", flexDirection: "column", gap: spacing.g5, width: "50%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "Date" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: new LocalDate(shift.logDate).toLocaleDateString(void 0, {
          day: "2-digit",
          month: "short",
          year: "numeric"
        }) })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "50%", maxWidth: "120px" }, children: /* @__PURE__ */ jsx(
        TimePickerComponent,
        {
          textFieldKey: "endHour",
          label: polyglot.t("UnfinishedAttendanceShiftDrawer.endHour"),
          value: formik.values.endHour,
          onChange: (event) => {
            const time = event.target.value;
            if (isValidTimeString(time)) {
              const date = new LocalDate(`${shift.logDate}T${time}:00`);
              formik.setFieldValue("endHour", date.toFullString());
              formik.setFieldValue("endHourTimestamp", date.getDate());
              formik.validateField("endHour");
              formik.validateField("endHourTimestamp");
            }
          },
          error: !!formik.errors.endHour && formik.touched.endHour || !!formik.errors.endHourTimestamp && formik.touched.endHourTimestamp,
          helperText: formik.touched.endHour && formik.errors.endHour || formik.touched.endHourTimestamp && formik.errors.endHourTimestamp,
          fullWidth: true
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("UnfinishedAttendanceShiftDrawer.endShift"),
        fullWidth: true,
        loading,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
