"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import CsvDownloader from "react-csv-downloader";
import { v4 as uuidv4 } from "uuid";
import { UploadInput } from "@/component/forms/UploadInput";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import {
  calcPaycodeTotalForPayrunEntry,
  getOptionalPayCodesInUse
} from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { titleSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { parseCsvBuffer } from "@/v2/util/csv.util";
import { arrayBufferToString, getFirstAndLastNameFromNameData } from "@/v2/util/string.util";
export const ImportIncomeDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  entries,
  payCodes,
  saveIncomeUpdates
}) => {
  const [showMessage] = useMessage();
  const [uploading, setUploading] = useState(false);
  const mapCSVUpdatesForIncomeImport = (entries2, payCodes2, parsedJSON) => {
    const updatesViaImport = [];
    for (const eachJSONUpdate of parsedJSON) {
      const matchingPayrunEntry = entries2.find(
        (eachEntry) => eachEntry.employmentDetails.payrollCode === eachJSONUpdate.payrollCode
      );
      if (!matchingPayrunEntry) continue;
      const _a = eachJSONUpdate, { firstName: _1, lastName: _2, payrollCode: _3, salaryBasis, rate, units } = _a, codes = __objRest(_a, ["firstName", "lastName", "payrollCode", "salaryBasis", "rate", "units"]);
      const mapPayCodesToPayLines = (kind) => {
        const isDeduction = kind === "deduction";
        return payCodes2.filter((payCode) => payCode.isDeduction === isDeduction && payCode.code.toLowerCase() in codes).map(({ code, title }) => ({
          id: uuidv4(),
          code,
          isDeduction,
          amount: parseFloat(codes[code.toLowerCase()] || "0"),
          description: title,
          recurringId: null,
          recurring: null
        }));
      };
      const update = {
        id: matchingPayrunEntry.id,
        userId: matchingPayrunEntry.userId,
        additions: {
          salary: {
            amount: parseFloat(rate || "0"),
            multiplier: ["Daily", "Hourly"].includes(salaryBasis) ? parseFloat(units || "1") : 1
          },
          paylines: mapPayCodesToPayLines("addition")
        },
        deductions: {
          paylines: mapPayCodesToPayLines("deduction")
        }
      };
      updatesViaImport.push(update);
    }
    return updatesViaImport;
  };
  const getIncomeTableForExport = () => __async(void 0, null, function* () {
    try {
      const allColumnsForTemplateCSV = entries.map((eachEntry) => {
        var _a, _b, _c, _d;
        const { payOptions } = eachEntry;
        const [firstName, lastName] = getFirstAndLastNameFromNameData((_a = eachEntry == null ? void 0 : eachEntry.employee) == null ? void 0 : _a.name);
        const paycodesInUse = [
          .../* @__PURE__ */ new Set([
            ...getOptionalPayCodesInUse(payCodes != null ? payCodes : [], [eachEntry], false),
            ...getOptionalPayCodesInUse(payCodes != null ? payCodes : [], [eachEntry], true),
            // always include all custom codes to use as an example
            ...(payCodes != null ? payCodes : []).filter((p) => !p.isSystemCode && !p.isControlCode)
          ])
        ].sort((a, b) => a.code.localeCompare(b.code));
        return __spreadValues({
          firstName,
          lastName,
          payrollCode: (_b = eachEntry == null ? void 0 : eachEntry.employmentDetails) == null ? void 0 : _b.payrollCode,
          salaryBasis: payOptions == null ? void 0 : payOptions.basis,
          rate: (_c = payOptions == null ? void 0 : payOptions.payAmount) == null ? void 0 : _c.toFixed(2),
          units: ((_d = eachEntry.salaryUnits) != null ? _d : 1).toFixed(2).replace(".00", "")
        }, paycodesInUse.reduce((codes, paycode) => {
          codes[paycode.code] = calcPaycodeTotalForPayrunEntry(eachEntry, paycode.code);
          return codes;
        }, {}));
      });
      return allColumnsForTemplateCSV;
    } catch (error) {
      console.error("Something went wrong fetching template file for income import", error.message);
      return [];
    }
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Import income" }),
    /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m15, display: "inline-block" }), children: [
      "Import income and deductions for the current payrun in bulk by using",
      " ",
      /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({ display: "inline-block" }, themeFonts.caption), { textDecoration: "underline", cursor: "pointer" }),
          children: /* @__PURE__ */ jsx(CsvDownloader, { filename: "income-import-template", separator: ",", datas: getIncomeTableForExport, children: "this template" })
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: 3 }, children: !uploading ? /* @__PURE__ */ jsx(
      UploadInput,
      {
        skipUpload: true,
        onChange: (_, file) => __async(void 0, null, function* () {
          try {
            setUploading(true);
            if (!file) return;
            const arrayBuffer = yield file.arrayBuffer();
            const csvBuffer = yield arrayBufferToString(arrayBuffer);
            const parsedJSONData = yield parseCsvBuffer(csvBuffer);
            const updatesViaImport = mapCSVUpdatesForIncomeImport(entries, payCodes != null ? payCodes : [], parsedJSONData);
            const result = yield saveIncomeUpdates(updatesViaImport);
            if (result) onClose();
          } catch (error) {
            showMessage("Error parsing file, please select a CSV file", "error");
            console.error(":::: ERROR PARSING FILE :::::", error);
          } finally {
            setUploading(false);
          }
        })
      }
    ) : /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", disabled: uploading, children: "Records being uploaded..." }) })
  ] }) });
};
