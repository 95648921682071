"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { NotEnrolledInPension } from "@/v2/feature/payroll/features/payroll-uk/components/not-enrolled-in-pension.component";
import {
  calcPaycodeTotalForPayrunEntry,
  getOptionalPayCodesInUse
} from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { EditDeductionsPage } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-deductions-page.component";
import { EditPayrunEntryDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-payrun-entry-drawer.component";
import {
  CurrencyWithDiff,
  ValueWithDiff
} from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component";
import { sum } from "@/v2/util/array.util";
export const PayrunDeductionsTable = ({
  entries,
  previousEntries,
  payCodes,
  payrunClosed,
  saveIncomeUpdates,
  sx
}) => {
  const [editingDeductions, setEditingDeductions] = useState();
  const optionalDeductionsInUse = useMemo(() => getOptionalPayCodesInUse(payCodes != null ? payCodes : [], entries, true), [
    entries,
    payCodes
  ]);
  const [deductionTotals, prevDeductionTotals] = useMemo(() => {
    const calculateTotals = (entries2) => ({
      paye: sum(entries2, (e) => {
        var _a;
        return (_a = e.paye) != null ? _a : 0;
      }),
      employeeNi: sum(entries2, (e) => {
        var _a;
        return (_a = e.employeeNi) != null ? _a : 0;
      }),
      employeePension: sum(entries2, (e) => {
        var _a;
        return (_a = e.employeePension) != null ? _a : 0;
      }),
      studentLoan: sum(entries2, (e) => {
        var _a;
        return (_a = e.studentLoan) != null ? _a : 0;
      }),
      postgradLoan: sum(entries2, (e) => {
        var _a;
        return (_a = e.postgradLoan) != null ? _a : 0;
      }),
      deductions: sum(entries2, (e) => {
        var _a;
        return (_a = e.deductions) != null ? _a : 0;
      })
    });
    return [
      calculateTotals(entries),
      previousEntries.length ? calculateTotals(previousEntries) : {}
    ];
  }, [entries, previousEntries]);
  const [optionalDeductionsTotals, prevOptionalDeductionsTotals] = useMemo(() => {
    const calculateTotals = (entries2) => {
      const totals = {};
      for (const { code } of optionalDeductionsInUse) {
        totals[code] = sum(entries2, (e) => calcPaycodeTotalForPayrunEntry(e, code));
      }
      return totals;
    };
    return [calculateTotals(entries), previousEntries.length ? calculateTotals(previousEntries) : {}];
  }, [entries, optionalDeductionsInUse, previousEntries]);
  const columnData = useMemo(() => {
    const previousById = new Map(previousEntries.map((item) => [item.employee.id, item]));
    const getPreviousPayrunEntry = (context) => {
      return previousById.get(context.getValue().employee.id);
    };
    const hideColumnIfNoValues = (visibleSize, getValue) => {
      return entries.some(getValue) || previousEntries.some(getValue) ? visibleSize : 0;
    };
    const columns = [
      {
        id: "employee",
        header: () => "Employee",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.employee.name),
        cell: (c) => /* @__PURE__ */ jsx(UserCell, { userId: c.row.original.userId }),
        footer: () => "Total",
        size: 100
      },
      {
        id: "paye",
        header: () => /* @__PURE__ */ jsx("span", { title: "Pay-as-you-earn", children: "PAYE" }),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.paye) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (item) => {
              var _a;
              return (_a = item.paye) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: deductionTotals.paye, previousValue: prevDeductionTotals.paye }),
        size: 70
      },
      {
        id: "employee-ni",
        header: () => /* @__PURE__ */ jsx("span", { title: "National insurance", children: "NI" }),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.employeeNi) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (item) => {
              var _a;
              return (_a = item.employeeNi) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: deductionTotals.employeeNi, previousValue: prevDeductionTotals.employeeNi }),
        size: 70
      },
      {
        id: "pension",
        header: () => "Pension",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.employeePension) != null ? _a : 0;
        }),
        cell: (c) => "employeePensionContribution" in c.getValue().totals ? /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: previousById.get(c.getValue().employee.id),
            getValue: (item) => {
              var _a;
              return (_a = item.employeePension) != null ? _a : 0;
            }
          }
        ) : /* @__PURE__ */ jsx(NotEnrolledInPension, { userId: c.row.original.userId }),
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: deductionTotals.employeePension,
            previousValue: prevDeductionTotals.employeePension
          }
        ),
        size: 70
      },
      {
        id: "student-loan",
        header: () => "Student loan",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.studentLoan) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (item) => {
              var _a;
              return (_a = item.studentLoan) != null ? _a : 0;
            },
            dimIfZero: true
          }
        ),
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: deductionTotals.studentLoan,
            previousValue: prevDeductionTotals.studentLoan
          }
        ),
        size: hideColumnIfNoValues(90, (e) => {
          var _a;
          return (_a = e.studentLoan) != null ? _a : 0;
        })
      },
      {
        id: "pg-loan",
        header: () => "Postgrad loan",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.postgradLoan) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (item) => {
              var _a;
              return (_a = item.postgradLoan) != null ? _a : 0;
            },
            dimIfZero: true
          }
        ),
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: deductionTotals.postgradLoan,
            previousValue: prevDeductionTotals.postgradLoan
          }
        ),
        size: hideColumnIfNoValues(90, (e) => {
          var _a;
          return (_a = e.postgradLoan) != null ? _a : 0;
        })
      },
      ...optionalDeductionsInUse.sort((a, b) => a.title.localeCompare(b.title, void 0, { sensitivity: "base" })).map(({ code, title }) => ({
        id: code,
        header: () => title,
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => calcPaycodeTotalForPayrunEntry(item, code)),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (item) => calcPaycodeTotalForPayrunEntry(item, code),
            dimIfZero: true
          }
        ),
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: optionalDeductionsTotals[code],
            previousValue: prevOptionalDeductionsTotals[code]
          }
        ),
        size: 90
      })),
      {
        id: "total-deductions",
        header: () => "Total deductions",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.deductions) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (item) => {
              var _a;
              return (_a = item.deductions) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: deductionTotals.deductions, previousValue: prevDeductionTotals.deductions }),
        size: 110
      }
    ];
    return columns.filter((c) => c.size);
  }, [
    deductionTotals,
    entries,
    optionalDeductionsInUse,
    optionalDeductionsTotals,
    previousEntries,
    prevDeductionTotals,
    prevOptionalDeductionsTotals
  ]);
  return /* @__PURE__ */ jsxs(Box, { sx, children: [
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: entries,
        columnData,
        hidePagination: true,
        showFooter: true,
        rowClick: (row) => setEditingDeductions(row.original)
      }
    ),
    /* @__PURE__ */ jsx(
      EditPayrunEntryDrawer,
      {
        userId: editingDeductions == null ? void 0 : editingDeductions.userId,
        payrunEntry: editingDeductions,
        payCodes,
        payrunClosed,
        saveIncomeUpdates,
        onClose: () => setEditingDeductions(void 0),
        Content: EditDeductionsPage
      }
    )
  ] });
};
