"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import { iconSize } from "@v2/components/forms/editable-title.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AllowanceRoundingType, HolidaysProratingRule } from "@v2/feature/absence/absence.interface";
import {
  getAbsenceProratingMethod,
  getPolicyAllowanceTypeLabel,
  getPolicyHolidaysProratingRuleOption,
  isHourlyPolicy,
  isUnlimitedPolicy
} from "@v2/feature/absence/absence.util";
import { getAccrualFrequency } from "@v2/feature/absence/subfeatures/settings/policy-details/absence-policy-settings.util";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { PolicyAccrualEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-accrual-edit-drawer.component";
import { PolicyAllowanceEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-allowance-edit-drawer.component";
import { PolicyCarryOverEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-carry-over-edit-drawer.component";
import { PolicyProratingEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-prorating-edit-drawer.component";
import { PolicyPublicHolidaysEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-public-holidays-edit-drawer.component";
import { PolicyRoundingEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-rounding-edit-drawer.component";
import { PolicyTenureEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-tenure-edit-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import {
  translateAbsencePolicyAccrualCliff,
  translateAbsencePolicyTenureAllocation
} from "@v2/infrastructure/i18n/translate.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
const getCarryOverExpirationDate = (absencePolicy) => {
  if (!absencePolicy.carryOverEnabled || !absencePolicy.carryOverExpirationMonth || !absencePolicy.carryOverExpirationDay)
    return null;
  const expiry = /* @__PURE__ */ new Date();
  expiry.setMonth(absencePolicy.carryOverExpirationMonth - 1, absencePolicy.carryOverExpirationDay);
  return expiry.toLocaleDateString(void 0, {
    day: "numeric",
    month: "long"
  });
};
const getAbsenceRoundingMethod = (policy, polyglot) => {
  if (policy.allowanceRoundingType === AllowanceRoundingType.UpToFullDay)
    return polyglot.t("AbsenceUtil.upToNearestFull");
  if (policy.allowanceRoundingType === AllowanceRoundingType.UpToHalfDay)
    return polyglot.t("AbsenceUtil.upToNearestHalf");
  if (policy.allowanceRoundingType === AllowanceRoundingType.NoRounding) return polyglot.t("AbsenceUtil.noRounding");
  return policy.allowanceRoundingType;
};
export const AbsencePolicyAllowanceSection = ({ absencePolicy, refresh }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [isPHDrawerOpen, setIsPHDrawerOpen] = useState(false);
  const [isProratingDrawerOpen, setIsProratingDrawerOpen] = useState(false);
  const [isRoundingDrawerOpen, setIsRoundingDrawerOpen] = useState(false);
  const [isCarryOverDrawerOpen, setIsCarryOverDrawerOpen] = useState(false);
  const [isTenureDrawerOpen, setIsTenureDrawerOpen] = useState(false);
  const [isAccrualDrawerOpen, setIsAccrualDrawerOpen] = useState(false);
  const [isResettingPublicHolidays, setIsResettingPublicHolidays] = useState(false);
  const [isResettingCarryOver, setIsResettingCarryOver] = useState(false);
  const [isResettingProration, setIsResettingProration] = useState(false);
  const [isResettingAccrual, setIsResettingAccrual] = useState(false);
  const [isResettingTenure, setIsResettingTenure] = useState(false);
  const [showMessage] = useMessage();
  const isHourly = isHourlyPolicy(absencePolicy);
  const isUnlimited = isUnlimitedPolicy(absencePolicy);
  const unit = useMemo(() => isHourly ? "hour" : "day", [isHourly]);
  const showPublicHolidaySection = absencePolicy.publicHolidayAutobook || absencePolicy.publicHolidayOnTop;
  const showProratingSection = !isUnlimited && (absencePolicy.proratingFte || absencePolicy.proratingStartDate);
  const showCarryOverSection = absencePolicy.carryOverEnabled && !isUnlimited;
  const showRoundingSection = showProratingSection && absencePolicy.allowanceRoundingType;
  const showTenureSection = absencePolicy.tenureEnabled && !isUnlimited;
  const showAccrualSection = absencePolicy.accrualUnit !== null && absencePolicy.accrualUnitsNo && !isUnlimited;
  const carryOverExpiryDate = getCarryOverExpirationDate(absencePolicy);
  const resetPublicHolidays = useCallback(() => __async(void 0, null, function* () {
    setIsResettingPublicHolidays(true);
    try {
      yield AbsenceAPI.updateAbsencePolicyPublicHolidays(absencePolicy.id, {
        publicHolidayAutobook: false,
        publicHolidayOnTop: false,
        holidaysProratingRule: HolidaysProratingRule.FullValueProrated
      });
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
    setIsResettingPublicHolidays(false);
  }), [polyglot, absencePolicy, showMessage, refresh]);
  const resetCarryOver = useCallback(() => __async(void 0, null, function* () {
    setIsResettingCarryOver(true);
    try {
      yield AbsenceAPI.updateAbsencePolicyCarryOver(absencePolicy.id, {
        carryOverEnabled: false,
        carryOverLimit: null,
        carryOverExpirationDay: null,
        carryOverExpirationMonth: null
      });
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
    setIsResettingCarryOver(false);
  }), [polyglot, absencePolicy, showMessage, refresh]);
  const resetProrating = useCallback(() => __async(void 0, null, function* () {
    setIsResettingProration(true);
    try {
      yield Promise.all([
        AbsenceAPI.updateAbsencePolicyProrating(absencePolicy.id, {
          proratingStartDate: false,
          proratingFte: false
        }),
        // Reset PH as well as we cannot prorate holidays if there is no prorating rule set for the main allowance
        AbsenceAPI.updateAbsencePolicyPublicHolidays(absencePolicy.id, {
          publicHolidayAutobook: absencePolicy.publicHolidayAutobook,
          publicHolidayOnTop: absencePolicy.publicHolidayOnTop,
          holidaysProratingRule: HolidaysProratingRule.AllInPeriod
          // Use this option if no prorating is available
        })
      ]);
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
    setIsResettingProration(false);
  }), [polyglot, absencePolicy, showMessage, refresh]);
  const resetAccrual = useCallback(() => __async(void 0, null, function* () {
    setIsResettingAccrual(true);
    try {
      yield AbsenceAPI.updateAbsencePolicyAccrual(absencePolicy.id, {
        accrualUnit: null,
        accrualUnitsNo: null,
        accrualCliff: null,
        accrueUpfront: false
      });
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
    setIsResettingAccrual(false);
  }), [polyglot, absencePolicy, showMessage, refresh]);
  const resetTenure = useCallback(() => __async(void 0, null, function* () {
    setIsResettingTenure(true);
    try {
      yield AbsenceAPI.updateAbsencePolicyTenure(absencePolicy.id, {
        tenureEnabled: false,
        tenureAdditionSettings: null,
        tenureAllocation: null
      });
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
    setIsResettingTenure(false);
  }), [polyglot, absencePolicy, showMessage, refresh]);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AbsencePolicyAllowanceSection.allowance"),
      onEdit: () => setIsEditDrawerOpen(true),
      buttons: [
        showPublicHolidaySection && showProratingSection && showCarryOverSection && showTenureSection || showPublicHolidaySection && isUnlimited ? null : /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: polyglot.t("AbsencePolicyAllowanceSection.addRule"),
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            },
            options: [
              {
                label: polyglot.t("AbsencePolicyAllowanceSection.publicHolidays"),
                handler: () => setIsPHDrawerOpen(true),
                hidden: showPublicHolidaySection
              },
              {
                label: polyglot.t("AbsencePolicyAllowanceSection.prorating"),
                handler: () => setIsProratingDrawerOpen(true),
                hidden: showProratingSection || isUnlimited
              },
              {
                label: polyglot.t("AbsencePolicyAllowanceSection.carryOver"),
                handler: () => setIsCarryOverDrawerOpen(true),
                hidden: showCarryOverSection || isUnlimited
              },
              {
                label: polyglot.t("AbsencePolicyAllowanceSection.tenureBonus"),
                handler: () => setIsTenureDrawerOpen(true),
                hidden: showTenureSection || isUnlimited
              },
              {
                label: polyglot.t("AbsenceModule.accrual"),
                handler: () => setIsAccrualDrawerOpen(true),
                hidden: showAccrualSection || isUnlimited
              }
            ]
          }
        )
      ],
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.type"),
                    value: getPolicyAllowanceTypeLabel(absencePolicy.allowanceType, polyglot)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.allowance"),
                    value: unit === "day" ? polyglot.t("AbsencePolicyAllowanceSection.numOfDays", {
                      smart_count: absencePolicy.allowance
                    }) : polyglot.t("AbsencePolicyAllowanceSection.numOfHours", {
                      smart_count: absencePolicy.allowance
                    }),
                    hidden: absencePolicy.allowance === null
                  }
                ]
              },
              {
                title: polyglot.t("AbsencePolicyAllowanceSection.tenureBonus"),
                hidden: !showTenureSection,
                onEdit: () => setIsTenureDrawerOpen(true),
                buttons: [
                  /* @__PURE__ */ jsx(
                    IconButton,
                    {
                      sx: tableIconButtonSx,
                      onClick: resetTenure,
                      disabled: isResettingTenure,
                      children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, actionIconSize))
                    },
                    "delete-tenure-bonus"
                  )
                ],
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.allocation"),
                    value: absencePolicy.tenureAllocation ? translateAbsencePolicyTenureAllocation(absencePolicy.tenureAllocation, polyglot) : "",
                    hidden: !absencePolicy.tenureAllocation
                  },
                  ...Object.keys((_a = absencePolicy.tenureAdditionSettings) != null ? _a : {}).map((key, index) => {
                    var _a2;
                    let totalAllowanceForThisRule = (_a2 = absencePolicy.allowance) != null ? _a2 : 0;
                    for (const localKey of Object.keys(absencePolicy.tenureAdditionSettings))
                      if (Number(localKey) <= Number(key))
                        totalAllowanceForThisRule += absencePolicy.tenureAdditionSettings[Number(localKey)];
                    return {
                      type: SectionItemType.Pair,
                      label: `Rule ${index + 1}`,
                      value: polyglot.t("AbsencePolicyAllowanceSection.addExtraUnits", {
                        noOfYears: polyglot.t("AbsencePolicyAllowanceSection.noOfMonths", { smart_count: Number(key) }),
                        noOfUnits: isHourly ? polyglot.t("AbsencePolicyAllowanceSection.noOfHours", {
                          smart_count: absencePolicy.tenureAdditionSettings[Number(key)]
                        }) : polyglot.t("AbsencePolicyAllowanceSection.noOfDays", {
                          smart_count: absencePolicy.tenureAdditionSettings[Number(key)]
                        }),
                        total: isHourly ? polyglot.t("AbsencePolicyAllowanceSection.noOfHours", {
                          smart_count: totalAllowanceForThisRule
                        }) : polyglot.t("AbsencePolicyAllowanceSection.noOfDays", {
                          smart_count: totalAllowanceForThisRule
                        })
                      }),
                      hidden: !absencePolicy.tenureAdditionSettings
                    };
                  })
                ]
              },
              {
                title: polyglot.t("AbsencePolicyAllowanceSection.publicHolidays"),
                onEdit: () => setIsPHDrawerOpen(true),
                buttons: [
                  /* @__PURE__ */ jsx(
                    IconButton,
                    {
                      sx: tableIconButtonSx,
                      onClick: resetPublicHolidays,
                      disabled: isResettingPublicHolidays,
                      children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, actionIconSize))
                    },
                    "delete-ph"
                  )
                ],
                hidden: !showPublicHolidaySection,
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.autobooking"),
                    value: polyglot.t("AbsencePolicyAllowanceSection.enabled"),
                    hidden: !absencePolicy.publicHolidayAutobook
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.addToAllowance"),
                    value: polyglot.t("AbsencePolicyAllowanceSection.enabled"),
                    hidden: !absencePolicy.publicHolidayOnTop
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.publicHolidayAllowance"),
                    value: (_c = (_b = getPolicyHolidaysProratingRuleOption(absencePolicy, polyglot)) == null ? void 0 : _b.label) != null ? _c : "Unknown",
                    hidden: !absencePolicy.publicHolidayOnTop
                  }
                ]
              },
              {
                title: polyglot.t("AbsencePolicyAllowanceSection.carryOver"),
                onEdit: () => setIsCarryOverDrawerOpen(true),
                buttons: [
                  /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: resetCarryOver, disabled: isResettingCarryOver, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, actionIconSize)) }, "delete")
                ],
                hidden: !showCarryOverSection,
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.cap"),
                    value: absencePolicy.carryOverLimit === null ? polyglot.t("AbsencePolicyAllowanceSection.unlimited") : unit === "day" ? polyglot.t("AbsencePolicyAllowanceSection.numOfDays", {
                      smart_count: absencePolicy.carryOverLimit
                    }) : polyglot.t("AbsencePolicyAllowanceSection.numOfHours", {
                      smart_count: absencePolicy.carryOverLimit
                    })
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.expiry"),
                    value: carryOverExpiryDate ? carryOverExpiryDate : polyglot.t("AbsencePolicyAllowanceSection.noExpiry")
                  }
                ]
              },
              {
                title: polyglot.t("AbsencePolicyAllowanceSection.prorating"),
                onEdit: () => setIsProratingDrawerOpen(true),
                buttons: [
                  /* @__PURE__ */ jsx(
                    IconButton,
                    {
                      sx: tableIconButtonSx,
                      onClick: resetProrating,
                      disabled: isResettingProration,
                      children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, actionIconSize))
                    },
                    "delete-prorating"
                  )
                ],
                hidden: !showProratingSection,
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.method"),
                    value: getAbsenceProratingMethod(absencePolicy, polyglot).value
                  }
                ]
              },
              {
                title: polyglot.t("AbsencePolicyAllowanceSection.rounding"),
                onEdit: () => setIsRoundingDrawerOpen(true),
                hidden: !showRoundingSection,
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyAllowanceSection.method"),
                    value: getAbsenceRoundingMethod(absencePolicy, polyglot)
                  }
                ]
              },
              {
                title: polyglot.t("AbsenceModule.accrual"),
                onEdit: () => setIsAccrualDrawerOpen(true),
                buttons: [
                  /* @__PURE__ */ jsx(
                    IconButton,
                    {
                      sx: tableIconButtonSx,
                      onClick: resetAccrual,
                      disabled: isResettingAccrual,
                      children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, actionIconSize))
                    },
                    "delete-accrual"
                  )
                ],
                hidden: !showAccrualSection,
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsenceModule.accrualFrequency"),
                    value: getAccrualFrequency(absencePolicy, polyglot)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsenceModule.accrueUpfront"),
                    value: absencePolicy.accrueUpfront ? polyglot.t("General.enabled") : polyglot.t("General.disabled")
                  },
                  ...absencePolicy.accrualCliff ? [
                    {
                      type: SectionItemType.Pair,
                      label: polyglot.t("AbsenceModule.accrualCliff"),
                      value: absencePolicy.accrualCliff ? translateAbsencePolicyAccrualCliff(absencePolicy.accrualCliff, polyglot) : ""
                    }
                  ] : []
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyAllowanceEditDrawer,
          {
            isOpen: isEditDrawerOpen,
            setIsOpen: setIsEditDrawerOpen,
            absencePolicy,
            refresh
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyPublicHolidaysEditDrawer,
          {
            isOpen: isPHDrawerOpen,
            setIsOpen: setIsPHDrawerOpen,
            absencePolicy,
            refresh
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyProratingEditDrawer,
          {
            isOpen: isProratingDrawerOpen,
            setIsOpen: setIsProratingDrawerOpen,
            absencePolicy,
            refresh
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyRoundingEditDrawer,
          {
            isOpen: isRoundingDrawerOpen,
            setIsOpen: setIsRoundingDrawerOpen,
            absencePolicy,
            refresh
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyCarryOverEditDrawer,
          {
            isOpen: isCarryOverDrawerOpen,
            setIsOpen: setIsCarryOverDrawerOpen,
            absencePolicy,
            refresh
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyTenureEditDrawer,
          {
            isOpen: isTenureDrawerOpen,
            setIsOpen: setIsTenureDrawerOpen,
            absencePolicy,
            refresh
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyAccrualEditDrawer,
          {
            isOpen: isAccrualDrawerOpen,
            setIsOpen: setIsAccrualDrawerOpen,
            absencePolicy,
            refresh
          }
        )
      ]
    }
  );
};
