"use strict";
import { add as addDate, endOfDay, isAfter, isBefore, isWithinInterval, startOfDay } from "date-fns";
import {
  PayScheduleEnum,
  SalaryBasisEnum
} from "@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto";
import { sum } from "@/v2/util/array.util";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
export const UK_TAX_YEAR_START = "04-06";
function startDateAtYear(year) {
  return startOfDay(new Date(Date.UTC(year, 3, 6)));
}
function endDateAtYear(year) {
  return endOfDay(new Date(Date.UTC(year + 1, 3, 5)));
}
function cutoffDateAtYear(year) {
  return startOfDay(new Date(Date.UTC(year, 4, 25)));
}
export function getUKTaxYear(date = /* @__PURE__ */ new Date()) {
  let currentYear = date.getFullYear();
  if (isBefore(date, startDateAtYear(currentYear))) {
    currentYear -= 1;
  } else if (isAfter(date, endDateAtYear(currentYear + 1))) {
    currentYear += 1;
  }
  return {
    start: startDateAtYear(currentYear),
    end: endDateAtYear(currentYear),
    cutOff: cutoffDateAtYear(currentYear)
  };
}
export function getUKTaxYearStartDate(relativeDate) {
  const date = relativeDate != null ? relativeDate : todaysDateShortISOString();
  let year = Number(date.slice(0, 4));
  if (date.slice(5) < UK_TAX_YEAR_START) {
    year -= 1;
  }
  return `${year}-${UK_TAX_YEAR_START}`;
}
export function showTaxYear(y) {
  return `Year${y.start.getFullYear()}`;
}
function isCurrentUKTaxYear(date) {
  const { start, end } = getUKTaxYear(/* @__PURE__ */ new Date());
  return isWithinInterval(date, { start, end });
}
export function getNewEmployeeTaxCodeNoP45(starterDeclaration) {
  switch (starterDeclaration) {
    case "A":
      return { taxCode: "1257L", starterDeclaration: "A", week1Month1: false };
    case "B":
      return { taxCode: "1257L", starterDeclaration: "B", week1Month1: true };
    case "C":
      return { taxCode: "BR", starterDeclaration: "C", week1Month1: false };
    default:
      throw new Error("Invalid starter declaration");
  }
}
function getTaxCodeFromPrevious(previousTaxCode) {
  const lastChar = previousTaxCode.charAt(previousTaxCode.length - 1);
  let taxCodeDigits = 0;
  try {
    taxCodeDigits = Number(previousTaxCode.substring(0, previousTaxCode.length - 1));
  } catch (e) {
    throw new Error("Invalid tax code, should be {Number}{Letter}; ie.: 123L");
  }
  if (lastChar === "L") taxCodeDigits += 7;
  if (lastChar === "M") taxCodeDigits += 8;
  if (lastChar === "N") taxCodeDigits += 6;
  return `${taxCodeDigits}${lastChar}`;
}
export function getNewEmployeeTaxCodeWithP45(startDate, leaveDate, previousTaxCode) {
  if (["BR", "0T", "D0", "D1"].includes(previousTaxCode)) {
    return { taxCode: previousTaxCode, starterDeclaration: "C", week1Month1: false };
  }
  if (isCurrentUKTaxYear(leaveDate)) {
    return { taxCode: previousTaxCode, starterDeclaration: "B", week1Month1: false };
  }
  const startTaxYear = getUKTaxYear(startDate);
  if (isAfter(startDate, startTaxYear.cutOff)) {
    return { taxCode: "1257L", starterDeclaration: "B", week1Month1: false };
  }
  const lastChar = previousTaxCode.charAt(previousTaxCode.length - 1);
  if (!["L", "M", "N"].includes(lastChar)) {
    return { taxCode: previousTaxCode, starterDeclaration: "B", week1Month1: false };
  }
  const taxCode = getTaxCodeFromPrevious(previousTaxCode);
  return { taxCode, starterDeclaration: "B", week1Month1: false };
}
export var PayrollStatusLabel = /* @__PURE__ */ ((PayrollStatusLabel2) => {
  PayrollStatusLabel2["Current"] = "Current";
  PayrollStatusLabel2["NewJoiner"] = "New joiner";
  PayrollStatusLabel2["Leaver"] = "Leaver";
  PayrollStatusLabel2["NotInPayroll"] = "Not in payroll";
  return PayrollStatusLabel2;
})(PayrollStatusLabel || {});
export const IconMapping = {
  ["Current" /* Current */]: "status-current",
  ["Leaver" /* Leaver */]: "status-leaver",
  ["Not in payroll" /* NotInPayroll */]: "not-in-payroll",
  ["New joiner" /* NewJoiner */]: "status-new"
};
export const getUserStatusFromUserPayrollForTableEntry = (payrollUser, payrun) => {
  if (!payrollUser.inPayroll)
    return {
      label: "Not in payroll" /* NotInPayroll */,
      icon: IconMapping["Not in payroll" /* NotInPayroll */]
    };
  const payrunStartDate = payrun.startDate;
  const payrunEndDate = payrun.endDate;
  const { startDate, leaveDate } = payrollUser.user;
  if (startDate && startDate >= payrunStartDate && startDate <= payrunEndDate)
    return { label: "New joiner" /* NewJoiner */, icon: IconMapping["New joiner" /* NewJoiner */] };
  if (leaveDate && leaveDate >= payrunStartDate && leaveDate <= payrunEndDate)
    return { label: "Leaver" /* Leaver */, icon: IconMapping["Leaver" /* Leaver */] };
  return { label: "Current" /* Current */, icon: IconMapping["Current" /* Current */] };
};
export const getUserStatusFromPayrunEntry = (payrunEntry) => {
  if (payrunEntry === "not-in-payroll") {
    return { label: "Not in payroll" /* NotInPayroll */, icon: IconMapping["Not in payroll" /* NotInPayroll */] };
  }
  const {
    startDate: payrunStartDate,
    endDate: payrunEndDate,
    employmentDetails: { starterDetails, leaverDetails }
  } = payrunEntry;
  if (leaverDetails == null ? void 0 : leaverDetails.hasLeft) {
    const { leaveDate } = leaverDetails;
    if (!leaveDate || leaveDate <= payrunEndDate) {
      return { label: "Leaver" /* Leaver */, icon: IconMapping["Leaver" /* Leaver */] };
    }
  }
  if ((starterDetails == null ? void 0 : starterDetails.startDate) && (starterDetails == null ? void 0 : starterDetails.startDate) >= payrunStartDate) {
    return { label: "New joiner" /* NewJoiner */, icon: IconMapping["New joiner" /* NewJoiner */] };
  }
  return { label: "Current" /* Current */, icon: IconMapping["Current" /* Current */] };
};
export function nextPayrunPeriod(payrun) {
  return {
    payPeriod: payrun.payPeriod,
    endDate: addDate(new Date(payrun.endDate), {
      weeks: payrun.payPeriod === "Weekly" ? 1 : 0,
      months: payrun.payPeriod === "Monthly" ? 1 : 0
    }).toISOString().slice(0, 10)
  };
}
export const HMRCPlaceholderData = {
  officeNumber: "000",
  payeReference: "00000000",
  accountsOfficeReference: "120PM02234138",
  // this must always be a valid value
  smallEmployersRelief: false,
  govGatewayId: "000000000000",
  contactFirstName: "Forename",
  contactLastName: "Surname",
  contactEmail: "user@company.com",
  password: ""
};
function extractHMRCParameters(details) {
  let officeNumber, payeReference, accountsOfficeReference;
  switch (details.kind) {
    case "employer":
      ({ officeNumber, payeReference, accountsOfficeReference } = details);
      break;
    case "fps":
      officeNumber = details.officeNo;
      payeReference = details.payeRef;
      accountsOfficeReference = details.aoRef;
      break;
  }
  return { officeNumber, payeReference, accountsOfficeReference };
}
export function isHMRCSetup(details) {
  if (!details) return false;
  const { officeNumber, payeReference, accountsOfficeReference } = extractHMRCParameters(details);
  return !!officeNumber && !!payeReference && !!accountsOfficeReference && !isUsingPlaceholderHMRCData(details);
}
export function isUsingPlaceholderHMRCData(details) {
  if (!details) return false;
  const { officeNumber, payeReference, accountsOfficeReference } = extractHMRCParameters(details);
  return officeNumber === HMRCPlaceholderData.officeNumber && payeReference === HMRCPlaceholderData.payeReference && accountsOfficeReference === HMRCPlaceholderData.accountsOfficeReference;
}
export const ignoredPayLines = /* @__PURE__ */ new Set([
  "PENSION",
  "PENSIONRAS",
  "PENSIONSS",
  "PENSIONCONTRIB",
  "EMPLOYEEPENCONTROL",
  "EMPLOYERPENCONTROL",
  "STLOAN",
  "PGLOAN",
  "NIC",
  "NIER",
  "EMPLYRNIC",
  "PAYE",
  // BASICDAILY and BASICHOURLY are not editable - BASIC should always be used instead
  "BASICDAILY",
  "BASICHOURLY"
]);
export function extractPayLineEntriesFromPayRunEntry(payrunEntry, payCodes, kind) {
  const recurringPaylines = [...payrunEntry.recurringPaylines];
  const extractRecurringPayline = (payline) => {
    const recurranceIdx = recurringPaylines.findIndex(
      ({ amount, code, description }) => payline.code === code && payline.value === amount && payline.description === description
    );
    return recurranceIdx >= 0 ? recurringPaylines.splice(recurranceIdx, 1)[0] : void 0;
  };
  const isDeduction = kind === "deduction";
  return payrunEntry.payOptions.regularPayLines.filter((payline) => !ignoredPayLines.has(payline.code)).filter((payline) => {
    var _a;
    return ((_a = payCodes.find((payCode) => payCode.code === payline.code)) == null ? void 0 : _a.isDeduction) === isDeduction;
  }).filter((payline) => !payline.isAutoGeneratedBasicPayLine).map((payline) => {
    var _a, _b;
    const recurrance = extractRecurringPayline(payline);
    return {
      id: payline.childId,
      code: payline.code,
      amount: payline.value,
      description: (_a = payline.description) != null ? _a : "",
      isDeduction,
      recurringId: (_b = recurrance == null ? void 0 : recurrance.id) != null ? _b : null,
      recurring: recurrance ? { startDate: recurrance.startDate, endDate: recurrance.endDate } : null
    };
  });
}
export const getOptionalPayCodesInUse = (payCodes, payrunEntries, deduction) => {
  return payCodes.filter(
    ({ code, isDeduction }) => isDeduction === deduction && // ignore codes that are handled using existing 'totals' fields
    !ignoredPayLines.has(code) && payrunEntries.some(
      (entry) => entry.payOptions.regularPayLines.some((pl) => pl.code === code && !pl.isAutoGeneratedBasicPayLine)
    )
  );
};
export const calcPaycodeTotalForPayrunEntry = (item, paycode) => {
  return sum(
    item.payOptions.regularPayLines.filter((pl) => pl.code === paycode && !pl.isAutoGeneratedBasicPayLine),
    (payline) => payline.value
  );
};
export const payScheduleUnit = (payScheduleValue) => {
  const mapping = {
    [PayScheduleEnum.Monthly]: "month",
    [PayScheduleEnum.Weekly]: "week"
  };
  return mapping[payScheduleValue];
};
export const salaryBasisQuantity = (salaryBasisValue) => {
  const mapping = {
    [SalaryBasisEnum.Annual]: "years",
    [SalaryBasisEnum.Monthly]: "months",
    [SalaryBasisEnum.Weekly]: "weeks",
    [SalaryBasisEnum.Daily]: "days",
    [SalaryBasisEnum.Hourly]: "hours"
  };
  return mapping[salaryBasisValue];
};
