"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import * as datefns from "date-fns";
import { identity } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { EquityAPI } from "@/api-client/equity.api";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { useProfileFormUpdate } from "@/v2/feature/user/context/user-profile.context";
import {
  FieldStructure,
  ProfileCard
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { MissingInformationLabel } from "@/v2/feature/user/features/user-profile/details/components/missing-information-label.component";
import { ShowHideButton } from "@/v2/feature/user/features/user-profile/details/components/show-hide-button.component";
import {
  cardHeaderSx,
  cardMaxWidth,
  definitionListSx,
  definitionValueSx,
  showTableSx,
  tablecardSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { EquityForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-equity-form.component";
import { translateequityTypeOptions } from "@/v2/infrastructure/i18n/translate.util";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { createShortDateAsUTC, timeAgo } from "@/v2/util/date-format.util";
const iconSize = { width: 14, height: 14 };
const EquityTableAllowedColumns = ["actions", "percentageVested"];
const calculateFirstVestingCliff = (vestingCliffList) => {
  const sortedVesting = vestingCliffList.filter((x) => !!x.vestingCliffDate).map((x) => x.vestingCliffDate).sort((a, b) => {
    if (!a) return -1;
    if (!b) return 1;
    return new Date(a).getTime() - new Date(b).getTime();
  });
  if (sortedVesting.length === 0) return "";
  if (sortedVesting[0] === null) return "";
  if (datefns.isValid(new Date(sortedVesting[0])) && !timeAgo(sortedVesting[0])) return "";
  else if (timeAgo(sortedVesting[0])) {
    return timeAgo(sortedVesting[0]) || "";
  }
  return "";
};
const getRemainingVestingList = (vestingCliffList) => {
  return vestingCliffList.map((x) => x.vestingCliffDate).filter((y) => !!y && datefns.isBefore(/* @__PURE__ */ new Date(), datefns.parseISO(new Date(y).toISOString())));
};
const calculateVestingCompleted = (remainingVestingList) => {
  if (!remainingVestingList.length) return "";
  const vestingCompleted = new Date(Math.max(...remainingVestingList.map((a) => new Date(a).getTime())));
  if (datefns.isValid(new Date(vestingCompleted)) && !timeAgo(vestingCompleted.toISOString())) return "";
  else if (timeAgo(vestingCompleted.toISOString())) return timeAgo(vestingCompleted.toISOString()) || "";
  else return "";
};
const getVestingCliffList = RA.map(
  (eachVesting) => ({
    vestingStart: eachVesting.vestingStart ? new Date(eachVesting.vestingStart) : null,
    vestingCliffDate: eachVesting.vestingStart ? createShortDateAsUTC(datefns.add(new Date(eachVesting.vestingStart), { months: eachVesting.vestingCliff })) : null,
    id: eachVesting.id,
    vestingMonths: eachVesting.vestingCliff,
    type: eachVesting.type
  })
);
const getEachVestingComplete = (vestingCliff) => {
  var _a, _b;
  if (!(vestingCliff == null ? void 0 : vestingCliff.vestingStart)) return 0;
  const daysPassed = Math.abs(datefns.differenceInDays(new Date(vestingCliff == null ? void 0 : vestingCliff.vestingStart), /* @__PURE__ */ new Date()));
  const totalDays = ((_a = vestingCliff == null ? void 0 : vestingCliff.vestingPeriod) != null ? _a : 0) * 30;
  const cliffDays = ((_b = vestingCliff == null ? void 0 : vestingCliff.vestingCliff) != null ? _b : 0) * 30;
  const vestEndDate = datefns.addDays(new Date(vestingCliff == null ? void 0 : vestingCliff.vestingStart), totalDays);
  if (daysPassed < cliffDays) return 0;
  if (datefns.isBefore(vestEndDate, /* @__PURE__ */ new Date())) {
    return 100;
  }
  if (totalDays === 0) return 100;
  else {
    const remainingAmount = daysPassed / totalDays * vestingCliff.amount;
    return remainingAmount / vestingCliff.amount * 100;
  }
};
const getTotalVestingComplete = (userEquity) => {
  if (userEquity.length < 1) return 0;
  else {
    const totalAmount = userEquity.reduce((sum, b) => sum + b.amount, 0);
    const weightedSum = userEquity.reduce((sum, b) => sum + getEachVestingComplete(b) / 100 * b.amount, 0);
    return weightedSum / totalAmount * 100;
  }
};
const getCompletionDate = (start, days) => {
  return datefns.addDays(start, days);
};
const getMaxCompletionDate = (userEquity) => {
  const completionDates = userEquity.filter((entry) => !!entry.vestingStart).map((entry) => {
    var _a;
    return getCompletionDate(new Date(entry.vestingStart), ((_a = entry.vestingPeriod) != null ? _a : 0) * 30);
  });
  return datefns.max(completionDates);
};
const SCOPE = "user.equity:all";
var EquityTypeOptionsStatus = /* @__PURE__ */ ((EquityTypeOptionsStatus2) => {
  EquityTypeOptionsStatus2["Share"] = "Share";
  EquityTypeOptionsStatus2["Option"] = "Option";
  EquityTypeOptionsStatus2["RSU"] = "RSU";
  return EquityTypeOptionsStatus2;
})(EquityTypeOptionsStatus || {});
var CurrencyOptionsStatus = /* @__PURE__ */ ((CurrencyOptionsStatus2) => {
  CurrencyOptionsStatus2["GBP"] = "GBP";
  CurrencyOptionsStatus2["USD"] = "USD";
  CurrencyOptionsStatus2["EUR"] = "EUR";
  return CurrencyOptionsStatus2;
})(CurrencyOptionsStatus || {});
const numberFormatter = Intl.NumberFormat("en-US");
export const UserEquityForm = ({ userId, handleSubmit = () => {
} }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowModalMode, setRowModalMode] = useState("add");
  const [rawEquityList, setRawEquityList] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const setFormCurrentlyEditing = useProfileFormUpdate();
  const defaultBlankModalValues = {
    id: 0,
    grantDate: void 0,
    type: "Share" /* Share */,
    amount: 0,
    vestingStart: void 0,
    vestingPeriod: 0,
    vestingCliff: 0,
    unitPrice: 0,
    currency: "GBP" /* GBP */,
    reason: ""
  };
  const [modalInitialValues, setModalInitialValues] = useState(defaultBlankModalValues);
  const refreshUserEquityData = useCallback(() => __async(void 0, null, function* () {
    if (!userId) return;
    setLoading(true);
    try {
      const userEquity = yield EquityAPI.getByUserId(userId);
      setEmpty(userEquity && userEquity.length < 1);
      setLoading(false);
      setRawEquityList(userEquity);
    } catch (error) {
      setLoading(false);
      showMessage(`${polyglot.t("UserEquityForm.errorMessages.load")}: ${error}`, "error");
    }
  }), [polyglot, userId, showMessage]);
  useEffect(() => {
    refreshUserEquityData();
  }, [refreshUserEquityData]);
  const editEquityRow = useCallback((rowData) => {
    setModalInitialValues(rowData);
    setIsModalOpen(true);
  }, []);
  const addEquityRow = () => {
    setModalInitialValues(defaultBlankModalValues);
    setRowModalMode("add");
    setIsModalOpen(true);
  };
  const columnData = useMemo(() => {
    const keysAllowed = rawEquityList && rawEquityList[0] ? new Set(Object.keys(rawEquityList[0])) : null;
    return [
      {
        header: () => polyglot.t("UserEquityForm.grantDate"),
        accessorFn: identity,
        id: "grantDate",
        enableSorting: false,
        size: 100,
        cell: ({
          row: {
            original: { grantDate }
          }
        }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 0.5 }, children: grantDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: grantDate }) : "-" })
      },
      {
        header: () => polyglot.t("UserEquityForm.type"),
        accessorFn: identity,
        id: "type",
        size: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx("div", { children: translateequityTypeOptions((_a2 = original.type) != null ? _a2 : "", polyglot) });
        }
      },
      {
        header: () => polyglot.t("UserEquityForm.amount"),
        accessorFn: identity,
        id: "amount",
        size: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.amount ? `${numberFormatter.format(original.amount)}` : "" })
      },
      {
        header: () => polyglot.t("UserEquityForm.vestingStart"),
        accessorFn: identity,
        id: "vestingStart",
        enableSorting: false,
        size: 100,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.vestingStart ? /* @__PURE__ */ jsx(DateLabelComponent, { date: original.vestingStart }) : "" })
      },
      {
        header: () => polyglot.t("UserEquityForm.vestingCliff"),
        accessorFn: identity,
        id: "vestingCliff",
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.vestingCliff ? `${original.vestingCliff} months` : "" })
      },
      {
        header: () => polyglot.t("UserEquityForm.vestingPeriod"),
        accessorFn: identity,
        id: "vestingPeriod",
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.vestingPeriod ? `${original.vestingPeriod} months` : "" })
      },
      {
        header: () => polyglot.t("UserEquityForm.exercisePrice"),
        accessorFn: identity,
        id: "unitPrice",
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.unitPrice ? original.unitPrice : "" })
      },
      {
        header: () => polyglot.t("UserEquityForm.percentageVested"),
        accessorFn: identity,
        id: "percentageVested",
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsxs("div", { children: [
            original ? (_a2 = getEachVestingComplete(original)) == null ? void 0 : _a2.toFixed(2) : "",
            "%"
          ] });
        }
      },
      {
        header: () => polyglot.t("UserEquityForm.reason"),
        accessorFn: identity,
        id: "reason",
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.reason ? original.reason : "" })
      },
      {
        header: () => polyglot.t("UserEquityForm.changedBy"),
        accessorFn: identity,
        id: "updatedBy",
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => original.updatedBy ? /* @__PURE__ */ jsx(UserCell, { userId: original.updatedBy }) : ""
      },
      {
        header: () => "",
        accessorFn: identity,
        id: "actions",
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: "edit",
            onClick: () => {
              setRowModalMode("edit");
              editEquityRow(original);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }) })
      }
    ].filter(
      (col) => col.id && EquityTableAllowedColumns.includes(col.id) || !keysAllowed || col.id && keysAllowed.has(col.id)
    );
  }, [rawEquityList, polyglot, scopesContext, editEquityRow]);
  const equity = useMemo(() => {
    if (rawEquityList.length > 0) {
      const totalEquityAmount = rawEquityList.reduce((total, obj) => obj.amount + total, 0);
      const vestingCliffList = getVestingCliffList(rawEquityList);
      const firstVestingCliff = calculateFirstVestingCliff(vestingCliffList);
      const remainingVestingCliff = getRemainingVestingList(vestingCliffList);
      const vestingCompleted = calculateVestingCompleted(remainingVestingCliff);
      const allGrantTypes = Array.from(new Set(vestingCliffList.map((a) => a.type))).join(", ");
      const totalVestingComplete = getTotalVestingComplete(rawEquityList);
      const completionDate = getMaxCompletionDate(rawEquityList);
      return {
        totalEquityAmount,
        vestingCliffList,
        firstVestingCliff,
        remainingVestingCliff,
        vestingCompleted,
        allGrantTypes,
        totalVestingComplete,
        completionDate
      };
    } else return null;
  }, [rawEquityList]);
  return /* @__PURE__ */ jsxs(ProfileCard, { fieldStubs: ["equity.type", "equity.amount", "equity.vestingPeriod"], sx: tablecardSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, cardMaxWidth), children: [
      /* @__PURE__ */ jsxs(Box, { component: "header", sx: cardHeaderSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "h3", sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("UserEquityForm.equity") }),
        /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(Button, { onClick: () => addEquityRow(), sx: secondaryCTABtn, children: polyglot.t("UserEquityForm.new") }, "add-leave") })
      ] }),
      /* @__PURE__ */ jsx(Box, { component: "section", children: isEmpty ? /* @__PURE__ */ jsx(MissingInformationLabel, {}) : /* @__PURE__ */ jsx(Box, { children: equity && /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserEquityForm.grantTypes"),
            fieldValue: translateequityTypeOptions((_a = equity.allGrantTypes) != null ? _a : "", polyglot),
            fieldStub: "equity.type"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserEquityForm.totalEquity"),
            fieldValue: (_b = numberFormatter.format(equity.totalEquityAmount)) != null ? _b : "",
            fieldStub: "equity.amount"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserEquityForm.firstVestingCliff"),
            fieldValue: equity.firstVestingCliff,
            fieldStub: "equity.vestingPeriod"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserEquityForm.vestingCompletionDate"),
            fieldValue: equity.completionDate && /* @__PURE__ */ jsx(DateLabelComponent, { date: equity.completionDate, titleSx: definitionValueSx }),
            fieldStub: "equity.vestingStart"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserEquityForm.percentageVested"),
            fieldValue: `${equity.totalVestingComplete.toFixed(2)}%`,
            fieldStub: "equity.amount"
          }
        )
      ] }) }) })
    ] }),
    !isEmpty && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(ShowHideButton, { showDetails, setShowDetails }) }),
      showDetails && /* @__PURE__ */ jsx(Box, { sx: showTableSx, children: /* @__PURE__ */ jsx(BasicTable, { rowData: [...rawEquityList], columnData }) })
    ] }),
    /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, children: /* @__PURE__ */ jsx(
      EquityForm,
      {
        initialValues: modalInitialValues,
        userId,
        refreshUserEquityData,
        rowModalMode,
        handleSubmit,
        setFormCurrentlyEditing,
        setIsModalOpen,
        setLoading,
        loading,
        onClose: () => setIsModalOpen(false)
      }
    ) }) })
  ] });
};
