"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { FixedSizeList as List } from "react-window";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as CleanGrey } from "@/images/fields/CleanGrey.svg";
import { nestErrorMessage } from "@/lib/errors";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import {
  ReviewerTypes
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ParticipantsAddReviewerModal = ({
  reviewCycle,
  selectedReviewee,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    ParticipantsAddReviewerContent,
    {
      reviewCycle,
      onClose,
      refresh,
      selectedReviewee
    }
  ) });
};
const useReviewerSelectForm = (selectedReviewee, reviewCycle, onClose, refresh, setLoading) => {
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      peerIds: [],
      upwardIds: [],
      managerIds: []
    },
    validationSchema: yup.object({
      peerIds: yup.array().of(yup.number()).required(),
      upwardIds: yup.array().of(yup.number()).required(),
      managerIds: yup.array().of(yup.number()).required()
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      if (!reviewCycle || !selectedReviewee) {
        showMessage("Review cycle or selected reviewee is not defined.", "error");
        return;
      }
      try {
        setLoading(true);
        const { state, id, reviewerSelect } = reviewCycle;
        if ([CycleState.Draft, CycleState.Scheduled].includes(state)) {
          const currentPeerIds = selectedReviewee.peerIds && selectedReviewee.peerIds.length > 0 ? selectedReviewee.peerIds : [];
          const currentUpwardIds = selectedReviewee.upwardIds && selectedReviewee.upwardIds.length > 0 ? selectedReviewee.upwardIds : [];
          const currentManagerIds = selectedReviewee.managerIds && selectedReviewee.managerIds ? selectedReviewee.managerIds : [];
          const updatedPeerIds = values.peerIds && values.peerIds.length > 0 ? [...currentPeerIds, ...values.peerIds] : currentPeerIds;
          const updatedUpwardIds = values.upwardIds && values.upwardIds.length > 0 ? [...currentUpwardIds, ...values.upwardIds] : currentUpwardIds;
          const updatedManagerIds = values.managerIds && values.managerIds.length > 0 ? [...currentManagerIds, ...values.managerIds] : currentManagerIds;
          yield ReviewCycleAPI.addReviewersToPreOngoing(reviewCycle.id, {
            selfId: selectedReviewee.selfId,
            peerIds: updatedPeerIds,
            upwardIds: updatedUpwardIds,
            managerIds: updatedManagerIds
          });
        }
        if (reviewCycle.state === CycleState.Ongoing) {
          const reviewers = reviewerSelect.reduce((acc, r) => {
            let ids = null;
            if (r === ReviewerTypes.Peer && values.peerIds && values.peerIds.length) {
              ids = values.peerIds;
            } else if (r === ReviewerTypes.Manager && values.managerIds && values.managerIds.length) {
              ids = values.managerIds;
            } else if (r === ReviewerTypes.Upward && values.upwardIds && values.upwardIds.length) {
              ids = values.upwardIds;
            }
            if (ids) {
              acc.push({ reviewerType: r, ids });
            }
            return acc;
          }, []);
          yield ReviewCycleAPI.addReviewers(id, {
            reviewers,
            revieweeId: selectedReviewee.selfId
          });
        }
        showMessage("Successfully added new reviewers", "success");
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return formik;
};
const RenderNewRow = ({ index, style, data }) => {
  const reviewerId = data[index];
  const { formik, type } = data.props;
  return /* @__PURE__ */ jsxs(
    Box,
    {
      style,
      sx: {
        paddingY: spacing.p12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      },
      children: [
        /* @__PURE__ */ jsx(UserCell, { userId: reviewerId }),
        /* @__PURE__ */ jsx(
          CleanGrey,
          __spreadProps(__spreadValues({}, iconSize), {
            style: { cursor: "pointer" },
            onClick: () => {
              const filteredIds = formik.values[type].filter((id) => id !== reviewerId);
              formik.setFieldValue(type, filteredIds);
            }
          })
        )
      ]
    }
  );
};
const ParticipantsAddReviewerContent = ({
  reviewCycle,
  onClose,
  refresh,
  selectedReviewee
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const formik = useReviewerSelectForm(selectedReviewee, reviewCycle, onClose, refresh, setLoading);
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Add reviewers" }),
    reviewCycle.state === CycleState.Ongoing && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Newly added participants will receive an invite." }),
    (selectedReviewee == null ? void 0 : selectedReviewee.selfId) && /* @__PURE__ */ jsx(ViewItem, { title: "Participant", value: /* @__PURE__ */ jsx(UserCell, { userId: selectedReviewee == null ? void 0 : selectedReviewee.selfId }) }),
    ((_a = reviewCycle.reviewerSelect) == null ? void 0 : _a.includes(ReviewerTypes.Manager)) && /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: "Manager reviewer",
        labelSx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }),
        selectedSpecificButtonLabel: "Add manager reviewer",
        value: (_c = (_b = formik.values) == null ? void 0 : _b.managerIds) != null ? _c : [],
        onChange: (userIds) => {
          formik.setFieldValue("managerIds", userIds);
        },
        fieldSx: __spreadValues({}, spacing.mb20),
        excludeEveryone: true,
        excludeCustomRule: true,
        allInvalidUserIds: [
          ...(_d = selectedReviewee == null ? void 0 : selectedReviewee.managerIds) != null ? _d : [],
          ...(selectedReviewee == null ? void 0 : selectedReviewee.selfId) ? [selectedReviewee.selfId] : []
        ],
        hideUserList: true
      }
    ),
    ((_e = formik.values) == null ? void 0 : _e.managerIds) && ((_f = formik.values) == null ? void 0 : _f.managerIds.length) > 0 && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: "Newly added manager reviewers" }),
      /* @__PURE__ */ jsx(
        List,
        {
          height: ((_g = formik.values) == null ? void 0 : _g.managerIds.length) * 40 > 250 ? 250 : ((_h = formik.values) == null ? void 0 : _h.managerIds.length) * 40,
          itemCount: formik.values.managerIds.length,
          itemSize: 40,
          width: "100%",
          itemData: __spreadProps(__spreadValues({}, formik.values.managerIds), {
            props: {
              formik,
              themeColors,
              spacing,
              iconSize,
              type: "managerIds"
            }
          }),
          children: RenderNewRow
        }
      )
    ] }),
    ((_i = reviewCycle.reviewerSelect) == null ? void 0 : _i.includes(ReviewerTypes.Upward)) && /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: "Upward reviewer",
        labelSx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }),
        selectedSpecificButtonLabel: "Add upward reviewer",
        value: (_k = (_j = formik.values) == null ? void 0 : _j.upwardIds) != null ? _k : [],
        onChange: (userIds) => {
          formik.setFieldValue("upwardIds", userIds);
        },
        fieldSx: __spreadValues({}, spacing.mb20),
        excludeEveryone: true,
        excludeCustomRule: true,
        allInvalidUserIds: [
          ...(_l = selectedReviewee == null ? void 0 : selectedReviewee.upwardIds) != null ? _l : [],
          ...(selectedReviewee == null ? void 0 : selectedReviewee.selfId) ? [selectedReviewee.selfId] : []
        ],
        hideUserList: true
      }
    ),
    ((_m = formik.values) == null ? void 0 : _m.upwardIds) && ((_n = formik.values) == null ? void 0 : _n.upwardIds.length) > 0 && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: "Newly added upward reviewers" }),
      /* @__PURE__ */ jsx(
        List,
        {
          height: ((_o = formik.values) == null ? void 0 : _o.upwardIds.length) * 40 > 250 ? 250 : ((_p = formik.values) == null ? void 0 : _p.upwardIds.length) * 40,
          itemCount: formik.values.upwardIds.length,
          itemSize: 40,
          width: "100%",
          itemData: __spreadProps(__spreadValues({}, formik.values.upwardIds), {
            props: { formik, themeColors, spacing, iconSize, type: "upwardIds" }
          }),
          children: RenderNewRow
        }
      )
    ] }),
    ((_q = reviewCycle.reviewerSelect) == null ? void 0 : _q.includes(ReviewerTypes.Peer)) && !reviewCycle.cycleSettings.allowRevieweesToInvitePeers && /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: "Peer reviewer",
        labelSx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }),
        selectedSpecificButtonLabel: "Add peer reviewer",
        value: (_s = (_r = formik.values) == null ? void 0 : _r.peerIds) != null ? _s : [],
        onChange: (userIds) => {
          formik.setFieldValue("peerIds", userIds);
        },
        fieldSx: __spreadValues({}, spacing.mb20),
        excludeEveryone: true,
        excludeCustomRule: true,
        allInvalidUserIds: [
          ...(_t = selectedReviewee == null ? void 0 : selectedReviewee.peerIds) != null ? _t : [],
          ...(selectedReviewee == null ? void 0 : selectedReviewee.selfId) ? [selectedReviewee.selfId] : []
        ],
        hideUserList: true
      }
    ),
    ((_u = formik.values) == null ? void 0 : _u.peerIds) && ((_v = formik.values) == null ? void 0 : _v.peerIds.length) > 0 && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: "Newly added peer reviewers" }),
      /* @__PURE__ */ jsx(
        List,
        {
          height: ((_w = formik.values) == null ? void 0 : _w.peerIds.length) * 40 > 250 ? 250 : ((_x = formik.values) == null ? void 0 : _x.peerIds.length) * 40,
          itemCount: formik.values.peerIds.length,
          itemSize: 40,
          width: "100%",
          itemData: __spreadProps(__spreadValues({}, formik.values.peerIds), {
            props: { formik, themeColors, spacing, iconSize, type: "peerIds" }
          }),
          children: RenderNewRow
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
const ViewItem = ({ title, value }) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g8
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: title }),
        typeof value === "string" ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: value }) : /* @__PURE__ */ jsx(Box, { children: value })
      ]
    }
  );
};
