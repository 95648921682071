"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { RichTextField } from "@v2/components/forms/rich-text/rich-text-field.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const EnrolledDeviceEditDetailsDrawerContent = ({
  device,
  refresh
}) => {
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const initialValues = {
    deviceName: device.deviceName,
    customerNotes: device.customerNotes
  };
  const validationSchema = yup.object({
    deviceName: yup.string().nullable().notRequired(),
    customerNotes: yup.string().nullable().notRequired()
  });
  const updateDeviceTechSpecs = (deviceUpdate) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const update = {
        deviceName: deviceUpdate.deviceName,
        customerNotes: deviceUpdate.customerNotes
      };
      yield DeviceAPI.updateEnrolledDeviceDetailsForm(device.id, update);
      showMessage("Device details successfully updated.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      yield updateDeviceTechSpecs(values);
    }),
    enableReinitialize: true
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Edit" }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Device Name",
        name: "deviceName",
        value: formik.values.deviceName,
        onChange: formik.handleChange,
        error: formik.touched.deviceName && Boolean(formik.errors.deviceName),
        helperText: formik.touched.deviceName && formik.errors.deviceName,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      RichTextField,
      {
        label: "Notes",
        value: formik.values.customerNotes,
        onChange: (value) => {
          if (value === "<p></p>") {
            formik.setFieldValue("customerNotes", null, true);
          } else {
            formik.setFieldValue("customerNotes", value, true);
          }
        }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading, fullWidth: true, colorVariant: "primary", sizeVariant: "medium" }) })
  ] }) });
};
