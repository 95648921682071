"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { GlobalContext } from "@/GlobalState";
import { canAccessScopes } from "@/lib/scopes";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { CycleCreationNotificationsModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-notifications/components/cycle-creation-notifications-modal.component";
import { TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import {
  ReminderFrequencyDict
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewCycleCreationNotificationsPage = ({
  reviewCycle,
  refresh,
  reach
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const isAdmin = canAccessScopes(user, ["reviews:all"]);
  const isManager = canAccessScopes(user, ["reviews:manager"]);
  const isEditable = useMemo(
    () => Boolean(
      reviewCycle && (reviewCycle.state === CycleState.Draft || reviewCycle.state === CycleState.Scheduled || reviewCycle.state === CycleState.Ongoing) && Boolean(isAdmin || isManager && reviewCycle.owner === user.userId)
    ),
    [reviewCycle, isAdmin, isManager, user]
  );
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  const { notificationSettings } = reviewCycle;
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle, reachType: reach }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading: false,
      children: [
        notificationSettings && /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                title: "Notifications",
                onEdit: () => setIsOpen(true),
                hideEdit: !isEditable,
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: "Reminder",
                    value: ReminderFrequencyDict[notificationSettings.reminderFrequency]
                  },
                  {
                    type: SectionItemType.Pair,
                    label: "Invite message",
                    value: "Use default message"
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationNotificationsModal,
          {
            reviewCycle,
            isOpen,
            setIsOpen,
            onClose: () => setIsOpen(false),
            refresh: () => __async(void 0, null, function* () {
              yield refresh == null ? void 0 : refresh();
            })
          }
        )
      ]
    }
  );
};
