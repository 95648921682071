"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { forwardRef } from "react";
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  ListSubheader,
  Paper,
  Popper,
  Typography
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { VariableSizeList } from "react-window";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as ArrowDownACIcon } from "@/images/fields/ArrowDown.svg";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { StyledAuto, StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const LISTBOX_PADDING = 8;
const iconSize = { width: "14px", height: "14px" };
function RenderRow(props) {
  const { polyglot } = usePolyglot();
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = __spreadProps(__spreadValues({}, style), {
    top: style.top + LISTBOX_PADDING
  });
  if (dataSet.hasOwnProperty("group")) {
    return /* @__PURE__ */ jsx(ListSubheader, { component: "div", style: inlineStyle, children: dataSet.group }, dataSet.key);
  }
  const user = dataSet[1];
  const description = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u;
    if (((_a = user == null ? void 0 : user.role) == null ? void 0 : _a.jobTitle) && ((_c = (_b = user == null ? void 0 : user.role) == null ? void 0 : _b.jobTitle) == null ? void 0 : _c.length) > 0 && ((_e = (_d = user == null ? void 0 : user.role) == null ? void 0 : _d.site) == null ? void 0 : _e.name) && ((_h = (_g = (_f = user == null ? void 0 : user.role) == null ? void 0 : _f.site) == null ? void 0 : _g.name) == null ? void 0 : _h.length) > 0) {
      return [polyglot.t((_j = (_i = user == null ? void 0 : user.role) == null ? void 0 : _i.jobTitle) != null ? _j : ""), polyglot.t((_m = (_l = (_k = user == null ? void 0 : user.role) == null ? void 0 : _k.site) == null ? void 0 : _l.name) != null ? _m : "")].join(", ");
    } else {
      if (((_n = user == null ? void 0 : user.role) == null ? void 0 : _n.jobTitle) && ((_p = (_o = user == null ? void 0 : user.role) == null ? void 0 : _o.jobTitle) == null ? void 0 : _p.length) > 0) return polyglot.t((_r = (_q = user == null ? void 0 : user.role) == null ? void 0 : _q.jobTitle) != null ? _r : "");
      else return polyglot.t((_u = (_t = (_s = user == null ? void 0 : user.role) == null ? void 0 : _s.site) == null ? void 0 : _t.name) != null ? _u : "");
    }
  };
  return /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({ component: "li" }, dataSet[0]), { noWrap: true, style: inlineStyle, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "block", minHeight: 20, paddingY: spacing.p5 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(UserAvatar, { userId: user.value, size: "xxsmall" }),
      /* @__PURE__ */ jsx(Box, { sx: { marginLeft: spacing.m10, minHeight: 20 }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: user.label }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { marginLeft: spacing.m30, display: "flex", flexDirection: "column" }, children: /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.captionSmall), {
          marginTop: spacing.m5,
          color: themeColors.Grey,
          textTransform: "capitalize"
        }),
        children: description()
      }
    ) })
  ] }, user.value) }));
}
const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return /* @__PURE__ */ jsx("div", __spreadValues(__spreadValues({ ref }, props), outerProps));
});
function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
const ListboxComponent = React.forwardRef(function ListboxComponent2(props, ref) {
  const _a = props, { children } = _a, other = __objRest(_a, ["children"]);
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...item.children || []);
  });
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 60 : 60;
  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }
    return itemSize;
  };
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };
  const gridRef = useResetCache(itemCount);
  return /* @__PURE__ */ jsx("div", { ref, children: /* @__PURE__ */ jsx(OuterElementContext.Provider, { value: other, children: /* @__PURE__ */ jsx(
    VariableSizeList,
    {
      itemData,
      height: getHeight() + 2 * LISTBOX_PADDING,
      width: "100%",
      ref: gridRef,
      outerElementType: OuterElementType,
      innerElementType: "ul",
      itemSize: (index) => getChildSize(itemData[index]),
      overscanCount: 5,
      itemCount,
      children: RenderRow
    }
  ) }) });
});
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
      '& li.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: "#fff !important"
      }
    }
  }
});
export const SingleUserSelect = forwardRef(
  ({
    name,
    options: optionsOrReach,
    value: selectedUserId,
    onChange,
    label,
    error,
    helperText,
    disabled = false,
    placeholder,
    allValidUserIds,
    allUsers = false
  }, ref) => {
    const { polyglot } = usePolyglot();
    const [globalState] = React.useContext(GlobalContext);
    const { nonTerminatedCachedUsers, cachedUsers } = useCachedUsers();
    const options = React.useMemo(() => {
      const validOptionsSet = allValidUserIds ? new Set(allValidUserIds) : null;
      if (typeof optionsOrReach === "object") {
        if (validOptionsSet) return optionsOrReach.filter((u) => u.userId && validOptionsSet.has(u.userId));
        return optionsOrReach;
      }
      const reach = optionsOrReach != null ? optionsOrReach : "company";
      return {
        user() {
          const user = nonTerminatedCachedUsers.find((u) => u.userId === globalState.user.userId);
          return user ? [__spreadValues({ label: polyglot.t(user.displayName), value: user.userId }, user)] : [];
        },
        team() {
          const reportsAndUs = new Set(globalState.user.reports);
          reportsAndUs.add(globalState.user.userId);
          return nonTerminatedCachedUsers.filter((u) => reportsAndUs.has(u.userId) && (!validOptionsSet || validOptionsSet.has(u.userId))).map((u) => __spreadValues({ label: polyglot.t(u.displayName), value: u.userId }, u));
        },
        company: () => {
          const validFiltered = validOptionsSet ? nonTerminatedCachedUsers.filter((u) => validOptionsSet.has(u.userId)) : allUsers ? cachedUsers : nonTerminatedCachedUsers;
          return validFiltered.map((u) => __spreadValues({ label: polyglot.t(u.displayName), value: u.userId }, u));
        }
      }[reach]();
    }, [
      allValidUserIds,
      optionsOrReach,
      allUsers,
      nonTerminatedCachedUsers,
      cachedUsers,
      polyglot,
      globalState.user.userId,
      globalState.user.reports
    ]);
    const selectedValue = React.useMemo(() => {
      if (!selectedUserId) return { value: void 0, label: "" };
      return options.find(({ value }) => value === selectedUserId);
    }, [options, selectedUserId]);
    return /* @__PURE__ */ jsx(
      Autocomplete,
      {
        ref,
        id: "virtualize-auto",
        disabled,
        options,
        value: selectedValue,
        onChange,
        fullWidth: true,
        disableListWrap: true,
        renderOption: (props, option, state) => {
          return [props, option, state.inputValue];
        },
        PopperComponent: StyledPopper,
        renderInput: (params) => {
          return /* @__PURE__ */ jsx(
            StyledAutoTextfield,
            __spreadProps(__spreadValues({}, params), {
              label,
              placeholder,
              InputLabelProps: { shrink: true },
              fullWidth: true,
              name,
              variant: "standard",
              error,
              helperText,
              InputProps: __spreadProps(__spreadValues({}, params.InputProps), {
                startAdornment: selectedUserId && /* @__PURE__ */ jsx(Box, { sx: { mr: spacing.m5 }, children: /* @__PURE__ */ jsx(UserAvatar, { userId: selectedUserId, size: "xxsmall" }, selectedUserId) })
              })
            })
          );
        },
        ListboxComponent,
        popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, __spreadValues({}, iconSize)),
        clearIcon: /* @__PURE__ */ jsx(
          ClearIcon,
          __spreadProps(__spreadValues({}, iconSize), {
            onClick: () => {
              onChange == null ? void 0 : onChange({}, null, {}, void 0);
            }
          })
        ),
        PaperComponent: ({ children }) => /* @__PURE__ */ jsx(Paper, { sx: StyledAuto, children }),
        filterOptions: (options2, state) => {
          var _a, _b;
          const inputValue = (_b = (_a = state.inputValue) == null ? void 0 : _a.toLowerCase()) != null ? _b : "";
          return options2.filter((o) => o.label && o.label.toLowerCase().includes(inputValue));
        }
      }
    );
  }
);
