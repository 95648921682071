"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { canApproveOrRejectRequest } from "@v2/feature/approval-rule/approval-rule.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { themeColors } from "@v2/styles/colors.styles";
import moment from "moment";
import { ExpenseAPI } from "@/api-client/expense.api";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as UploadDoc } from "@/images/documents/UploadedDoc.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Document.svg";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { PublicImageView } from "@/v2/components/upload/public-image-viewer.component";
import { ExpenseModal } from "@/v2/feature/payments/pages/components/expense-modal.component";
import { ExpenseStatus } from "@/v2/feature/payments/payments.interface";
import { getContractorInvoiceStatusComponent } from "@/v2/feature/payments/utils/get-contractor-invoice-status.util";
import { PaymentCategoryEnum } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { TableColumn } from "@/v2/feature/super-admin/components/helper/table-helper";
import { SelectDeselectIdRows } from "@/v2/feature/task/components/task-table/select-deselect-string-id-rows.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { getDisplayName } from "@/v2/util/string-format.util";
import { truncateWithEllipses } from "@/v2/util/string.util";
const DATE_FORMAT = "DD MMM YYYY";
const TabFilter = (polyglot) => {
  return [
    { name: polyglot.t("PaymentStatusFilter.all"), value: ExpenseStatus.All },
    { name: polyglot.t("PaymentStatusFilter.draft"), value: ExpenseStatus.Draft },
    { name: polyglot.t("PaymentStatusFilter.pending"), value: ExpenseStatus.Pending },
    { name: polyglot.t("PaymentStatusFilter.approved"), value: ExpenseStatus.Approved },
    { name: polyglot.t("PaymentStatusFilter.rejected"), value: ExpenseStatus.Rejected }
  ];
};
export function ExpenseTable({
  expenses,
  refreshData,
  reach,
  selectionModel,
  setSelectionModel,
  loadingExpenses
}) {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const [selectedExpense, setSelectedExpense] = useState(void 0);
  const [openExpenseModal, setOpenExpenseModal] = useState(false);
  const [publicURL, setPublicURL] = useState(void 0);
  const [filterValue, setFilterValue] = useState(
    (_d = (_c = (_b = (_a = state.user.features) == null ? void 0 : _a.expense) == null ? void 0 : _b.table) == null ? void 0 : _c.selectedFilters) != null ? _d : ExpenseStatus.Pending
  );
  const [searchInput, setSearchInput] = useState("");
  const [showMessage] = useMessage();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const currentUserIsAdmin = hasScopes(["expenses:all"], getScopesContext(user));
  const { getCachedUserById } = useCachedUsers();
  const { data: settingsAndAppConfig } = useApiClient(PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId(), {
    suspense: false
  });
  const expenseTypes = useMemo(() => {
    var _a2, _b2;
    return (_b2 = (_a2 = settingsAndAppConfig == null ? void 0 : settingsAndAppConfig.settings) == null ? void 0 : _a2.filter((type) => type.type === PaymentCategoryEnum.EXPENSE)) != null ? _b2 : [];
  }, [settingsAndAppConfig]);
  const isAccountingAppConfigured = settingsAndAppConfig == null ? void 0 : settingsAndAppConfig.accountingAppConfigured;
  const bulkApproveExpenses = () => __async(this, null, function* () {
    try {
      const expenseCount = yield ExpenseAPI.bulkApprove(selectionModel);
      setSelectionModel([]);
      refreshData();
      showMessage(polyglot.t("ExpenseModal.successMessages.bulkApproval", { expenseCount }), "success");
    } catch (error) {
      showMessage(
        polyglot.t("ExpenseModal.errorMessages.bulkApproval", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const filteredExpenses = useMemo(() => {
    if (!expenses) return [];
    let filteredExpenses2 = [...expenses];
    if (filterValue === "Approved") filteredExpenses2 = expenses.filter((i) => i.status === ExpenseStatus.Approved);
    if (filterValue === "Pending") filteredExpenses2 = expenses.filter((i) => i.status === ExpenseStatus.Pending);
    if (filterValue === "Draft") filteredExpenses2 = expenses.filter((i) => i.status === ExpenseStatus.Draft);
    if (filterValue === "Rejected")
      filteredExpenses2 = expenses.filter((i) => [ExpenseStatus.Rejected].includes(i.status));
    if (searchInput) {
      const loweredCaseSearch = searchInput.toLowerCase();
      filteredExpenses2 = filteredExpenses2.filter((i) => {
        var _a2, _b2, _c2;
        const beneficiary = i.from ? getCachedUserById(i.from) : void 0;
        const name = beneficiary ? beneficiary.displayName : "";
        return (name == null ? void 0 : name.toLowerCase().includes(loweredCaseSearch)) || ((_a2 = i.notes) == null ? void 0 : _a2.toLowerCase().includes(loweredCaseSearch)) || ((_b2 = i.amount) == null ? void 0 : _b2.toString().includes(loweredCaseSearch)) || ((_c2 = i.gross) == null ? void 0 : _c2.toString().includes(loweredCaseSearch));
      });
    }
    return filteredExpenses2;
  }, [expenses, filterValue, searchInput, getCachedUserById]);
  const updatedExpenses = useMemo(() => {
    return filteredExpenses;
  }, [filteredExpenses]);
  const handlePreviewClick = useCallback(
    (expense) => __async(this, null, function* () {
      var _a2;
      try {
        if (!expense.attachment || ((_a2 = expense.attachment) == null ? void 0 : _a2.length) === 0) {
          console.error("Invalid receipt attachment");
          return;
        }
        const url = `${state.publicURL}/${expense.attachment}`;
        setOpenPreviewModal(true);
        setPublicURL(url);
      } catch (e) {
        console.error("::URL Download error", e);
        showMessage(`Failed to preview attachment: ${nestErrorMessage(e)}`, "error");
      }
    }),
    [setOpenPreviewModal, setPublicURL, showMessage, state == null ? void 0 : state.publicURL]
  );
  const selectableExpenseIds = useMemo(
    () => new Set(filteredExpenses.filter((e) => canApproveOrRejectRequest(e, user.userId)).map(({ id }) => id)),
    [filteredExpenses, user.userId]
  );
  const expensesColumn = useMemo(
    () => [
      ...selectableExpenseIds.size > 0 ? [
        {
          id: "select",
          enableSorting: false,
          minSize: 20,
          maxSize: 20,
          header: () => {
            const allSelected = selectionModel.length > 0 && selectionModel.length === selectableExpenseIds.size && selectionModel.every((id) => selectableExpenseIds.has(id));
            return /* @__PURE__ */ jsx(Box, { onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: "allSelected",
                checked: allSelected,
                value: "allSelected",
                onChange: (_, checked) => {
                  setSelectionModel(checked ? [...selectableExpenseIds] : []);
                }
              }
            ) });
          },
          cell: ({ row: { original } }) => {
            var _a2, _b2, _c2, _d2;
            return selectableExpenseIds.has(original.id) ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m10 }, children: /* @__PURE__ */ jsx(Box, { onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: (_b2 = (_a2 = original.id) == null ? void 0 : _a2.toString()) != null ? _b2 : "",
                checked: selectionModel.includes(original.id),
                value: (_d2 = (_c2 = original.id) == null ? void 0 : _c2.toString()) != null ? _d2 : "",
                onChange: () => {
                  let finalArray;
                  if (selectionModel == null ? void 0 : selectionModel.includes(original.id)) {
                    finalArray = selectionModel.filter((sm) => sm !== original.id);
                  } else finalArray = [...selectionModel, original.id];
                  setSelectionModel(finalArray);
                }
              }
            ) }) }) : null;
          }
        }
      ] : [],
      ...reach !== "me" ? [
        new TableColumn().define({
          header: polyglot.t("PaymentTableHeaders.beneficiary"),
          id: "createdBy",
          size: 140,
          fieldName: "createdByUser",
          sortingFn: (a, b) => sortString(
            a,
            b,
            (item) => item.from ? getDisplayName(getCachedUserById(item.from)) : ""
          ),
          parseRow: (row) => row.from ? /* @__PURE__ */ jsx(UserCell, { userId: row.from }) : /* @__PURE__ */ jsx(EmptyCell, {})
        })
      ] : [],
      new TableColumn().define({
        header: polyglot.t("ExpenseModal.policy"),
        id: "type",
        size: 150,
        fieldName: "type",
        enableSorting: false,
        parseRow: (row) => {
          var _a2;
          return /* @__PURE__ */ jsx("div", { style: { paddingRight: "10px" }, children: row.typeId ? (_a2 = expenseTypes == null ? void 0 : expenseTypes.find((et) => et.id === row.typeId)) == null ? void 0 : _a2.name : "" });
        }
      }),
      new TableColumn().define({
        header: polyglot.t("PaymentTableHeaders.reference"),
        id: "reference",
        size: 140,
        fieldName: "notes",
        enableSorting: false,
        parseRow: (row) => {
          const truncateLimit = 30;
          const notesTruncated = (row == null ? void 0 : row.notes) && row.notes.length > truncateLimit;
          return !!notesTruncated ? /* @__PURE__ */ jsx(StyledTooltip, { title: row.notes, placement: "right", children: /* @__PURE__ */ jsx("div", { style: { paddingRight: "10px" }, children: truncateWithEllipses(row.notes, truncateLimit) }) }) : /* @__PURE__ */ jsx("div", { style: { paddingRight: "10px" }, children: row.notes });
        }
      }),
      new TableColumn().define({
        header: "",
        id: "attachment",
        size: 30,
        fieldName: "attachment",
        enableSorting: false,
        parseRow: (row) => {
          return /* @__PURE__ */ jsx(
            Box,
            {
              onClick: (event) => {
                event.stopPropagation();
              },
              sx: { width: 20 },
              children: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }, children: row && (row == null ? void 0 : row.attachment) ? /* @__PURE__ */ jsx(
                IconButton,
                {
                  sx: __spreadProps(__spreadValues({}, iconCTAButtonSx), { fill: themeColors.green, background: themeColors.white }),
                  onClick: () => {
                    if (row) {
                      handlePreviewClick(row);
                    }
                  },
                  children: /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize))
                }
              ) : /* @__PURE__ */ jsx(UploadDoc, __spreadValues({}, iconSize)) })
            }
          );
        }
      }),
      new TableColumn().define({
        header: polyglot.t("PaymentTableHeaders.date"),
        id: "date",
        size: 140,
        fieldName: "date",
        sortingFn: (a, b) => sortNumeric(a, b, (item) => new Date(item.date).getTime()),
        formatter: (date) => {
          return /* @__PURE__ */ jsx(Box, { sx: { color: new Date(date).getTime() < Date.now() ? themeColors.RedDark : themeColors.black }, children: moment(date).format(DATE_FORMAT) });
        },
        parseRow: (row) => {
          const showAlert = [ExpenseStatus.Pending].includes(row.status) && new Date(row.dueDate).getTime() < Date.now();
          return /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                color: showAlert ? themeColors.RedDark : themeColors.black
              },
              children: moment(row.date).format(DATE_FORMAT)
            }
          );
        }
      }),
      new TableColumn().define({
        header: polyglot.t("PaymentTableHeaders.status"),
        id: "status",
        size: 140,
        fieldName: "status",
        sortingFn: (a, b) => sortString(a, b, (item) => item.status),
        parseRow: (row) => {
          return /* @__PURE__ */ jsx(Fragment, { children: getContractorInvoiceStatusComponent(
            row.status,
            __spreadValues({}, themeFonts.caption),
            !!isAccountingAppConfigured,
            !!row.externalId
          ) });
        }
      }),
      {
        header: polyglot.t("PaymentTableHeaders.gross"),
        accessorFn: (row) => row,
        id: "gross",
        size: 180,
        fieldName: "gross",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.gross),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { paddingRight: "10px" }, children: formatCurrency(original.gross, void 0, original.currency) })
      }
    ],
    [
      expenseTypes,
      getCachedUserById,
      handlePreviewClick,
      isAccountingAppConfigured,
      polyglot,
      reach,
      selectionModel,
      setSelectionModel,
      selectableExpenseIds
    ]
  );
  const allSelectedExpensesArePending = useMemo(() => {
    const selectedExpenses = filteredExpenses.filter((expense) => selectionModel.includes(expense.id));
    return selectedExpenses.every((eachExpense) => eachExpense.status === ExpenseStatus.Pending);
  }, [filteredExpenses, selectionModel]);
  const getExpenseBulkActionsOptions = () => {
    return [
      {
        icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
        handler: () => bulkApproveExpenses(),
        label: "Approve selected",
        disabled: selectionModel.length === 0 || !allSelectedExpensesArePending
      }
    ];
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { height: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }, children: [
      /* @__PURE__ */ jsx(
        TabFilterButtons,
        {
          filters: TabFilter(polyglot),
          setFilterValue,
          filterValue,
          hasSearch: true,
          onFilterChange: (_0) => __async(this, [_0], function* ({ filterValue: filterValue2, searchInput: searchInput2 }) {
            try {
              setSelectionModel([]);
              setFilterValue(filterValue2);
              setSearchInput(searchInput2);
              const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
                "expense",
                "table",
                "selectedFilters",
                filterValue2
              );
              dispatch({
                type: GlobalStateActions.UPDATE_USER,
                payload: updatedGlobalUser
              });
            } catch (error) {
              showMessage(
                polyglot.t("ExpenseModal.errorMessages.filter", {
                  errorMessage: nestErrorMessage(error)
                }),
                "error"
              );
            }
          })
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "end", gap: spacing.g5, marginLeft: spacing.ml10 }, children: [
        /* @__PURE__ */ jsx(
          SelectDeselectIdRows,
          {
            selectionModel,
            setSelectionModel,
            rows: filteredExpenses,
            hideSelectAll: true
          }
        ),
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: getExpenseBulkActionsOptions(),
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "Actions",
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            }
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: [...updatedExpenses],
        columnData: expensesColumn,
        rowClick: (row) => {
          setSelectedExpense(row.original);
          setOpenExpenseModal(true);
        },
        initialSort: [{ id: "date", desc: true }],
        loading: loadingExpenses
      }
    ) }),
    openExpenseModal && selectedExpense && /* @__PURE__ */ jsx(
      ExpenseModal,
      {
        isOpen: openExpenseModal,
        setIsOpen: setOpenExpenseModal,
        selectedExpense,
        onClose: () => {
          setSelectedExpense(void 0);
          setOpenExpenseModal(false);
        },
        onActionPerformed: () => __async(this, null, function* () {
          refreshData();
        }),
        reach,
        currentUserIsAdmin
      }
    ),
    openPreviewModal && /* @__PURE__ */ jsx(
      PublicImageView,
      {
        publicUrl: publicURL,
        showPreview: openPreviewModal,
        handleClose: () => {
          setOpenPreviewModal(false);
          setPublicURL(void 0);
        }
      }
    )
  ] });
}
