"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Box, Menu, MenuItem } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { capitalize } from "lodash";
import { StyledTooltip } from "./styled-tooltip.component";
import { ButtonComponent } from "../forms/button.component";
import { GlobalContext } from "@/GlobalState";
import { checkScopes } from "@/lib/scopes";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyledMenu = styled((props) => /* @__PURE__ */ jsx(
  Menu,
  __spreadValues({
    elevation: 0,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right"
    }
  }, props)
))(() => ({
  "& .MuiPaper-root": {
    boxShadow: "0px 2px 20px 0px rgba(13, 13, 14, 0.1)",
    textAlign: "left",
    borderRadius: radius.br10,
    "& .MuiMenu-list": __spreadProps(__spreadValues({}, spacing.pad20), {
      gap: "50px"
    }),
    "& .MuiMenuItem-root": __spreadProps(__spreadValues({
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      padding: "0px",
      minHeight: "20px",
      gap: spacing.g10
    }, themeFonts.caption), {
      fill: themeColors.DarkGrey,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        color: themeColors.Grey,
        fill: themeColors.Grey
      }
    })
  }
}));
export const StyledMenuComponent = ({
  options,
  checkOptions,
  actionButtonDetails,
  headerOptions,
  anchorOrigin = { horizontal: "right", vertical: "bottom" },
  transformOrigin = { horizontal: "right", vertical: "top" },
  disabled,
  loading,
  sx,
  selectedCheckbox = [],
  onCheckboxChange,
  closeOnSelect = true,
  onClose = void 0,
  className = void 0
}) => {
  const [state] = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const filteredHeaderOption = useMemo(() => {
    var _a;
    return (_a = headerOptions == null ? void 0 : headerOptions.filter((o) => o.options.length > 0 && !o.hidden)) != null ? _a : [];
  }, [headerOptions]);
  const open = Boolean(anchorEl);
  const getActionButton = (actionButtonDetails2) => {
    var _a, _b, _c, _d, _e;
    if (actionButtonDetails2.type === "iconButton") {
      return /* @__PURE__ */ jsx(StyledTooltip, { title: capitalize(actionButtonDetails2.title), children: /* @__PURE__ */ jsx(
        IconButton,
        {
          id: `btn${actionButtonDetails2.title}`,
          onClick: (event) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
          },
          sizeVariant: (_a = actionButtonDetails2.sizeVariant) != null ? _a : "small",
          colorVariant: (_b = actionButtonDetails2.colorVariant) != null ? _b : "primary",
          title: actionButtonDetails2.title,
          disabled,
          children: actionButtonDetails2.icon
        }
      ) });
    } else {
      return /* @__PURE__ */ jsxs(
        ButtonComponent,
        {
          disabled,
          loading,
          style: actionButtonDetails2.style,
          onClick: (event) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
          },
          sizeVariant: (_c = actionButtonDetails2.sizeVariant) != null ? _c : "medium",
          colorVariant: (_d = actionButtonDetails2.colorVariant) != null ? _d : "primary",
          fullWidth: (_e = actionButtonDetails2.fullWidth) != null ? _e : false,
          children: [
            actionButtonDetails2.icon && actionButtonDetails2.iconPosition !== "end" && /* @__PURE__ */ jsxs(Fragment, { children: [
              actionButtonDetails2.icon,
              "\xA0"
            ] }),
            actionButtonDetails2.title,
            actionButtonDetails2.icon && actionButtonDetails2.iconPosition === "end" && /* @__PURE__ */ jsxs(Fragment, { children: [
              "\xA0",
              actionButtonDetails2.icon
            ] })
          ]
        }
      );
    }
  };
  const hasVisibleActions = useMemo(() => {
    var _a;
    const visibleHeaders = (_a = headerOptions == null ? void 0 : headerOptions.filter((ho) => !ho.hidden)) != null ? _a : [];
    const hasVisibleHeadersOptions = visibleHeaders.filter((ho) => ho.options.some((v) => !v.hidden));
    const hasVisibleOptions = options == null ? void 0 : options.some((o) => !o.hidden);
    return Boolean(hasVisibleHeadersOptions || hasVisibleOptions);
  }, [options, headerOptions]);
  return hasVisibleActions ? /* @__PURE__ */ jsxs(Box, { sx, onClick: (e) => e.stopPropagation(), className, children: [
    getActionButton(actionButtonDetails),
    /* @__PURE__ */ jsxs(
      StyledMenu,
      {
        id: `btnMenuOptions`,
        MenuListProps: {
          "aria-labelledby": "btnMenuOptions"
        },
        anchorEl,
        anchorOrigin,
        transformOrigin,
        open,
        onClose: () => __async(void 0, null, function* () {
          setAnchorEl(null);
          if (onClose) yield onClose();
        }),
        children: [
          filteredHeaderOption.map((headerOption, i) => {
            const visibleOptions = headerOption.options.filter((o) => !o.hidden);
            return /* @__PURE__ */ jsxs(Box, { sx: { marginTop: i > 0 && visibleOptions.length > 0 ? "20px" : 0 }, children: [
              visibleOptions.length > 0 && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: headerOption.title }),
              visibleOptions.map((option, idx) => /* @__PURE__ */ jsxs(
                MenuItem,
                {
                  onClick: (e) => {
                    option.handler();
                    if (closeOnSelect) setAnchorEl(null);
                    e.stopPropagation();
                  },
                  disableRipple: true,
                  disabled: (option == null ? void 0 : option.disabled) || false,
                  sx: { marginTop: "10px" },
                  children: [
                    option.icon,
                    option.label
                  ]
                },
                idx
              ))
            ] }, headerOption.title);
          }),
          options && options.filter((option) => checkScopes(state.user, option.scopes, option.context) && !option.hidden).map((option, i) => /* @__PURE__ */ jsxs(
            MenuItem,
            {
              id: `btn${option.label}_${i}`,
              onClick: (e) => {
                option.handler(e);
                if (closeOnSelect) setAnchorEl(null);
                e.stopPropagation();
              },
              disableRipple: true,
              disabled: (option == null ? void 0 : option.disabled) || false,
              sx: { marginTop: i > 0 ? "10px" : 0 },
              children: [
                option.icon,
                option.label
              ]
            },
            i
          )),
          checkOptions && checkOptions.map((option, i) => /* @__PURE__ */ jsx(
            MenuItem,
            {
              id: `check-${option.name}_${i}`,
              disableRipple: true,
              sx: { marginTop: i > 0 ? "10px" : 0 },
              children: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  label: option.name,
                  name: option.name,
                  value: option.value,
                  checked: selectedCheckbox == null ? void 0 : selectedCheckbox.includes(option.value),
                  onChange: (e) => {
                    onCheckboxChange == null ? void 0 : onCheckboxChange(option);
                    if (closeOnSelect) setAnchorEl(null);
                    e.stopPropagation();
                  }
                }
              )
            },
            option.name
          ))
        ]
      }
    )
  ] }) : null;
};
