"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class AttendanceAPI {
  static findAllAttendancePerUserId(page, pageSize, searchInput, filterString) {
    return __async(this, null, function* () {
      var _a;
      const queryString = [
        filterString ? filterString : void 0,
        searchInput ? `search=${searchInput}` : void 0,
        page ? `page=${page}` : void 0,
        pageSize ? `pageSize=${pageSize}` : void 0
      ].filter(Boolean).join("&");
      return (_a = yield axios.get(`/apiv2/attendances/company/count-per-userId?${queryString}`)) == null ? void 0 : _a.data;
    });
  }
  static findTeamAttendancePerUserId(page, pageSize, searchInput, filterString) {
    return __async(this, null, function* () {
      var _a;
      const queryString = [
        filterString ? filterString : void 0,
        searchInput ? `search=${searchInput}` : void 0,
        page ? `page=${page}` : void 0,
        pageSize ? `pageSize=${pageSize}` : void 0
      ].filter(Boolean).join("&");
      return (_a = yield axios.get(`/apiv2/attendances/team/count-per-userId?${queryString}`)) == null ? void 0 : _a.data;
    });
  }
  static createAttendanceSchedule(createBody) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/attendance-schedules", createBody)).data;
    });
  }
  static updateAttendanceScheduleGeneral(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/general`, update);
    });
  }
  static updateAttendanceSchedulePattern(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/pattern`, update);
    });
  }
  static updateAttendanceScheduleTrackingSettings(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/tracking-settings`, update);
    });
  }
  static updateAttendanceSchedulePayroll(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/payroll`, update);
    });
  }
  static updateAttendanceScheduleSettings(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/schedule-settings`, update);
    });
  }
  static updateAttendanceScheduleApproval(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/approval`, update);
    });
  }
  static deleteAttendanceScheduleById(id) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/attendance-schedules/${id}`);
    });
  }
  static createAttendanceLog(createData) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/attendances", createData);
    });
  }
  static updateAttendanceLogById(id, updateData) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendances/${id}`, updateData);
    });
  }
  static getPersonalAttendanceEntriesByWeekNo(userId, weekNo, year) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/attendances/${userId}/weekly/${weekNo}/${year}`)).data;
    });
  }
  static getAttendanceTableByUserId(userId, filterString) {
    return __async(this, null, function* () {
      const queryString = [filterString ? filterString : void 0].filter(Boolean).join("&");
      return (yield axios.get(`/apiv2/attendances/${userId}/table/by-user?${queryString}`)).data;
    });
  }
  static getWeekListByUserId(userId, year, searchInput) {
    return __async(this, null, function* () {
      var _a;
      const queryString = [`year=${year}`, searchInput ? `search=${searchInput}` : void 0].filter(Boolean).join("&");
      return (_a = yield axios.get(`/apiv2/attendances/${userId}/week-list?${queryString}`)) == null ? void 0 : _a.data;
    });
  }
  static getAttendanceByUserIdAndLogDate(userId, logDate) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/attendances/${userId}/${logDate}/by-date`)).data;
    });
  }
  static submitUserAttendanceByWeek(userId, year, weekNo) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendances/submit/week/${weekNo}/year/${year}/users/${userId}`);
    });
  }
  static approveAttendanceById(userId, attendanceId, status) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendances/${attendanceId}/users/${userId}/single-approval`, { status });
    });
  }
  static approveUserAttendanceByWeek(userId, year, weekNo) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendances/approve/week/${weekNo}/year/${year}/users/${userId}`);
    });
  }
  static rejectUserAttendanceByWeek(userId, year, weekNo) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendances/reject/week/${weekNo}/year/${year}/users/${userId}`);
    });
  }
  static approveUsersRequestByWeekOrMonth(userId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendances/bulk-approve/users/${userId}`, update);
    });
  }
  static rejectUsersRequestByWeekOrMonth(userId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendances/bulk-reject/users/${userId}`, update);
    });
  }
  static deleteAttendanceById(id) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/attendances/${id}`);
    });
  }
  static deleteUserAttendancesByWeek(userId, year, weekNo) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/attendances/week/${weekNo}/year/${year}/users/${userId}`);
    });
  }
  static autopopulateUserAttendancesByWeek(userId, weekNo, year, monday) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendances/autopopulate/week/${weekNo}/year/${year}/users/${userId}`, { monday });
    });
  }
  static copyUserAttendancesFromLastWeek(userId, weekNo, year) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendances/copy-last-week/week/${weekNo}/year/${year}/users/${userId}`);
    });
  }
  static updateAttendanceSettings(updateData) {
    return __async(this, null, function* () {
      yield axios.put("/apiv2/attendance-settings", updateData);
    });
  }
}
export class AttendanceEndpoints {
  static getAttendanceSchedules() {
    return {
      url: "/apiv2/attendance-schedules"
    };
  }
  static getTeamAttendanceSchedules() {
    return {
      url: "/apiv2/attendance-schedules/team"
    };
  }
  static getAttendanceScheduleById(id) {
    return {
      url: `/apiv2/attendance-schedules/${id}`
    };
  }
  static getUserAttendanceSchedule(userId) {
    return {
      url: `/apiv2/attendance-schedules/users/${userId}`
    };
  }
  static getUserAttendanceScheduleByAttendanceId(userId, attendanceId) {
    return {
      url: `/apiv2/attendance-schedules/users/${userId}/attendances/${attendanceId}`
    };
  }
  static getCompanyAttendanceTypes() {
    return {
      url: `/apiv2/attendance-types`
    };
  }
  static getTeamAttendanceTypes() {
    return {
      url: `/apiv2/attendance-types/team`
    };
  }
  // TODO: @approval is this protected by approval rule + is it needed
  static canApproveRejectSchedule(userId) {
    return {
      url: `/apiv2/attendance-schedules/users/${userId}/can-approve-and-reject`
    };
  }
  static getMyAttendanceRequests() {
    return {
      url: "/apiv2/attendances/me"
    };
  }
  static getUserAttendanceRequests(userId) {
    return {
      url: `/apiv2/attendances/users/${userId}`
    };
  }
  static getScheduleMembers(scheduleId) {
    return {
      url: `/apiv2/attendance-schedules/${scheduleId}/members`
    };
  }
  static getCompanyAttendanceSettings() {
    return {
      url: "/apiv2/attendance-settings"
    };
  }
  static getAttendanceById(id, userId) {
    return {
      url: `/apiv2/attendances/${id}/${userId}/by-attendance`
    };
  }
  static getAttendanceByUserIdAndLogDate(userId, logDate) {
    return {
      url: `/apiv2/attendances/${userId}/${logDate}/by-date`
    };
  }
}
