"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FormControlLabel, RadioGroup, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { CalendarEndpoints } from "../../calendar.api";
import { iCalLinkModeEnum } from "../../calendar.interface";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { fieldSx, titleSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const ICAL_LINK_HELPER_TEXT = "Copy and paste this link into your calendar. Check support for more guidance.";
export const ICalLinkDrawer = ({ isOpen, setIsOpen, filterString }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(ICalLinkDrawerContent, { filterString }) });
};
export const ICalLinkDrawerContent = ({ filterString }) => {
  const { polyglot } = usePolyglot();
  const [linkMode, setLinkMode] = useState(iCalLinkModeEnum.ALL);
  const { data: icsLinkAddressHash, isValidating: loadingLink } = useApiClient(
    CalendarEndpoints.getCalendarIcsAddressHash(linkMode, linkMode === iCalLinkModeEnum.ALL ? void 0 : filterString),
    {
      suspense: false
    }
  );
  const linkModeOptions = [
    { value: iCalLinkModeEnum.ALL, label: "Subscribe to all events" },
    { value: iCalLinkModeEnum.FILTERED, label: "Subscribe to the currently filtered view" }
  ];
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("CalendarPage.iCalLink") }),
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("iCalLinkDrawer.body") }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { display: "flex", gap: spacing.g10, mb: spacing.m20, mt: spacing.mt20 }), children: /* @__PURE__ */ jsx(
      RadioGroup,
      {
        name: "ical-link-mode",
        onChange: (event) => {
          if (!event.target.value) return;
          setLinkMode(event.target.value);
        },
        children: linkModeOptions.map((option) => /* @__PURE__ */ jsx(
          FormControlLabel,
          {
            labelPlacement: "end",
            value: option.value,
            checked: linkMode ? linkMode === option.value : false,
            control: /* @__PURE__ */ jsx(StyledRadio, {}),
            label: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: option.label }),
            sx: { mb: spacing.m5 }
          },
          option.value
        ))
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: loadingLink ? /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "100%",
        height: "5vh",
        sx: { borderRadius: "10px", mx: "auto", mt: spacing.mt15 }
      }
    ) : /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: polyglot.t("iCalLinkDrawer.link"),
          value: icsLinkAddressHash != null ? icsLinkAddressHash : "",
          endAdornment: "copy",
          disabled: true
        }
      ),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.captionSmall), { mt: spacing.mt10 }), children: ICAL_LINK_HELPER_TEXT })
    ] }) })
  ] });
};
