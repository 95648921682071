"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useContext, useMemo } from "react";
import { generatePath, Redirect, Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import {
  SURVEYS_TEAM_ONGOING_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_FORM_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_GENERAL_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE
} from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { ReachType } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { GrowthForbiddenAccess } from "@/v2/feature/growth/shared/components/growth-forbidden-access.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { SCUpsertGeneralPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/sc-upsert-general.page";
import { SCUpsertLaunchPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/sc-upsert-launch.page";
import { SurveyCycleCreationParticipantsPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/sc-upsert-participants.page";
import { SCUpsertTimelinePage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/sc-upsert-timeline.page";
import { SurveyCycleCreationNotificationsPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-notifications/survey-cycle-creation-notifications.page";
import { SurveyCycleCreationQuestionPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/survey-cycle-creation-question.page";
import { SURVEYS_MANAGER_SCOPE } from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const getPageConfig = (cycleId, surveyCycle, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("CycleSettingsRouter.general"),
          stub: "general",
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_GENERAL_ROUTE, { cycleId })
        },
        {
          title: "Questions",
          stub: "question",
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_FORM_ROUTE, { cycleId })
        },
        {
          title: "Participants",
          stub: "participants",
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE, { cycleId })
        },
        {
          title: "Timeline",
          stub: "timeline",
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE, { cycleId })
        },
        {
          title: "Notifications",
          stub: "notifications",
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE, { cycleId })
        },
        ...(surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Draft || (surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Scheduled ? [
          {
            title: "Start survey",
            stub: "launch",
            path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE, { cycleId })
          }
        ] : []
      ]
    }
  ];
};
export const SurveyCycleCreationTeamRouter = () => {
  const { polyglot } = usePolyglot();
  const params = useParams();
  const cycleId = params.cycleId;
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const isAdmin = canAccessScopes(user, ["surveys:all"]);
  const isManager = canAccessScopes(user, ["surveys:manager"]);
  const { data: surveyCycle, mutate: refreshSurveyCycle, isLoading: cycleLoading } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleById(cycleId, ReachType.Team),
    {
      suspense: false
    }
  );
  const refresh = () => __async(void 0, null, function* () {
    yield refreshSurveyCycle == null ? void 0 : refreshSurveyCycle();
  });
  const settingPathVisibility = useMemo(
    () => surveyCycle && Boolean(isAdmin || isManager && surveyCycle.owner === user.userId),
    [surveyCycle, isManager, isAdmin, user]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "Surveys",
        subtitle: polyglot.t("OngoingReviewList.reviews"),
        pageConfig: getPageConfig(cycleId, surveyCycle, polyglot),
        backPath: SURVEYS_TEAM_ONGOING_ROUTE,
        showBack: true,
        type: "Domain"
      }
    ),
    settingPathVisibility ? /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_SETUP_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(
        SCUpsertGeneralPage,
        {
          surveyCycle,
          refresh,
          cycleLoading,
          reach: ReachType.Team
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_SETUP_FORM_ROUTE, children: /* @__PURE__ */ jsx(SurveyCycleCreationQuestionPage, { cycleId }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE, children: /* @__PURE__ */ jsx(SurveyCycleCreationParticipantsPage, { cycleId, reach: ReachType.Team }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE, children: /* @__PURE__ */ jsx(SCUpsertTimelinePage, { cycleId, reach: ReachType.Team }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE, children: /* @__PURE__ */ jsx(SurveyCycleCreationNotificationsPage, { surveyCycle, refresh }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE, children: /* @__PURE__ */ jsx(SCUpsertLaunchPage, { cycleId, reach: ReachType.Team }) }),
      /* @__PURE__ */ jsx(Redirect, { to: SURVEYS_TEAM_ONGOING_SETUP_GENERAL_ROUTE, exact: true })
    ] }) : /* @__PURE__ */ jsx("div", { style: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(GrowthForbiddenAccess, {}) })
  ] });
};
