"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { AttendanceAPI, AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { AttendanceStatus, ScheduleTrackingType } from "@v2/feature/attendance/attendance.interface";
import { getWeekDates, getWeekStatusFromAttendanceStatuses } from "@v2/feature/attendance/attendance.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { ReactComponent as Reject } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Star } from "@/images/side-bar-icons/Star.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
export const SubmitWeekRequestsButtonComponent = ({
  userId,
  year,
  weekNo,
  weekStatuses,
  refreshRequests,
  trackingType,
  isClockInSchedule = false
}) => {
  const { polyglot } = usePolyglot();
  const { data: approvalData } = useApiClient(AttendanceEndpoints.canApproveRejectSchedule(userId), {
    suspense: false
  });
  const { hasScopes, getScopesContext } = useScopes();
  const canManage = hasScopes(["attendance:manager"], getScopesContext({ userId }));
  const [anchorEl, setAnchorEl] = useState(null);
  const [mode, setMode] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
    setMode(null);
  };
  const [showMessage] = useMessage();
  const { canApproveOrReject, isAutoapprove } = useMemo(
    () => approvalData != null ? approvalData : {
      canApproveOrReject: false,
      isAutoapprove: false
    },
    [approvalData]
  );
  const weekStatus = useMemo(() => {
    const weekStatusSet = new Set(weekStatuses);
    return getWeekStatusFromAttendanceStatuses(weekStatusSet);
  }, [weekStatuses]);
  const submitWeekRequests = useCallback(() => __async(void 0, null, function* () {
    try {
      yield AttendanceAPI.submitUserAttendanceByWeek(userId, year, weekNo);
      showMessage(polyglot.t("AttendanceDomain.entriesSubmitted"), "success");
      yield refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotSubmitEntries", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [userId, year, weekNo, refreshRequests, showMessage, polyglot]);
  const approveSubmission = useCallback(() => __async(void 0, null, function* () {
    try {
      yield AttendanceAPI.approveUserAttendanceByWeek(userId, year, weekNo);
      showMessage(polyglot.t("AttendanceDomain.submissionApproved"), "success");
      yield refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotApproveSubmission", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, userId, year, weekNo, refreshRequests, showMessage]);
  const deleteWeekRequests = useCallback(() => __async(void 0, null, function* () {
    try {
      yield AttendanceAPI.deleteUserAttendancesByWeek(userId, year, weekNo);
      showMessage(polyglot.t("AttendanceDomain.submissionDeleted"), "success");
      yield refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotDeleteSubmission", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, userId, year, weekNo, refreshRequests, showMessage]);
  const rejectSubmission = useCallback(() => __async(void 0, null, function* () {
    try {
      yield AttendanceAPI.rejectUserAttendanceByWeek(userId, year, weekNo);
      showMessage(polyglot.t("AttendanceDomain.submissionRejected"), "success");
      yield refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotRejectSubmission", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, userId, year, weekNo, refreshRequests, showMessage]);
  const autopopulateFromSchedule = useCallback(() => __async(void 0, null, function* () {
    const weekDates = getWeekDates(year, weekNo);
    const monday = weekDates[0].value;
    try {
      yield AttendanceAPI.autopopulateUserAttendancesByWeek(userId, weekNo, year, monday);
      yield refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.somethingWentWrong", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, userId, weekNo, year, showMessage, refreshRequests]);
  const copyFromLastWeek = useCallback(() => __async(void 0, null, function* () {
    try {
      yield AttendanceAPI.copyUserAttendancesFromLastWeek(userId, weekNo, year);
      yield refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.somethingWentWrong", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, userId, weekNo, year, showMessage, refreshRequests]);
  const showActions = canManage || canApproveOrReject && !isAutoapprove;
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
    weekStatuses.length > 0 && (canApproveOrReject || weekStatus === AttendanceStatus.InProgress) && /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: [
          {
            icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
            handler: deleteWeekRequests,
            label: polyglot.t("AttendanceDomain.clear"),
            hidden: !showActions
          },
          ...canApproveOrReject && weekStatus === AttendanceStatus.Submitted ? [
            {
              icon: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize)),
              handler: () => __async(void 0, null, function* () {
                return yield rejectSubmission();
              }),
              label: polyglot.t("AttendanceDomain.rejectAll")
            }
          ] : []
        ],
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: polyglot.t("General.actions"),
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end"
        }
      }
    ),
    trackingType && trackingType !== ScheduleTrackingType.ClockInClockOut && weekStatuses.length === 0 && weekStatus === "none" && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: [
          {
            icon: /* @__PURE__ */ jsx(Star, __spreadValues({}, iconSize)),
            handler: autopopulateFromSchedule,
            label: polyglot.t("AttendanceDomain.autopopulate"),
            hidden: !canApproveOrReject && isClockInSchedule
          },
          {
            icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
            handler: copyFromLastWeek,
            label: polyglot.t("AttendanceDomain.copyFromLastWeek"),
            hidden: !canApproveOrReject && isClockInSchedule
          }
        ],
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: polyglot.t("General.actions"),
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end"
        }
      }
    ) }),
    weekStatus === AttendanceStatus.InProgress && /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "small",
        colorVariant: "primary",
        onClick: (e) => {
          setAnchorEl(e.currentTarget);
          setMode("submit");
        },
        children: polyglot.t("General.submit")
      }
    ),
    canApproveOrReject && weekStatus === AttendanceStatus.Submitted && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "small",
        colorVariant: "primary",
        onClick: (e) => {
          setAnchorEl(e.currentTarget);
          setMode("approve");
        },
        children: polyglot.t("AttendanceDomain.approveAll")
      }
    ) }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: mode !== null,
        onClose: () => handleClose(),
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (mode === "submit") yield submitWeekRequests();
          if (mode === "approve") yield approveSubmission();
          setMode(null);
        }),
        message: mode === "submit" ? polyglot.t("AttendanceDomain.wantToSubmit") : mode === "approve" ? polyglot.t("AttendanceDomain.wantToApprove") : "",
        callToAction: polyglot.t("General.yes")
      }
    )
  ] });
};
