"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { getTaskStatus } from "@v2/feature/task/components/task.util";
import { TaskStatuses } from "@v2/feature/task/task.interface";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { urlify } from "@v2/util/string.util";
import { useLocation, useParams } from "react-router-dom";
import { getLinkToUploadedFile } from "@/api-client/routes";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DocumentIcon } from "@/images/documents/PinnedBlack.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { stringToAttachmentComponentValue } from "@/v2/components/forms/attachment.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { InlineLink } from "@/v2/components/inline-link.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { previewFileInNewTab } from "@/v2/feature/documents/documents.util";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const TaskViewModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  task,
  action,
  deleteTask,
  editTask,
  onboardingClose
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { pathname } = useLocation();
  const inOnboarding = pathname.includes("onboarding");
  const params = useParams();
  const onboardingUserId = inOnboarding ? Number(params.userId) : 0;
  const taskBelongsToOnboardingUser = inOnboarding && (task == null ? void 0 : task.assignedUserId) === onboardingUserId;
  const [showMessage] = useMessage();
  const handleStatusChange = () => __async(void 0, null, function* () {
    if (!(task == null ? void 0 : task.id) || !action || !(task == null ? void 0 : task.assignedUserId)) return;
    const statusChange = task.status === TaskStatuses.COMPLETE ? TaskStatuses.INCOMPLETE : TaskStatuses.COMPLETE;
    setLoading(true);
    yield action(task.id, task.assignedUserId, statusChange);
    onClose();
    if (taskBelongsToOnboardingUser && statusChange === TaskStatuses.COMPLETE) {
      if (onboardingClose) yield onboardingClose(true);
    } else {
      if (onboardingClose) yield onboardingClose(false);
    }
    setLoading(false);
  });
  const getLinktoUploadDocument = (id) => __async(void 0, null, function* () {
    try {
      yield DocumentAPI.downloadViaId(id).then(({ url }) => {
        previewFileInNewTab(url);
      });
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
    }
  });
  const tasksOptions = useMemo(() => {
    return [
      ...deleteTask ? [
        {
          icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
          handler: () => __async(void 0, null, function* () {
            if (task == null ? void 0 : task.id) yield deleteTask([task.id]);
          }),
          label: polyglot.t("TaskViewModal.delete"),
          disabled: false
        }
      ] : [],
      ...editTask ? [
        {
          icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
          handler: () => editTask(),
          label: polyglot.t("TaskViewModal.edit"),
          disabled: false
        }
      ] : []
    ];
  }, [polyglot, deleteTask, editTask, task == null ? void 0 : task.id]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center"
        },
        children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_a = task == null ? void 0 : task.name) != null ? _a : "Task" })
      }
    ),
    (task == null ? void 0 : task.description) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("TaskViewModal.description") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: (_b = urlify(task.description)) != null ? _b : "" } })
    ] }),
    (task == null ? void 0 : task.dueDate) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("TaskViewModal.dueDate") }),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadProps(__spreadValues({}, __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey })), {
            display: "flex",
            alignItem: "center",
            gap: spacing.g10
          }),
          children: getDateString(task.dueDate)
        }
      )
    ] }),
    (task == null ? void 0 : task.completedOn) && task.status === TaskStatuses.COMPLETE && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("TaskViewModal.completedOn") }),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadProps(__spreadValues({}, __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey })), {
            display: "flex",
            alignItem: "center",
            gap: spacing.g10
          }),
          children: getDateString(task.completedOn)
        }
      )
    ] }),
    (task == null ? void 0 : task.assignedUserId) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("TaskViewModal.assignedTo") }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5, display: "flex", alignItem: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(UserAvatar, { userId: task.assignedUserId, showName: true, nameVariant: "title4", size: "xxsmall" }) })
    ] }),
    (task == null ? void 0 : task.requestedForId) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("TaskViewModal.requestedFor") }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5, display: "flex", alignItem: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(UserAvatar, { userId: task.requestedForId, showName: true, nameVariant: "title4", size: "xxsmall" }) })
    ] }),
    (task == null ? void 0 : task.status) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("TaskViewModal.status") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getTaskStatus(task, polyglot) })
    ] }),
    (task == null ? void 0 : task.attachmentValues) && (() => {
      const value = stringToAttachmentComponentValue(task.attachmentValues);
      if (!value) return " ";
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("TaskViewModal.attachment") }),
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
          value.type === "file" && /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)),
          value.type === "link" || value.type === "file" ? /* @__PURE__ */ jsx(
            InlineLink,
            {
              href: value.type === "link" ? value.name : getLinkToUploadedFile(value.uuid),
              title: value.name,
              style: __spreadProps(__spreadValues({}, themeFonts.title4), {
                color: themeColors.DarkGrey,
                textDecoration: "underline"
              }),
              openInNewWindow: true
            }
          ) : /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "textUnderline",
              sizeVariant: "small",
              onClick: () => __async(void 0, null, function* () {
                yield getLinktoUploadDocument(value.id);
              }),
              children: value.name
            }
          )
        ] })
      ] });
    })(),
    (task == null ? void 0 : task.status) === TaskStatuses.INCOMPLETE ? /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: (task == null ? void 0 : task.assignedUserId) === user.userId || taskBelongsToOnboardingUser ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        ScopesControl,
        {
          scopes: ["task:all", "task:manager"],
          context: (task == null ? void 0 : task.assignedUserId) ? { userId: task == null ? void 0 : task.assignedUserId } : void 0,
          children: tasksOptions.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: tasksOptions,
              actionButtonDetails: {
                type: "button",
                colorVariant: "secondary",
                sizeVariant: "medium",
                title: polyglot.t("TaskViewModal.actions"),
                icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                iconPosition: "end",
                fullWidth: true
              },
              anchorOrigin: { horizontal: "left", vertical: "bottom" },
              transformOrigin: { horizontal: "left", vertical: "top" }
            }
          ) })
        }
      ),
      taskBelongsToOnboardingUser ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("TaskViewModal.done"),
          loading,
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true,
          type: "button",
          onClick: handleStatusChange
        }
      ) }) : /* @__PURE__ */ jsx(
        ScopesControl,
        {
          scopes: ["task"],
          context: (task == null ? void 0 : task.assignedUserId) ? { userId: task == null ? void 0 : task.assignedUserId } : void 0,
          children: /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: polyglot.t("TaskViewModal.done"),
              loading,
              sizeVariant: "medium",
              colorVariant: "primary",
              fullWidth: true,
              type: "button",
              onClick: handleStatusChange
            }
          )
        }
      )
    ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        ScopesControl,
        {
          scopes: ["task:all", "task:manager"],
          context: (task == null ? void 0 : task.assignedUserId) ? { userId: task == null ? void 0 : task.assignedUserId } : void 0,
          children: deleteTask && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              fullWidth: true,
              onClick: () => __async(void 0, null, function* () {
                setIsDeleting(true);
                if (task.id) yield deleteTask([task.id]);
                setIsDeleting(false);
              }),
              sizeVariant: "medium",
              colorVariant: "danger",
              loading: isDeleting,
              children: polyglot.t("General.delete")
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        ScopesControl,
        {
          scopes: ["task:all", "task:manager"],
          context: (task == null ? void 0 : task.assignedUserId) ? { userId: task == null ? void 0 : task.assignedUserId } : void 0,
          children: editTask && /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, onClick: () => editTask(), sizeVariant: "medium", colorVariant: "secondary", children: polyglot.t("General.edit") })
        }
      )
    ] }) }) : /* @__PURE__ */ jsx(
      ScopesControl,
      {
        scopes: ["task:all", "task:manager"],
        context: (task == null ? void 0 : task.assignedUserId) ? { userId: task == null ? void 0 : task.assignedUserId } : void 0,
        children: (task == null ? void 0 : task.id) && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
          deleteTask && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              fullWidth: true,
              onClick: () => __async(void 0, null, function* () {
                yield deleteTask([task.id]);
              }),
              sizeVariant: "medium",
              colorVariant: "secondary",
              children: polyglot.t("General.delete")
            }
          ),
          editTask && /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, onClick: () => editTask(), sizeVariant: "medium", colorVariant: "secondary", children: polyglot.t("General.edit") })
        ] })
      }
    )
  ] }) });
};
