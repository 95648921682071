"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import { AttendanceAPI, AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import {
  AttendanceStatus,
  DayNoToWeekDay,
  ScheduleTrackingType
} from "@v2/feature/attendance/attendance.interface";
import {
  calculateTotalEntriesDuration,
  getAttendanceStatusIcon,
  getScheduleExpectedRegularAndBreakTimeByDay
} from "@v2/feature/attendance/attendance.util";
import { SelectWorkOptions } from "@v2/feature/attendance/company/components/select-work-options.component";
import { AttendanceShiftEndpoints } from "@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api";
import { UserShiftHandler } from "@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler.component";
import { AttendanceDrawerPayItemPreview } from "@v2/feature/payroll/features/pay-item/components/attendance-drawer-pay-item-preview.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { SiteEndpoints } from "@/api-client/site.api";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Location } from "@/images/side-bar-icons/Location.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DrawerViewerItem } from "@/v2/feature/absence/components/drawer-viewer-item.component";
import { SummaryDetailsComponent } from "@/v2/feature/attendance/company/components/summary-details.component";
const DayNoToScheduleDay = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday"
};
const adjustTimeToLogDate = (timeString, logDate) => {
  if (!timeString) return "";
  return `${logDate}T${timeString.split("T")[1]}`;
};
const getAttendanceEntriesFromSchedule = (logDate, userSchedule) => {
  var _a, _b;
  if (!logDate || !(userSchedule == null ? void 0 : userSchedule.attendanceTypesAllowed) || userSchedule.attendanceTypesAllowed.length === 0) return [];
  const date = new LocalDate(logDate);
  const dayInSchedule = DayNoToScheduleDay[date.getDate().getDay()];
  const daySchedule = userSchedule[dayInSchedule];
  const weekIndex = getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks);
  if (!daySchedule || !daySchedule[weekIndex]) return [];
  const dayEntry = daySchedule[weekIndex];
  const regularTypeId = (_a = userSchedule.attendanceTypesAllowed.find((type) => type.name === "Regular")) == null ? void 0 : _a.id;
  if (!regularTypeId) return [];
  const startHour = adjustTimeToLogDate(dayEntry.from, logDate);
  const endHour = adjustTimeToLogDate(dayEntry.to, logDate);
  const startHourTimestamp = startHour ? new LocalDate(startHour).getDate() : null;
  const endHourTimestamp = endHour ? new LocalDate(endHour).getDate() : null;
  const breakTypeId = dayEntry.break ? (_b = userSchedule.attendanceTypesAllowed.find((type) => type.name === "Break")) == null ? void 0 : _b.id : null;
  return [
    {
      typeId: regularTypeId,
      startHour,
      endHour,
      startHourTimestamp,
      endHourTimestamp,
      priority: 1
    },
    ...breakTypeId ? [
      {
        typeId: breakTypeId,
        startHour: "",
        endHour: "",
        startHourTimestamp: null,
        endHourTimestamp: null,
        priority: 2
      }
    ] : []
  ];
};
export const TrackTimeDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  attendanceId,
  onClose,
  afterClose,
  refresh,
  view,
  logDate,
  mode = void 0,
  weekAttendance
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const { hasScopes, getScopesContext } = useScopes();
  const canManageAttendanceForUser = userId && hasScopes(["attendance:manager"], getScopesContext({ userId }));
  const { data: selectedAttendance, isLoading, mutate: refreshAttendance } = useApiClient(
    attendanceId && userId ? AttendanceEndpoints.getAttendanceById(attendanceId, userId) : null,
    { suspense: false }
  );
  const { data: userSchedule, isLoading: isLoadingSchedule } = useApiClient(
    attendanceId && userId ? AttendanceEndpoints.getUserAttendanceScheduleByAttendanceId(userId, attendanceId) : userId ? AttendanceEndpoints.getUserAttendanceSchedule(userId) : null,
    { suspense: false }
  );
  const { data: userSite, isLoading: isLoadingSite } = useApiClient(
    (userSchedule == null ? void 0 : userSchedule.trackingType) === ScheduleTrackingType.ClockInClockOut && userId ? SiteEndpoints.getUserSite(userId) : null
  );
  const { data: todaysShift, isLoading: isLoadingShift, mutate: refreshShift } = useApiClient(
    (userSchedule == null ? void 0 : userSchedule.trackingType) === ScheduleTrackingType.ClockInClockOut && userId ? AttendanceShiftEndpoints.getTodaysShiftByUser(userId) : null
  );
  const refreshTodaysShift = useCallback(() => __async(void 0, null, function* () {
    if (refreshShift) yield refreshShift();
  }), [refreshShift]);
  const refreshLocalAttendance = useCallback(() => __async(void 0, null, function* () {
    if (refreshAttendance) yield refreshAttendance();
  }), [refreshAttendance]);
  const [isEditMode, setIsEditMode] = useState(Boolean(mode === "edit" || !attendanceId));
  useEffect(() => {
    if (isOpen) setIsEditMode(Boolean(mode === "edit" || !attendanceId || attendanceId === 0));
  }, [mode, attendanceId, isOpen]);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose,
      afterClose,
      loading: isLoading || isLoadingSchedule || isLoadingShift || isLoadingSite,
      children: selectedAttendance && !isEditMode ? /* @__PURE__ */ jsx(
        ViewTrackTimeDrawerContent,
        {
          request: selectedAttendance,
          setEditMode: setIsEditMode,
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([refresh(), refreshLocalAttendance()]);
          }),
          setIsOpen,
          userSchedule
        }
      ) : isEditMode && (canManageAttendanceForUser || (userSchedule == null ? void 0 : userSchedule.trackingType) !== ScheduleTrackingType.ClockInClockOut || logDate && logDate === (todaysShift == null ? void 0 : todaysShift.logDate) && (todaysShift == null ? void 0 : todaysShift.isEnded)) ? /* @__PURE__ */ jsx(
        EditTrackTimeDrawerContent,
        {
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([refresh(), refreshLocalAttendance()]);
          }),
          userId,
          setIsOpen,
          setEditMode: setIsEditMode,
          request: selectedAttendance,
          view,
          logDate: (_a = selectedAttendance == null ? void 0 : selectedAttendance.logDate) != null ? _a : logDate,
          userSchedule
        }
      ) : isEditMode && ((_b = selectedAttendance == null ? void 0 : selectedAttendance.logDate) != null ? _b : logDate) && userId && (userSchedule == null ? void 0 : userSchedule.trackingType) === ScheduleTrackingType.ClockInClockOut ? /* @__PURE__ */ jsx(
        UserShiftHandler,
        {
          selectedDate: (_c = selectedAttendance == null ? void 0 : selectedAttendance.logDate) != null ? _c : logDate,
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([refresh(), refreshTodaysShift(), refreshLocalAttendance()]);
          }),
          shift: todaysShift,
          userSite,
          currentWeekAttendance: weekAttendance != null ? weekAttendance : [],
          attendanceSchedule: userSchedule,
          mode: "drawer"
        }
      ) : /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ViewTrackTimeDrawerContent.attendance") }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: "20px" }, children: polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: "Please try again." }) })
      ] })
    }
  );
};
export const EditTrackTimeDrawerContent = ({
  userId,
  refresh,
  setIsOpen,
  setEditMode,
  request,
  logDate,
  view,
  showDatePicker = false,
  userSchedule
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { getCachedUserById } = useCachedUsers({ refresh: true });
  const user = useMemo(() => {
    var _a2;
    const currentUserId = (_a2 = request == null ? void 0 : request.userId) != null ? _a2 : userId;
    return currentUserId ? getCachedUserById(currentUserId) : null;
  }, [request == null ? void 0 : request.userId, userId, getCachedUserById]);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      var _a2, _b2;
      if (!values.userId) {
        showMessage(polyglot.t("EditTrackTimeDrawerContent.errorMessages.select"), "error");
        return;
      }
      const filteredAttendanceEntries = (_b2 = (_a2 = values == null ? void 0 : values.attendanceEntries) == null ? void 0 : _a2.filter(
        (a) => a.startHour.length > 0 && a.endHour.length > 0 && typeof a.typeId === "number"
      )) != null ? _b2 : [];
      try {
        setLoading(true);
        const attendanceData = {
          userId: values.userId,
          logDate: values.logDate,
          notes: values.notes ? values.notes : null,
          attendanceEntries: filteredAttendanceEntries
        };
        if (request == null ? void 0 : request.id) {
          yield AttendanceAPI.updateAttendanceLogById(request.id, attendanceData);
          showMessage(polyglot.t("EditTrackTimeDrawerContent.successMessages.update"), "success");
        } else {
          yield AttendanceAPI.createAttendanceLog(attendanceData);
          showMessage(polyglot.t("EditTrackTimeDrawerContent.successMessages.submit"), "success");
        }
        yield refresh();
        if (setEditMode) setEditMode(false);
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("EditTrackTimeDrawerContent.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, request == null ? void 0 : request.id, setIsOpen, setEditMode, showMessage, refresh]
  );
  const formik = useFormik({
    initialValues: {
      userId: (_a = request == null ? void 0 : request.userId) != null ? _a : userId,
      logDate: (_c = (_b = request == null ? void 0 : request.logDate) != null ? _b : logDate) != null ? _c : new LocalDate().toDateString(),
      attendanceEntries: (request == null ? void 0 : request.attendanceEntries) ? request.attendanceEntries.map((entry, index) => __spreadProps(__spreadValues({}, entry), {
        priority: index
      })) : [],
      notes: (_d = request == null ? void 0 : request.notes) != null ? _d : ""
    },
    validationSchema: Yup.object({
      userId: Yup.number().integer().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.userRequired")),
      logDate: Yup.string().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.dateRequired")),
      attendanceEntries: Yup.array().of(
        Yup.object({
          typeId: Yup.number().integer().typeError(polyglot.t("EditTrackTimeDrawerContent.errorMessages.workTypeInvalid")).required(),
          startHour: Yup.string().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.starthourRequired")),
          endHour: Yup.string().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.endHourRequired")),
          startHourTimestamp: Yup.date().typeError(polyglot.t("EditTrackTimeDrawerContent.errorMessages.dateInvalid")).required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.starthourRequired")),
          endHourTimestamp: Yup.date().min(Yup.ref("startHourTimestamp"), polyglot.t("EditTrackTimeDrawerContent.errorMessages.dateInvalid")).typeError(polyglot.t("EditTrackTimeDrawerContent.errorMessages.dateInvalid")).required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.endHourRequired")),
          priority: Yup.number().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.priorityRequired"))
        })
      ).min(1, polyglot.t("EditTrackTimeDrawerContent.errorMessages.minOne")).required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.minOne")),
      notes: Yup.string().max(255, polyglot.t("EditTrackTimeDrawerContent.errorMessages.maximumChars")).notRequired()
    }),
    onSubmit
  });
  useEffect(() => {
    const entries = (request == null ? void 0 : request.attendanceEntries) && request.attendanceEntries.length > 0 ? request.attendanceEntries.map((entry, index) => __spreadProps(__spreadValues({}, entry), {
      priority: index
    })) : userSchedule ? getAttendanceEntriesFromSchedule(formik.values.logDate, userSchedule) : [];
    formik.setFieldValue("attendanceEntries", entries);
  }, [request == null ? void 0 : request.attendanceEntries, userSchedule, formik.values.logDate]);
  const totalTime = useMemo(() => calculateTotalEntriesDuration(formik.values.attendanceEntries, polyglot), [
    formik.values.attendanceEntries,
    polyglot
  ]);
  const regularTypeId = useMemo(
    () => {
      var _a2, _b2;
      return (_b2 = (_a2 = userSchedule == null ? void 0 : userSchedule.attendanceTypesAllowed.find((type) => type.name === "Regular")) == null ? void 0 : _a2.id) != null ? _b2 : null;
    },
    [userSchedule]
  );
  const breakTypeId = useMemo(
    () => {
      var _a2, _b2;
      return (_b2 = (_a2 = userSchedule == null ? void 0 : userSchedule.attendanceTypesAllowed.find((type) => type.name === "Break")) == null ? void 0 : _a2.id) != null ? _b2 : null;
    },
    [userSchedule]
  );
  const scheduleExpectedWorkTime = useMemo(() => {
    const weekDay = DayNoToWeekDay[new LocalDate(formik.values.logDate).getDate().getDay()];
    return getScheduleExpectedRegularAndBreakTimeByDay(weekDay, userSchedule, formik.values.logDate);
  }, [userSchedule, formik.values.logDate]);
  const differenceBetweenLoggedTimeAndExpectedTime = useMemo(() => {
    const regularEntries = regularTypeId ? formik.values.attendanceEntries.filter((entry) => entry.typeId === regularTypeId) : [];
    const breakEntries = breakTypeId ? formik.values.attendanceEntries.filter((entry) => entry.typeId === breakTypeId) : [];
    const loggedBreak = (userSchedule == null ? void 0 : userSchedule.isFlexible) ? 0 : breakEntries.reduce((total, entry) => {
      if (!entry.startHour || !entry.endHour) return total;
      return total + Math.round((new Date(entry.endHour).getTime() - new Date(entry.startHour).getTime()) / 6e4);
    }, 0);
    const loggedRegular = regularEntries.reduce((total, entry) => {
      if (!entry.startHour || !entry.endHour) return total;
      return total + Math.round((new Date(entry.endHour).getTime() - new Date(entry.startHour).getTime()) / 6e4);
    }, 0) - loggedBreak;
    return {
      Regular: scheduleExpectedWorkTime.Regular - loggedRegular,
      Break: (userSchedule == null ? void 0 : userSchedule.isFlexible) ? 0 : scheduleExpectedWorkTime.Break - loggedBreak
    };
  }, [userSchedule, scheduleExpectedWorkTime, formik.values.attendanceEntries, regularTypeId, breakTypeId]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(
    Form,
    {
      autoComplete: "off",
      onSubmit: (event) => {
        if (differenceBetweenLoggedTimeAndExpectedTime.Regular !== 0 || differenceBetweenLoggedTimeAndExpectedTime.Break !== 0) {
          event.preventDefault();
          setAnchorEl(event.currentTarget);
        } else {
          formik.handleSubmit(event);
        }
      },
      style: drawerContentSx,
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: request ? polyglot.t("EditTrackTimeDrawerContent.edit") : polyglot.t("EditTrackTimeDrawerContent.track") }),
        !userId && !request && /* @__PURE__ */ jsx(
          SingleUserSelect,
          {
            name: "userId",
            options: view,
            onChange: (_, x) => {
              var _a2;
              const updateValue = (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null;
              formik.setFieldValue("userId", updateValue);
              if (!updateValue) formik.setFieldValue("attendanceEntries", []);
            },
            value: formik.values.userId,
            label: polyglot.t("EditTrackTimeDrawerContent.employee"),
            error: (
              // show error from formik OR show error if user is not predefined & user has no schedule
              formik.touched.userId && Boolean(formik.errors.userId) || !userId && !userSchedule
            ),
            helperText: formik.touched.userId && formik.errors.userId || !userSchedule && polyglot.t("AttendanceDomain.noScheduleFound")
          }
        ),
        request && /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("EditTrackTimeDrawerContent.employee") }),
          /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m5, display: "flex", alignItem: "center", gap: spacing.g10 }, children: [
            /* @__PURE__ */ jsx(UserAvatar, { userId: request.userId, size: "xxsmall" }),
            /* @__PURE__ */ jsx(Typography, { variant: "title4", children: user ? user.displayName || `${user.firstName} ${user.lastName}` : "" })
          ] })
        ] }),
        showDatePicker ? /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            inputFormat: "DD/MM/YYYY",
            value: (_e = formik.values.logDate) != null ? _e : null,
            onChange: (value) => {
              if (dayjs(value).isValid()) formik.setFieldValue("logDate", value);
              else formik.setFieldValue("logDate", "");
            },
            name: "logDate",
            label: polyglot.t("EditTrackTimeDrawerContent.period"),
            error: !!formik.errors.logDate && formik.touched.logDate,
            helperText: formik.touched.logDate && formik.errors.logDate
          }
        ) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("EditTrackTimeDrawerContent.period") }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: new LocalDate(formik.values.logDate).toLocaleDateString(void 0, {
            day: "2-digit",
            month: "short",
            year: "numeric"
          }) })
        ] }),
        userId && !userSchedule && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "RedDark", children: polyglot.t("EditTrackTimeDrawerContent.noScheduleNotification") }),
        userSchedule && formik.values.logDate && /* @__PURE__ */ jsx(
          SelectWorkOptions,
          {
            logDate: formik.values.logDate,
            attendanceSchedule: userSchedule,
            entries: formik.values.attendanceEntries,
            setEntries: (value) => {
              formik.setFieldValue(
                "attendanceEntries",
                value.map((o, index) => __spreadProps(__spreadValues({}, o), { priority: index }))
              );
            },
            showErrorMessage: Boolean(formik.touched.attendanceEntries && !!formik.errors.attendanceEntries)
          }
        ),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("EditTrackTimeDrawerContent.overlapNote") }) }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "notes",
            label: polyglot.t("AttendanceDomain.notes"),
            value: formik.values.notes,
            onChange: formik.handleChange,
            endAdornment: "none",
            error: !!formik.errors.notes && formik.touched.notes,
            helperText: formik.touched.notes && formik.errors.notes
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("EditTrackTimeDrawerContent.total") }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: totalTime })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
          /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setIsOpen(false), fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", children: polyglot.t("General.cancel") }),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: polyglot.t("General.save"),
              fullWidth: true,
              loading,
              sizeVariant: "medium",
              colorVariant: "primary"
            }
          ),
          /* @__PURE__ */ jsx(
            NotificationModal,
            {
              isOpen: Boolean(anchorEl),
              onClose: () => setAnchorEl(null),
              anchorEl,
              takeAction: () => {
                formik.handleSubmit();
                setAnchorEl(null);
              },
              message: polyglot.t("EditTrackTimeDrawerContent.continueMessage"),
              callToAction: polyglot.t("General.yes")
            }
          )
        ] })
      ]
    }
  ) });
};
const ViewTrackTimeDrawerContent = ({
  request,
  refresh,
  setEditMode,
  setIsOpen,
  userSchedule
}) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { getScopesContext, hasScopes } = useScopes();
  const hasStdAttendanceScope = hasScopes(["attendance"], getScopesContext({ userId: request.userId }));
  const canManageThis = hasScopes(["attendance:manager"], getScopesContext({ userId: request.userId }));
  const canApproveOrReject = useMemo(() => {
    var _a;
    return Boolean((_a = request.approversIds) == null ? void 0 : _a.includes(state.user.userId));
  }, [
    request,
    state.user.userId
  ]);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const attendanceTypesAllowedById = useMemo(
    () => {
      var _a, _b;
      return (_b = (_a = userSchedule == null ? void 0 : userSchedule.attendanceTypesAllowed) == null ? void 0 : _a.reduce((response, type) => {
        response[type.id] = type;
        return response;
      }, {})) != null ? _b : {};
    },
    [userSchedule]
  );
  const attendanceUser = useMemo(() => getCachedUserById(request.userId), [getCachedUserById, request.userId]);
  const approver = useMemo(() => request.approvedBy && getCachedUserById(request.approvedBy), [
    getCachedUserById,
    request.approvedBy
  ]);
  const deleteRequest = useCallback(() => __async(void 0, null, function* () {
    try {
      yield AttendanceAPI.deleteAttendanceById(request.id);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.delete"), "success");
      yield refresh();
      setIsOpen(false);
    } catch (e) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.delete", { errorMessage: nestErrorMessage(e) }),
        "error"
      );
    }
  }), [showMessage, request, refresh, setIsOpen, polyglot]);
  const approveRequest = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsApproveLoading(true);
      yield AttendanceAPI.approveAttendanceById(request.userId, request.id, AttendanceStatus.Approved);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.approve"), "success");
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.approve", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsApproveLoading(false);
    }
  }), [setIsOpen, request.id, request.userId, showMessage, refresh, polyglot]);
  const rejectRequest = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsRejectLoading(true);
      yield AttendanceAPI.approveAttendanceById(request.userId, request.id, AttendanceStatus.Rejected);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.reject"), "success");
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.reject", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsRejectLoading(false);
    }
  }), [setIsOpen, request.id, request.userId, showMessage, refresh, polyglot]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ViewTrackTimeDrawerContent.attendance") }),
      (canManageThis || hasStdAttendanceScope && request.status === AttendanceStatus.InProgress && request.userId === currentUser.userId && (userSchedule == null ? void 0 : userSchedule.trackingType) !== ScheduleTrackingType.ClockInClockOut) && /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          options: [
            {
              icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
              handler: () => setEditMode(true),
              label: polyglot.t("General.edit")
            },
            {
              icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
              handler: deleteRequest,
              label: polyglot.t("General.delete")
            }
          ],
          actionButtonDetails: {
            type: "iconButton",
            colorVariant: "secondary",
            sizeVariant: "small",
            title: polyglot.t("ViewTrackTimeDrawerContent.actions"),
            icon: /* @__PURE__ */ jsx(ActionsSmall, { width: "14px", height: "14px" })
          }
        }
      )
    ] }),
    attendanceUser && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("ViewTrackTimeDrawerContent.employee"),
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId: attendanceUser.userId, size: "xxsmall" }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: attendanceUser.displayName || `${attendanceUser.firstName} ${attendanceUser.lastName}` })
        ] })
      },
      "user"
    ),
    /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("ViewTrackTimeDrawerContent.status"),
        value: getAttendanceStatusIcon(request.status, false, polyglot)
      }
    ),
    approver && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: `${request.status} by`,
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId: approver.userId, size: "xxsmall" }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: approver.displayName || `${approver.firstName} ${approver.lastName}` })
        ] })
      },
      "approver"
    ),
    userSchedule && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("ViewTrackTimeDrawerContent.schedule"),
        value: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: userSchedule.name }) })
      },
      "schedule"
    ),
    /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("ViewTrackTimeDrawerContent.period"),
        value: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: new LocalDate(request.logDate).toLocaleDateString() }) })
      }
    ),
    /* @__PURE__ */ jsx(
      SummaryDetailsComponent,
      {
        request,
        userSchedule,
        attendanceTypesAllowedById
      }
    ),
    request.attendanceEntries.map((entry, index) => {
      var _a, _b, _c;
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(
          DrawerViewerItem,
          {
            title: (_b = (_a = attendanceTypesAllowedById[entry.typeId]) == null ? void 0 : _a.name) != null ? _b : "N/A",
            value: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: `${entry.startHour.slice(
              11,
              16
            )} - ${entry.endHour.slice(11, 16)}` })
          },
          "entry-name"
        ),
        /* @__PURE__ */ jsx(
          DrawerViewerItem,
          {
            title: ((_c = entry.type) == null ? void 0 : _c.name) ? polyglot.t("ViewTrackTimeDrawerContent.typeLength", { type: entry.type.name }) : polyglot.t("ViewTrackTimeDrawerContent.length"),
            value: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "title4",
                sx: {
                  color: themeColors.DarkGrey,
                  minWidth: "80px"
                },
                children: convertMinutesToClockHours(
                  (new Date(entry.endHourTimestamp).getTime() - new Date(entry.startHourTimestamp).getTime()) / (60 * 1e3),
                  polyglot
                )
              }
            )
          },
          "entry-length"
        )
      ] }, index);
    }),
    request.startLongitude && request.startLatitude && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("ViewTrackTimeDrawerContent.location") }),
          /* @__PURE__ */ jsx(
            "a",
            {
              href: `https://www.google.com/maps/place/${request.startLatitude},${request.startLongitude}`,
              target: "_blank",
              rel: "noopener noreferrer",
              style: { textDecoration: "none" },
              children: /* @__PURE__ */ jsx(Location, { width: "12px", height: "12px", fill: themeColors.Grey })
            }
          )
        ] }),
        value: /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "title4",
            sx: { color: themeColors.DarkGrey },
            children: `${request.startLatitude}, ${request.startLongitude}`
          }
        )
      }
    ),
    request.notes && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("ViewTrackTimeDrawerContent.notes"),
        value: /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "title4",
            sx: {
              color: themeColors.DarkGrey,
              minWidth: "80px"
            },
            children: request.notes
          }
        )
      },
      "notes"
    ),
    /* @__PURE__ */ jsx(ScopesControl, { scopes: ["payroll:all"], context: { userId: request.userId }, children: /* @__PURE__ */ jsx(AttendanceDrawerPayItemPreview, { userId: request.userId, attendanceId: request.id }) }),
    request.status === AttendanceStatus.Submitted && (canApproveOrReject || canManageThis) && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          type: "button",
          fullWidth: true,
          name: polyglot.t("General.reject"),
          loading: isRejectLoading,
          onClick: rejectRequest,
          sizeVariant: "medium",
          colorVariant: "secondary"
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          type: "button",
          name: polyglot.t("General.approve"),
          loading: isApproveLoading,
          fullWidth: true,
          onClick: approveRequest,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] });
};
