"use strict";
import { PAYMENTS_CREATE_ROUTE, PAYMENTS_SELECT_BANK_ROUTE } from "@v2/feature/payments/payments.router";
import { matchPath } from "react-router-dom";
import {
  APP_INTEGRATION_DETAILS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_ROUTE,
  DEVICES_COMPANY_DEVICE_DETAILS_ROUTE,
  DEVICES_ME_OVERVIEW_DEVICE_ROUTE,
  EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE,
  EXPENSE_COMPANY_NEW_EXPENSE_ROUTE,
  EXPENSE_ME_EDIT_EXPENSE_ROUTE,
  EXPENSE_ME_NEW_EXPENSE_ROUTE,
  EXPENSE_TEAM_EDIT_EXPENSE_ROUTE,
  EXPENSES_TEAM_NEW_EXPENSE_ROUTE,
  INVOICES_COMPANY_EDIT_INVOICE_ROUTE,
  INVOICES_COMPANY_NEW_INVOICE_ROUTE,
  INVOICES_ME_EDIT_INVOICE_ROUTE,
  INVOICES_ME_NEW_INVOICE_ROUTE,
  INVOICES_TEAM_NEW_INVOICE_ROUTE,
  PARTNER_API_AUTHORISE_ROUTE,
  PAYMENT_ME_NEW_EXPENSE_ROUTE,
  PAYMENT_ME_NEW_INVOICE_ROUTE,
  PAYROLL_COMPANY_PAYROLL_PAYRUNS_ROUTE,
  PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE,
  REPORTS_COMPANY_ADVANCED_REPORTS_UPDATE_ROUTE,
  REPORTS_COMPANY_CREATE_ROUTE,
  REPORTS_COMPANY_VIEW_REPORT_ROUTE,
  REPORTS_CREATE_OLD_ROUTE,
  REPORTS_TEAM_CREATE_ROUTE,
  REPORTS_TEAM_VIEW_REPORT_ROUTE,
  REPORTS_UPDATE_OLD_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_ROUTE,
  REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE,
  REVIEWS_ME_RESULT_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_PATTERN_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_ROUTE,
  SETTINGS_BENEFITS_DETAILS_ROUTE,
  SETTINGS_CREATE_NEW_BENEFIT_ROUTE,
  SETTINGS_DEVICES_DEP_ROUTE,
  SETTINGS_DEVICES_ZELT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_ROUTE,
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE,
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE,
  SETTINGS_PERMISSION_GROUP_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_TIME_HOLIDAY_CALENDAR_DETAILS_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_ROUTE,
  SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_ROUTE,
  TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE,
  TEMPLATE_CONTRACT_SIGN_ROUTE,
  USER_DETAILS_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_DETAIL_ROUTE
} from "@/lib/routes";
export const noSidebarRoutes = [
  PAYMENTS_CREATE_ROUTE,
  PAYMENTS_SELECT_BANK_ROUTE,
  SETTINGS_CREATE_NEW_BENEFIT_ROUTE,
  REPORTS_CREATE_OLD_ROUTE,
  REPORTS_COMPANY_CREATE_ROUTE,
  REPORTS_TEAM_CREATE_ROUTE,
  REPORTS_COMPANY_VIEW_REPORT_ROUTE,
  REPORTS_TEAM_VIEW_REPORT_ROUTE,
  REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE,
  SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_PATTERN_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE,
  TEMPLATE_CONTRACT_SIGN_ROUTE,
  TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE,
  PARTNER_API_AUTHORISE_ROUTE,
  SETTINGS_DEVICES_DEP_ROUTE
];
export const showSidebar = (route) => {
  const filtered = noSidebarRoutes.filter((n) => {
    return matchPath(route, { path: n });
  });
  return !noSidebarRoutes.includes(route) && filtered.length === 0;
};
export const hasDomainSidebarRoutes = [
  // Settings domain
  SETTINGS_ROUTE,
  // User profile domain
  USER_DETAILS_ROUTE,
  // APPS
  APP_INTEGRATION_DETAILS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_ROUTE,
  // REVIEWS SETTINGS
  REVIEWS_COMPANY_ONGOING_DETAIL_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_ROUTE,
  REVIEWS_ME_RESULT_DETAIL_ROUTE,
  // SURVEY SETTINGS
  SURVEYS_TEAM_ONGOING_SETUP_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE,
  // Money
  PAYROLL_COMPANY_PAYROLL_PAYRUNS_ROUTE,
  PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE,
  // REPORTS
  REPORTS_UPDATE_OLD_ROUTE,
  REPORTS_COMPANY_ADVANCED_REPORTS_UPDATE_ROUTE,
  // PAYMENTS
  INVOICES_TEAM_NEW_INVOICE_ROUTE,
  EXPENSES_TEAM_NEW_EXPENSE_ROUTE,
  INVOICES_ME_NEW_INVOICE_ROUTE,
  INVOICES_ME_EDIT_INVOICE_ROUTE,
  INVOICES_COMPANY_EDIT_INVOICE_ROUTE,
  INVOICES_COMPANY_NEW_INVOICE_ROUTE,
  PAYMENT_ME_NEW_INVOICE_ROUTE,
  PAYMENT_ME_NEW_EXPENSE_ROUTE,
  EXPENSE_ME_NEW_EXPENSE_ROUTE,
  EXPENSE_COMPANY_NEW_EXPENSE_ROUTE,
  EXPENSE_ME_EDIT_EXPENSE_ROUTE,
  EXPENSE_TEAM_EDIT_EXPENSE_ROUTE,
  EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE,
  //Devices
  DEVICES_COMPANY_DEVICE_DETAILS_ROUTE,
  DEVICES_ME_OVERVIEW_DEVICE_ROUTE
];
export const hasCustomDomain = [
  // Reviews
  REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
  // Settings
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_DETAIL_ROUTE,
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE,
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE,
  SETTINGS_BENEFITS_DETAILS_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE,
  SETTINGS_TIME_HOLIDAY_CALENDAR_DETAILS_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_ROUTE,
  SETTINGS_DEVICES_ZELT_ROUTE,
  SETTINGS_PERMISSION_GROUP_ROUTE,
  //Documents
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE
];
export const showSecondMenuBar = (route) => {
  const filtered = hasDomainSidebarRoutes.filter((n) => {
    return matchPath(route, { path: n });
  });
  return !hasDomainSidebarRoutes.includes(route) && filtered.length === 0;
};
export const showDomainSideBar = (route) => {
  if (showCustomDomainSidebar(route)) {
    return false;
  }
  return !showSecondMenuBar(route);
};
export const showCustomDomainSidebar = (route) => {
  const filtered = hasCustomDomain.filter((n) => {
    return matchPath(route, { path: n });
  });
  return hasCustomDomain.includes(route) || filtered.length > 0;
};
