"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Loader } from "@v2/components/loader.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { DocumentAPI } from "@v2/feature/documents/document.api";
import {
  downloadFileByUrl,
  initialiseState,
  reduceDocumentFormModal
} from "@v2/feature/documents/documents.util";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { secondaryTableSmallBtn } from "@v2/styles/buttons.styles";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconCTAButtonSx, tableSecondaryIconButtonSx } from "@v2/styles/icon-button.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { pipe } from "fp-ts/function";
import { generatePath } from "react-router-dom";
import { Subject } from "rxjs";
import { FILE_UPLOAD_BACKOFFICE_POLICY_DOCUMENTS_ENDPOINT } from "@/api-client/routes";
import { UploadInput } from "@/component/forms/UploadInput";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Delete } from "@/images/side-bar-icons/Delete.svg";
import { ReactComponent as Document } from "@/images/side-bar-icons/Document.svg";
import { ReactComponent as Download } from "@/images/side-bar-icons/Download.svg";
import { nestErrorMessage } from "@/lib/errors";
export const UploadInsurancePolicyDocumentsDrawer = ({
  isOpen,
  setIsOpen,
  policyId,
  onSave,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(UploadInsurancePolicyDocumentsDrawerContent, { policyId, refresh, onSave }) });
};
const UploadInsurancePolicyDocumentsDrawerContent = ({
  policyId,
  onSave,
  refresh
}) => {
  var _a, _b;
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const [policy, setPolicy] = useState(null);
  const [noOfAlreadyUploadedDocuments, setNoOfAlreadyUploadedDocuments] = useState(
    (_b = (_a = policy == null ? void 0 : policy.uploadedDocuments) == null ? void 0 : _a.length) != null ? _b : 0
  );
  const [noOfDocumentToBeUploaded, setNoOfDocumentToBeUploaded] = useState(1);
  const MAX_NO_OF_DOCUMENTS_ALLOWED = 5;
  const documentReducers = [
    // Allow up to MAX_NO_OF_DOCUMENTS_ALLOWED document uploads
    useReducer(reduceDocumentFormModal, initialiseState([])),
    useReducer(reduceDocumentFormModal, initialiseState([])),
    useReducer(reduceDocumentFormModal, initialiseState([])),
    useReducer(reduceDocumentFormModal, initialiseState([])),
    useReducer(reduceDocumentFormModal, initialiseState([]))
  ];
  const refreshPolicyDetails = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2;
    try {
      setLoading(true);
      const policy2 = yield InsuranceAPI.getInsurancePolicyDetailsAsSuperAdmin(policyId, true);
      setPolicy(policy2);
      const noOfAlreadyUploadedDocuments2 = (_b2 = (_a2 = policy2 == null ? void 0 : policy2.uploadedDocuments) == null ? void 0 : _a2.length) != null ? _b2 : 0;
      setNoOfAlreadyUploadedDocuments(noOfAlreadyUploadedDocuments2);
      setNoOfDocumentToBeUploaded(Math.max(1, noOfAlreadyUploadedDocuments2));
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage, policyId]);
  useEffect(() => {
    refreshPolicyDetails();
  }, [refreshPolicyDetails]);
  const uploadDocuments = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const validDocumentsData = documentReducers.filter(([document]) => document.fileUuid).map(([document]) => ({
        name: document.name,
        fileUuid: document.fileUuid,
        note: document.note
      }));
      yield InsuranceAPI.uploadPolicyDocumentsAsSuperAdmin(policyId, validDocumentsData);
      yield refresh();
      onSave();
    } catch (error) {
      showMessage(`Could not upload documents. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Loader, { loading, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Upload documents" }),
    policy && Array.from(Array(noOfDocumentToBeUploaded)).map((_, index) => {
      if (index < noOfAlreadyUploadedDocuments)
        return /* @__PURE__ */ jsx(
          InsuranceDocumentDetails,
          {
            document: policy.uploadedDocuments[index],
            refresh: refreshPolicyDetails
          },
          index
        );
      return /* @__PURE__ */ jsx(
        InsuranceDocumentUpload,
        {
          policyId,
          documentState: documentReducers[index][0],
          dispatch: documentReducers[index][1]
        },
        index
      );
    }),
    (documentReducers[noOfDocumentToBeUploaded - 1][0].fileUuid || noOfAlreadyUploadedDocuments === noOfDocumentToBeUploaded) && noOfDocumentToBeUploaded < MAX_NO_OF_DOCUMENTS_ALLOWED && /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ width: 1, display: "flex", justifyContent: "end" }, spacing.mb40), children: /* @__PURE__ */ jsx(
      Button,
      {
        sx: secondaryTableSmallBtn,
        onClick: () => {
          setNoOfDocumentToBeUploaded((prevCount) => Math.min(prevCount + 1, MAX_NO_OF_DOCUMENTS_ALLOWED));
        },
        children: "+ One more"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        loading,
        type: "button",
        onClick: uploadDocuments,
        sizeVariant: "small",
        colorVariant: "primary",
        disabled: noOfAlreadyUploadedDocuments >= MAX_NO_OF_DOCUMENTS_ALLOWED || !documentReducers[noOfAlreadyUploadedDocuments][0].fileUuid,
        fullWidth: true
      }
    ) })
  ] });
};
const InsuranceDocumentUpload = ({ policyId, documentState, dispatch }) => {
  const [changeName$] = useState(() => new Subject());
  const [changeNote$] = useState(() => new Subject());
  useEffect(
    () => pipe(
      changeName$,
      ($) => $.subscribe((value) => dispatch({ kind: "change_name", value })),
      (s) => () => s.unsubscribe()
    ),
    [changeName$, dispatch]
  );
  useEffect(
    () => pipe(
      changeNote$,
      ($) => $.subscribe((value) => dispatch({ kind: "change_note", value })),
      (s) => () => s.unsubscribe()
    ),
    [changeNote$, dispatch]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mb: 0 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: "Name",
        value: documentState.name,
        onChange: (e) => {
          changeName$.next(e.target.value);
        },
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mb: 0 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "note",
        label: "Notes",
        value: documentState.note,
        onChange: (e) => {
          changeNote$.next(e.target.value);
        },
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mb30), children: /* @__PURE__ */ jsx(
      UploadInput,
      {
        endpoint: generatePath(FILE_UPLOAD_BACKOFFICE_POLICY_DOCUMENTS_ENDPOINT, { policyId }),
        onChange: (file) => {
          if (file) {
            dispatch({ kind: "upload_file", value: { fileUuid: file.uuid, fileName: file.fileName } });
          } else {
            dispatch({ kind: "clear_file" });
          }
        }
      }
    ) })
  ] });
};
const InsuranceDocumentDetails = ({ document, refresh }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const deleteDocument = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield DocumentAPI.deleteDocumentByIdAsSuperAdmin(document.id);
      yield refresh();
    } catch (error) {
      showMessage(`Could not delete document. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(Loader, { loading, children: /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "row",
        width: 1,
        gap: spacing.gap10,
        alignItems: "center"
      },
      children: [
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Document, { width: 20, height: 20 }) }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", width: 1, gap: spacing.gap5 }, children: [
          /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: document.name }) }),
          document.note && /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: document.note }) })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10, width: "80px" }, children: [
          /* @__PURE__ */ jsx(
            IconButton,
            {
              disableRipple: true,
              sx: tableSecondaryIconButtonSx,
              onClick: () => {
                const attachmentExists = document.attachments && document.attachments.length > 0;
                if (attachmentExists) {
                  const documentUrl = document.attachments[0].url;
                  downloadFileByUrl(documentUrl, document.name);
                }
              },
              children: /* @__PURE__ */ jsx(Download, { width: 20, height: 20 })
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              onClick: (event) => {
                setAnchorEl(event.currentTarget);
              },
              disableRipple: true,
              sx: iconCTAButtonSx,
              children: /* @__PURE__ */ jsx(Delete, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            NotificationModal,
            {
              isOpen: Boolean(anchorEl),
              onClose: () => setAnchorEl(null),
              anchorEl,
              takeAction: () => __async(void 0, null, function* () {
                yield deleteDocument();
                setAnchorEl(null);
              }),
              message: "Are you sure you want to delete this document?",
              callToAction: "Yes"
            }
          )
        ] })
      ]
    }
  ) });
};
