"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  ConfigurablePoliciesByZeltMdm,
  DeliveryMethod,
  DeviceContractLength,
  DeviceOrderStatus,
  DevicePolicyType,
  DevicePossessionType,
  DeviceShippingMethod,
  DeviceTransitStatus,
  DeviceType,
  OrderDeliveryCountry,
  SecurityStatus
} from "@v2/feature/device/device.interface";
import { themeColors } from "@v2/styles/colors.styles";
import { differenceInDays } from "date-fns";
import AcerAspire3 from "@/images/devices/acer-aspire-3.jpg";
import DellInspiron15 from "@/images/devices/dell-inspiron-15.png";
import DellMonitorP2422h from "@/images/devices/dell-monitor-p2422h.jpg";
import DellXPS from "@/images/devices/dell-xps.webp";
import Dell274KMonitor from "@/images/devices/Dell274KMonitor.webp";
import Dell27Monitor from "@/images/devices/Dell27Monitor.webp";
import Dell32Curved4KUHDMonitor from "@/images/devices/Dell32Curved4KUHDMonitor.webp";
import pixel8 from "@/images/devices/Google_Pixel_8.webp";
import HeadsetStore from "@/images/devices/Headset.webp";
import ipad12pro from "@/images/devices/ipad12pro.webp";
import iphone13pro from "@/images/devices/iphone13pro.webp";
import KeyboardStore from "@/images/devices/Keyboard.webp";
import LaptopStore from "@/images/devices/Laptop.svg";
import LenovoIdeapad from "@/images/devices/lenovo-ideapad.webp";
import LenovoTabM7 from "@/images/devices/lenovo-tab-m7.webp";
import LenovoThinkpadCarbonX1 from "@/images/devices/lenovo-thinkpad-carbon-x1.webp";
import LGGram from "@/images/devices/lg-gram.png";
import MacbookAir from "@/images/devices/macbook-air.webp";
import MacbookPro from "@/images/devices/macbook-pro.webp";
import MicrosoftSurface from "@/images/devices/MicrosoftSurface.webp";
import MonitorStore from "@/images/devices/Monitor.svg";
import MouseStore from "@/images/devices/Mouse.webp";
import OtherStore from "@/images/devices/Other.webp";
import PhoneStore from "@/images/devices/Phone.svg";
import TabletStore from "@/images/devices/Tablet.svg";
import { ReactComponent as Clock } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Checked } from "@/images/side-bar-icons/Ok.svg";
import { ReactComponent as Problem } from "@/images/side-bar-icons/problem.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as Shipping } from "@/images/side-bar-icons/Shipping.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { EnrollmentStatus } from "@/v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const DEVICES_RENTAL_TAX_RATE = 0.2;
export const MDMABLE_DEVICE_TYPES = [DeviceType.Laptop, DeviceType.Tablet, DeviceType.Phone, DeviceType.Desktop];
export const DeviceOSMacOS = { value: "macos", label: "MacOS" };
export const DeviceOSiOS = { value: "ios", label: "iOS" };
export const DeviceOSiPadOS = { value: "ipados", label: "iPadOS" };
export const DeviceOSAndroid = { value: "android", label: "Android" };
export const DeviceOSWindows = { value: "windows", label: "Windows" };
export const DeviceOSLinux = { value: "linux", label: "Linux" };
export const DeviceOSOther = { value: "other", label: "Other" };
export const DeviceOSValueLabelOptions = [
  DeviceOSMacOS,
  DeviceOSiOS,
  DeviceOSiPadOS,
  DeviceOSWindows,
  DeviceOSLinux,
  DeviceOSAndroid,
  DeviceOSOther
];
export const DeviceTypesValueLabelOptions = [
  DeviceType.Laptop,
  DeviceType.Desktop,
  DeviceType.Phone,
  DeviceType.Tablet,
  DeviceType.Monitor,
  DeviceType.Headset,
  DeviceType.Mouse,
  DeviceType.Keyboard,
  DeviceType.Other
].map((type) => ({ label: type, value: type }));
export const ManufacturerOptions = [
  "Acer",
  "Apple",
  "Asus",
  "Dell",
  "Google",
  "Lenovo",
  "LG",
  "Microsoft",
  "Samsung",
  "Other"
];
export const ManufacturerValueLabelOptions = ManufacturerOptions.map((option) => ({
  label: option,
  value: option.toLowerCase()
}));
export const RamValueLabelOptions = [
  { label: "8GB", value: 8 },
  { label: "16GB", value: 16 },
  { label: "32GB", value: 32 }
];
export const StorageValueLabelOptions = [
  { label: "256GB", value: 256 },
  { label: "512GB", value: 512 },
  { label: "1024GB", value: 1024 }
];
export const IsAvailableValueLabelOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" }
];
export const DeviceConditionValueLabelOptions = [{ label: "New", value: "new" }];
export var DeviceShopFilter = /* @__PURE__ */ ((DeviceShopFilter2) => {
  DeviceShopFilter2["Manufacturer"] = "Manufacturer";
  DeviceShopFilter2["Type"] = "Type";
  DeviceShopFilter2["RAM"] = "RAM";
  DeviceShopFilter2["Storage"] = "Storage";
  DeviceShopFilter2["OS"] = "OS";
  DeviceShopFilter2["IsAvailable"] = "Is Available";
  DeviceShopFilter2["Condition"] = "Condition";
  return DeviceShopFilter2;
})(DeviceShopFilter || {});
export const DeviceTypeFilterOptions = [
  { label: "Laptop", value: DeviceType.Laptop },
  { label: "Desktop", value: DeviceType.Desktop },
  { label: "Tablet", value: DeviceType.Tablet },
  { label: "Phone", value: DeviceType.Phone },
  { label: "Monitor", value: DeviceType.Monitor },
  { label: "Headset", value: DeviceType.Headset },
  { label: "Keyboard", value: DeviceType.Keyboard },
  { label: "Mouse", value: DeviceType.Mouse },
  { label: "Other", value: DeviceType.Other }
];
export const DeviceOrderStatusToProgressScore = {
  [DeviceOrderStatus.Cancelled]: 0,
  [DeviceOrderStatus.Rejected]: 0,
  [DeviceOrderStatus.Placed]: 0,
  [DeviceOrderStatus.Accepted]: 35,
  [DeviceOrderStatus.Shipping]: 68,
  [DeviceOrderStatus.Delivered]: 100
};
export const DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS = [
  { value: DeviceContractLength.Months24, label: "24 months" },
  { value: DeviceContractLength.Months36, label: "36 months" }
];
export const DeviceRentOption = { value: "rent", label: "Rent" };
export const DevicePurchaseOption = { value: "purchase", label: "Buy" };
export const DeviceOwnershipOptions = [DeviceRentOption, DevicePurchaseOption];
export const DeliveryCountryUK = { value: OrderDeliveryCountry.UK, label: "UK" };
export const DeliveryCountryEU = { value: OrderDeliveryCountry.EU, label: "EU" };
export const DeliveryCountryOptions = [DeliveryCountryUK, DeliveryCountryEU];
export const DeliveryMethodsOptions = [
  { value: DeliveryMethod.ShippingToEmployee, label: "Shipping to Employee" },
  { value: DeliveryMethod.ShippingToSite, label: "Shipping to Site" },
  { value: DeliveryMethod.Handover, label: "Handover" }
];
export const DeviceShippingMethodsOptions = [
  { value: DeviceShippingMethod.NoTrackingReassignInstantly, label: "No tracking, reassign instantly" },
  { value: DeviceShippingMethod.RequestZeltShipping, label: "Request Zelt shipping (Charges apply)" }
];
export const getDeviceOwnerByDevicePossession = (devicePossession, site, getCachedUserById) => {
  var _a, _b;
  if (!devicePossession) return "Unknown";
  if (devicePossession.possessionType === DevicePossessionType.User) {
    return (_b = (_a = getCachedUserById(devicePossession.possessionId)) == null ? void 0 : _a.displayName) != null ? _b : "Unknown";
  }
  if (devicePossession.possessionType === DevicePossessionType.CompanySite)
    return site ? `${site.name} Site` : "Company Site";
  if (devicePossession.possessionType === DevicePossessionType.ZeltStorage) return "Zelt Storage";
  if (devicePossession.possessionType === DevicePossessionType.ZeltStock) return "Zelt Stock";
  return "Unknown";
};
export const getUserNamesKeyedByUserIds = (users) => {
  return users.reduce((accumulator, user) => {
    var _a;
    accumulator[user.userId] = (_a = user.displayName) != null ? _a : `${user.firstName} ${user.lastName}`;
    return accumulator;
  }, {});
};
export const getCompanyNamesKeyedByCompanyIds = (companies) => {
  return companies.reduce((accumulator, company) => {
    accumulator[company.companyId] = company.name;
    return accumulator;
  }, {});
};
export const getDeviceOwnerAsSuperadminByDevicePossession = (devicePossession, usersNames, sitesById) => {
  var _a, _b;
  if (!devicePossession) return "Unknown";
  if (devicePossession.possessionType === DevicePossessionType.User) {
    return (_a = usersNames[devicePossession.possessionId]) != null ? _a : "Unknown";
  }
  if (devicePossession.possessionType === DevicePossessionType.CompanySite)
    return sitesById[devicePossession.possessionId] ? `${(_b = sitesById[devicePossession.possessionId]) == null ? void 0 : _b.name} Site` : "Company Site";
  if (devicePossession.possessionType === DevicePossessionType.ZeltStorage) return "Zelt Storage";
  if (devicePossession.possessionType === DevicePossessionType.ZeltStock) return "Zelt Stock";
  return "Unknown";
};
export const getDeviceOwnerByDevicePossessionTable = (devicePossession, site, polyglot, displayName) => {
  if (!devicePossession) return "Unknown";
  if (devicePossession.possessionType === DevicePossessionType.User && displayName) {
    return devicePossession.possessionId ? /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          alignItems: "center",
          gap: spacing.m5
        },
        children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId: devicePossession.possessionId, size: "xxsmall" }, `avatar-${devicePossession.id}`),
          polyglot.t(displayName != null ? displayName : "")
        ]
      },
      devicePossession.id
    ) : "Unknown";
  }
  if (devicePossession.possessionType === DevicePossessionType.User) return "Could not identify user owner";
  if (devicePossession.possessionType === DevicePossessionType.CompanySite) {
    return site ? `${site.name} Site` : "Company Site";
  }
  if (devicePossession.possessionType === DevicePossessionType.ZeltStorage) return "Zelt Storage";
  if (devicePossession.possessionType === DevicePossessionType.ZeltStock) return "Zelt Stock";
  return "Unknown";
};
const deviceImageMapping = {
  [DeviceType.Laptop]: {
    lenovo: {
      x1: LenovoThinkpadCarbonX1,
      ideapad: LenovoIdeapad,
      thinkbook: LenovoThinkpadCarbonX1
    },
    acer: {
      aspire: AcerAspire3
    },
    dell: {
      xps: DellXPS,
      inspiron: DellInspiron15
    },
    lg: {
      gram: LGGram
    },
    macbookair: MacbookAir,
    macbookpro: MacbookPro,
    surface: MicrosoftSurface
  },
  [DeviceType.Tablet]: {
    lenovotabm7: LenovoTabM7,
    ipad: ipad12pro
  },
  [DeviceType.Phone]: {
    iphone: iphone13pro,
    pixel: pixel8
  },
  [DeviceType.Monitor]: {
    dell32curved4k: Dell32Curved4KUHDMonitor,
    dell274k: Dell274KMonitor,
    dell27: Dell27Monitor,
    dellmonitor24: DellMonitorP2422h
  },
  [DeviceType.Desktop]: MonitorStore,
  [DeviceType.Keyboard]: KeyboardStore,
  [DeviceType.Mouse]: MouseStore,
  [DeviceType.Headset]: HeadsetStore,
  [DeviceType.Other]: OtherStore
};
const getImageSrc = (type, name) => {
  if (!type || type === null) return deviceImageMapping[DeviceType.Other];
  const typeMapping = deviceImageMapping[type] || deviceImageMapping[DeviceType.Other];
  if (typeof typeMapping === "string") {
    return typeMapping;
  }
  for (const key in typeMapping) {
    if (name.includes(key)) {
      const nestedMapping = typeMapping[key];
      if (typeof nestedMapping === "string") {
        return nestedMapping;
      }
      for (const nestedKey in nestedMapping) {
        if (name.includes(nestedKey)) {
          return nestedMapping[nestedKey];
        }
      }
    }
  }
  switch (type) {
    case DeviceType.Laptop:
      return LaptopStore;
    case DeviceType.Tablet:
      return TabletStore;
    case DeviceType.Phone:
      return PhoneStore;
    case DeviceType.Monitor:
      return MonitorStore;
    case DeviceType.Desktop:
      return MonitorStore;
    case DeviceType.Keyboard:
      return KeyboardStore;
    case DeviceType.Mouse:
      return MouseStore;
    case DeviceType.Headset:
      return HeadsetStore;
    default:
      return OtherStore;
  }
};
export const getModelImage = (type, modelName, size = {
  width: "100%",
  height: "auto"
}) => {
  const name = (modelName != null ? modelName : "").toLowerCase().replaceAll(" ", "");
  const imgSrc = getImageSrc(type, name);
  return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: name, src: imgSrc }, size), { loading: "lazy", style: { objectFit: "contain" } }));
};
const iconSize = { width: 14, height: 14 };
export const getOrderStatus = (status) => {
  switch (status) {
    case DeviceOrderStatus.Placed:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", color: themeColors.Grey }, children: [
        /* @__PURE__ */ jsx(Clock, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Grey } })),
        " Placed"
      ] });
    case DeviceOrderStatus.Requested:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", color: themeColors.Grey }, children: [
        /* @__PURE__ */ jsx(Clock, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Grey } })),
        status
      ] });
    case DeviceOrderStatus.Rejected:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", color: themeColors.Red }, children: [
        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        " ",
        status
      ] });
    case DeviceOrderStatus.Cancelled:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Problem, __spreadValues({}, iconSize)),
        " ",
        status
      ] });
    case DeviceOrderStatus.Accepted:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
        " Accepted"
      ] });
    case DeviceOrderStatus.Shipping:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Shipping, __spreadValues({}, iconSize)),
        " Shipping"
      ] });
    case DeviceOrderStatus.Delivered:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadValues({}, iconSize)),
        " ",
        status
      ] });
    default:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } })),
        " ",
        status
      ] });
  }
};
export const getTransitStatus = (status) => {
  switch (status) {
    case DeviceTransitStatus.Pending:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", color: themeColors.Grey }, children: [
        /* @__PURE__ */ jsx(Clock, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Grey })),
        " Pending"
      ] });
    case DeviceTransitStatus.Rejected:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        " ",
        status
      ] });
    case DeviceTransitStatus.Cancelled:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Problem, __spreadValues({}, iconSize)),
        " ",
        status
      ] });
    case DeviceTransitStatus.Shipping:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Shipping, __spreadValues({}, iconSize)),
        " Shipping"
      ] });
    case DeviceTransitStatus.Delivered:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
        " ",
        status
      ] });
    default:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } })),
        " ",
        status
      ] });
  }
};
export const getFullPriceDetails = (deviceModel, deliveryCountry) => {
  const price = deliveryCountry === OrderDeliveryCountry.UK ? deviceModel.fullPrice : deviceModel.fullPriceEu;
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "block" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Payment details" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Purchase price (ex. VAT)" }),
      /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
        "\xA3",
        price
      ] })
    ] })
  ] });
};
export const getRentalPaymentDetails = (deviceModel, contractLength, deliveryCountry) => {
  const isEUDelivery = deliveryCountry === OrderDeliveryCountry.EU;
  const devicePrice = isEUDelivery ? contractLength === 24 ? deviceModel.priceEu24 : deviceModel.priceEu36 : contractLength === 24 ? deviceModel.price24 : deviceModel.price36;
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "block" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Payment details" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Monthly Rent (ex. VAT)" }),
      /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
        "\xA3",
        devicePrice
      ] })
    ] })
  ] });
};
export const FieldValueComponent = ({
  title,
  value,
  titleSx = {},
  titleVariant = "caption",
  titleColor
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.s1 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: titleVariant, color: titleColor, sx: titleSx, children: title }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: value })
  ] });
};
const getDeviceSecurityScore = (selectedPolicies, device) => {
  let counter = 0;
  const checkPolicyApplies = (policyTypeName, deviceProperty) => {
    if (selectedPolicies.includes(policyTypeName) && deviceProperty) {
      counter += 1;
    }
  };
  const checkPolicyApplied = (policyTypeName, deviceProperty) => {
    if (selectedPolicies.includes(policyTypeName) && deviceProperty) {
      return true;
    }
    return false;
  };
  const checkHexnodePolicies = (policyType, externalId) => {
    var _a;
    if (selectedPolicies.includes(policyType) && ((_a = device.policies) == null ? void 0 : _a.some((p) => p.id === externalId))) {
      counter += 1;
    }
  };
  if (device.inHouseMdm) {
    checkPolicyApplies(ConfigurablePoliciesByZeltMdm.configurablePasscode, device.passwordProtected);
    checkPolicyApplies(ConfigurablePoliciesByZeltMdm.configurableICloudRestriction, device.icloudRestrictionEnabled);
    checkPolicyApplies(ConfigurablePoliciesByZeltMdm.configurableFirewallPolicy, device.firewallEnabled);
    if (device.latestAvailableOsVersion && device.osVersion && device.latestAvailableOsVersion <= device.osVersion)
      counter += 1;
    if (device.availableStorage && device.storage) {
      const memoryPercentage = device.storage && device.storage > 0 && device.availableStorage ? 100 - device.availableStorage / device.storage * 100 : 0;
      if (memoryPercentage < 80) {
        counter += 1;
      }
    }
    if (device.activationLockBypassKey) {
      counter += 1;
    }
    if (checkPolicyApplied(ConfigurablePoliciesByZeltMdm.configurableEncryptionPolicy, device.encryptionEnabled) && device.filevaultKey) {
      counter += 1;
    }
    if (device.lastCheckIn && differenceInDays(/* @__PURE__ */ new Date(), new Date(device.lastCheckIn)) <= 3) counter += 1;
  } else {
    checkPolicyApplies(DevicePolicyType.passcode.valueOf(), device.passwordProtected);
    checkPolicyApplies(DevicePolicyType.encryption.valueOf(), device.encryptionEnabled);
    checkPolicyApplies(DevicePolicyType.firewall.valueOf(), device.firewallEnabled);
    checkHexnodePolicies(DevicePolicyType.location, 17);
    if (device.lastCheckIn && differenceInDays(/* @__PURE__ */ new Date(), new Date(device.lastCheckIn)) <= 3) counter += 1;
  }
  return counter;
};
const supportedPoliciesToSecurityScore = [
  "configurableFirewallPolicy",
  "configurableEncryptionPolicy",
  "configurableICloudRestriction",
  "configurablePasscode",
  "configurableActivationLock"
];
const calculateSecurityScore = (device, appliedZeltPolicies, appliedZeltMobilePolicies, appliedHexPolicies) => {
  var _a;
  let policiesSelectedToApply = [];
  function processSettings(policyValues) {
    if (Array.isArray(policyValues)) {
      return policyValues.length > 0;
    } else if (policyValues !== null && "ActivationLockAllowedWhileSupervised" in policyValues) {
      if (policyValues.ActivationLockAllowedWhileSupervised && policyValues.ActivationLockAllowedWhileSupervised === true) {
        return true;
      }
    } else {
      return Object.keys(policyValues).length > 0;
    }
  }
  if (device.inHouseMdm) {
    const mobileDevices = ["ipados", "ios"];
    const isMobileAppleDevice = mobileDevices.includes((_a = device.os) != null ? _a : "");
    if (appliedZeltPolicies && !isMobileAppleDevice) {
      policiesSelectedToApply = Object.keys(appliedZeltPolicies).filter((key) => key.startsWith("configurable") && key !== "configurableApps").filter((key) => supportedPoliciesToSecurityScore.includes(key)).filter((key) => {
        const policyValues = appliedZeltPolicies[key];
        return policyValues && processSettings(policyValues);
      }).map((a) => a);
    }
    if (appliedZeltMobilePolicies && isMobileAppleDevice) {
      policiesSelectedToApply = Object.keys(appliedZeltMobilePolicies).filter((key) => key.startsWith("configurable") && key !== "configurableApps").filter((key) => supportedPoliciesToSecurityScore.includes(key)).filter((key) => {
        const policyValues = appliedZeltMobilePolicies[key];
        return policyValues && processSettings(policyValues);
      }).map((a) => a);
    }
  } else {
    if (appliedHexPolicies) {
      policiesSelectedToApply = Object.keys(appliedHexPolicies).filter(
        (p) => appliedHexPolicies[p].some(
          (a) => a !== 0
        ) && p !== "apps" && p !== "icloud" && p !== "updates"
      ).map((a) => a);
    }
  }
  let maxScore = policiesSelectedToApply.length;
  if (device.inHouseMdm) {
    maxScore += 3;
  } else {
    maxScore += 1;
  }
  return { maxScore, securityScore: getDeviceSecurityScore(policiesSelectedToApply, device) };
};
const getComplianceScoreOfDevicePossessionByMDM = (devicePossession, appliedZeltPolicies, appliedZeltMobilePolicies, appliedHexPolicies) => {
  if (!devicePossession) return { status: SecurityStatus.None, tooltip: null, missingPoliciesCounter: null };
  if (!(devicePossession == null ? void 0 : devicePossession.device)) return { status: SecurityStatus.None, tooltip: null, missingPoliciesCounter: null };
  if (devicePossession.device.enrollmentStatus === "unenrolled" || !devicePossession.device.enrollmentStatus)
    return { status: SecurityStatus.NotEnrolled, tooltip: "Disenroll", missingPoliciesCounter: null };
  if (!Boolean(
    devicePossession.device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED || devicePossession.device.enrollmentStatus === "enrolled"
  ))
    return { status: SecurityStatus.NotEnrolled, tooltip: "MDM not installed", missingPoliciesCounter: null };
  const riskScore = calculateSecurityScore(
    devicePossession.device,
    appliedZeltPolicies,
    appliedZeltMobilePolicies,
    appliedHexPolicies
  );
  if (riskScore.maxScore > riskScore.securityScore) {
    return {
      status: SecurityStatus.Alert,
      tooltip: "Some configurations are missing",
      missingPoliciesCounter: riskScore.maxScore - riskScore.securityScore
    };
  }
  return { status: SecurityStatus.Good, tooltip: null, missingPoliciesCounter: 0 };
};
export const getSecurityStatusIcon = (status, tooltip, missingPoliciesCounter) => {
  if (status === SecurityStatus.NotEnrolled)
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(MistakeIcon, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.GreyMiddle })),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: SecurityStatus.NotEnrolled })
    ] });
  if (status === SecurityStatus.Alert)
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(MistakeIcon, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
      /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "Red", children: [
        missingPoliciesCounter,
        " ",
        missingPoliciesCounter === 1 ? "alert" : "alerts"
      ] })
    ] });
  if (status === SecurityStatus.Good)
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Checked, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.green } })),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Good" })
    ] });
  return /* @__PURE__ */ jsx(EmptyCell, {});
};
export const getRiskStatus = (devicePossession, appliedZeltPolicies, appliedZeltMobilePolicies, appliedHexPolicies) => {
  var _a;
  const { status, tooltip, missingPoliciesCounter } = getComplianceScoreOfDevicePossessionByMDM(
    devicePossession,
    appliedZeltPolicies,
    appliedZeltMobilePolicies,
    appliedHexPolicies
  );
  if ((_a = devicePossession.device) == null ? void 0 : _a.mdmable) return getSecurityStatusIcon(status, tooltip, missingPoliciesCounter);
  return /* @__PURE__ */ jsx(EmptyCell, {});
};
export const getTechSpecs = (deviceModel) => {
  let detail = [];
  if (deviceModel.modelVersion) detail.push(`Model ${deviceModel.modelVersion}`);
  if (deviceModel.screenSize) detail.push(`Size ${deviceModel.screenSize} inch`);
  if (deviceModel.storage) detail.push(`Storage ${deviceModel.storage} GB`);
  if (deviceModel.gpuCores) detail.push(`GPU ${deviceModel.gpuCores} core`);
  if (deviceModel.cpuCores) detail.push(`CPU ${deviceModel.cpuCores} core`);
  if (deviceModel.ram) detail.push(`RAM ${deviceModel.ram} GB`);
  return detail;
};
export const isEnroled = (enrolmentStatus) => {
  if (!enrolmentStatus) {
    return false;
  }
  return ["ENROLMENT_FINISHED", "enrolled"].includes(enrolmentStatus);
};
export const getMdmServer = (device, matchings) => {
  const mdmType = (device == null ? void 0 : device.inHouseMdm) ? "Zelt MDM" : "Hexnode";
  const externalId = (device == null ? void 0 : device.id) ? matchings[device.id] : null;
  const mdmResult = mdmType === "Hexnode" && !externalId ? "None" : mdmType;
  return mdmResult;
};
export const isUrlValid = (url) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z/\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$",
    // fragment locator
    "i"
  );
  return pattern.test(url);
};
export const processMdmServerName = (mdmServerName) => {
  return mdmServerName.replaceAll(" ", "_").replaceAll(/[^\p{L}\p{N}_]+/gu, "");
};
