"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import {
  AttendanceStatus,
  ScheduleTrackingTimesheetType,
  ScheduleTrackingType,
  WeekDayNames
} from "@v2/feature/attendance/attendance.interface";
import { dateFieldTest, isMonday } from "@v2/infrastructure/date/date-format.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { LocalDate } from "@v2/util/local-date";
import { format } from "date-fns";
import * as yup from "yup";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as WaitingEmpty } from "@/images/side-bar-icons/WaitingEmpty.svg";
import { ReactComponent as WaitingEmptyRed } from "@/images/side-bar-icons/WaitingEmptyRed.svg";
import { spacing } from "@/v2/styles/spacing.styles";
export const getTheDateOfFirstMondayInYear = (year = (/* @__PURE__ */ new Date()).getFullYear()) => {
  const firstDay = new Date(year, 0, 1);
  const dayOfWeek = firstDay.getDay();
  const daysToAdd = (8 - dayOfWeek) % 7;
  const firstMonday = new Date(firstDay);
  firstMonday.setDate(firstDay.getDate() + daysToAdd);
  return new LocalDate(firstMonday).toDateString();
};
export const EMPLOYEE_SITE = (polyglot) => {
  return { value: "employeeSite", label: polyglot.t("EMPLOYEE_SITE.employeeSite") };
};
export const getEmptyDaySlot = () => ({
  from: "",
  to: "",
  fromTimestamp: null,
  toTimestamp: null,
  break: "",
  totalHours: ""
});
const getDayScheduleValidationSchema = (polyglot) => yup.array().of(
  yup.object({
    from: yup.string().required(),
    to: yup.string().required(),
    fromTimestamp: yup.date().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
    toTimestamp: yup.date().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
    break: yup.string().notRequired().matches(/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d$/, polyglot.t("AttendanceDomain.validTimeValue"))
  }).nullable().notRequired()
).required(polyglot.t("validation.requiredField"));
export const getScheduleValidationSchemaV2 = (polyglot) => yup.object({
  monday: getDayScheduleValidationSchema(polyglot),
  tuesday: getDayScheduleValidationSchema(polyglot),
  wednesday: getDayScheduleValidationSchema(polyglot),
  thursday: getDayScheduleValidationSchema(polyglot),
  friday: getDayScheduleValidationSchema(polyglot),
  saturday: getDayScheduleValidationSchema(polyglot),
  sunday: getDayScheduleValidationSchema(polyglot),
  noOfWeeks: yup.number().typeError(polyglot.t("validation.selectValid")).integer(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
  startDateOfFirstWeek: yup.string().test(dateFieldTest).test(isMonday).required(polyglot.t("validation.requiredField"))
});
export const getScheduleGeneralValidationSchema = (polyglot) => yup.object({
  name: yup.string().required(polyglot.t("validation.requiredField"))
});
export const getScheduleTrackingSettingsValidationSchema = (polyglot) => yup.object({
  trackingType: yup.string().required(polyglot.t("validation.requiredField")),
  attendanceTypesAllowedIds: yup.array().of(
    yup.number().integer().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField"))
  ).required(polyglot.t("validation.requiredField")),
  timesheetType: yup.string().oneOf([
    ScheduleTrackingTimesheetType.Daily,
    ScheduleTrackingTimesheetType.Weekly,
    ScheduleTrackingTimesheetType.Monthly
  ]).required(polyglot.t("validation.selectValid")),
  useGeolocation: yup.boolean().required(polyglot.t("validation.requiredField")),
  restrictByGeolocation: yup.boolean().required(polyglot.t("validation.requiredField")),
  geolocationDistance: yup.number().when("restrictByGeolocation", {
    is: true,
    then: (schema) => schema.integer(polyglot.t("validation.selectValid")).typeError(polyglot.t("validation.selectValid")).min(100, polyglot.t("AttendanceDomain.minimumDistanceAllowed", { distance: 100 })).max(500, polyglot.t("AttendanceDomain.maximumDistanceAllowed", { distance: 500 })).required(polyglot.t("validation.requiredField")),
    otherwise: (schema) => schema.nullable().notRequired()
  }),
  geolocationEmployeeSite: yup.boolean().required(polyglot.t("validation.requiredField")),
  allowedWorkSites: yup.array().when("restrictByGeolocation", {
    is: true,
    then: (schema) => schema.when("geolocationEmployeeSite", {
      is: true,
      then: (schema2) => schema2.of(yup.number().integer().typeError(polyglot.t("validation.selectValid"))).nullable().notRequired(),
      otherwise: (schema2) => schema2.of(yup.number().integer().typeError(polyglot.t("validation.selectValid"))).min(1, polyglot.t("validation.requiredField")).required(polyglot.t("validation.requiredField"))
    }),
    otherwise: (schema) => schema.nullable().notRequired()
  }),
  clockInEarlyCapMinutes: yup.number().when("trackingType", {
    is: (val) => val === ScheduleTrackingType.ClockInClockOut,
    then: (schema) => schema.integer().typeError(polyglot.t("validation.selectValid")).min(0, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
    otherwise: (schema) => schema.nullable().notRequired()
  })
});
export const getScheduleApprovalValidationSchema = (polyglot) => yup.object({
  approvalRuleId: yup.number().integer().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField"))
});
export const getSchedulePayrollValidationSchema = (polyglot) => yup.object({
  includedInPayroll: yup.boolean().required(polyglot.t("validation.requiredField")),
  payCode: yup.string().when("includedInPayroll", {
    is: true,
    then: (schema) => schema.required(polyglot.t("validation.requiredField")),
    otherwise: (schema) => schema.notRequired()
  })
});
export const getScheduleSettingsValidationSchema = (polyglot) => yup.object({
  isFlexible: yup.boolean().required(polyglot.t("validation.requiredField")),
  fteEquivalent: yup.number().integer(polyglot.t("validation.selectValid")).typeError(polyglot.t("validation.selectValid")).min(1, polyglot.t("validation.selectValid")).max(10080, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
  fteEquivalentInDays: yup.number().typeError(polyglot.t("validation.selectValid")).min(1, polyglot.t("validation.selectValid")).max(7, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField"))
});
export function getTrackingTypeLabel(type, polyglot) {
  if (type === ScheduleTrackingType.None) return polyglot.t("getTrackingTypeLabel.noTracking");
  if (type === ScheduleTrackingType.ClockInClockOut) return polyglot.t("getTrackingTypeLabel.clockInOut");
  if (type === ScheduleTrackingType.Regular) return polyglot.t("getTrackingTypeLabel.regularTracking");
  return "Unknown tracking type";
}
export function getTrackingTypeLabelDescription(type, polyglot) {
  if (type === ScheduleTrackingType.None) return polyglot.t("getTrackingTypeLabelDescription.employees");
  if (type === ScheduleTrackingType.ClockInClockOut) return polyglot.t("getTrackingTypeLabelDescription.clockIn");
  if (type === ScheduleTrackingType.Regular) return polyglot.t("getTrackingTypeLabelDescription.loggingHours");
  return "";
}
export const getAllTimeFromScheduleByWeekIndex = (schedule, weekIndex) => {
  const lengthByDays = WeekDayNames.map((day) => {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!schedule[day] || !schedule[day][weekIndex] || !schedule[day][weekIndex].fromTimestamp || !schedule[day][weekIndex].toTimestamp)
      return 0;
    if (schedule.isFlexible) {
      const [totalHoursH, totalHoursM] = schedule[day] && ((_a = schedule[day][weekIndex].totalHours) == null ? void 0 : _a.slice(11, 16).match(/^\d\d:\d\d$/g)) ? (_b = schedule[day][weekIndex].totalHours) == null ? void 0 : _b.slice(11, 16).split(":") : [0, 0];
      return Number(totalHoursM) + Number(totalHoursH) * 60;
    } else {
      const [breakH, breakM] = schedule[day] && ((_d = (_c = schedule[day][weekIndex]) == null ? void 0 : _c.break) == null ? void 0 : _d.slice(11, 16).match(/^\d\d:\d\d$/g)) ? (_e = schedule[day][weekIndex]) == null ? void 0 : _e.break.slice(11, 16).split(":") : ["0", "0"];
      const dayBreakMins = Number(breakM) + Number(breakH) * 60;
      const toTicks = (_f = new Date(schedule[day][weekIndex].toTimestamp).getTime()) != null ? _f : 0;
      const fromTicks = (_g = new Date(schedule[day][weekIndex].fromTimestamp).getTime()) != null ? _g : 0;
      let dayTime = schedule[day] && schedule[day][weekIndex] ? (toTicks - fromTicks) / (1e3 * 60) : 0;
      if (toTicks < fromTicks) dayTime += 24 * 60;
      return dayTime - dayBreakMins;
    }
  });
  return lengthByDays.reduce((a, b) => a + b);
};
export function calculateTotalEntriesDuration(allEntries, polyglot) {
  let totalDuration = 0;
  const entries = allEntries.filter(
    (entry) => entry.startHourTimestamp && entry.endHourTimestamp && new Date(entry.endHourTimestamp).getTime() > new Date(entry.startHourTimestamp).getTime()
  );
  entries.sort((a, b) => new Date(a.startHourTimestamp).getTime() - new Date(b.startHourTimestamp).getTime());
  let previousEnd = null;
  entries.forEach((entry) => {
    const { startHourTimestamp, endHourTimestamp } = entry;
    const start = new Date(startHourTimestamp);
    const end = new Date(endHourTimestamp);
    if (previousEnd && start.getTime() < previousEnd.getTime()) {
      if (end.getTime() > previousEnd.getTime()) {
        totalDuration += end.getTime() - previousEnd.getTime();
        previousEnd = end;
      }
    } else {
      totalDuration += end.getTime() - start.getTime();
      previousEnd = end;
    }
  });
  const totalLengthInMinutes = Math.round(totalDuration / (1e3 * 60));
  return convertMinutesToClockHours(totalLengthInMinutes, polyglot);
}
export const getAttendanceStatusIcon = (status, cancellationRequested, polyglot) => {
  switch (status) {
    case AttendanceStatus.InProgress:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(WaitingEmpty, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("AttendanceStatus.inprogress") })
      ] });
    case AttendanceStatus.Submitted:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(WaitingEmpty, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("AttendanceStatus.submitted") })
      ] });
    case AttendanceStatus.Approved:
      return cancellationRequested ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(WaitingEmptyRed, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Red }), children: polyglot.t("AttendanceStatus.pendingcancellation") })
      ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: polyglot.t("AttendanceStatus.approved") })
      ] });
    case AttendanceStatus.Rejected:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: polyglot.t("AttendanceStatus.rejected") })
      ] });
    case "none":
      return /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("AttendanceStatus.none") });
    default:
      return /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: status });
  }
};
export function getWeekDates(year, weekNumber) {
  const startDate = new Date(year, 0, 1);
  const firstDay = startDate.getDay();
  const daysToAdd = (weekNumber - 1) * 7 - firstDay + 1;
  startDate.setDate(startDate.getDate() + daysToAdd);
  const dates = [];
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate.getTime());
    currentDate.setDate(currentDate.getDate() + i);
    dates.push({
      value: new LocalDate(currentDate).toDateString(),
      label: format(currentDate, "eee")
    });
  }
  return dates;
}
export function getTimeFromDateString(dateString) {
  const dateTimeParts = dateString.split("T");
  return dateTimeParts[1].substring(0, 5);
}
export function getWeekStatusFromAttendanceStatuses(weekStatuses) {
  if (weekStatuses.size === 0) return "none";
  if (weekStatuses.size === 1) return [...weekStatuses][0];
  if (weekStatuses.has(AttendanceStatus.InProgress)) return AttendanceStatus.InProgress;
  if (weekStatuses.has(AttendanceStatus.Rejected)) return AttendanceStatus.Rejected;
  return AttendanceStatus.Submitted;
}
export const getBreakFromSchedule = (day, userSchedule, logDate) => {
  if (!userSchedule) return 0;
  const weekIndex = getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks);
  const breakValue = userSchedule[day] && userSchedule[day][weekIndex] ? userSchedule[day][weekIndex].break : null;
  if (!breakValue) return 0;
  const [h, m] = breakValue.split("T")[1].split(":");
  return Number(h) * 60 + Number(m);
};
export const getTotalHoursFromFlexibleSchedule = (day, userSchedule, logDate) => {
  if (!userSchedule) return 0;
  const weekIndex = getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks);
  const totalHours = userSchedule && userSchedule[day] && userSchedule[day][weekIndex] ? userSchedule[day][weekIndex].totalHours : null;
  if (!totalHours) return 0;
  const [h, m] = totalHours.split("T")[1].split(":");
  return Number(h) * 60 + Number(m);
};
export const getRegularTimeFromInterval = (day, userSchedule, breakTime, logDate) => {
  if (!userSchedule) return 0;
  const weekIndex = getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks);
  const dayData = userSchedule && userSchedule[day] && userSchedule[day][weekIndex] ? userSchedule[day][weekIndex] : null;
  if (!dayData || !dayData.from || !dayData.to) return 0;
  return Math.round((new Date(dayData.to).getTime() - new Date(dayData.from).getTime()) / 6e4) - breakTime;
};
export const getScheduleExpectedRegularAndBreakTimeByDay = (day, userSchedule, logDate) => {
  if (userSchedule == null ? void 0 : userSchedule.isFlexible)
    return {
      Regular: getTotalHoursFromFlexibleSchedule(day, userSchedule, logDate),
      Break: 0
    };
  const dayBreak = getBreakFromSchedule(day, userSchedule, logDate);
  const dayRegular = getRegularTimeFromInterval(day, userSchedule, dayBreak, logDate);
  return {
    Regular: dayRegular,
    Break: dayBreak
  };
};
export const getWeekInterval = (year, weekNo) => {
  const date = new Date(year, 0, 1);
  const startDay = 1;
  while (date.getDay() !== startDay) {
    date.setDate(date.getDate() + 1);
  }
  date.setDate(date.getDate() + (weekNo - 1) * 7);
  const startDate = new Date(date.getTime());
  startDate.setDate(startDate.getDate() - startDate.getDay() + startDay);
  const endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + 6);
  return {
    start: new LocalDate(startDate).toDateString(),
    end: new LocalDate(endDate).toDateString()
  };
};
