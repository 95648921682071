"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { activeTabCount, activeTabsFilterBtnSx, tabCountSx, tabsFilterBtnSx } from "@/v2/styles/buttons.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function TabFilterButtons({
  filters,
  hasSearch,
  setFilterValue,
  filterValue,
  onFilterChange,
  showCount,
  disabled,
  handleBlur
}) {
  const [searchInput, setSearchInput] = useState("");
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        gap: spacing.m5,
        alignItems: "center",
        justifyContent: "flex-start"
      },
      children: [
        filters.map((tab) => /* @__PURE__ */ jsxs(
          Button,
          {
            sx: __spreadProps(__spreadValues({}, tab.value === filterValue ? activeTabsFilterBtnSx : tabsFilterBtnSx), {
              display: "flex",
              gap: spacing.g5
            }),
            onClick: () => {
              setFilterValue(tab.value);
              onFilterChange == null ? void 0 : onFilterChange({
                filterValue: tab.value,
                searchInput
              });
            },
            disableRipple: true,
            disabled: disabled || tab.disabled,
            children: [
              tab.name,
              " ",
              showCount && tab.count !== void 0 && tab.count > 0 ? /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, tab.value === filterValue ? activeTabCount : tabCountSx), children: tab.count }) : void 0
            ]
          },
          tab.value
        )),
        hasSearch && /* @__PURE__ */ jsx(
          TableSearch,
          {
            disabled,
            query: searchInput,
            handleChange: (e) => {
              const newSearchInput = e.target.value;
              setSearchInput(newSearchInput);
              onFilterChange == null ? void 0 : onFilterChange({
                filterValue,
                searchInput: newSearchInput
              });
            },
            handleBlur
          }
        )
      ]
    }
  );
}
