"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { isHourlyPolicy } from "@v2/feature/absence/absence.util";
import { isDefined } from "@v2/feature/user-onboarding/user-onboarding.util";
import { COUNTRY_CODE_TO_NAME_MAPPING, COUNTRY_ISO_CODE_MAPPING } from "@v2/infrastructure/country/country.interface";
import {
  ddmmyyyyDateFormat,
  ddMMYYYYToIsoDateString,
  isoDateAndTimeFormat,
  isoDateFormat,
  isoDateStringToDDMMYYY,
  isValidIsoDateString,
  parseDDMMYYYYDateAsLocalDateString
} from "@v2/infrastructure/date/date-format.util";
import { camelCaseToTitleCase, toTitleCase } from "@v2/util/string.util";
import { v4 } from "uuid";
import { AbsenceStatus } from "@/v2/feature/absence/absence.interface";
import { stringifiedBooleanValues } from "@/v2/util/array.util";
import { LocalDate } from "@/v2/util/local-date";
export const sanitizeUserImportRecord = (record) => {
  return Object.fromEntries(
    Object.entries(record).map(([key, value]) => {
      return [key, typeof value === "string" ? value.trim() : value];
    })
  );
};
const getCountryCodeOrDefault = (value) => {
  var _a;
  if (value.length === 2) {
    return value;
  }
  return value ? (_a = COUNTRY_ISO_CODE_MAPPING[value]) == null ? void 0 : _a.toLowerCase() : value;
};
const emailAddressForImport = (record, zeltUser) => {
  var _a, _b, _c, _d;
  if (zeltUser) {
    return ((_a = record == null ? void 0 : record.workEmail) == null ? void 0 : _a.length) && ((_b = record == null ? void 0 : record.workEmail) == null ? void 0 : _b.length) > 0 ? record == null ? void 0 : record.workEmail.toLowerCase().trim() : zeltUser == null ? void 0 : zeltUser.emailAddress;
  }
  return (_d = record.emailAddress) != null ? _d : (_c = record.workEmail) == null ? void 0 : _c.toLowerCase().trim();
};
const entityValueForMapping = (entityName, entityList) => {
  if (entityName && !!(entityList == null ? void 0 : entityList.find((eachEntity) => eachEntity.legalName === entityName))) return entityName;
  else return null;
};
export const prorateSalaryByFteValueForMapping = (record, attendanceSchedule) => {
  var _a, _b;
  if (attendanceSchedule && record.prorateSalaryByFte && stringifiedBooleanValues.has((_a = String(record.prorateSalaryByFte)) == null ? void 0 : _a.toLowerCase()))
    return JSON.parse((_b = String(record.prorateSalaryByFte)) == null ? void 0 : _b.toLowerCase());
  else return false;
};
export const compensationRateValueForMapping = (record, attendanceSchedule) => {
  if (attendanceSchedule && record.annualSalary && +record.annualSalary && prorateSalaryByFteValueForMapping(record, attendanceSchedule))
    return +attendanceSchedule.ftePercent / 100 * +record.annualSalary;
  else if (record.annualSalary && +record.annualSalary) return +record.annualSalary;
  else return void 0;
};
export const mapUserImportToZelt = (record, departments, sites, zeltUser, entityList, attendanceSchedules) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  const attendanceSchedule = attendanceSchedules == null ? void 0 : attendanceSchedules.find((schedule) => schedule.name === (record == null ? void 0 : record.attendanceSchedule));
  return Object.assign(
    {},
    __spreadProps(__spreadValues({}, record), {
      // length limited fields
      postCode: (record == null ? void 0 : record.postCode) ? (_a = record == null ? void 0 : record.postCode) == null ? void 0 : _a.slice(0, 20) : "",
      city: (record == null ? void 0 : record.city) ? (_b = record == null ? void 0 : record.city) == null ? void 0 : _b.slice(0, 35) : "",
      addressLine2: (record == null ? void 0 : record.addressLine2) ? (_c = record == null ? void 0 : record.addressLine2) == null ? void 0 : _c.slice(0, 35) : "",
      emailAddress: emailAddressForImport(record, zeltUser),
      workEmail: (_d = record == null ? void 0 : record.workEmail) != null ? _d : record == null ? void 0 : record.emailAddress,
      userId: zeltUser ? zeltUser.userId : (record == null ? void 0 : record.userId) ? Number(record.userId) : void 0,
      id: v4(),
      // Basic
      dob: record.dateOfBirth && isValidIsoDateString(record.dateOfBirth) ? new LocalDate(record.dateOfBirth).toDateString() : (_e = record.dateOfBirth && parseDDMMYYYYDateAsLocalDateString(record.dateOfBirth)) != null ? _e : void 0,
      gender: (_f = record.gender) == null ? void 0 : _f.toLowerCase(),
      // Address
      postcode: record.postCode,
      // Role
      departmentId: (_g = departments == null ? void 0 : departments.find((d) => d.label === record.department)) == null ? void 0 : _g.value,
      siteId: (_h = sites == null ? void 0 : sites.find((s) => s.label === record.site)) == null ? void 0 : _h.value,
      // Lifecycle
      startDate: record.startDate && isValidIsoDateString(record.startDate) ? new LocalDate(record.startDate).toDateString() : (_i = record.startDate && parseDDMMYYYYDateAsLocalDateString(record.startDate)) != null ? _i : void 0,
      leaveDate: record.leaveDate && isValidIsoDateString(record.leaveDate) ? new LocalDate(record.leaveDate).toDateString() : (_j = record.leaveDate && parseDDMMYYYYDateAsLocalDateString(record.leaveDate)) != null ? _j : void 0,
      // Compensation
      effectiveDate: record == null ? void 0 : record.startDate,
      currency: (record == null ? void 0 : record.compensationCurrency) ? record.compensationCurrency : void 0,
      compensationCurrency: (record == null ? void 0 : record.compensationCurrency) ? record.compensationCurrency : void 0,
      paySchedule: isDefined(record == null ? void 0 : record.paySchedule) ? record == null ? void 0 : record.paySchedule : void 0,
      salaryBasis: "Annual",
      rate: !prorateSalaryByFteValueForMapping(record, attendanceSchedule) ? compensationRateValueForMapping(record, attendanceSchedule) : (_k = record == null ? void 0 : record.proratedRate) != null ? _k : compensationRateValueForMapping(record, attendanceSchedule),
      prorateSalaryByFte: prorateSalaryByFteValueForMapping(record, attendanceSchedule),
      nonProratedRate: (record == null ? void 0 : record.nonProratedRate) ? record == null ? void 0 : record.nonProratedRate : record == null ? void 0 : record.annualSalary,
      proratedRate: compensationRateValueForMapping(record, attendanceSchedule),
      // Bank account
      bankName: record == null ? void 0 : record.bankName,
      bankCurrency: (record == null ? void 0 : record.bankCurrency) ? record.bankCurrency : void 0,
      accountName: record == null ? void 0 : record.accountHolderName,
      accountNumber: record == null ? void 0 : record.accountNumberIban,
      sortCode: (record == null ? void 0 : record.sortCodeBicSwift) ? record == null ? void 0 : record.sortCodeBicSwift : void 0,
      bankCountry: (record == null ? void 0 : record.bankCountry) && isDefined(record == null ? void 0 : record.bankCountry) && (record == null ? void 0 : record.bankCountry.length) === 2 ? COUNTRY_CODE_TO_NAME_MAPPING[record == null ? void 0 : record.bankCountry.toUpperCase()] : record == null ? void 0 : record.bankCountry,
      // Contract
      type: record == null ? void 0 : record.employmentType,
      contract: record == null ? void 0 : record.employmentContract,
      attendanceSchedule: (_l = attendanceSchedule == null ? void 0 : attendanceSchedule.name) != null ? _l : null,
      ftePercent: attendanceSchedule == null ? void 0 : attendanceSchedule.ftePercent,
      entityName: entityValueForMapping(record == null ? void 0 : record.entity, entityList),
      publicHolidays: (record == null ? void 0 : record.publicHolidays) ? getCountryCodeOrDefault(record == null ? void 0 : record.publicHolidays) : null,
      holidayCalendar: (record == null ? void 0 : record.holidayCalendar) ? getCountryCodeOrDefault(record == null ? void 0 : record.holidayCalendar) : null,
      country: (record == null ? void 0 : record.country) ? getCountryCodeOrDefault(record == null ? void 0 : record.country) : null,
      nationality: (record == null ? void 0 : record.nationality) ? getCountryCodeOrDefault(record == null ? void 0 : record.nationality) : null,
      probationPeriodUnit: (record == null ? void 0 : record.probationPeriodUnit) ? toTitleCase(record == null ? void 0 : record.probationPeriodUnit) : null,
      noticePeriodUnit: (record == null ? void 0 : record.noticePeriodUnit) ? toTitleCase(record == null ? void 0 : record.noticePeriodUnit) : null,
      // Leave
      leaveEntitlement: (record == null ? void 0 : record.leaveEntitlement) ? Number(record == null ? void 0 : record.leaveEntitlement) : null,
      leaveEntitlementType: (record == null ? void 0 : record.leaveEntitlementType) ? toTitleCase(record == null ? void 0 : record.leaveEntitlementType) : null,
      leaveEntitlementCurrency: (record == null ? void 0 : record.leaveEntitlementCurrency) ? record.leaveEntitlementCurrency : null,
      leaveEntitlementRate: (record == null ? void 0 : record.leaveEntitlementRate) ? Number(record == null ? void 0 : record.leaveEntitlementRate) : null,
      leaveEntitlementRateType: (record == null ? void 0 : record.leaveEntitlementRateType) ? toTitleCase(record == null ? void 0 : record.leaveEntitlementRateType) : null,
      leaveEntitlementRateCurrency: (record == null ? void 0 : record.leaveEntitlementRateCurrency) ? record.leaveEntitlementRateCurrency : null,
      leaveEntitlementRatePeriod: (record == null ? void 0 : record.leaveEntitlementRatePeriod) ? Number(record == null ? void 0 : record.leaveEntitlementRatePeriod) : null,
      // Equity
      // Equity - NOT BEING VALIDATED
      amount: (record == null ? void 0 : record.amount) ? Number(record == null ? void 0 : record.amount) : void 0,
      equityType: record == null ? void 0 : record.equityType,
      equityCurrency: void 0,
      grantDate: (record == null ? void 0 : record.grantDate) && isValidIsoDateString(record == null ? void 0 : record.grantDate) ? new LocalDate(record == null ? void 0 : record.grantDate).toDateString() : (_m = (record == null ? void 0 : record.grantDate) && parseDDMMYYYYDateAsLocalDateString(record == null ? void 0 : record.grantDate)) != null ? _m : void 0,
      vestingStart: (record == null ? void 0 : record.vestingStart) && isValidIsoDateString(record == null ? void 0 : record.vestingStart) ? new LocalDate(record == null ? void 0 : record.vestingStart).toDateString() : (_n = (record == null ? void 0 : record.vestingStart) && parseDDMMYYYYDateAsLocalDateString(record == null ? void 0 : record.vestingStart)) != null ? _n : void 0,
      unitPrice: (record == null ? void 0 : record.exercisePrice) ? Number(record == null ? void 0 : record.exercisePrice) : void 0,
      vestingPeriod: (record == null ? void 0 : record.exercisePrice) ? Number(record == null ? void 0 : record.vestingPeriodMonths) : void 0,
      vestingCliff: (record == null ? void 0 : record.exercisePrice) ? Number(record == null ? void 0 : record.vestingCliffMonths) : void 0,
      // Right to work - NOT BEING VALIDATED
      rightToWorkChecked: (record == null ? void 0 : record.rightToWorkCheckDate) && isDefined(record.rightToWorkCheckDate) ? true : false,
      rightToWorkCheckDate: record == null ? void 0 : record.rightToWorkCheckDate,
      rightToWorkDocumentTypes: record.documentType ? record.documentType : void 0,
      rightToWorkIsLimited: record.visaHasExpiry ? record.visaHasExpiry === true : void 0,
      rightToWorkExpiryDate: record.visaExpirationDate ? record.visaExpirationDate : void 0,
      // Emergency information
      emergencyName: record == null ? void 0 : record.emergencyName,
      emergencyRelationship: record == null ? void 0 : record.emergencyRelationship,
      emergencyNumber: record == null ? void 0 : record.emergencyNumber
    })
  );
};
const getDateBasedOnInputFormat = (inputDate) => {
  if (inputDate.match(isoDateFormat)) {
    return isoDateStringToDDMMYYY(inputDate);
  }
  if (inputDate.match(ddmmyyyyDateFormat)) {
    return inputDate;
  }
  return inputDate;
};
const valueEmptyOrUndefined = (value) => {
  return value === "" || !value;
};
export const mapAbsenceImportToZelt = (record, policy, zeltUser) => {
  var _a, _b, _c;
  const start = getDateBasedOnInputFormat(record.start);
  const end = !!record.end ? getDateBasedOnInputFormat(record.end) : null;
  const isHourly = isHourlyPolicy(policy);
  const isDailyWithHours = record.start && isoDateAndTimeFormat.test(record.start) || record.end && isoDateAndTimeFormat.test(record.end);
  let startHour = null;
  let endHour = null;
  let morningOnly = valueEmptyOrUndefined(String(record.morningOnly)) ? null : record.morningOnly;
  let afternoonOnly = valueEmptyOrUndefined(String(record.afternoonOnly)) ? null : record.afternoonOnly;
  if (isHourly || isDailyWithHours) {
    startHour = (_a = record.start) != null ? _a : null;
    endHour = (_b = record.end) != null ? _b : null;
    afternoonOnly = null;
    morningOnly = null;
  }
  return Object.assign(
    {},
    __spreadProps(__spreadValues({}, record), {
      id: v4(),
      workEmail: zeltUser ? zeltUser.emailAddress : record.workEmail.toLowerCase().trim(),
      userId: zeltUser ? zeltUser.userId : 0,
      policyName: (_c = policy == null ? void 0 : policy.fullName) != null ? _c : "",
      policyId: policy == null ? void 0 : policy.id,
      start,
      end,
      startHour,
      endHour,
      morningOnly,
      afternoonOnly,
      status: valueEmptyOrUndefined(String(record.status)) ? AbsenceStatus.Pending : record.status,
      notes: record.notes
    })
  );
};
export const mapAbsenceAdjustmentsImportToZelt = (record, policy, zeltUser) => {
  var _a, _b;
  return Object.assign(
    {},
    __spreadProps(__spreadValues({}, record), {
      id: v4(),
      workEmail: zeltUser ? zeltUser.emailAddress : record.workEmail.toLowerCase().trim(),
      userId: zeltUser ? zeltUser.userId : record.userId,
      policyName: (_a = policy == null ? void 0 : policy.fullName) != null ? _a : record.policyName,
      policyId: (_b = policy == null ? void 0 : policy.id) != null ? _b : record.policyId,
      effectiveYear: record.effectiveYear ? Number(record.effectiveYear) : record.effectiveYear,
      adjustment: record.adjustment,
      unit: record.unit,
      note: record.note
    })
  );
};
export const mapDeviceImportToZelt = (record) => {
  var _a;
  return Object.assign(
    {},
    __spreadProps(__spreadValues({}, record), {
      id: v4(),
      inUseBy: (_a = record.inUseBy) == null ? void 0 : _a.toLowerCase()
    })
  );
};
export const entityIdForName = (currentValues, entityList) => {
  var _a;
  return currentValues && (currentValues == null ? void 0 : currentValues.entityName) ? (_a = entityList == null ? void 0 : entityList.find((eachEntity) => eachEntity.legalName === (currentValues == null ? void 0 : currentValues.entityName))) == null ? void 0 : _a.id : void 0;
};
export const finalisePeopleDataForImport = (importState, entityList, attendanceSchedules, selectionModel) => {
  var _a, _b;
  return ((_a = importState == null ? void 0 : importState.result) == null ? void 0 : _a.errors.some((e) => e.entity)) ? ((_b = importState == null ? void 0 : importState.result) == null ? void 0 : _b.errors).filter(
    (e) => {
      var _a2, _b2;
      return (selectionModel == null ? void 0 : selectionModel.length) && (selectionModel == null ? void 0 : selectionModel.length) > 0 ? selectionModel == null ? void 0 : selectionModel.includes((_a2 = e.entity) == null ? void 0 : _a2.id) : (_b2 = e.entity) == null ? void 0 : _b2.id;
    }
  ).flatMap((record) => {
    var _a2, _b2, _c, _d, _e, _f, _g, _h;
    const {
      id,
      avatar,
      gender,
      userId,
      employeeId,
      firstName,
      lastName,
      displayName,
      emailAddress,
      nationality,
      passportNumber,
      personalEmail,
      phone,
      dateOfBirth,
      startDate,
      leaveDate,
      addressLine1,
      addressLine2,
      city,
      postCode,
      country,
      department,
      jobTitle,
      managerEmail,
      site,
      roleEffectiveDate,
      lifecycleStatus,
      lifecycleEffectiveDate,
      paySchedule,
      compensationCurrency,
      compensationEffectiveDate,
      prorateSalaryByFte,
      nonProratedRate,
      rate,
      accountHolderName,
      accountNumberIban,
      bankName,
      sortCodeBicSwift,
      bankCurrency,
      bankCountry,
      grantDate,
      equityType,
      amount,
      vestingStart,
      vestingCliffMonths,
      vestingPeriodMonths,
      exercisePrice,
      employmentContract,
      employmentType,
      contractEffectiveDate,
      ftePercent,
      changeReason,
      noticePeriodLength,
      noticePeriodUnit,
      probationPeriodLength,
      probationPeriodUnit,
      holidayCalendar,
      entityName,
      attendanceSchedule,
      rightToWorkCheckDate,
      documentType,
      visaHasExpiry,
      visaExpirationDate,
      emergencyName,
      emergencyNumber,
      emergencyRelationship
    } = record.entity;
    return {
      id,
      avatar,
      userId,
      employeeId,
      firstName,
      lastName,
      displayName,
      emailAddress,
      nationality: nationality && isDefined(nationality) ? COUNTRY_CODE_TO_NAME_MAPPING[nationality.toUpperCase()] : void 0,
      passportNumber,
      personalEmail,
      phone,
      dateOfBirth: dateOfBirth ? ddMMYYYYToIsoDateString(dateOfBirth) : dateOfBirth,
      startDate: startDate ? ddMMYYYYToIsoDateString(startDate != null ? startDate : "") : new LocalDate().toDateString(),
      // if start date does not exist, default to today
      leaveDate: leaveDate ? ddMMYYYYToIsoDateString(leaveDate != null ? leaveDate : "") : leaveDate,
      addressLine1,
      addressLine2,
      city,
      postCode,
      country: country && isDefined(country) ? COUNTRY_CODE_TO_NAME_MAPPING[country.toUpperCase()] : void 0,
      department,
      jobTitle,
      managerEmail,
      site,
      roleEffectiveDate: (_a2 = ddMMYYYYToIsoDateString(roleEffectiveDate != null ? roleEffectiveDate : "")) != null ? _a2 : void 0,
      lifecycleStatus,
      lifecycleEffectiveDate: (_b2 = ddMMYYYYToIsoDateString(lifecycleEffectiveDate != null ? lifecycleEffectiveDate : "")) != null ? _b2 : void 0,
      paySchedule,
      annualSalary: rate && !isNaN(+rate) ? String(rate) : void 0,
      currency: compensationCurrency,
      compensationEffectiveDate: (_c = ddMMYYYYToIsoDateString(compensationEffectiveDate != null ? compensationEffectiveDate : "")) != null ? _c : void 0,
      prorateSalaryByFte,
      nonProratedRate: nonProratedRate && !isNaN(+nonProratedRate) ? String(nonProratedRate) : void 0,
      accountHolderName,
      accountNumberIban,
      bankName,
      sortCodeBicSwift,
      bankCurrency,
      bankCountry,
      grantDate: (_d = ddMMYYYYToIsoDateString(grantDate != null ? grantDate : "")) != null ? _d : void 0,
      equityType,
      amount: amount ? String(amount) : amount,
      vestingStart: (_e = ddMMYYYYToIsoDateString(vestingStart != null ? vestingStart : "")) != null ? _e : void 0,
      vestingCliffMonths,
      vestingPeriodMonths,
      exercisePrice,
      employmentContract,
      employmentType,
      contractEffectiveDate: (_f = ddMMYYYYToIsoDateString(contractEffectiveDate != null ? contractEffectiveDate : "")) != null ? _f : void 0,
      attendanceScheduleId: (_h = (_g = attendanceSchedules.find((schedule) => schedule.name === attendanceSchedule)) == null ? void 0 : _g.id) != null ? _h : void 0,
      ftePercent: ftePercent ? String(ftePercent) : ftePercent,
      changeReason,
      noticePeriodLength: noticePeriodLength ? String(noticePeriodLength) : noticePeriodLength,
      noticePeriodUnit: noticePeriodUnit != null ? noticePeriodUnit : void 0,
      probationPeriodLength: probationPeriodLength ? String(probationPeriodLength) : probationPeriodLength,
      probationPeriodUnit: probationPeriodUnit != null ? probationPeriodUnit : void 0,
      holidayCalendar: holidayCalendar && isDefined(holidayCalendar) ? holidayCalendar : void 0,
      entityName,
      entityId: entityName ? entityIdForName(record.entity, entityList) : void 0,
      rightToWorkCheckDate: rightToWorkCheckDate ? ddMMYYYYToIsoDateString(String(rightToWorkCheckDate)) : rightToWorkCheckDate,
      documentType: documentType != null ? documentType : void 0,
      visaHasExpiry,
      visaExpirationDate: visaExpirationDate ? ddMMYYYYToIsoDateString(String(visaExpirationDate)) : visaExpirationDate,
      gender: gender ? toTitleCase(gender) : gender,
      emergencyName,
      emergencyRelationship,
      emergencyNumber,
      importSource: importState == null ? void 0 : importState.source
    };
  }) : [];
};
export const normaliseDataForDownload = (data) => {
  const normalisedData = [];
  for (const record of data) {
    const updatedRecord = {};
    for (const [k, v] of Object.entries(record)) {
      const fixedKey = camelCaseToTitleCase(k);
      updatedRecord[fixedKey] = v;
    }
    normalisedData.push(updatedRecord);
  }
  return normalisedData;
};
