"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, DEVICES_ME_DEVICE_SECURITY_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { IconContentActionCard } from "@/v2/components/theme-components/icon-content-action-card.component";
import { getModelImage, getTransitStatus } from "@/v2/feature/device/device.util";
import { DeviceReturnDrawer } from "@/v2/feature/device/features/devices-company/components/device-return-drawer.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MyDevicesReturns = ({
  deviceTransits,
  refreshDevicesData,
  userId
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDeviceTransit, setSelectedDeviceTransit] = useState(void 0);
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const currentUserId = globalState.user.userId;
  const hasDevicesAll = useMemo(
    () => checkScopes(globalState.user, ["devices:all"], { userId: globalState.user.userId }),
    [globalState.user]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Return" }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: deviceTransits.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: deviceTransits.map((d, idx) => {
      var _a, _b, _c, _d, _e;
      return /* @__PURE__ */ jsx(
        IconContentActionCard,
        {
          iconMedia: d && getModelImage((_a = d == null ? void 0 : d.device) == null ? void 0 : _a.type, (_b = d == null ? void 0 : d.device) == null ? void 0 : _b.modelName, {
            width: "150px",
            height: "auto"
          }),
          title: (_c = d.device) == null ? void 0 : _c.type,
          content: /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m5, display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
            ((_d = d.device) == null ? void 0 : _d.modelName) && /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: (_e = d.device) == null ? void 0 : _e.modelName }),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: getTransitStatus(d.status) })
          ] }),
          showDivider: idx < deviceTransits.length - 1
        }
      );
    }) }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "There are no active returns or handovers at the moment" }) }),
    selectedDeviceTransit && /* @__PURE__ */ jsx(
      DrawerModal,
      {
        setIsOpen: setIsDrawerOpen,
        isOpen: isDrawerOpen,
        onClose: () => {
          setIsDrawerOpen(false);
          setSelectedDeviceTransit(void 0);
        },
        showExpand: true,
        expandAction: currentUserId === userId || hasDevicesAll ? () => {
          if (currentUserId === userId)
            routerHistory.push(
              generatePath(DEVICES_ME_DEVICE_SECURITY_ROUTE, {
                devicePossessionId: selectedDeviceTransit.senderId
              })
            );
          else if (hasDevicesAll) {
            generatePath(DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, {
              devicePossessionId: selectedDeviceTransit.senderId
            });
          }
        } : void 0,
        children: /* @__PURE__ */ jsx(
          DeviceReturnDrawer,
          {
            selectedDeviceTransit,
            refresh: () => __async(void 0, null, function* () {
              setIsDrawerOpen(false);
              setSelectedDeviceTransit(void 0);
              yield refreshDevicesData();
            }),
            reach: "me"
          }
        )
      }
    )
  ] });
};
