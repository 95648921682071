"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import { v4 } from "uuid";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { REVIEWS_COMPANY_ONGOING_SETUP_ROUTE, REVIEWS_TEAM_ONGOING_SETUP_ROUTE } from "@/lib/routes";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReachType } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleType, cycleTypeOptions } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
export const RCCreationModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  reach
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(RCCreationModalContent, { reviewCycle, onClose, refresh, reach }) });
};
const useCreateReviewCycleForm = (reviewCycle, onClose, refresh, reach, setUpgradeModalOpen) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      internalName: reviewCycle ? reviewCycle.internalName : "",
      displayName: reviewCycle ? reviewCycle.displayName : "",
      type: reviewCycle ? reviewCycle.type : CycleType.Scheduled
    },
    validationSchema: yup.object({
      internalName: yup.string().required(polyglot.t("NewReviewCycleDrawer.errorMessages.name")),
      displayName: yup.string().notRequired(),
      type: yup.string().required()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (reviewCycle) {
          yield ReviewCycleAPI.updateReviewCycle(__spreadProps(__spreadValues({}, reviewCycle), {
            internalName: values.internalName,
            displayName: Boolean(values.displayName) ? values.displayName : values.internalName,
            type: values.type
          }));
          showMessage("Successfully update the cycle", "success");
        } else {
          const reachRoute = reach === ReachType.Company ? REVIEWS_COMPANY_ONGOING_SETUP_ROUTE : REVIEWS_TEAM_ONGOING_SETUP_ROUTE;
          const newlyCreatedCycle = yield ReviewCycleAPI.createReviewCycle(__spreadProps(__spreadValues({}, values), {
            displayName: Boolean(values.displayName) ? values.displayName : values.internalName,
            type: values.type,
            id: v4()
          }));
          routerHistory.push(generatePath(reachRoute, { cycleId: newlyCreatedCycle.id }));
        }
        yield refresh();
        onClose();
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(nestErrorMessage(error), "error");
        }
      } finally {
        setSubmitting(false);
      }
    })
  });
  return formik;
};
const RCCreationModalContent = ({
  reviewCycle,
  onClose,
  refresh,
  reach
}) => {
  const { polyglot } = usePolyglot();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const formik = useCreateReviewCycleForm(reviewCycle, onClose, refresh, reach, setUpgradeModalOpen);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("NewReviewCycleDrawer.newReview") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: "Type",
        options: cycleTypeOptions,
        disabled: Boolean(reviewCycle == null ? void 0 : reviewCycle.id),
        value: formik.values.type,
        onChange: formik.handleChange,
        compareValue: formik.values.type,
        error: !!formik.errors.type && formik.touched.type,
        helperText: formik.touched.type && formik.errors.type
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "internalName",
        label: "Internal name",
        value: formik.values.internalName,
        onChange: formik.handleChange,
        error: formik.touched.internalName && !!formik.errors.internalName,
        helperText: formik.touched.internalName && formik.errors.internalName,
        endAdornment: "none",
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "displayName",
        label: "Display name",
        value: formik.values.displayName,
        onChange: formik.handleChange,
        error: formik.touched.displayName && !!formik.errors.displayName,
        helperText: formik.touched.displayName && formik.errors.displayName,
        endAdornment: "none",
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) }),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.GROWTH_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] }) });
};
