"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import { getDay } from "date-fns";
import { Typography } from "@/v2/components/typography/typography.component";
import { DrawerViewerItem } from "@/v2/feature/absence/components/drawer-viewer-item.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SummaryDetailsComponent = ({
  userSchedule,
  request,
  attendanceTypesAllowedById
}) => {
  const { polyglot } = usePolyglot();
  const latestRegularStartHour = useMemo(() => {
    if (!(request == null ? void 0 : request.attendanceEntries[0]) || !request.attendanceEntries[0].startHour) return null;
    let earliestStartHour = request.attendanceEntries[0].startHour;
    request.attendanceEntries.forEach((item) => {
      var _a;
      if (new Date(item.startHour) < new Date(earliestStartHour) && ((_a = attendanceTypesAllowedById[item.typeId]) == null ? void 0 : _a.name) === "Regular") {
        earliestStartHour = item.startHour;
      }
    });
    return earliestStartHour.slice(11, 16);
  }, [request, attendanceTypesAllowedById]);
  const latestRegularEndHour = useMemo(() => {
    if (!(request == null ? void 0 : request.attendanceEntries) || !request.attendanceEntries[0]) return null;
    let latestEndHour = request.attendanceEntries[0].endHour;
    request.attendanceEntries.forEach((item) => {
      var _a;
      if (new Date(item.endHour) > new Date(latestEndHour) && ((_a = attendanceTypesAllowedById[item.typeId]) == null ? void 0 : _a.name) === "Regular") {
        latestEndHour = item.endHour;
      }
    });
    return latestEndHour.slice(11, 16);
  }, [request, attendanceTypesAllowedById]);
  const calculateTotalRegularHours = useMemo(() => {
    let totalMinutes = 0;
    request.attendanceEntries.forEach((entry) => {
      var _a;
      if (((_a = attendanceTypesAllowedById[entry.typeId]) == null ? void 0 : _a.name) === "Regular") {
        const start = new Date(entry.startHourTimestamp).getTime();
        const end = new Date(entry.endHourTimestamp).getTime();
        const difference = end - start;
        totalMinutes += Math.round(difference / (1e3 * 60));
      }
    });
    const hours = Math.round(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours === 0) {
      return `${Math.round(minutes)}m`;
    } else {
      return `${hours}h ${Math.round(minutes)}m`;
    }
  }, [request, attendanceTypesAllowedById]);
  const calculateDuration = (obj) => {
    if (!obj) return "0h";
    const fromTime = new Date(obj.from);
    const toTime = new Date(obj.to);
    let breakDuration = 0;
    if (obj.break) {
      const breakTime = /* @__PURE__ */ new Date("1970-01-01T" + obj.break.split("T")[1]);
      breakDuration = breakTime.getHours() * 60 * 60 * 1e3 + breakTime.getMinutes() * 60 * 1e3;
    }
    let totalDuration = toTime.getTime() - fromTime.getTime();
    if (toTime < fromTime) totalDuration += 24 * 60 * 60 * 1e3;
    const durationAfterBreak = totalDuration - breakDuration;
    const hours = Math.floor(durationAfterBreak / (1e3 * 60 * 60));
    const minutes = Math.floor(durationAfterBreak % (1e3 * 60 * 60) / (1e3 * 60));
    return `${Math.round(hours)}h ${Math.round(minutes)}m`;
  };
  const parseDuration = (duration) => {
    const parts = duration.split(" ");
    let hrs = 0;
    let mins = 0;
    parts.forEach((part) => {
      if (part.includes("h")) {
        hrs = parseInt(part, 10);
      } else if (part.includes("m")) {
        mins = parseInt(part, 10);
      }
    });
    return hrs * 60 + mins;
  };
  const getLogDaySchedule = (logDate) => {
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const dayOfWeek = getDay(new Date(logDate));
    const dayName = days[dayOfWeek];
    const weekIndex = userSchedule ? getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks) : null;
    return userSchedule && weekIndex !== null && userSchedule[dayName] ? userSchedule[dayName][weekIndex] : null;
  };
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };
  const formatTimeDifference = (differenceInMinutes) => {
    const hours = differenceInMinutes < 0 ? Math.ceil(differenceInMinutes / 60) : Math.floor(differenceInMinutes / 60);
    const minutes = differenceInMinutes % 60;
    const sign = differenceInMinutes >= 0 ? "+" : "-";
    return differenceInMinutes === 0 ? /* @__PURE__ */ jsx(Fragment, {}) : /* @__PURE__ */ jsxs(Typography, { variant: "title4", sx: { color: differenceInMinutes >= 0 ? themeColors.Green : themeColors.Red }, children: [
      sign,
      Math.abs(Math.round(hours)),
      "h ",
      Math.abs(Math.round(minutes)),
      "m"
    ] });
  };
  const getStartComparison = (logDate, earliestStart) => {
    const logDateSchedule = getLogDaySchedule(logDate);
    const earlyHourSchedule = logDateSchedule ? logDateSchedule.from.slice(11, 16) : null;
    if (earlyHourSchedule) {
      const differenceInMinutes = timeToMinutes(earlyHourSchedule) - timeToMinutes(earliestStart);
      return formatTimeDifference(differenceInMinutes);
    }
  };
  const getEndComparison = (logDate, latestEnd) => {
    const logDateSchedule = getLogDaySchedule(logDate);
    const latestHourSchedule = logDateSchedule ? logDateSchedule.to.slice(11, 16) : null;
    if (latestHourSchedule) {
      const differenceInMinutes = timeToMinutes(latestEnd) - timeToMinutes(latestHourSchedule);
      return formatTimeDifference(differenceInMinutes);
    }
  };
  const getLengthComparison = (logDate, calculateTotalRegularHours2) => {
    const logDateSchedule = getLogDaySchedule(logDate);
    if (logDateSchedule) {
      const duration = calculateDuration(logDateSchedule);
      const totalMinutes1 = parseDuration(calculateTotalRegularHours2);
      const totalMinutes2 = parseDuration(duration);
      const difference = totalMinutes1 - totalMinutes2;
      return formatTimeDifference(difference);
    }
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { color: themeColors.DarkGrey }, children: polyglot.t("SummaryDetailsComponent.summary") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
      latestRegularStartHour && /* @__PURE__ */ jsx(
        DrawerViewerItem,
        {
          title: polyglot.t("SummaryDetailsComponent.start"),
          value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g20 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: latestRegularStartHour }),
            userSchedule && userSchedule.totalTime !== 0 && !userSchedule.isFlexible && /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: getStartComparison(request.logDate, latestRegularStartHour) })
          ] })
        }
      ),
      latestRegularEndHour && /* @__PURE__ */ jsx(
        DrawerViewerItem,
        {
          title: polyglot.t("SummaryDetailsComponent.end"),
          value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g20 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: latestRegularEndHour }),
            userSchedule && userSchedule.totalTime !== 0 && !userSchedule.isFlexible && getEndComparison(request.logDate, latestRegularEndHour)
          ] })
        }
      ),
      /* @__PURE__ */ jsx(
        DrawerViewerItem,
        {
          title: polyglot.t("SummaryDetailsComponent.length"),
          value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g20 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: calculateTotalRegularHours }),
            userSchedule && userSchedule.totalTime !== 0 && !userSchedule.isFlexible && getLengthComparison(request.logDate, calculateTotalRegularHours)
          ] })
        }
      )
    ] })
  ] });
};
