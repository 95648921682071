"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { IconButton, Stack, SwipeableDrawer, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { UploadInput } from "@/component/forms/UploadInput";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as UploadedDocIcon } from "@/images/documents/UploadedDoc.svg";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { getDocumentTypeLabel } from "@/lib/documents";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { getApiErrorMessage } from "@/v2/infrastructure/api-error/api-error.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DocumentUploadDrawer = ({ close, document, documentTypes }) => {
  const { polyglot } = usePolyglot();
  const [documentUploading, setDocumentUploading] = useState(false);
  const [showMessage] = useMessage();
  const [fileNote, setFileNote] = useState("");
  const [uploadedFileUUID, setUploadedFileUUID] = useState();
  const [currentFile, setCurrentFile] = useState();
  useEffect(() => {
    var _a, _b;
    setFileNote((_a = document == null ? void 0 : document.note) != null ? _a : "");
    setCurrentFile((_b = document == null ? void 0 : document.attachments) == null ? void 0 : _b[0]);
  }, [document]);
  const saveDocument = useCallback(
    (fileUuid, note) => __async(void 0, null, function* () {
      if (!document || !document.belongsTo) return;
      try {
        setDocumentUploading(true);
        yield DocumentAPI.updateDocument(document.id, {
          name: document.name,
          type: document.type,
          belongsTo: document.belongsTo.map((x) => ({
            value: x.User.userId
          })),
          fileUuid,
          note
        });
        close(fileUuid);
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.documentUpdateFailed", {
            errorMessage: getApiErrorMessage(error)
          }),
          "warning"
        );
      } finally {
        setDocumentUploading(false);
      }
    }),
    [polyglot, close, document, showMessage]
  );
  const currentFileUUID = uploadedFileUUID || (currentFile == null ? void 0 : currentFile.fileUuid);
  return /* @__PURE__ */ jsx(SwipeableDrawer, { anchor: "right", open: !!document, onClose: () => close(), onOpen: () => {
  }, children: document && /* @__PURE__ */ jsxs(Stack, { sx: { p: "30px 40px", width: "440px", boxSizing: "border-box", maxWidth: "100vw" }, children: [
    !currentFile && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingByUser.addDocument") }),
    currentFile && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingByUser.editDocument") }),
    /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
          mt: spacing.mt20,
          color: themeColors.DarkGrey
        }),
        children: polyglot.t("OnboardingByUser.name")
      }
    ),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { mt: spacing.m5, color: themeColors.DarkGrey }), children: document.name }),
    /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
          mt: spacing.mt20,
          color: themeColors.DarkGrey
        }),
        children: polyglot.t("OnboardingByUser.type")
      }
    ),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { mt: spacing.m5, color: themeColors.DarkGrey }), children: getDocumentTypeLabel(document, documentTypes) }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Note",
        disabled: documentUploading,
        onChange: (e) => setFileNote(e.target.value),
        value: fileNote,
        sx: { mt: spacing.mt30 }
      }
    ),
    currentFile && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", mt: spacing.mt40 }, children: [
      /* @__PURE__ */ jsx(UploadedDocIcon, {}),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: currentFile.fileName.split("/").pop() }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { ml: "auto" }),
          onClick: () => setCurrentFile(void 0),
          title: polyglot.t("General.remove"),
          children: /* @__PURE__ */ jsx(TrashIcon, {})
        }
      )
    ] }),
    !currentFile && /* @__PURE__ */ jsx(
      UploadInput,
      {
        onChange: (response) => {
          setUploadedFileUUID(response == null ? void 0 : response.uuid);
        },
        sx: { mt: spacing.mt40 }
      }
    ),
    currentFileUUID && /* @__PURE__ */ jsx(
      LoaderButton,
      {
        fullWidth: true,
        name: polyglot.t("General.save"),
        loading: documentUploading,
        onClick: () => __async(void 0, null, function* () {
          yield saveDocument(currentFileUUID, fileNote);
        }),
        style: { marginTop: spacing.m30 },
        sizeVariant: "large",
        colorVariant: "primary"
      }
    )
  ] }) });
};
