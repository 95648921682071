"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { DocumentAPI } from "@v2/feature/documents/document.api";
import { DocPreviewer } from "@v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { getDocumentTypeLabel } from "@/lib/documents";
import { nestErrorMessage } from "@/lib/errors";
import {
  USER_ONBOARDING_ABOUT,
  USER_ONBOARDING_DOCUMENTS,
  USER_ONBOARDING_PAYROLL,
  USER_ONBOARDING_TASKS
} from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TeamSlide } from "@/v2/components/team-slide.component";
import { ProfileModal } from "@/v2/components/theme-components/profile-modal.component";
import { SignatoryType } from "@/v2/feature/templates/templates.interface";
import { UserProfileBar } from "@/v2/feature/user/components/user-profile-bar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserLifecycleStatuses } from "@/v2/feature/user/features/user-forms/user-lifecycle/user-lifecycle.interface";
import { OnboardingContracts } from "@/v2/feature/user-onboarding/onboarding-by-user/components/onboarding-contracts/onboarding-contracts.component";
import { OnboardingActionCard } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-action-card.component";
import { OnboardingUserStats } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-user-stats.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingOverview = ({
  companyName,
  progressPercent,
  requiredSteps,
  missingFields,
  documents,
  documentTypes,
  pendingContracts,
  incompleteTasks,
  checklistNames,
  startDate,
  sx,
  taskCount,
  userId,
  publicURL
}) => {
  const { polyglot, useInitLanguage } = usePolyglot();
  const [showMessage] = useMessage();
  useInitLanguage();
  const router = useHistory();
  const { cachedUsers: companyUsers } = useCachedUsers();
  const { trackPage } = useJune();
  const [contractToSign, setContractToSign] = useState(null);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUserId, setSetSelectedUserId] = useState(null);
  const [selectedDocBuffer, setSelectedDocBuffer] = useState();
  const [selectedDocName, setSelectedDocName] = useState("");
  const [selectedDocContentType, setSelectedDocContentType] = useState("");
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const cards = useMemo(
    () => [...companyUsers].filter((u) => {
      var _a;
      return ((_a = u.userEvent) == null ? void 0 : _a.status) === UserLifecycleStatuses.Employed;
    }).sort(() => 0.5 - Math.random()).map((user) => {
      var _a, _b;
      return {
        user,
        cardProps: {
          subTitle: (_b = (_a = user.role) == null ? void 0 : _a.jobPosition) == null ? void 0 : _b.title
        },
        action: () => {
          setIsOpen(true);
          setSetSelectedUserId(user.userId);
        },
        publicURL
      };
    }),
    [companyUsers, publicURL]
  );
  const missingDocuments = useMemo(() => documents.filter((d) => {
    var _a;
    return !d.canAllEmployeesSee && !((_a = d.attachments) == null ? void 0 : _a.length);
  }), [
    documents
  ]);
  const pendingContractForStep = useCallback(
    (step) => {
      switch (step) {
        case 1:
          return (pendingContracts == null ? void 0 : pendingContracts.length) > 0 && pendingContracts[0] ? pendingContracts[0] : void 0;
        case 2:
          return (pendingContracts == null ? void 0 : pendingContracts.length) > 0 && pendingContracts[1] ? pendingContracts[1] : void 0;
        case 3:
          return (pendingContracts == null ? void 0 : pendingContracts.length) > 0 && pendingContracts[2] ? pendingContracts[2] : void 0;
        case 4:
          return (pendingContracts == null ? void 0 : pendingContracts.length) > 0 && pendingContracts[3] ? pendingContracts[3] : void 0;
        case 5:
          return (pendingContracts == null ? void 0 : pendingContracts.length) > 0 && pendingContracts[4] ? pendingContracts[4] : void 0;
      }
    },
    [pendingContracts]
  );
  const handleContractSignAction = useCallback(
    (step) => {
      const pc = pendingContractForStep(step);
      if (pc && pc.contract) {
        setContractToSign(pc.contract);
        setOpenMissingFieldContractModal(true);
      }
    },
    [pendingContractForStep, setContractToSign]
  );
  const pendingContractRequiresAdditional = useCallback(
    (step) => {
      var _a;
      const pc = pendingContractForStep(step);
      const requiresSignaturesFromAdditional = (pc == null ? void 0 : pc.contract) && pc.contract.signatoriesRequired && pc.contract.signatoriesRequired.includes(SignatoryType.additional);
      return Boolean(requiresSignaturesFromAdditional && !((_a = pc == null ? void 0 : pc.contract) == null ? void 0 : _a.companySignatureTimestamp));
    },
    [pendingContractForStep]
  );
  const pendingContractIsFinalised = useCallback(
    (step) => {
      var _a, _b;
      const pc = pendingContractForStep(step);
      const contractIsMissingInformation = ((_a = pc == null ? void 0 : pc.contract) == null ? void 0 : _a.hasMissingPersonalFields) || ((_b = pc == null ? void 0 : pc.contract) == null ? void 0 : _b.hasMissingWorkFields);
      const requiresSignaturesFromRecipient = (pc == null ? void 0 : pc.contract) && pc.contract.signatoriesRequired && pc.contract.signatoriesRequired.includes(SignatoryType.recipient);
      return (!requiresSignaturesFromRecipient || !!((pc == null ? void 0 : pc.contract) && (pc == null ? void 0 : pc.contract.recipientSignatureTimestamp) && (pc == null ? void 0 : pc.contract.recipient))) && !contractIsMissingInformation;
    },
    [pendingContractForStep]
  );
  const noSignaturesButMissingInfo = useCallback(
    (step) => {
      var _a, _b;
      const pc = pendingContractForStep(step);
      const contractIsMissingInformation = ((_a = pc == null ? void 0 : pc.contract) == null ? void 0 : _a.hasMissingPersonalFields) || ((_b = pc == null ? void 0 : pc.contract) == null ? void 0 : _b.hasMissingWorkFields);
      const requiresSignaturesFromRecipient = (pc == null ? void 0 : pc.contract) && pc.contract.signatoriesRequired && pc.contract.signatoriesRequired.includes(SignatoryType.recipient);
      return !requiresSignaturesFromRecipient && contractIsMissingInformation;
    },
    [pendingContractForStep]
  );
  const getContractChipsForStep = useCallback(
    (step) => {
      const pc = pendingContractForStep(step);
      if (pc) return [pc.name];
      return void 0;
    },
    [pendingContractForStep]
  );
  const handlePreviewClick = useCallback(
    (zeltDocument) => __async(void 0, null, function* () {
      var _a, _b;
      try {
        const fileUuidFromAttachment = zeltDocument.attachments && zeltDocument.attachments[0] && ((_a = zeltDocument.attachments[0]) == null ? void 0 : _a.fileUuid);
        const finalUuid = (_b = zeltDocument.fileUuid) != null ? _b : fileUuidFromAttachment;
        if (!finalUuid) return;
        yield DocumentAPI.previewViaUuid(finalUuid).then((_0) => __async(void 0, [_0], function* ({ contentType, file }) {
          setSelectedDocName(zeltDocument.name);
          setSelectedDocBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        }));
      } catch (e) {
        console.error("::URL Download error", e);
        showMessage(polyglot.t("DocumentTable.errorMessages.preview", { errorMessage: nestErrorMessage(e) }), "error");
      }
    }),
    [polyglot, showMessage]
  );
  const getOnboardingActionCardForContractByStep = useCallback(
    (step) => {
      var _a;
      const contractDoc = pendingContractForStep(step);
      return /* @__PURE__ */ jsx(
        OnboardingActionCard,
        {
          label: polyglot.t("OnboardingOverview.contract"),
          completed: pendingContractIsFinalised(step),
          chips: getContractChipsForStep(step),
          actionButton: contractDoc && !pendingContractIsFinalised(step) ? /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => handleContractSignAction(step),
              sizeVariant: "small",
              colorVariant: "primary",
              fullWidth: true,
              children: noSignaturesButMissingInfo(step) ? polyglot.t("DocumentTable.fillMissingFields") : polyglot.t("OnboardingOverview.sign")
            }
          ) : void 0,
          completedButton: contractDoc && !pendingContractRequiresAdditional(step) ? /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => __async(void 0, null, function* () {
                try {
                  setLoadingStates((prevLoadingStates) => __spreadProps(__spreadValues({}, prevLoadingStates), {
                    [step]: true
                  }));
                  yield handlePreviewClick(contractDoc);
                } finally {
                  setLoadingStates((prevLoadingStates) => __spreadProps(__spreadValues({}, prevLoadingStates), {
                    [step]: false
                  }));
                }
              }),
              sizeVariant: "small",
              colorVariant: "secondary",
              fullWidth: true,
              loading: (_a = loadingStates[step]) != null ? _a : false,
              children: "View"
            }
          ) : void 0
        }
      );
    },
    [
      getContractChipsForStep,
      handleContractSignAction,
      handlePreviewClick,
      loadingStates,
      noSignaturesButMissingInfo,
      pendingContractForStep,
      pendingContractIsFinalised,
      pendingContractRequiresAdditional,
      polyglot
    ]
  );
  const actionCards = useMemo(() => {
    return requiredSteps.map(
      (step) => ({
        basic: () => /* @__PURE__ */ jsx(
          OnboardingActionCard,
          {
            label: polyglot.t("OnboardingOverview.completeProfile"),
            completed: missingFields.basic.length === 0,
            onStartClick: () => router.push(generatePath(USER_ONBOARDING_ABOUT, { userId })),
            showEdit: missingFields.basic.length === 0,
            onEditClick: () => router.push(generatePath(USER_ONBOARDING_ABOUT, { userId }))
          }
        ),
        benefits: () => /* @__PURE__ */ jsx(OnboardingActionCard, { label: polyglot.t("OnboardingOverview.selectBenefits") }),
        documents: () => /* @__PURE__ */ jsx(
          OnboardingActionCard,
          {
            label: polyglot.t("OnboardingOverview.uploadDocuments"),
            completed: missingDocuments.length === 0,
            onStartClick: () => router.push(generatePath(USER_ONBOARDING_DOCUMENTS, { userId })),
            chips: missingDocuments.map((d) => getDocumentTypeLabel(d, documentTypes))
          }
        ),
        payroll: () => /* @__PURE__ */ jsx(
          OnboardingActionCard,
          {
            label: polyglot.t("OnboardingOverview.completePayroll"),
            completed: missingFields.payroll.length === 0,
            onStartClick: () => router.push(generatePath(USER_ONBOARDING_PAYROLL, { userId })),
            showEdit: missingFields.payroll.length === 0,
            onEditClick: () => router.push(generatePath(USER_ONBOARDING_PAYROLL, { userId }))
          }
        ),
        tasks: () => /* @__PURE__ */ jsx(
          OnboardingActionCard,
          {
            label: polyglot.t("OnboardingOverview.tasks"),
            completed: incompleteTasks.length === 0,
            onStartClick: () => router.push(generatePath(USER_ONBOARDING_TASKS, { userId })),
            chips: checklistNames
          }
        ),
        contracts: () => getOnboardingActionCardForContractByStep(1),
        contracts_step2: () => getOnboardingActionCardForContractByStep(2),
        contracts_step3: () => getOnboardingActionCardForContractByStep(3),
        contracts_step4: () => getOnboardingActionCardForContractByStep(4),
        contracts_step5: () => getOnboardingActionCardForContractByStep(5)
      })[step]()
    );
  }, [
    getOnboardingActionCardForContractByStep,
    checklistNames,
    polyglot,
    documentTypes,
    incompleteTasks.length,
    missingDocuments,
    missingFields.basic.length,
    missingFields.payroll.length,
    requiredSteps,
    router,
    userId
  ]);
  useEffect(() => {
    trackPage("User onboarding");
  }, []);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", gap: "140px" }, sx), children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: "0 0", maxWidth: "470px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingOverview.getStarted") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m10, textAlign: "justify", color: themeColors.DarkGrey }), children: polyglot.t("OnboardingOverview.welcomeMessage", { companyName }) }),
      cards && /* @__PURE__ */ jsx(TeamSlide, { cards, title: "Your colleagues", sx: { mt: spacing.mt40 }, width: 470 })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, maxWidth: "1000px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingOverview.onboardingTasks") }),
      /* @__PURE__ */ jsx(
        OnboardingUserStats,
        {
          progressPercent,
          startDate,
          pendingTaskCount: taskCount,
          sx: { mt: spacing.mt20, mb: spacing.mb5 }
        }
      ),
      /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.m25 }, children: actionCards.map((card, idx) => /* @__PURE__ */ jsxs(React.Fragment, { children: [
        idx > 0 && /* @__PURE__ */ jsx("div", { style: { height: 1, backgroundColor: themeColors.lightGrey } }),
        card
      ] }, `action-card-${idx}`)) }),
      selectedDocBuffer && openPreviewModal && /* @__PURE__ */ jsx(
        DocPreviewer,
        {
          fileBuffer: selectedDocBuffer,
          contentType: selectedDocContentType,
          visible: true,
          onClose: () => setOpenPreviewModal(false),
          title: selectedDocName
        }
      ),
      /* @__PURE__ */ jsx(
        ProfileModal,
        {
          isOpen,
          setIsOpen,
          onClose: () => {
            setIsOpen(false);
          },
          children: selectedUserId ? /* @__PURE__ */ jsx(UserProfileBar, { userId: selectedUserId }) : void 0
        }
      ),
      contractToSign && /* @__PURE__ */ jsx(
        OnboardingContracts,
        {
          selectedContract: contractToSign,
          setOpenModal: setOpenMissingFieldContractModal,
          openModal: openMissingFieldContractModal
        }
      )
    ] })
  ] });
};
