"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { PlusOneNextDayComponent } from "@v2/components/plus-one-next-day.component";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { getEmptyDaySlot } from "@v2/feature/attendance/attendance.util";
import { isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
export const SchedulePatternRow = ({
  day,
  weekNo,
  values,
  setDayForm
}) => {
  const { polyglot } = usePolyglot();
  const [isEnabled, setIsEnabled] = useState(!!values[day][weekNo]);
  const [dayTime, setDayTime] = useState(0);
  const updateDayTime = useCallback(
    (record) => {
      if (!isEnabled || !(record == null ? void 0 : record.fromTimestamp) || !record.toTimestamp) return 0;
      const [breakHours, breakMinutes] = record.break && record.break.slice(11, 16).match(/^\d\d:\d\d$/g) ? record.break.slice(11, 16).split(":") : [0, 0];
      const breakInMinutes = 60 * Number(breakHours) + Number(breakMinutes);
      let workMinutes = Math.round(
        (new Date(record.toTimestamp).getTime() - new Date(record.fromTimestamp).getTime()) / (1e3 * 60)
      );
      if (record.toTimestamp < record.fromTimestamp) workMinutes += 60 * 24;
      setDayTime(workMinutes - breakInMinutes);
    },
    [isEnabled]
  );
  useEffect(() => {
    updateDayTime(values[day][weekNo]);
  }, [updateDayTime, values, day, weekNo]);
  const isOvernight = values[day] && values[day][weekNo] && values[day][weekNo].fromTimestamp && values[day][weekNo].toTimestamp && values[day][weekNo].fromTimestamp > values[day][weekNo].toTimestamp;
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
      },
      children: [
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t(`Days.${day.toLowerCase()}`),
            name: `${day}-enabled`,
            checked: isEnabled,
            onChange: (_, checked) => {
              setIsEnabled(checked);
              setDayForm(checked ? getEmptyDaySlot() : null);
            }
          }
        ),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "grid",
              gridTemplateColumns: "1fr 1fr 0.25fr 0.75fr 1fr",
              gridGap: spacing.g10,
              alignItems: "center",
              transition: "all 0.2s linear",
              transitionProperty: "opacity, height, margin-top",
              visibility: isEnabled ? "visible" : "hidden",
              opacity: isEnabled ? 1 : 0,
              height: isEnabled ? "auto" : "0px",
              marginTop: isEnabled ? "10px" : "0px"
            },
            children: [
              /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
                TimePickerComponent,
                {
                  textFieldKey: "from",
                  label: polyglot.t("ScheduleRow.from"),
                  value: values[day][weekNo] ? values[day][weekNo].from : "",
                  onChange: (event) => {
                    var _a;
                    const time = event.target.value;
                    if (isValidTimeString(time)) {
                      const date = new LocalDate(`${new LocalDate().toDateString()}T${time}:00`);
                      const dayArray = (_a = values[day][weekNo]) != null ? _a : null;
                      const dayRecord = __spreadValues({}, dayArray ? dayArray : getEmptyDaySlot());
                      dayRecord.from = date.toFullString();
                      dayRecord.fromTimestamp = date.getDate();
                      if (dayRecord.to && dayRecord.toTimestamp) {
                        const toTimestamp = new Date(dayRecord.toTimestamp);
                        toTimestamp.setDate(date.getDate().getDate());
                        toTimestamp.setMonth(date.getDate().getMonth());
                        toTimestamp.setFullYear(date.getDate().getFullYear());
                        dayRecord.to = `${date.toDateString()}T${dayRecord.to.split("T")[1]}`;
                        dayRecord.toTimestamp = toTimestamp;
                      }
                      setDayForm(dayRecord);
                    }
                  },
                  disabled: !isEnabled,
                  fullWidth: true
                }
              ) }),
              /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
                TimePickerComponent,
                {
                  textFieldKey: "to",
                  label: polyglot.t("ScheduleRow.to"),
                  value: values[day][weekNo] ? values[day][weekNo].to : "",
                  onChange: (event) => {
                    var _a;
                    const time = event.target.value;
                    if (isValidTimeString(time)) {
                      const date = new LocalDate(`${new LocalDate().toDateString()}T${time}:00`);
                      const dayArray = (_a = values[day][weekNo]) != null ? _a : null;
                      const dayRecord = __spreadValues({}, dayArray ? dayArray : getEmptyDaySlot());
                      dayRecord.to = date.toFullString();
                      dayRecord.toTimestamp = date.getDate();
                      if (dayRecord.from && dayRecord.fromTimestamp) {
                        const fromTimestamp = new Date(dayRecord.fromTimestamp);
                        fromTimestamp.setDate(date.getDate().getDate());
                        fromTimestamp.setMonth(date.getDate().getMonth());
                        fromTimestamp.setFullYear(date.getDate().getFullYear());
                        dayRecord.from = `${date.toDateString()}T${dayRecord.from.split("T")[1]}`;
                        dayRecord.fromTimestamp = fromTimestamp;
                      }
                      setDayForm(dayRecord);
                    }
                  },
                  disabled: !isEnabled,
                  fullWidth: true
                }
              ) }),
              /* @__PURE__ */ jsx(Box, { children: isOvernight && /* @__PURE__ */ jsx(PlusOneNextDayComponent, {}) }),
              /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
                Typography,
                {
                  sx: __spreadProps(__spreadValues({}, themeFonts.title4), {
                    color: isEnabled ? void 0 : themeColors.Grey,
                    whiteSpace: "nowrap",
                    textAlign: "center"
                  }),
                  children: convertMinutesToClockHours(dayTime, polyglot)
                }
              ) }),
              /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
                TimePickerComponent,
                {
                  textFieldKey: "break",
                  label: polyglot.t("ScheduleRow.break"),
                  value: values[day][weekNo] && values[day][weekNo] ? values[day][weekNo].break : "",
                  onChange: (event) => {
                    var _a;
                    const time = event.target.value;
                    if (isValidTimeString(time)) {
                      const date = new LocalDate(`${new LocalDate().toDateString()}T${time}:00`);
                      const dayArray = (_a = values[day][weekNo]) != null ? _a : null;
                      const dayRecord = __spreadValues({}, dayArray ? dayArray : getEmptyDaySlot());
                      dayRecord.break = date.toFullString();
                      setDayForm(dayRecord);
                    }
                  },
                  disabled: !isEnabled,
                  fullWidth: true
                }
              ) })
            ]
          }
        )
      ]
    }
  );
};
