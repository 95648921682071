"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  DevicePossessionType,
  DeviceShippingMethod,
  EnrolmentType,
  WipeMethod
} from "@v2/feature/device/device.interface";
import { DeviceShippingMethodsOptions } from "@v2/feature/device/device.util";
import { EnrollmentStatus } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { UserAddressAPI } from "@v2/feature/user/features/user-forms/user-address/user-address.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { StyledRadio } from "@v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { formatAddress } from "@v2/util/user-data.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReassignDeviceUserForm = ({
  currentDevicePossession,
  reassignDevice,
  isInventory
}) => {
  var _a, _b, _c, _d, _e, _f;
  const [loading, setLoading] = useState(false);
  const [senderAddress, setSenderAddress] = useState("");
  const [sitesOptions, setSitesOptions] = useState([]);
  const { hasScopes, getScopesContext } = useScopes();
  const [showMessage] = useMessage();
  const validationSchema = yup.object({
    shippingMethod: yup.string().required(),
    newUserId: yup.number().required("New user is required"),
    // senderAddress: yup.string().when('shippingMethod', {
    //   is: DeviceShippingMethod.ManualTracking,
    //   then: (schema) => schema.required('Sender Address is required'),
    //   otherwise: (schema) => schema.nullable().notRequired(),
    // }),
    // deliveryAddress: yup.string().when('shippingMethod', {
    //   is: DeviceShippingMethod.ManualTracking,
    //   then: (schema) => schema.required('Sender Address is required'),
    //   otherwise: (schema) => schema.nullable().notRequired(),
    // }),
    deviceAccountName: yup.string().nullable().matches(/^[a-zA-Z0-9_.-@]*$/, "Device account name must not contain spaces or special characters."),
    wipeMethod: yup.string().required()
  });
  const formik = useFormik({
    initialValues: {
      shippingMethod: currentDevicePossession.possessionType === DevicePossessionType.ZeltStorage ? DeviceShippingMethod.RequestZeltShipping : DeviceShippingMethod.NoTrackingReassignInstantly,
      newUserId: 0,
      senderAddress,
      deliveryAddress: "",
      deviceAccountName: null,
      wipeMethod: ((_a = currentDevicePossession == null ? void 0 : currentDevicePossession.device) == null ? void 0 : _a.inHouseMdm) ? WipeMethod.WipeNow : WipeMethod.WipeLater,
      inventory: isInventory
    },
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      const { shippingMethod, newUserId, wipeMethod, deliveryAddress, deviceAccountName, inventory } = values;
      if (currentDevicePossession.possessionType === DevicePossessionType.User && currentDevicePossession.possessionId === newUserId) {
        showMessage("The sender and the receiver should not be the same.", "error");
        return;
      }
      if (!inventory && !newUserId) {
        showMessage("No receiver has been selected.", "error");
        return;
      }
      const reassignDeviceData = {
        shippingMethod,
        newUserId,
        senderAddress,
        deliveryAddress,
        wipeMethod,
        deviceAccountName,
        inventory: isInventory
      };
      setLoading(true);
      yield reassignDevice(currentDevicePossession.id, reassignDeviceData);
      setLoading(false);
    }),
    enableReinitialize: true,
    validateOnMount: true
  });
  const handleDeviceAccountNameChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9_.-@]/g, "");
    if (sanitizedValue !== inputValue) {
      showMessage("Device account name must not contain spaces or special characters.", "error");
    }
    formik.setFieldValue("deviceAccountName", sanitizedValue);
  };
  const fetchOwnerAddress = useCallback(
    (devicePossession) => __async(void 0, null, function* () {
      try {
        if (devicePossession.possessionType === DevicePossessionType.User) {
          const hasReadAddressScope = hasScopes(
            ["user.address:read"],
            getScopesContext({ userId: devicePossession.possessionId })
          );
          if (!hasReadAddressScope) return "";
          const userAddress = yield UserAddressAPI.findByUserId(devicePossession.possessionId);
          if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
            const effectiveAddress = userAddress.effectiveRecord;
            return formatAddress(effectiveAddress);
          }
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, "error");
      }
      return "";
    }),
    [getScopesContext, hasScopes, showMessage]
  );
  useEffect(() => {
    if (!isInventory) return;
    (() => __async(void 0, null, function* () {
      try {
        const sites = yield SiteAPI.listSites();
        const defaultSite = { value: -1, label: "Zelt cloud storage", address: "" };
        setSitesOptions([
          defaultSite,
          ...sites.map((site) => {
            var _a2;
            return { value: site.id, label: site.name, address: (_a2 = site.address) != null ? _a2 : "" };
          })
        ]);
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [showMessage, isInventory]);
  const fetchReceiverAddress = useCallback((possessionId) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const userAddress = yield UserAddressAPI.findByUserId(possessionId);
      if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
        const effectiveAddress = userAddress.effectiveRecord;
        return formatAddress(effectiveAddress);
      }
    } catch (error) {
      console.error("Could not retrieve user address.");
    } finally {
      setLoading(false);
    }
    return "";
  }), []);
  const setOwnerAddress = useCallback(() => __async(void 0, null, function* () {
    const ownerAddress = yield fetchOwnerAddress(currentDevicePossession);
    setSenderAddress(ownerAddress);
  }), [fetchOwnerAddress, currentDevicePossession]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      yield setOwnerAddress();
    }))();
  }, [setOwnerAddress]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    isInventory ? /* @__PURE__ */ jsx(
      TitleSubHeaderActionComponent,
      {
        title: "Step 1: Inventory",
        action: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "newUserId",
            label: "Site",
            options: sitesOptions,
            value: formik.values.newUserId,
            compareValue: formik.values.newUserId,
            onChange: (e) => __async(void 0, null, function* () {
              var _a2, _b2;
              const siteId = Number(e.target.value);
              const receiverAddress = (_b2 = (_a2 = sitesOptions.find((s) => s.value === siteId)) == null ? void 0 : _a2.address) != null ? _b2 : "";
              yield formik.setFieldValue("newUserId", siteId);
              yield formik.setFieldValue("deliveryAddress", receiverAddress);
              if (siteId === -1) {
                yield formik.setFieldValue("shippingMethod", DeviceShippingMethod.RequestZeltShipping);
              } else {
                yield formik.setFieldValue("shippingMethod", DeviceShippingMethod.NoTrackingReassignInstantly);
              }
            }),
            placeholder: "Select a site"
          }
        )
      }
    ) : /* @__PURE__ */ jsx(
      TitleSubHeaderActionComponent,
      {
        title: "Step 1: New user",
        action: /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(
            SingleUserSelect,
            {
              name: "newUserId",
              options: "company",
              value: formik.values.newUserId === 0 ? null : formik.values.newUserId,
              onChange: (_, x) => __async(void 0, null, function* () {
                var _a2;
                const userId = (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null;
                yield formik.setFieldValue("newUserId", userId);
                if (userId && userId !== 0) {
                  const receiverAddress = yield fetchReceiverAddress(userId);
                  yield formik.setFieldValue("deliveryAddress", receiverAddress);
                } else {
                  yield formik.setFieldValue("deliveryAddress", "");
                }
              }),
              label: "Employee",
              error: Boolean(formik.errors.newUserId),
              helperText: formik.errors.newUserId,
              placeholder: "Select an employee"
            }
          ),
          ((_b = currentDevicePossession == null ? void 0 : currentDevicePossession.device) == null ? void 0 : _b.inHouseMdm) && /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "deviceAccountName",
              label: "Device account name",
              value: (_c = formik.values.deviceAccountName) != null ? _c : "",
              type: "text",
              onChange: handleDeviceAccountNameChange,
              error: formik.touched.deviceAccountName && !!formik.errors.deviceAccountName,
              helperText: formik.touched.deviceAccountName && formik.errors.deviceAccountName,
              clearText: () => formik.setFieldValue("deviceAccountName", ""),
              tooltip: "To set up your device, enter a unique name for the account. If not provided, the email of the user will be used as the account name."
            }
          )
        ] })
      }
    ),
    /* @__PURE__ */ jsx(
      TitleSubHeaderActionComponent,
      {
        title: "Step 2: Shipping",
        subheader: "Set the shipping you would like to use for this reassignment.",
        action: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "shippingMethod",
            label: "Method",
            options: DeviceShippingMethodsOptions,
            value: formik.values.shippingMethod,
            compareValue: formik.values.shippingMethod,
            error: !!formik.errors.shippingMethod && formik.touched.shippingMethod,
            onChange: formik.handleChange,
            disabled: formik.values.newUserId === -1 || currentDevicePossession.possessionType === DevicePossessionType.ZeltStorage,
            helperText: formik.touched.shippingMethod && formik.errors.shippingMethod
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      TitleSubHeaderActionComponent,
      {
        title: "Step 3: Wipe",
        subheader: "Remove old settings and files to prepare this device for the new user. Device must be online for a\n            successful wipe.",
        action: /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%", marginTop: "-8px" }, children: /* @__PURE__ */ jsxs(
          RadioGroup,
          {
            "aria-labelledby": "payment-method-group-label",
            name: "wipeMethod",
            value: formik.values.wipeMethod,
            onChange: formik.handleChange,
            sx: { gap: "10px" },
            children: [
              ((_d = currentDevicePossession == null ? void 0 : currentDevicePossession.device) == null ? void 0 : _d.inHouseMdm) && /* @__PURE__ */ jsx(
                FormControlLabel,
                {
                  labelPlacement: "end",
                  value: "wipeNow",
                  control: /* @__PURE__ */ jsx(StyledRadio, {}),
                  label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Wipe now" })
                },
                "wipeNow"
              ),
              /* @__PURE__ */ jsx(
                FormControlLabel,
                {
                  labelPlacement: "end",
                  value: "wipeLater",
                  control: /* @__PURE__ */ jsx(StyledRadio, {}),
                  label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Wipe manually" })
                },
                "wipeLater"
              ),
              ((_e = currentDevicePossession.device) == null ? void 0 : _e.enrolmentType.toString()) === EnrolmentType.OPEN_ENROLMENT && ((_f = currentDevicePossession.device) == null ? void 0 : _f.enrollmentStatus) === EnrollmentStatus.ENROLMENT_FINISHED.toString() && /* @__PURE__ */ jsx(
                FormControlLabel,
                {
                  labelPlacement: "end",
                  value: "disenrol",
                  control: /* @__PURE__ */ jsx(StyledRadio, {}),
                  label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Disenrol from Zelt MDM" })
                },
                "disenrol"
              )
            ]
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        colorVariant: "primary",
        sizeVariant: "medium",
        type: "submit",
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
const TitleSubHeaderActionComponent = ({
  title,
  subheader,
  action
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: title }),
      subheader && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: subheader })
    ] }),
    action
  ] });
};
