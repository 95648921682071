"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import {
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE,
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE
} from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { PaymentType } from "@/v2/feature/payments/payments.dto";
import { PaymentSettingsNewTypeDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component";
import {
  PaymentCategoryEnum
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { truncateWithEllipses } from "@/v2/util/string.util";
export const PaymentsSettingsPage = ({ type }) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const [searchInput, setSearchInput] = useState("");
  const isExpense = type === PaymentType.Expense;
  const isInvoice = type === PaymentType.Invoice;
  const { mutate: refreshPaymentTypeSettingsData, isValidating: validatingPaymentTypeSettingsData } = useApiClient(
    isExpense ? PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId() : isInvoice ? PaymentTypeSettingsEndpoints.getInvoiceTypesForCompanyId() : { url: void 0 },
    {
      suspense: false
    }
  );
  const { data, isValidating: loadingPaymentTypeSettingsData } = useApiClient(
    type === PaymentType.Expense ? PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId() : type === PaymentType.Invoice ? PaymentTypeSettingsEndpoints.getInvoiceTypesForCompanyId() : { url: void 0 },
    {
      suspense: false
    }
  );
  const [typeForEdit, setTypeForEdit] = useState(void 0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const paymentTypeSettingsData = useMemo(() => {
    var _a;
    if (!searchInput || !data) return (_a = data == null ? void 0 : data.settings) != null ? _a : [];
    const search = searchInput.toLowerCase();
    return data.settings.filter((p) => p.name.toLowerCase().includes(search));
  }, [data, searchInput]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { sx: { scrollbarWidth: "none" }, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: `${isExpense ? polyglot.t("SettingsRouterPageConfig.expenses") : polyglot.t("SettingsRouterPageConfig.invoices")}`,
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          !loadingPaymentTypeSettingsData && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => {
                setTypeForEdit(void 0);
                setIsDrawerOpen(true);
              },
              disabled: loadingPaymentTypeSettingsData,
              children: polyglot.t("PaymentSettingsPage.newType")
            }
          )
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            loadingAll: loadingPaymentTypeSettingsData || validatingPaymentTypeSettingsData,
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchInput,
                        handleChange: (e) => {
                          setSearchInput(e.target.value);
                        }
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (paymentTypeSettingsData || []).map((item) => {
                      const accountingCodeDescription = item.accountingCodeConfig ? item.accountingCodeConfig.map((acc) => `${acc.accountingCode} - ${acc.accountingCodeDescription}`).join(", ") : "";
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: item.name,
                          boxSx: responsiveCardStyle,
                          boxAction: () => {
                            const isExpense2 = item.type === PaymentCategoryEnum.EXPENSE;
                            if (!item.id) return;
                            history.push(
                              generatePath(
                                isExpense2 ? SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE : SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE,
                                {
                                  id: item.id
                                }
                              )
                            );
                          },
                          contentItemsSets: [
                            {
                              name: item.type,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            },
                            {
                              name: accountingCodeDescription ? `${truncateWithEllipses(accountingCodeDescription, 40)}` : "",
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            }
                          ]
                        },
                        item.name
                      );
                    }) })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      PaymentSettingsNewTypeDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        refreshAllSettings: refreshPaymentTypeSettingsData,
        typeForEdit,
        typeCategory: isInvoice ? PaymentCategoryEnum.INVOICE : PaymentCategoryEnum.EXPENSE,
        redirectToSettings: true
      }
    )
  ] });
};
