"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class DeviceAPI {
  static getActiveDevicePossessions() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/devices/active")).data;
    });
  }
  static getInInventoryDevices() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/devices/in-inventory")).data;
    });
  }
  static getInTransitDevices() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/devices/in-transit")).data;
    });
  }
  static getUserInTransitDevices(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/users/${userId}/in-transit`)).data;
    });
  }
  static getUserDeviceOrders(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/orders/users/${userId}`)).data;
    });
  }
  static getDeviceOrders() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/devices/orders")).data;
    });
  }
  static getDeviceOrderDetails(orderId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/orders/${orderId}`)).data;
    });
  }
  static getDeviceOrdersAsSuperadmin(eligibleForRefinancingOnly = false) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/superadmin/orders?eligibleForRefinancingOnly=${eligibleForRefinancingOnly}`)).data;
    });
  }
  static getStockDevicesAsSuperadmin() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/devices/superadmin/stock")).data;
    });
  }
  static getStockDevicePossessionsAsSuperadmin() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/devices/superadmin/device-possessions/stock")).data;
    });
  }
  static getZeltTransitsAsSuperadmin() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/devices/superadmin/transits/zelt")).data;
    });
  }
  static addDeviceInStockAsSuperadmin(deviceModelId, quantity) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/devices/superadmin/stock", { deviceModelId, quantity })).data;
    });
  }
  static updateDeviceByIdAsSuperadmin(deviceId, deviceUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/devices/superadmin/stock/${deviceId}`, deviceUpdate);
    });
  }
  static updateEnrolledDeviceDetailsForm(deviceId, updateEnrolledDeviceDetailsDto) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/devices/${deviceId}/enrolled/update-details`, updateEnrolledDeviceDetailsDto);
    });
  }
  static updateDeviceTechSpecsByPossessionId(devicePossessionId, deviceUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/devices/possessions/${devicePossessionId}/tech-specs`, deviceUpdate);
    });
  }
  static reassignInventoryDevicePossession(devicePossessionId, newPossessionDetails, deliveryDetails) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${devicePossessionId}/assign-device`, { newPossessionDetails, deliveryDetails });
    });
  }
  static assignDepDeviceToUser(devicePossessionId, newPossessionDetails, deliveryDetails) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${devicePossessionId}/assign-dep-device`, {
        newPossessionDetails,
        deliveryDetails
      });
    });
  }
  static rejectDeviceOrderAsSuperadmin(orderId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/status/rejected`)).data;
    });
  }
  static acceptDeviceOrderAsSuperadmin(orderId, deliveryDate) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/status/accepted`, {
        deliveryDate
      })).data;
    });
  }
  // Used when user confirms order delivery
  static confirmDeviceOrderDelivery(orderId, deliveryDate) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.post(`/apiv2/devices/orders/${orderId}/confirm-delivery`, { deliveryDate })).data) != null ? _a : null;
    });
  }
  // [superadmin]: Used to mark order as shipped
  static setDeviceOrderInShippingAsSuperadmin(orderId, deviceId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/status/shipping`, {
        deviceId
      })).data;
    });
  }
  // [superadmin] assign a device to an order
  static assignDeviceToDeviceOrderAsSuperadmin(orderId, deviceId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/assign-device`, {
        deviceId
      })).data;
    });
  }
  // [superadmin] set order as delivered
  static setDeviceOrderAsDeliveredAsSuperadmin(orderId, deviceId, deliveryDate) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/status/delivered`, {
        deliveryDate,
        deviceId
      })).data;
    });
  }
  static getActiveDevicePossessionsByUserId(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/users/${userId}`)).data;
    });
  }
  static getAllCachedDevicesAsSuperadmin(inHouseMdm) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/superadmin?inHouseMdm=${inHouseMdm}`)).data;
    });
  }
  static orderDevice(deviceModelId, devicePossessionDetails, delivery, contractLength, phoneNumber, isPurchase) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/devices", {
        deviceModelId,
        devicePossessionDetails,
        delivery,
        contractLength,
        phoneNumber,
        isPurchase
      });
    });
  }
  static getDevicesInventoryStats() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/devices/inventory-stats")).data;
    });
  }
  static addExistingDevice(device) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/devices/existing-device", device)).data;
    });
  }
  static approveDeviceOrder(orderId) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/device-orders/${orderId}/approve-order`);
    });
  }
  static getOrderTrackingLinkAsSuperAdmin(orderId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/device-orders/${orderId}/tracking-link/superadmin`)).data;
    });
  }
  static getOrderTrackingLink(orderId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/device-orders/${orderId}/tracking-link`)).data;
    });
  }
  static updateOrderTrackingLink(orderId, trackingLink) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/device-orders/${orderId}/tracking-link/superadmin`, { trackingLink });
    });
  }
  static updateDeviceTransitTrackingLink(transitId, trackingLink) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/devices/transit/${transitId}/tracking-link`, { trackingLink });
    });
  }
  static rejectDeviceOrder(orderId) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/device-orders/${orderId}/reject-order`);
    });
  }
  // used to cancel an order as a user
  static cancelDeviceOrder(orderId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/device-orders/${orderId}/cancel-order`)).data;
    });
  }
  // [superadmin] used to confirm the return of a device
  static markDeviceAsReturnedAsSuperAdmin(deviceTransitId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/superadmin/transits/${deviceTransitId}/stock-device/mark-as-returned`);
    });
  }
  // [superadmin] used to mark a storage device as shipped (to user / company site)
  static markStorageDeviceAsShippedAsSuperAdmin(deviceTransitId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/superadmin/transits/${deviceTransitId}/storage-device/mark-as-shipped`);
    });
  }
  static markDeviceTransitMangedByZeltAsSuperAdmin(deviceTransitId, status) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/superadmin/transits/${deviceTransitId}/manged-by-zelt/device/mark-as/${status}`);
    });
  }
  // [superadmin] used to mark a storage device as delivered (to user / company site)
  static markStorageDeviceAsDeliveredAsSuperAdmin(deviceTransitId, deliveryDate) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/superadmin/transits/${deviceTransitId}/storage-device/mark-as-delivered`, {
        deliveryDate
      });
    });
  }
  static deleteDeviceOrderAsSuperAdmin(orderId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/device-orders/${orderId}/superadmin`);
    });
  }
  static deleteDevicePossessionAsSuperAdmin(possessionId, deviceId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/devices/superadmin/${deviceId}/possessions/${possessionId}`);
    });
  }
  static createDevicePossessionAsSuperAdmin(deviceId, createData) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/superadmin/${deviceId}/possessions`, createData);
    });
  }
  static updateDevicePossessionAsSuperAdmin(deviceId, possessionId, updateData) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/devices/superadmin/${deviceId}/possessions/${possessionId}`, updateData);
    });
  }
  static updateDeviceOrderAsSuperadmin(orderId, deviceOrderUpdate) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/devices/orders/${orderId}/superadmin`, deviceOrderUpdate)).data;
    });
  }
  static deleteDeviceOrder(orderId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/device-orders/${orderId}`);
    });
  }
  static deleteStockDeviceByDeviceIdAsSuperadmin(deviceId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/devices/${deviceId}/superadmin`);
    });
  }
  static deleteCompanyOwnedDeviceByDevicePossessionId(devicePossessionId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/devices/${devicePossessionId}`);
    });
  }
  static getDevicePossession(devicePossessionId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.get(`/apiv2/devices/possessions/${devicePossessionId}`)).data) != null ? _a : {};
    });
  }
  static getAllDevicePossessionsByCompanyId(companyId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.get(`/apiv2/devices/superadmin/companies/${companyId}/possessions`)).data) != null ? _a : [];
    });
  }
  static updateCompanyDevicePossessionById(companyId, devicePossessionId, update) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/devices/superadmin/companies/${companyId}/possessions/${devicePossessionId}`, update)).data;
    });
  }
  static getActiveTransitForDevicePossession(devicePossessionId) {
    return __async(this, null, function* () {
      const transit = (yield axios.get(`/apiv2/devices/${devicePossessionId}/active-transit`)).data;
      return Boolean(transit) ? transit : null;
    });
  }
  static getUserDevicePossessionDetails(userId, devicePossessionId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/${devicePossessionId}/users/${userId}`)).data;
    });
  }
  static getRefreshedDevicePossessionDetails(devicePossessionId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.get(`/apiv2/devices/${devicePossessionId}/superadmin/refresh`)).data) != null ? _a : null;
    });
  }
  static syncWithExternalProviderById(devicePossessionId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/devices/superadmin/${devicePossessionId}/sync`)).data;
    });
  }
  static scanDevice(devicePossessionId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${devicePossessionId}/actions/scan-device`);
    });
  }
  static scanDeviceBySuperadmin(devicePossessionId, companyId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${devicePossessionId}/company/${companyId}/actions/scan-device`);
    });
  }
  static wipeDevice(devicePossessionId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${devicePossessionId}/actions/wipe-device`);
    });
  }
  static disenrollDevice(devicePossessionId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${devicePossessionId}/actions/disenroll-device`);
    });
  }
  static lockDevice(devicePossessionId, message) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${devicePossessionId}/actions/lock-device`, message);
    });
  }
  static getAvailableCompanyDevicePolicies() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/device-policies")).data;
    });
  }
  static getAppliedCompanyDevicePolicies() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/device-policies/applied-policies")).data;
    });
  }
  static getDeviceManagedApps(devicePossessionId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/managed-apps/device/${devicePossessionId}/all`)).data;
    });
  }
  static getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/device-policies/applied-policies-by-zelt-mdm")).data;
    });
  }
  static getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeatureForMobile() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/device-policies/applied-policies-by-zelt-mdm-mobile")).data;
    });
  }
  static getSuperadminAppliedCompanyDevicePoliciesByZeltMdm(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/device-policies/superadmin/applied-policies-by-zelt-mdm/company/${companyId}`)).data;
    });
  }
  static getSuperadminAppliedCompanyDevicePoliciesByZeltMobileMdm(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/device-policies/superadmin/applied-policies-by-zelt-mdm-mobile/company/${companyId}`)).data;
    });
  }
  static getSuperadminAvailableCompanyDevicePolicies(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/device-policies/superadmin/company/${companyId}`)).data;
    });
  }
  static getSuperadminAppliedCompanyDevicePolicies(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/device-policies/superadmin/applied-policies/company/${companyId}`)).data;
    });
  }
  static updateCompanyDeviceAppliedPoliciesByZeltMdm(updatedAppliedPolicies, mobile, configurablePolicies) {
    return __async(this, null, function* () {
      const queryParam = { isMobile: mobile };
      if (configurablePolicies) {
        yield axios.patch(
          "/apiv2/device-policies/applied-policies-by-zelt-mdm",
          __spreadValues(__spreadValues({}, updatedAppliedPolicies), configurablePolicies),
          { params: queryParam }
        );
      } else {
        yield axios.patch("/apiv2/device-policies/applied-policies-by-zelt-mdm", updatedAppliedPolicies, {
          params: queryParam
        });
      }
    });
  }
  static updateCompanyDeviceAppliedPolicies(updatedAppliedPolicies) {
    return __async(this, null, function* () {
      yield axios.patch("/apiv2/device-policies/applied-policies", updatedAppliedPolicies);
    });
  }
  static sendEnrollmentRequest(devicePossessionId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${devicePossessionId}/actions/enrolment-request`);
    });
  }
  static getUnmatchedExternalDevicesForPossession(devicePossessionId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/${devicePossessionId}/external-unmatched-devices`)).data;
    });
  }
  static matchDeviceLocalIdWithDeviceExternalId(devicePossessionId, externalDeviceId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${devicePossessionId}/match-device`, {
        externalDeviceId
      });
    });
  }
  static getDeviceAssignedExternalUserCredentials(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/user-credentials/${userId}`)).data;
    });
  }
  static getAlerts(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/${userId}/alerts`)).data;
    });
  }
  static getDeviceModels(includeAll = false, order = "ASC") {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/models?includeAll=${includeAll}&order${order}`)).data;
    });
  }
  static updateDeviceModel(modelId, update) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/devices/models/${modelId}`, update);
    });
  }
  static addDeviceModel(deviceModel) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/devices/models", deviceModel);
    });
  }
  static deleteDeviceModel(modelId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/devices/models/${modelId}`);
    });
  }
  static reassignDevice(devicePossessionId, reassignDeviceData) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/devices/${devicePossessionId}/reassign-device`, reassignDeviceData)).data;
    });
  }
  // initiate an internal transit from user to user/site
  static changeDeviceOwnerTransitRequest(devicePossessionId, transitData) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/devices/${devicePossessionId}/change-owner`, transitData)).data;
    });
  }
  // mark as shipped an internal transit as a user (shipping from user to user/site)
  static changeDeviceOwnerSetTransitInShipping(deviceTransitId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/devices/${deviceTransitId}/change-owner/confirm-shipping`)).data;
    });
  }
  // confirm an internal transit delivery as a user (delivery from user to user/site
  static changeDeviceOwnerSetTransitAsDelivered(deviceTransitId, deliveryDate) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/${deviceTransitId}/change-owner/confirm-delivery`, { deliveryDate });
    });
  }
  // initiate return of device to CompanySite / ZeltStorage / ZeltStock (cancel contract)
  static initiateDeviceReturn(devicePossessionId, returnReason, senderAddress, deliveryAddress, siteId, notes) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/devices/${devicePossessionId}/return-device`, {
        returnReason,
        notes,
        senderAddress,
        deliveryAddress,
        siteId
      })).data;
    });
  }
  static notifyDeviceEnrolledByHexnode(deviceId) {
    return __async(this, null, function* () {
      yield axios.get(`/apiv2/devices/${deviceId}/notify-admins`);
    });
  }
  // mark returning device as shipped as an user
  static markReturnTransitAsShipped(transitId) {
    return __async(this, null, function* () {
      const updatedTransit = (yield axios.post(`/apiv2/devices/transits/${transitId}/mark-return-transit-as-shipped`)).data;
      return Boolean(updatedTransit) ? updatedTransit : null;
    });
  }
  static cancelDeviceTransit(transitId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/devices/transits/${transitId}/cancel`);
    });
  }
  static upgradeToZeltMDM() {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/devices/upgrade`);
    });
  }
  static triggerInstallOsUpdate(deviceId, installAction, version) {
    return __async(this, null, function* () {
      const osUpdate = {
        zeltDeviceId: deviceId,
        updates: [{ InstallAction: installAction, ProductVersion: version }]
      };
      return (yield axios.post(`/apiv2/os-updates/device/update`, osUpdate)).data;
    });
  }
  static getAllDevicesAsSuperAdminWithoutPagination(inHouseMdm, searchInput) {
    return __async(this, null, function* () {
      const queryParams = new URLSearchParams();
      if (inHouseMdm !== null) {
        queryParams.append("inHouseMdm", String(inHouseMdm));
      }
      if (searchInput) {
        queryParams.append("searchInput", searchInput);
      }
      const queryString = queryParams.toString();
      const url = `/apiv2/devices/superadmin/${queryString ? `?${queryString}` : ""}`;
      try {
        const response = yield axios.get(url);
        return response.data;
      } catch (error) {
        console.error("Error fetching devices as super admin:", error);
        throw error;
      }
    });
  }
  static getDirectoryDeviceByPossessionId(possessionId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/company/directory/device-possession/${possessionId}`)).data;
    });
  }
  static updateDeviceCustomerNotes(deviceId, notes) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/devices/${deviceId}/notes`, notes);
    });
  }
  static deviceOrderAllowed() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/devices/order/allowed`)).data;
    });
  }
}
export class DeviceEndpoints {
  static getDeviceByIdAsSuperAdmin(deviceId) {
    return {
      url: `/apiv2/devices/superadmin/${deviceId}`
    };
  }
  static getOrdersByDeviceIdAsSuperAdmin(deviceId) {
    return {
      url: `/apiv2/devices/superadmin/${deviceId}/orders`
    };
  }
  static getPossessionsByDeviceIdAsSuperAdmin(deviceId) {
    return {
      url: `/apiv2/devices/superadmin/${deviceId}/possessions`
    };
  }
  static getTransitsByDeviceIdAsSuperAdmin(deviceId) {
    return {
      url: `/apiv2/devices/superadmin/${deviceId}/transits`
    };
  }
  static getTransitsByTransitId(transitId) {
    return {
      url: `/apiv2/devices/${transitId}/transit`
    };
  }
  static getAlerts(userId) {
    return {
      url: `/apiv2/devices/${userId}/alerts`
    };
  }
  static getDeviceModelsByCompanyId(includeAll = false) {
    return {
      url: `/apiv2/devices/models/store?includeAll=${includeAll}`
    };
  }
  static getDirectoryDevices() {
    return {
      url: `/apiv2/devices/company/directory`
    };
  }
  static getOffboardingDevices(userId) {
    return {
      url: `/apiv2/devices/users/${userId}/offboarding`
    };
  }
  static getActivityLogForDevice(deviceId) {
    return {
      url: `/apiv2/devices/${deviceId}/activity-log`
    };
  }
  static getAllAvailableDevicePolicies() {
    return {
      url: `/apiv2/device-policies`
    };
  }
  static getAllDevicesAsSuperAdmin(inHouseMdm, searchInput, page, pageSize) {
    const queryParams = new URLSearchParams();
    if (inHouseMdm !== null) {
      queryParams.append("inHouseMdm", String(inHouseMdm));
    }
    if (page) {
      queryParams.append("page", String(page));
    }
    if (pageSize) {
      queryParams.append("offset", String(pageSize));
    }
    if (searchInput) queryParams.append("searchInput", searchInput);
    const queryString = queryParams.toString();
    const url = `/apiv2/devices/superadmin/${queryString ? `?${queryString}` : ""}`;
    return {
      url
    };
  }
  static getOrderByCompanyIdAndDeviceIdAsSuperadmin(companyId, deviceId) {
    return {
      url: `apiv2/devices/superadmin/orders/companies/${companyId}/device/${deviceId}`
    };
  }
  static getEventSourceUrlReassignAction(channelName) {
    return {
      url: `/apiv2/push-events/${channelName}-reassignDevice`
    };
  }
}
