"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DEFAULT_SCHEDULE_CAP, getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import { UserShiftHandlerDrawerContent } from "@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler-drawer-content.component";
import { UserShiftHandlerWidgetContent } from "@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler-widget-content.component";
import { useTimeout } from "@v2/feature/dashboard/features/sections/user-shift/use-timeout.hook";
import {
  endAttendanceBreak,
  endAttendanceShift,
  extractTimeFromShiftEntries,
  getClosestDateForDay,
  getCurrentPosition,
  getDateDayUtil,
  getDistance,
  getHasStartedBreak,
  getRelevantShiftDayFromSchedule,
  getShiftLoggedHours,
  getTheSelectedDaySchedule,
  isInAcceptedProximityUtil,
  isLessThanEarlyCapMinutesUntilStart,
  startAttendanceBreak,
  startAttendanceShift
} from "@v2/feature/dashboard/features/sections/user-shift/user-shift.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { SiteEndpoints } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
export const UserShiftHandler = ({
  attendanceSchedule,
  shift,
  userSite,
  currentWeekAttendance,
  refresh,
  mode,
  selectedDate = new LocalDate().toDateString(),
  readOnly = false
}) => {
  var _a, _b;
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isFetchingLocation, setIsFetchingLocation] = useState(true);
  const [isStartingShift, setIsStartingShift] = useState(false);
  const [isEndingShift, setIsEndingShift] = useState(false);
  const [isStartingBreak, setIsStartingBreak] = useState(false);
  const [isEndingBreak, setIsEndingBreak] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  useTimeout(() => {
    setIsFetchingLocation(false);
  }, 3e3);
  useEffect(() => {
    getCurrentPosition(navigator, setIsFetchingLocation, setCurrentPosition);
  }, []);
  const distance = useMemo(() => {
    return getDistance(attendanceSchedule, currentPosition, userSite, sites);
  }, [currentPosition, userSite, sites, attendanceSchedule]);
  const isInAcceptedProximity = useMemo(() => {
    return isInAcceptedProximityUtil(attendanceSchedule, distance);
  }, [distance, attendanceSchedule]);
  const todaysDay = useMemo(() => getDateDayUtil(), []);
  const selectedDay = useMemo(() => getDateDayUtil(selectedDate), [selectedDate]);
  const relevantDay = useMemo(
    () => getRelevantShiftDayFromSchedule(attendanceSchedule, todaysDay, currentWeekAttendance, shift),
    [attendanceSchedule, todaysDay, currentWeekAttendance, shift]
  );
  const relevantDayWeekIndex = useMemo(
    () => getIndexOfWeekInSchedule(
      attendanceSchedule.startDateOfFirstWeek,
      relevantDay.weekDate,
      attendanceSchedule.noOfWeeks
    ),
    [relevantDay, attendanceSchedule]
  );
  const shiftOrScheduledShift = useMemo(() => {
    return Boolean(
      shift || attendanceSchedule && attendanceSchedule[relevantDay.weekDay] && attendanceSchedule[relevantDay.weekDay][relevantDayWeekIndex]
    );
  }, [relevantDayWeekIndex, attendanceSchedule, relevantDay, shift]);
  const isScheduledForToday = useMemo(() => {
    return Boolean(
      attendanceSchedule && attendanceSchedule[relevantDay.weekDay] && attendanceSchedule[relevantDay.weekDay][relevantDayWeekIndex] && selectedDay === relevantDay.weekDay
    );
  }, [relevantDayWeekIndex, attendanceSchedule, relevantDay, selectedDay]);
  const theFollowingScheduledDay = useMemo(
    () => getTheSelectedDaySchedule(attendanceSchedule, relevantDay.weekDay, relevantDay.weekDate),
    [relevantDay, attendanceSchedule]
  );
  const relevantDate = useMemo(() => {
    if (shift) return new LocalDate(shift.logDate).getDate();
    if (attendanceSchedule && attendanceSchedule[relevantDay.weekDay] && attendanceSchedule[relevantDay.weekDay][relevantDayWeekIndex])
      return getClosestDateForDay(relevantDay.weekDay);
    return new LocalDate().getDate();
  }, [relevantDayWeekIndex, attendanceSchedule, relevantDay, shift]);
  const theSelectedDaySchedule = useMemo(
    () => getTheSelectedDaySchedule(attendanceSchedule, selectedDay, selectedDate),
    [selectedDate, selectedDay, attendanceSchedule]
  );
  const isStarted = useMemo(() => Boolean(shift && !shift.isEnded), [shift]);
  const lessThanEarlyCapMinutesUntilStart = useMemo(() => {
    if (new LocalDate().toDateString() !== new LocalDate(relevantDate).toDateString()) return true;
    return isLessThanEarlyCapMinutesUntilStart(
      attendanceSchedule,
      shiftOrScheduledShift,
      isStarted,
      theFollowingScheduledDay
    );
  }, [shiftOrScheduledShift, theFollowingScheduledDay, isStarted, attendanceSchedule, relevantDate]);
  const breakType = useMemo(() => attendanceSchedule.attendanceTypesAllowed.find((type) => type.name === "Break"), [
    attendanceSchedule
  ]);
  const hasStartedBreak = useMemo(() => getHasStartedBreak(shift, breakType), [shift, breakType]);
  const time = useMemo(() => extractTimeFromShiftEntries(shift), [shift]);
  const isEnded = useMemo(() => Boolean(shift == null ? void 0 : shift.isEnded), [shift == null ? void 0 : shift.isEnded]);
  const startShift = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2;
    if (!(currentPosition == null ? void 0 : currentPosition.coords) && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t("AttendanceDomain.AttendanceShift.couldNotGetLocation"), "error");
      return;
    }
    setIsStartingShift(true);
    yield startAttendanceShift(
      isInAcceptedProximity,
      distance,
      attendanceSchedule.useGeolocation ? (_a2 = currentPosition == null ? void 0 : currentPosition.coords.longitude) != null ? _a2 : null : null,
      attendanceSchedule.useGeolocation ? (_b2 = currentPosition == null ? void 0 : currentPosition.coords.latitude) != null ? _b2 : null : null,
      showMessage,
      refresh,
      polyglot,
      todaysDay !== relevantDay.weekDay
    );
    setIsStartingShift(false);
  }), [
    attendanceSchedule.useGeolocation,
    currentPosition,
    isInAcceptedProximity,
    distance,
    showMessage,
    refresh,
    polyglot,
    todaysDay,
    relevantDay
  ]);
  const endShift = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2;
    if (!(currentPosition == null ? void 0 : currentPosition.coords) && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t("AttendanceDomain.AttendanceShift.couldNotGetLocation"), "error");
      return;
    }
    setIsEndingShift(true);
    yield endAttendanceShift(
      attendanceSchedule.useGeolocation ? (_a2 = currentPosition == null ? void 0 : currentPosition.coords.longitude) != null ? _a2 : null : null,
      attendanceSchedule.useGeolocation ? (_b2 = currentPosition == null ? void 0 : currentPosition.coords.latitude) != null ? _b2 : null : null,
      showMessage,
      refresh,
      polyglot,
      todaysDay !== relevantDay.weekDay
    );
    setIsEndingShift(false);
  }), [attendanceSchedule.useGeolocation, currentPosition, refresh, showMessage, polyglot, todaysDay, relevantDay]);
  const startBreak = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2;
    if (!(currentPosition == null ? void 0 : currentPosition.coords) && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t("AttendanceDomain.AttendanceShift.couldNotGetLocation"), "error");
      return;
    }
    setIsStartingBreak(true);
    yield startAttendanceBreak(
      attendanceSchedule.useGeolocation ? (_a2 = currentPosition == null ? void 0 : currentPosition.coords.longitude) != null ? _a2 : null : null,
      attendanceSchedule.useGeolocation ? (_b2 = currentPosition == null ? void 0 : currentPosition.coords.latitude) != null ? _b2 : null : null,
      showMessage,
      refresh,
      polyglot
    );
    setIsStartingBreak(false);
  }), [attendanceSchedule.useGeolocation, currentPosition, refresh, showMessage, polyglot]);
  const endBreak = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2;
    if (!(currentPosition == null ? void 0 : currentPosition.coords) && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t("AttendanceDomain.AttendanceShift.couldNotGetLocation"), "error");
      return;
    }
    setIsEndingBreak(true);
    yield endAttendanceBreak(
      attendanceSchedule.useGeolocation ? (_a2 = currentPosition == null ? void 0 : currentPosition.coords.longitude) != null ? _a2 : null : null,
      attendanceSchedule.useGeolocation ? (_b2 = currentPosition == null ? void 0 : currentPosition.coords.latitude) != null ? _b2 : null : null,
      showMessage,
      refresh,
      polyglot
    );
    setIsEndingBreak(false);
  }), [attendanceSchedule.useGeolocation, currentPosition, refresh, showMessage, polyglot]);
  const shiftLoggedHours = useMemo(() => getShiftLoggedHours(theFollowingScheduledDay, polyglot), [
    theFollowingScheduledDay,
    polyglot
  ]);
  const shiftSelectedLoggedHours = useMemo(() => getShiftLoggedHours(theSelectedDaySchedule, polyglot), [
    polyglot,
    theSelectedDaySchedule
  ]);
  return mode === "widget" ? /* @__PURE__ */ jsx(
    UserShiftHandlerWidgetContent,
    {
      clockInEarlyCapMinutes: (_a = attendanceSchedule.clockInEarlyCapMinutes) != null ? _a : DEFAULT_SCHEDULE_CAP,
      theFollowingScheduledDay,
      isStarted,
      hasStartedBreak,
      isEnded,
      time,
      shiftLoggedHours,
      isStartingShift,
      isFetchingLocation,
      lessThanEarlyCapMinutesUntilStart,
      isStartingBreak,
      startShift,
      startBreak,
      endShift,
      endBreak,
      isEndingBreak,
      isEndingShift,
      relevantDate,
      readOnly,
      shiftOrScheduledShift
    }
  ) : /* @__PURE__ */ jsx(
    UserShiftHandlerDrawerContent,
    {
      clockInEarlyCapMinutes: (_b = attendanceSchedule.clockInEarlyCapMinutes) != null ? _b : DEFAULT_SCHEDULE_CAP,
      theFollowingScheduledDay,
      isStarted,
      hasStartedBreak,
      isEnded,
      time,
      shiftLoggedHours,
      isStartingShift,
      isFetchingLocation,
      lessThanEarlyCapMinutesUntilStart,
      isStartingBreak,
      startShift,
      startBreak,
      endShift,
      endBreak,
      isEndingBreak,
      isEndingShift,
      isScheduledForToday,
      selectedDate,
      shiftSelectedLoggedHours
    }
  );
};
