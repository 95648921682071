"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { AbsenceEndpoints } from "@/v2/feature/absence/absence.api";
import { getWeekInterval } from "@/v2/feature/attendance/attendance.util";
import { WeekCalendar } from "@/v2/feature/attendance/components/week-calendar.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const AttendanceUserWeekCalendar = ({
  userId,
  year,
  weekNo,
  userAttendanceSchedule,
  weekAttendances,
  refreshWeekAttendances
}) => {
  const { start, end } = getWeekInterval(year, weekNo);
  const { data: userAbsences } = useApiClient(
    AbsenceEndpoints.fetchUserAllowedAbsencesByDateRange({ start, end }, userId),
    { suspense: false }
  );
  return /* @__PURE__ */ jsx(
    WeekCalendar,
    {
      userId,
      data: weekAttendances,
      attendanceSchedule: userAttendanceSchedule,
      weekNo,
      year,
      refreshRequests: () => __async(void 0, null, function* () {
        yield refreshWeekAttendances();
      }),
      userAbsences: userAbsences != null ? userAbsences : []
    }
  );
};
