"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { ScheduleTrackingTimesheetType } from "@v2/feature/attendance/attendance.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { ReactComponent as ClockIcon } from "@/images/side-bar-icons/TimeAway.svg";
import { AttendanceActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/attendance-action-item.component";
import { TodoCard } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { iconSize } from "@/v2/styles/menu.styles";
export const SubmittedAttendanceTodo = ({
  todo,
  setLastChild,
  refreshTodos
}) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState(void 0);
  const weeklyAttendancesByUserByWeek = useMemo(() => {
    var _a;
    return (_a = todo.attendance) == null ? void 0 : _a.filter((attend) => attend.timesheetType === ScheduleTrackingTimesheetType.Weekly);
  }, [todo.attendance]);
  const dailyAttendances = useMemo(() => {
    var _a;
    return (_a = todo.attendance) == null ? void 0 : _a.filter((attend) => attend.timesheetType === ScheduleTrackingTimesheetType.Daily);
  }, [todo.attendance]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    weeklyAttendancesByUserByWeek.map((attendance, idx) => {
      var _a;
      return /* @__PURE__ */ jsx(
        TodoCard,
        {
          icon: /* @__PURE__ */ jsx(ClockIcon, __spreadValues({}, iconSize)),
          title: /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              sx: {
                display: "inline-block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%"
              },
              children: polyglot.t("SubmittedAttendanceTodo.waitingApprovalFromandWeek", {
                user: (_a = getCachedUserById(attendance.userId)) == null ? void 0 : _a.displayName,
                weekNum: attendance.weekNo
              })
            }
          ),
          actionOnclick: () => {
            setSelectedRow(attendance);
          },
          showBorder: !(setLastChild && todo.attendance.length === idx + 1 && dailyAttendances.length === 0),
          userAvatar: /* @__PURE__ */ jsx(UserAvatar, { userId: Number(attendance.userId), size: "xxxsmall" })
        },
        `attendance-${attendance.userId}-${attendance.weekNo}-${idx}`
      );
    }),
    dailyAttendances.map((attendance, idx) => {
      var _a;
      const title = `${(_a = getCachedUserById(attendance.userId)) == null ? void 0 : _a.displayName}, ${new LocalDate(
        attendance.logDate
      ).toLocaleDateString(void 0, {
        day: "numeric",
        month: "short"
      })}`;
      return /* @__PURE__ */ jsx(
        TodoCard,
        {
          icon: /* @__PURE__ */ jsx(ClockIcon, __spreadValues({}, iconSize)),
          title: /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              sx: {
                display: "inline-block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%"
              },
              children: polyglot.t("SubmittedAttendanceTodo.waitingApprovalFrom", {
                title
              })
            }
          ),
          actionOnclick: () => {
            setSelectedRow(attendance);
          },
          showBorder: !(setLastChild && todo.attendance.length === idx + 1),
          userAvatar: /* @__PURE__ */ jsx(UserAvatar, { userId: attendance.userId, size: "xxxsmall" })
        },
        `attendance-${attendance.id}`
      );
    }),
    selectedRow && /* @__PURE__ */ jsx(
      AttendanceActionItem,
      {
        attendanceActionRow: selectedRow,
        refresh: refreshTodos,
        afterClose: () => setSelectedRow(void 0)
      }
    )
  ] });
};
