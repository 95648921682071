"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { SwitchComponent } from "@v2/components/forms/switch.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DEFAULT_SCHEDULE_CAP } from "@v2/feature/attendance/attendance-schedule.util";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import {
  ScheduleTrackingTimesheetType,
  ScheduleTrackingType,
  ScheduleTrackingTypeOptions
} from "@v2/feature/attendance/attendance.interface";
import { EMPLOYEE_SITE, getScheduleTrackingSettingsValidationSchema } from "@v2/feature/attendance/attendance.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { StyledRadio } from "@v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { drawerContentSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const ScheduleSettingsForm = ({
  attendanceSchedule,
  onSubmit,
  buttonName,
  loading,
  sites
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const { data: attendanceTypes } = useApiClient(
    AttendanceEndpoints.getCompanyAttendanceTypes(),
    { suspense: false }
  );
  const formik = useFormik({
    initialValues: {
      trackingType: attendanceSchedule.trackingType,
      attendanceTypesAllowedIds: attendanceSchedule.attendanceTypesAllowedIds,
      timesheetType: attendanceSchedule.timesheetType,
      useGeolocation: attendanceSchedule.useGeolocation,
      restrictByGeolocation: attendanceSchedule.restrictByGeolocation,
      geolocationDistance: (_a = attendanceSchedule.geolocationDistance) != null ? _a : null,
      geolocationEmployeeSite: (_b = attendanceSchedule.geolocationEmployeeSite) != null ? _b : false,
      allowedWorkSites: (_c = attendanceSchedule.allowedWorkSites) != null ? _c : null,
      clockInEarlyCapMinutes: (_d = attendanceSchedule.clockInEarlyCapMinutes) != null ? _d : null
    },
    validationSchema: getScheduleTrackingSettingsValidationSchema(polyglot),
    onSubmit
  });
  useEffect(() => {
    var _a2;
    const allTypes = (_a2 = attendanceTypes == null ? void 0 : attendanceTypes.map((type) => type.id)) != null ? _a2 : [];
    formik.setFieldValue("attendanceTypesAllowedIds", allTypes);
  }, [attendanceTypes]);
  const SitesOptions = useMemo(() => {
    return [EMPLOYEE_SITE(polyglot), ...sites ? sites.map((site) => ({ value: site.id, label: site.name })) : []];
  }, [sites, polyglot]);
  const SelectedSiteOptions = useMemo(() => {
    const employeeSite = formik.values.geolocationEmployeeSite ? [EMPLOYEE_SITE(polyglot)] : [];
    const sites2 = formik.values.allowedWorkSites ? SitesOptions.filter(
      (site) => typeof site.value === "number" && formik.values.allowedWorkSites.includes(site.value)
    ) : [];
    return [...employeeSite, ...sites2];
  }, [SitesOptions, formik.values.allowedWorkSites, formik.values.geolocationEmployeeSite, polyglot]);
  const MaximumDistanceOptions = useMemo(() => {
    return [100, 200, 300, 400, 500].map((distance) => ({
      value: distance,
      label: polyglot.t("ScheduleSettingsForm.distanceMeter", { distance })
    }));
  }, [polyglot]);
  const trackingTypeOptions = useMemo(() => ScheduleTrackingTypeOptions(attendanceSchedule.isFlexible, polyglot), [
    polyglot,
    attendanceSchedule
  ]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("SettingsSectionReadonly.timesheets") }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: formik.values.trackingType !== ScheduleTrackingType.None,
          name: "trackingType",
          onChange: (_e2, enabled) => {
            if (enabled) formik.setFieldValue("trackingType", ScheduleTrackingType.Regular);
            else {
              formik.setFieldValue("trackingType", ScheduleTrackingType.None);
              formik.setFieldValue("timesheetType", ScheduleTrackingTimesheetType.Weekly);
              formik.setFieldValue("useGeolocation", false);
              formik.setFieldValue("restrictByGeolocation", false);
              formik.setFieldValue("clockInEarlyCapMinutes", null);
              formik.setFieldValue("geolocationEmployeeSite", false);
              formik.setFieldValue("allowedWorkSites", null);
            }
          }
        }
      )
    ] }),
    formik.values.trackingType !== ScheduleTrackingType.None && /* @__PURE__ */ jsxs(FormControl, { sx: { width: "100%" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("ScheduleSettingsForm.trackingType") }),
      /* @__PURE__ */ jsxs(
        RadioGroup,
        {
          "aria-labelledby": "method",
          name: "trackingTypeEnabled",
          onChange: (event) => {
            var _a2;
            formik.setFieldValue("trackingType", event.target.value);
            if (event.target.value === ScheduleTrackingType.ClockInClockOut) {
              formik.setFieldValue("timesheetType", ScheduleTrackingTimesheetType.Daily);
              formik.setFieldValue(
                "clockInEarlyCapMinutes",
                (_a2 = attendanceSchedule.clockInEarlyCapMinutes) != null ? _a2 : DEFAULT_SCHEDULE_CAP
              );
            } else {
              formik.setFieldValue("timesheetType", ScheduleTrackingTimesheetType.Weekly);
              formik.setFieldValue("useGeolocation", false);
              formik.setFieldValue("restrictByGeolocation", false);
              formik.setFieldValue("clockInEarlyCapMinutes", null);
              formik.setFieldValue("geolocationEmployeeSite", false);
              formik.setFieldValue("allowedWorkSites", null);
            }
          },
          children: [
            /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: ScheduleTrackingType.Regular,
                checked: formik.values.trackingType === ScheduleTrackingType.Regular,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsx(
                  Typography,
                  {
                    variant: formik.values.trackingType === ScheduleTrackingType.Regular ? "title4" : "caption",
                    children: (_f = (_e = trackingTypeOptions.find((o) => o.value === ScheduleTrackingType.Regular)) == null ? void 0 : _e.label) != null ? _f : ScheduleTrackingType.Regular
                  }
                )
              },
              "regular"
            ),
            /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: ScheduleTrackingType.ClockInClockOut,
                checked: formik.values.trackingType === ScheduleTrackingType.ClockInClockOut,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsx(
                  Typography,
                  {
                    variant: formik.values.trackingType === ScheduleTrackingType.ClockInClockOut ? "title4" : "caption",
                    children: (_h = (_g = trackingTypeOptions.find((o) => o.value === ScheduleTrackingType.ClockInClockOut)) == null ? void 0 : _g.label) != null ? _h : ScheduleTrackingType.ClockInClockOut
                  }
                )
              },
              "clock-in-clock-out"
            )
          ]
        }
      )
    ] }),
    formik.values.trackingType === ScheduleTrackingType.ClockInClockOut && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mt: "10px" }, children: polyglot.t("SettingsSectionReadonly.additionalSettings") }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: polyglot.t("ScheduleSettingsForm.clockInEarlyCapMinutes"),
          name: "clockInEarlyCapMinutes",
          value: formik.values.clockInEarlyCapMinutes,
          onChange: formik.handleChange,
          error: formik.touched.clockInEarlyCapMinutes && !!formik.errors.clockInEarlyCapMinutes,
          helperText: (_i = formik.touched.clockInEarlyCapMinutes && formik.errors.clockInEarlyCapMinutes) != null ? _i : " ",
          endAdornment: "none"
        }
      ) }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("SettingsSectionReadonly.useGPSForShifts") }),
        /* @__PURE__ */ jsx(
          SwitchComponent,
          {
            checked: formik.values.useGeolocation,
            name: "useGeolocation",
            onChange: (_, enabled) => {
              formik.setFieldValue("useGeolocation", enabled);
              if (!enabled) formik.setFieldValue("restrictByGeolocation", false);
            }
          }
        )
      ] }),
      formik.values.useGeolocation && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("SettingsSectionReadonly.restrictLocation") }),
        /* @__PURE__ */ jsx(
          SwitchComponent,
          {
            checked: formik.values.restrictByGeolocation,
            name: "restrictByGeolocation",
            onChange: (_, enabled) => {
              formik.setFieldValue("restrictByGeolocation", enabled);
              if (!enabled) {
                formik.setFieldValue("geolocationEmployeeSite", false);
                formik.setFieldValue("allowedWorkSites", null);
                formik.setFieldValue("geolocationDistance", null);
              }
            }
          }
        )
      ] }),
      formik.values.trackingType === ScheduleTrackingType.ClockInClockOut && formik.values.restrictByGeolocation && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        MultipleSelectCheckbox,
        {
          id: "group-select",
          options: SitesOptions,
          value: SelectedSiteOptions,
          onChange: (_, values) => {
            const employeeSites = !!values.find((site) => site.value === "employeeSite");
            const sites2 = values.filter((site) => typeof site.value === "number").map((site) => site.value);
            formik.setFieldValue("geolocationEmployeeSite", employeeSites);
            formik.setFieldValue("allowedWorkSites", sites2);
          },
          isOptionEqualToValue: (x, y) => x.value === y.value,
          getOptionLabel: ({ label }) => label,
          label: polyglot.t("ScheduleSettingsForm.allowedWork"),
          helperText: formik.touched.allowedWorkSites || formik.touched.geolocationEmployeeSite ? formik.errors.allowedWorkSites || formik.errors.geolocationEmployeeSite : "",
          error: Boolean(
            (formik.touched.allowedWorkSites || formik.touched.geolocationEmployeeSite) && (formik.errors.allowedWorkSites || formik.errors.geolocationEmployeeSite)
          )
        }
      ) }),
      formik.values.trackingType === ScheduleTrackingType.ClockInClockOut && formik.values.restrictByGeolocation && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "geolocationDistance",
          label: polyglot.t("ScheduleSettingsForm.geolocationDistance"),
          options: MaximumDistanceOptions,
          value: formik.values.geolocationDistance,
          compareValue: formik.values.geolocationDistance,
          onChange: formik.handleChange,
          error: !!formik.errors.geolocationDistance && formik.touched.geolocationDistance,
          helperText: formik.touched.geolocationDistance && formik.errors.geolocationDistance
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: buttonName, loading, fullWidth: true, sizeVariant: "medium", colorVariant: "primary" }) })
  ] }) });
};
