"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { UserDirectoryRouter } from "@v2/feature/user/user-directory.router";
import { Redirect, Route, Switch } from "react-router-dom";
import { UserDetailsRouter } from "@/component/dashboard/userDetails/user-details.router";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  DOCUMENTS_COMPANY_DOMAIN_ROUTE,
  DOCUMENTS_ME_DOMAIN_ROUTE,
  DOCUMENTS_TEAM_DOMAIN_ROUTE,
  OFFBOARDING_ROUTE,
  ONBOARDING_DOMAIN_ROUTE,
  PEOPLE_COMPANY_ACTION_ITEMS,
  PEOPLE_COMPANY_DIRECTORY_ROUTE,
  PEOPLE_ME_ACTION_ITEMS,
  PEOPLE_ME_DIRECTORY_ROUTE,
  PEOPLE_ROUTE,
  PEOPLE_TEAM_ACTION_ITEMS,
  PEOPLE_TEAM_DIRECTORY_ROUTE,
  REQUESTS_ROUTE,
  TASKS_COMPANY_ROUTE,
  TASKS_ME_ROUTE,
  TASKS_TEAM_ROUTE,
  USER_DETAILS_ROUTE
} from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { UserActionList } from "@/v2/feature/dashboard/features/sections/user-todos/user-action-list.page";
import { DocumentsRouter } from "@/v2/feature/documents/documents.router";
import { OffboardingRouter } from "@/v2/feature/offboarding/offboarding.router";
import { OnboardingRouter } from "@/v2/feature/onboarding/onboarding.router";
import { RequestsRouter } from "@/v2/feature/requests/requests.router";
import { TaskRouter } from "@/v2/feature/task/task.router";
export const PeopleRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["user:read:all"],
        scopesContext,
        path: [PEOPLE_COMPANY_DIRECTORY_ROUTE, PEOPLE_TEAM_DIRECTORY_ROUTE, PEOPLE_ME_DIRECTORY_ROUTE],
        component: UserDirectoryRouter
      }
    ),
    /* @__PURE__ */ jsx(Route, { path: REQUESTS_ROUTE, component: RequestsRouter }),
    /* @__PURE__ */ jsx(
      Route,
      {
        path: [PEOPLE_COMPANY_ACTION_ITEMS, PEOPLE_TEAM_ACTION_ITEMS, PEOPLE_ME_ACTION_ITEMS],
        component: UserActionList
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["user.onboard:all"], path: ONBOARDING_DOMAIN_ROUTE, component: OnboardingRouter }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["user.onboard:all"], path: OFFBOARDING_ROUTE, component: OffboardingRouter }),
    /* @__PURE__ */ jsx(Route, { path: [TASKS_COMPANY_ROUTE, TASKS_TEAM_ROUTE, TASKS_ME_ROUTE], component: TaskRouter }),
    /* @__PURE__ */ jsx(
      Route,
      {
        path: [DOCUMENTS_COMPANY_DOMAIN_ROUTE, DOCUMENTS_TEAM_DOMAIN_ROUTE, DOCUMENTS_ME_DOMAIN_ROUTE],
        component: DocumentsRouter
      }
    ),
    /* @__PURE__ */ jsx(Route, { path: USER_DETAILS_ROUTE, component: UserDetailsRouter }),
    canAccessScopes(user, ["user.onboard:all", "task:all", "documents:all", "company.settings:all"]) && /* @__PURE__ */ jsx(Redirect, { from: PEOPLE_ROUTE, to: PEOPLE_COMPANY_DIRECTORY_ROUTE }),
    canAccessScopes(user, ["documents:manager", "task:manager"]) && /* @__PURE__ */ jsx(Redirect, { from: PEOPLE_ROUTE, to: PEOPLE_TEAM_DIRECTORY_ROUTE }),
    canAccessScopes(user, ["user"]) && /* @__PURE__ */ jsx(Redirect, { from: PEOPLE_ROUTE, to: PEOPLE_ME_DIRECTORY_ROUTE })
  ] });
};
