"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell, IconEmptyCell } from "@v2/components/table/empty-cell.component";
import { sortDate, sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { DevicePossessionType } from "@v2/feature/device/device.interface";
import { getDeviceOwnerByDevicePossessionTable, getModelImage } from "@v2/feature/device/device.util";
import {
  getDeviceStatus,
  getEnrolmentDeviceStatus
} from "@v2/feature/device/features/devices-company/components/configurable-device-view.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
const formatDate = (date) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};
export const DevicesTable = ({
  devices,
  handleRowClick,
  hiddenColumns,
  loading,
  fixedLastColumn
}) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const getUserName = useCallback(
    (assignedUserId) => {
      var _a;
      const user = getCachedUserById(assignedUserId);
      return polyglot.t((_a = user == null ? void 0 : user.displayName) != null ? _a : "");
    },
    [getCachedUserById, polyglot]
  );
  const tableColumns = useMemo(
    () => [
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.device"),
        accessorFn: (row) => row,
        id: "device",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.devicePossession.device) == null ? void 0 : _a.modelName;
        }),
        cell: (info) => {
          var _a, _b, _c, _d, _e, _f, _g;
          const configurableDevice = info.row.original;
          const modelName = configurableDevice.status === "order" ? (_b = (_a = configurableDevice.order) == null ? void 0 : _a.deviceModel) == null ? void 0 : _b.modelName : (_c = configurableDevice.devicePossession.device) == null ? void 0 : _c.modelName;
          const deviceType = configurableDevice.status === "order" ? (_e = (_d = configurableDevice.order) == null ? void 0 : _d.deviceModel) == null ? void 0 : _e.type : (_f = configurableDevice.devicePossession.device) == null ? void 0 : _f.type;
          const deviceId = configurableDevice.status === "order" ? (_g = configurableDevice.order) == null ? void 0 : _g.deviceId : configurableDevice.devicePossession.deviceId;
          return modelName && deviceType ? /* @__PURE__ */ jsxs(
            Box,
            {
              sx: { display: "flex", gap: spacing.g10, alignItems: "center", height: "inherit" },
              children: [
                /* @__PURE__ */ jsx(
                  Box,
                  {
                    sx: {
                      objectFit: "contain"
                    },
                    children: getModelImage(deviceType, modelName, {
                      width: "30px",
                      height: "30px"
                    })
                  },
                  `model-img-cell-${deviceId}`
                ),
                /* @__PURE__ */ jsx("div", { children: modelName }, `model-name-${deviceId}`)
              ]
            },
            `model-cell-${deviceId}`
          ) : /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 1 }, children: /* @__PURE__ */ jsx(IconEmptyCell, {}) }, `${info.row.id}-${info.row.index}`);
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.usedBy"),
        accessorFn: (row) => row,
        id: "usedBy",
        enableSorting: false,
        cell: (info) => {
          var _a, _b;
          const devicePossession = info.getValue().devicePossession;
          if (devicePossession.possessionType === DevicePossessionType.User && devicePossession.possessionId)
            return /* @__PURE__ */ jsxs(
              Box,
              {
                sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.m5
                }),
                children: [
                  /* @__PURE__ */ jsx(
                    UserAvatar,
                    {
                      userId: devicePossession.possessionId,
                      size: "xxsmall"
                    },
                    `avatar-${devicePossession.id}`
                  ),
                  /* @__PURE__ */ jsx("div", { children: getUserName(devicePossession.possessionId) }, `user-${devicePossession.id}`)
                ]
              },
              devicePossession.id
            );
          return /* @__PURE__ */ jsx(Box, { children: getDeviceOwnerByDevicePossessionTable(
            devicePossession,
            info.getValue().sites.find((item) => item.id === devicePossession.possessionId),
            polyglot,
            (_b = (_a = getCachedUserById(devicePossession.possessionId)) == null ? void 0 : _a.displayName) != null ? _b : ""
          ) }, devicePossession.id);
        },
        maxSize: 200,
        minSize: 140
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.deviceName"),
        accessorFn: (row) => row,
        id: "deviceName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item.devicePossession.device) == null ? void 0 : _a.deviceName) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a, _b;
          return ((_a = devicePossession.device) == null ? void 0 : _a.deviceName) ? /* @__PURE__ */ jsx("div", { children: (_b = devicePossession.device) == null ? void 0 : _b.deviceName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.serialNumber"),
        accessorFn: (row) => row,
        id: "serialNumber",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item.devicePossession.device) == null ? void 0 : _a.serialNumber) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a, _b;
          return ((_a = devicePossession.device) == null ? void 0 : _a.serialNumber) ? /* @__PURE__ */ jsx("div", { children: (_b = devicePossession.device) == null ? void 0 : _b.serialNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.model"),
        accessorFn: (row) => row,
        id: "modelNumber",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item.devicePossession.device) == null ? void 0 : _a.modelNumber) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a, _b;
          return ((_a = devicePossession.device) == null ? void 0 : _a.modelNumber) ? /* @__PURE__ */ jsx("div", { children: (_b = devicePossession.device) == null ? void 0 : _b.modelNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 150,
        minSize: 100
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.lastActive"),
        accessorFn: (row) => row,
        id: "lastActive",
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item.devicePossession.device) == null ? void 0 : _a.lastCheckIn) != null ? _b : void 0;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a, _b;
          return ((_a = devicePossession.device) == null ? void 0 : _a.lastCheckIn) ? /* @__PURE__ */ jsx("div", { children: formatDate(new Date((_b = devicePossession.device) == null ? void 0 : _b.lastCheckIn)) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item.devicePossession.device) == null ? void 0 : _a.enrollmentStatus) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { status }
          }
        }) => status ? getDeviceStatus(status) : /* @__PURE__ */ jsx(EmptyCell, {}),
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.enrolment"),
        accessorFn: (row) => row,
        id: "enrolment",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item.devicePossession.device) == null ? void 0 : _a.enrollmentStatus) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { enrolment }
          }
        }) => enrolment ? getEnrolmentDeviceStatus(enrolment) : /* @__PURE__ */ jsx(EmptyCell, {}),
        maxSize: 110,
        minSize: 100
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.storage"),
        accessorFn: (row) => row,
        id: "storage",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item.devicePossession.device) == null ? void 0 : _a.storage) != null ? _b : void 0;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a, _b;
          return ((_a = devicePossession.device) == null ? void 0 : _a.storage) ? /* @__PURE__ */ jsxs("div", { children: [
            (_b = devicePossession.device) == null ? void 0 : _b.storage,
            " GB"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.notes"),
        accessorFn: (row) => row,
        id: "customerNotes",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item.devicePossession.device) == null ? void 0 : _a.customerNotes) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a, _b;
          return ((_a = devicePossession.device) == null ? void 0 : _a.customerNotes) ? /* @__PURE__ */ jsx("div", { children: (_b = devicePossession.device) == null ? void 0 : _b.customerNotes }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.osVersion"),
        accessorFn: (row) => row,
        id: "osVersion",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item.devicePossession.device) == null ? void 0 : _a.osVersion) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a, _b;
          return ((_a = devicePossession.device) == null ? void 0 : _a.osVersion) ? /* @__PURE__ */ jsx("div", { children: (_b = devicePossession.device) == null ? void 0 : _b.osVersion }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      }
    ],
    [polyglot, getCachedUserById, getUserName]
  );
  return /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: devices,
      columnData: tableColumns,
      loading,
      initialSort: [{ id: "enrolment", desc: true }],
      rowClick: handleRowClick,
      hiddenColumns,
      fixedLastColumn
    }
  ) });
};
