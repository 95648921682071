"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
import { extractFilters } from "@/v2/feature/super-admin/features/super-admin-billing/helper/extract-filters.helper";
import { convertDateFields } from "@/v2/util/date-format.util";
export class UserAPI {
  static getUserAvatarURL(userId) {
    return `/apiv2/users/${userId}/avatar`;
  }
  static createBulk(bulkUserList) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/users/bulk", bulkUserList);
    });
  }
  static getUserAvatar(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(this.getUserAvatarURL(userId))).data;
    });
  }
  static deleteUserAvatar(userId) {
    return __async(this, null, function* () {
      yield axios.delete(this.getUserAvatarURL(userId));
    });
  }
  static patchUserBasicInfo(userId, userBasicsUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/basic`, userBasicsUpdate);
    });
  }
  static getUserBasicInfo(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/basic`)).data;
    });
  }
  static patchUserPersonalInfo(userId, userPersonalUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/personal`, userPersonalUpdate);
    });
  }
  static patchUserFamilyInfo(userId, userPersonalFamilyUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/family`, userPersonalFamilyUpdate);
    });
  }
  static patchUserAboutInfo(userId, userAboutUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/about`, userAboutUpdate);
    });
  }
  static patchUserEmergencyContactInfo(userId, userEmergencyContactUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/emergency-contact`, userEmergencyContactUpdate);
    });
  }
  static patchUserWorkContactInfo(userId, userWorkContactUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/work-contact`, userWorkContactUpdate);
    });
  }
  static getUserLifecycleInfo(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/lifecycle`)).data;
    });
  }
  static updateEmployeeId(userId, employeeIdUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/lifecycle/employeeId`, employeeIdUpdate);
    });
  }
  static getAllUsersAsSuperAdmin(param) {
    return __async(this, null, function* () {
      const filters = extractFilters((param == null ? void 0 : param.filter) || "");
      let searchParams;
      if ((param == null ? void 0 : param.filter) && filters) {
        const _a = param, { filter } = _a, otherParams = __objRest(_a, ["filter"]);
        searchParams = new URLSearchParams(__spreadValues(__spreadValues({}, otherParams), filters));
      } else {
        searchParams = new URLSearchParams(param);
      }
      return (yield axios.get(`/apiv2/users/superadmin?${searchParams.toString()}`)).data;
    });
  }
  static getUserSuperadminBasicByCompany(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/superadmin/${companyId}/basic`)).data;
    });
  }
  // TODO: @user-endpoint - this should be entirely replaced
  static inviteUser(userId, mode) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/users/${userId}/invite?mode=${mode}`)).data;
    });
  }
  static getUserPersonalInfo(userId) {
    return __async(this, null, function* () {
      const { data } = yield axios.get(`/apiv2/users/${userId}/personal`);
      data.dob = data.dob && data.dob.split("T")[0];
      return data;
    });
  }
  static getUserAbout(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/about`)).data;
    });
  }
  /**
   * Fetches all users to cache.
   * @param useCache whether to bypass the cached version of the result of this api.
   * @param shouldRethrow Whether to rethrow errors, default true. As this api call is now used asynchronously,
   * errors can be unhandled. Only set false if you want to run in the background.
   */
  static fetchUserCache(useCache = true, shouldRethrow = true) {
    return __async(this, null, function* () {
      try {
        if (!this.userCacheResponse || !useCache) {
          this.userCacheResponse = axios.get("/apiv2/users/cache");
          this.userCacheResponse.catch(() => {
            this.userCacheResponse = null;
          });
        }
        return (yield this.userCacheResponse).data;
      } catch (error) {
        this.userCacheResponse = null;
        if (shouldRethrow) throw error;
        return [];
      }
    });
  }
  static getUserEmergencyContact(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/emergency-contact`)).data;
    });
  }
  static getUserFamilyInfo(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/family`)).data;
    });
  }
  static getUserWorkContactInfo(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/work-contact`)).data;
    });
  }
  static addUserFamilyMember(userId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/family/members`, data);
    });
  }
  static updateUserFamilyMemberById(userId, memberId, update) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/users/${userId}/family/members/${memberId}`, update);
    });
  }
  static deleteUserFamilyMemberById(userId, memberId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/users/${userId}/family/members/${memberId}`);
    });
  }
  static getUserAccountInfo(userId) {
    return __async(this, null, function* () {
      const { data } = yield axios.get(`/apiv2/users/${userId}/account-info`);
      return convertDateFields(data, ["createdAt", "updatedAt", "lastTimeOnline"]);
    });
  }
  static getUserSummaryForOnboardingById(userId, includeReports = true) {
    return __async(this, null, function* () {
      const opts = { params: { reports: includeReports } };
      return (yield axios.get(`/apiv2/users/${userId}/summary-for-onboarding`, opts)).data;
    });
  }
  static getAlerts(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/alerts`)).data;
    });
  }
  // WARNING: Don't forget to update the global user each time you use this
  static updateOwnUserFeatures(domain, subdomain, featureName, value) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/users/features/${domain}/${subdomain}/${featureName}`, { value })).data;
    });
  }
  static exportPeopleDirectory() {
    return "/apiv2/users/filtered";
  }
  static exportSelectedPeopleDirectory(selectedUserIds) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/users/export-selected", { selectedUserIds })).data;
    });
  }
  static inviteSelectedPeople(selectedUserIds) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/users/invite-selected", { selectedUserIds })).data;
    });
  }
  static getUserLocaleSettings() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/locale`)).data;
    });
  }
  static patchUserLocaleSettings(userUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/locale`, userUpdate);
    });
  }
  static patchUserPersonalSettings(userUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/privacy`, userUpdate);
    });
  }
  static exportReportById() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/stats/orgchart-csv`)).data;
    });
  }
  static sendOsVersionUpdateNotification(update) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(`/apiv2/users/notifications/os-update-notification`, update)) == null ? void 0 : _a.data;
    });
  }
}
UserAPI.userCacheResponse = null;
export class UserEndpoints {
  static getUserAvatarURL(userId) {
    return {
      url: `/apiv2/users/${userId}/avatar`
    };
  }
  static getAllUsersDirectoryV2(filterString) {
    return {
      url: `/apiv2/users/user-directory-v2?${filterString}`
    };
  }
  static getUserSummaryById(userId, includeReports = true) {
    return {
      url: `/apiv2/users/${userId}/summary?reports=${includeReports}`
    };
  }
  static getUserPersonalInfo(userId) {
    return {
      url: `/apiv2/users/${userId}/personal`
    };
  }
  static getUserBasicInfo(userId) {
    return {
      url: `/apiv2/users/${userId}/basic`
    };
  }
  static getOrgChartUsers() {
    return {
      url: "/apiv2/users/stats/orgchart"
    };
  }
  static getUserOverviewConfig(userId) {
    return {
      url: `/apiv2/users/${userId}/overview/config`
    };
  }
  static getUserMissingFields(userId) {
    return {
      url: `/apiv2/users/${userId}/missing-fields`
    };
  }
  static getBulkDirectReports(listOfUserIds) {
    return {
      url: `/apiv2/users/bulk-direct-reports?userList=${listOfUserIds == null ? void 0 : listOfUserIds.join(",")}`
    };
  }
  static getUserAbout(userId, hasScope) {
    return {
      url: hasScope ? `/apiv2/users/${userId}/about` : void 0
    };
  }
  static getUserFamily(userId) {
    return {
      url: `/apiv2/users/${userId}/family`
    };
  }
  static getUserFamilyMembers(userId) {
    return {
      url: `/apiv2/users/${userId}/family/members`
    };
  }
  static getUserFamilyMembersAsSuperAdmin(userId) {
    return {
      url: `/apiv2/users/${userId}/family/superadmin/members`
    };
  }
  static getUserBenefitsPeopleData() {
    return {
      url: "/apiv2/user-benefits"
    };
  }
  static getUserLifecycleInfo(userId) {
    return {
      url: `/apiv2/users/${userId}/lifecycle`
    };
  }
  static getPersonalSettings() {
    return {
      url: "/apiv2/users/personal-settings"
    };
  }
  static getUserWorkContactInfo(userId) {
    return {
      url: `/apiv2/users/${userId}/work-contact`
    };
  }
  static getUserEmergencyContact(userId) {
    return {
      url: `/apiv2/users/${userId}//emergency-contact`
    };
  }
}
