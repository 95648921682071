"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { themeColors } from "@v2/styles/colors.styles";
import { radius } from "@v2/styles/radius.styles";
import { spacing } from "@v2/styles/spacing.styles";
const chipBgColorMapping = {
  secondary: themeColors.Background,
  warning: themeColors.PastelYellow,
  success: themeColors.PastelGreen
};
const chipColorMapping = {
  secondary: themeColors.DarkGrey,
  warning: themeColors.RedDark,
  success: themeColors.DarkGreen
};
export const FieldCard = ({ name, value, subValue, action, color, sx = {} }) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: __spreadValues({
        boxSizing: "border-box",
        height: "64px",
        minWidth: "115px",
        borderRadius: "10px",
        p: spacing.p10,
        border: `1px solid ${themeColors.GreyMiddle}`,
        cursor: action ? "pointer" : void 0,
        ":hover": {
          bgcolor: action ? themeColors.TableHover : "initial"
        }
      }, sx),
      onClick: action,
      children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5, width: "100%" }, children: [
        /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "captionSmall",
            sx: {
              paddingX: "3px",
              paddingY: "1px",
              borderRadius: radius.br8,
              width: "fit-content",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              backgroundColor: chipBgColorMapping[color],
              color: chipColorMapping[color]
            },
            children: name
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "title3", children: value }),
        subValue && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: subValue })
      ] })
    }
  );
};
