"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import "./WeekView.css";
export const TableCalendar = ({ header, isActiveHeader, renderTimeSlots, onDayClick }) => {
  return /* @__PURE__ */ jsxs("table", { className: "week-view", children: [
    /* @__PURE__ */ jsx("thead", { children: /* @__PURE__ */ jsxs("tr", { children: [
      /* @__PURE__ */ jsx("th", {}),
      header.map((headerObj, index) => {
        return /* @__PURE__ */ jsx("th", { className: "week-days", children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              backgroundColor: isActiveHeader(headerObj.value) ? themeColors.DarkGrey : themeColors.white,
              paddingX: spacing.p5,
              borderRadius: radius.br10,
              width: "fit-content",
              textAlign: "center"
            },
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                sx: __spreadProps(__spreadValues({}, themeFonts.captionSmall), {
                  color: isActiveHeader(headerObj.value) ? themeColors.white : themeColors.Grey
                }),
                children: headerObj.label
              }
            )
          }
        ) }) }, index);
      })
    ] }) }),
    /* @__PURE__ */ jsx("tbody", { children: renderTimeSlots.map((row) => /* @__PURE__ */ jsxs("tr", { id: row.rowId, children: [
      /* @__PURE__ */ jsx(
        "td",
        {
          ref: (rowRef) => rowRef && row.index === row.earliestStartHour && rowRef.scrollIntoView({ behavior: "smooth", inline: "start" }),
          children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey, zIndex: 2 }), className: "slot-hour", children: row.firstColumnLabel })
        },
        row.index
      ),
      row.slots.map((slot, index) => {
        return /* @__PURE__ */ jsx(
          "td",
          {
            className: "slot-td-placeholder",
            onClick: () => {
              var _a;
              if (onDayClick && ((_a = slot == null ? void 0 : slot.dateObj) == null ? void 0 : _a.value)) onDayClick(slot.dateObj.value);
            },
            style: { cursor: "pointer" },
            children: slot.events.map((event, idx) => {
              return event.isAbsence || event.hasAttendanceEntries || event.isScheduled ? /* @__PURE__ */ jsx(
                "div",
                {
                  id: `item-half-${idx}`,
                  className: "slot-absolute",
                  style: {
                    height: event.height,
                    boxShadow: event.showBoxShadow ? `0 -2.5px 0 ${event.backgroundColor}, 0 2.5px 0 ${event.backgroundColor}` : "",
                    top: event.top,
                    backgroundColor: event.backgroundColor,
                    borderRadius: event.borderRadius,
                    borderStyle: event.borderStyle,
                    borderWidth: event.borderWidth,
                    borderColor: event.borderColor
                  },
                  children: (event.isAbsence || event.hasAttendanceEntries || event.isScheduled) && /* @__PURE__ */ jsx(
                    Typography,
                    {
                      sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                        color: themeColors.DarkGrey,
                        p: spacing.p10,
                        position: "relative",
                        zIndex: 1,
                        visibility: { xs: "hidden", sm: "hidden", md: "visible", lg: "visible", xl: "visible" }
                      }),
                      children: event.label
                    }
                  )
                }
              ) : null;
            })
          },
          `${index}-slot`
        );
      })
    ] }, `${row.index}-time`)) })
  ] });
};
