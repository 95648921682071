"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton } from "@mui/material";
import "@/component/dashboard/dashboard.css";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as Back } from "@/images/fields/Left.svg";
import { ReactComponent as Expand } from "@/images/new-theme-icon/Expand.svg";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { StyledSwipeableDrawer } from "@/v2/styles/sidebar-edit.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const TRANSITION_DURATION = 400;
export const DrawerModal = ({
  isOpen,
  setIsOpen,
  children,
  onClose,
  afterClose,
  widthPercentage = 35,
  fixedWidthPx = void 0,
  bgcolor = themeColors.white,
  sx = spacing.px16,
  showExpand = false,
  expandAction,
  closeIcon = "close",
  loading
}) => {
  const width = widthPercentage / 100;
  return /* @__PURE__ */ jsx(
    StyledSwipeableDrawer,
    {
      anchor: "right",
      open: isOpen,
      transitionDuration: 400,
      ModalProps: {
        // setting this to true will load the drawer content before the drawer is opened
        // (the documentation says the default value is false, but in the code it has been changed to true)
        keepMounted: false
      },
      PaperProps: {
        sx: {
          height: "100%",
          width: fixedWidthPx !== void 0 ? `${fixedWidthPx}px` : {
            xs: "100%",
            sm: "380px",
            md: "380px",
            lg: `calc(calc(100% - 199px) * ${width})`,
            xl: `calc(calc(100% - 199px) * ${width})`
          },
          bgcolor
        }
      },
      onOpen: () => {
        if (!isOpen) {
          setIsOpen(true);
        }
      },
      onClose: () => __async(void 0, null, function* () {
        if (onClose) yield onClose();
        setIsOpen(false);
        if (afterClose) setTimeout(() => afterClose(), TRANSITION_DURATION);
      }),
      children: /* @__PURE__ */ jsxs(Fragment, { children: [
        isOpen && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(
            Box,
            {
              className: "fadeIn-delay",
              sx: {
                position: "fixed",
                zIndex: 2,
                right: fixedWidthPx !== void 0 ? `${fixedWidthPx + 10}px` : {
                  xs: "10px",
                  sm: `calc(calc(100% - 199px) * 390px)`,
                  md: `calc(calc(100% - 199px) * 390px)`,
                  lg: `calc(calc(100% - 199px) * ${width} + 10px)`,
                  xl: `calc(calc(100% - 199px) * ${width} + 10px)`
                },
                top: 20
              },
              children: /* @__PURE__ */ jsx(
                IconButton,
                {
                  sx: __spreadProps(__spreadValues({}, iconCTAButtonSx), { backgroundColor: themeColors.white, fill: themeColors.DarkGrey }),
                  onClick: () => __async(void 0, null, function* () {
                    if (onClose) yield onClose();
                    setIsOpen(false);
                  }),
                  children: {
                    close: () => /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey })),
                    back: () => /* @__PURE__ */ jsx(Back, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey }))
                  }[closeIcon]()
                }
              )
            }
          ),
          showExpand && /* @__PURE__ */ jsx(
            Box,
            {
              className: "fadeIn-delay",
              sx: {
                position: "fixed",
                zIndex: 2,
                right: fixedWidthPx !== void 0 ? `${fixedWidthPx}px` : {
                  xs: "10px",
                  sm: `calc(calc(100% - 199px) * 390px)`,
                  md: `calc(calc(100% - 199px) * 390px)`,
                  lg: `calc(calc(100% - 199px) * ${width} + 10px)`,
                  xl: `calc(calc(100% - 199px) * ${width} + 10px)`
                },
                top: 80
              },
              children: /* @__PURE__ */ jsx(
                IconButton,
                {
                  sx: __spreadProps(__spreadValues({}, iconCTAButtonSx), { backgroundColor: themeColors.white, fill: themeColors.DarkGrey }),
                  onClick: expandAction,
                  children: /* @__PURE__ */ jsx(Expand, { width: 14, height: 14, fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey })
                }
              )
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({ py: spacing.p16, position: "relative" }, sx), { height: "100%" }), children: loading ? /* @__PURE__ */ jsx(DrawerModalLoader, {}) : /* @__PURE__ */ jsx(Fragment, { children }) })
      ] })
    }
  );
};
const DrawerModalLoader = () => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", height: "36px", width: "100%", sx: { background: themeColors.Background } }),
    /* @__PURE__ */ jsx(LoadingSpinner, { size: "lg", yMargin: "lg" })
  ] });
};
