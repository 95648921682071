"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
const BootstrapTooltip = styled((_a) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ jsx(Tooltip, __spreadProps(__spreadValues({}, props), { arrow: true, classes: { popper: className } }));
})(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: themeColors.DarkGrey
  },
  [`& .${tooltipClasses.tooltip}`]: __spreadProps(__spreadValues({
    backgroundColor: themeColors.DarkGrey
  }, themeFonts.captionSmall), {
    color: themeColors.white,
    padding: "10px",
    borderRadius: radius.br5
  })
}));
export const StyledTooltip = (props) => {
  const _a = props, { children, title, disabled } = _a, tooltipProps = __objRest(_a, ["children", "title", "disabled"]);
  return /* @__PURE__ */ jsx(
    BootstrapTooltip,
    __spreadProps(__spreadValues({}, tooltipProps), {
      title: !title || disabled ? "" : title,
      children
    })
  );
};
