"use strict";
import { round2Digits } from "@v2/util/number.util";
import { differenceInDays } from "date-fns";
export const DEFAULT_SCHEDULE_CAP = 15;
export function getAverageNumberOfWorkingDaysPerScheduleWeek(schedule) {
  let totalNoOfWorkingDaysDefined = 0;
  for (let weekNo = 0; weekNo < schedule.noOfWeeks; weekNo += 1) {
    if (schedule.monday && schedule.monday[weekNo]) totalNoOfWorkingDaysDefined += 1;
    if (schedule.tuesday && schedule.tuesday[weekNo]) totalNoOfWorkingDaysDefined += 1;
    if (schedule.wednesday && schedule.wednesday[weekNo]) totalNoOfWorkingDaysDefined += 1;
    if (schedule.thursday && schedule.thursday[weekNo]) totalNoOfWorkingDaysDefined += 1;
    if (schedule.friday && schedule.friday[weekNo]) totalNoOfWorkingDaysDefined += 1;
    if (schedule.saturday && schedule.saturday[weekNo]) totalNoOfWorkingDaysDefined += 1;
    if (schedule.sunday && schedule.sunday[weekNo]) totalNoOfWorkingDaysDefined += 1;
  }
  return round2Digits(totalNoOfWorkingDaysDefined / schedule.noOfWeeks);
}
export function getIndexOfWeekInSchedule(scheduleStart, date, noOfWeeks) {
  if (!scheduleStart) throw new Error("Missing scheduleStart!");
  if (!noOfWeeks) throw new Error("Missing noOfWeeks!");
  const diff = differenceInDays(new Date(date), new Date(scheduleStart));
  const week = Math.floor(diff / 7) % noOfWeeks;
  return week >= 0 ? week : week + noOfWeeks;
}
