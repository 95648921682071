"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SURVEYS_COMPANY_ONGOING_ROUTE, SURVEYS_TEAM_ONGOING_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReachType } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { SurveyCycleAPI } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const SCGeneralDuplicateModal = ({
  surveyCycle,
  reach,
  isOpen,
  setIsOpen,
  onClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(SCGeneralDuplicateModalContent, { surveyCycle, onClose, reach }) });
};
const SCGeneralDuplicateModalContent = ({
  surveyCycle,
  reach,
  onClose
}) => {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const handleDuplicateCycle = () => __async(void 0, null, function* () {
    try {
      const reachRoute = reach === ReachType.Company ? SURVEYS_COMPANY_ONGOING_ROUTE : SURVEYS_TEAM_ONGOING_ROUTE;
      if (!surveyCycle) {
        showMessage("Cycle does not exist", "error");
        return;
      }
      yield SurveyCycleAPI.duplicateCycle(surveyCycle.id);
      showMessage("Successfully duplicate the cycle", "success");
      routerHistory.push(reachRoute);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Duplicate cycle" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "All cycle settings will be duplicated into a new draft cycle." }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { onClick: onClose, sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, children: "Cancel" }),
      /* @__PURE__ */ jsx(ButtonComponent, { onClick: handleDuplicateCycle, sizeVariant: "medium", colorVariant: "primary", fullWidth: true, children: "Duplicate" })
    ] })
  ] });
};
