"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { PaymentExpensePreview } from "@v2/feature/payments/components/payment-expense-preview.component";
import { PaymentInvoicePreview } from "@v2/feature/payments/components/payment-invoice-preview.component";
import { PaymentsGroupDrawer } from "@v2/feature/payments/components/payments-group-drawer.component";
import {
  PaymentCategory,
  PaymentStatus,
  PaymentType,
  TransactionStatus,
  TransactionType
} from "@v2/feature/payments/payments.dto";
import {
  getPaymentStatus,
  HMRC_ACCOUNT,
  paymentPaidOrMarkedPaid,
  transactionTypeToLabel
} from "@v2/feature/payments/payments.util";
import { UserBankAccountEndpoints } from "@v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { iconSize } from "@v2/styles/table.styles";
import { useHistory } from "react-router-dom";
import { ContractorInvoiceAPI } from "@/api-client/contractor-invoice-api";
import { ExpenseAPI } from "@/api-client/expense.api";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as Info } from "@/images/side-bar-icons/Info.svg";
import { ReactComponent as ErrorIcon } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getRequestFromPayment, usePaymentContext } from "@/v2/feature/payments/features/make-payment/payment.context";
import { PaymentsAPI, PaymentsEndpoints } from "@/v2/feature/payments/payments.api";
import { PAYMENTS_SELECT_BANK_ROUTE } from "@/v2/feature/payments/payments.router";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
export const ViewerItem = ({ title, value, valueOnClick }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: title }),
    /* @__PURE__ */ jsx(
      Typography,
      {
        onClick: valueOnClick,
        variant: "title4",
        color: valueOnClick ? "blue" : "DarkGrey",
        sx: { textDecoration: valueOnClick ? "underline" : "none", cursor: valueOnClick ? "pointer" : "default" },
        children: value
      }
    )
  ] });
};
export const PaymentDetailsDrawer = ({
  isOpen,
  setIsOpen,
  payment,
  refresh,
  paymentAlerts,
  onClose
}) => {
  var _a, _b;
  const { getCachedUserById } = useCachedUsers();
  const user = payment.userId ? getCachedUserById(payment.userId) : void 0;
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
        }
      ),
      children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Payment" }),
        user && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: (_b = (_a = user == null ? void 0 : user.role) == null ? void 0 : _a.jobPosition) == null ? void 0 : _b.title }),
          /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(UserCell, { userId: user.userId, nameVariant: "title4" }) })
        ] }),
        /* @__PURE__ */ jsx(
          PaymentDetailsDrawerContent,
          {
            payment,
            refresh,
            paymentAlerts,
            setIsOpen
          }
        )
      ] })
    }
  ) });
};
export const PaymentDetailsDrawerContent = ({
  payment,
  refresh,
  paymentAlerts,
  setIsOpen
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { data: userBankAccountDto } = useApiClient(
    UserBankAccountEndpoints.findCurrentByUserId((_a = payment.userId) != null ? _a : null),
    { suspense: true }
  );
  const [showMessage] = useMessage();
  const [bankAccountDetails, setBankAccountDetails] = useState(void 0);
  const [paymentInvoice, setPaymentInvoice] = useState(null);
  const [paymentExpense, setPaymentExpense] = useState(null);
  const getPayeeBankDetails = useCallback(() => __async(void 0, null, function* () {
    if (!payment.userId && payment.type === PaymentType.Taxes && payment.category === PaymentCategory.Payroll) {
      setBankAccountDetails(HMRC_ACCOUNT);
      return;
    }
    try {
      if (payment.userId) {
        if (!userBankAccountDto) {
          setBankAccountDetails(void 0);
          return;
        }
        setBankAccountDetails({
          accountName: userBankAccountDto.accountName,
          accountNumber: userBankAccountDto.accountNumber,
          sortCode: userBankAccountDto.sortCode,
          bankName: userBankAccountDto.bankName
        });
      }
    } catch (error) {
      showMessage(`Could not retrieve payee details. ${nestErrorMessage(error)}`, "error");
    }
  }), [payment.userId, payment.type, payment.category, userBankAccountDto, showMessage]);
  const getPaymentInvoiceIfTypeOfPaymentIsInvoice = useCallback(() => __async(void 0, null, function* () {
    if (!payment.userId || payment.category !== PaymentCategory.Invoice || payment.type !== PaymentType.Employee)
      return;
    try {
      const invoice = yield ContractorInvoiceAPI.getUserInvoiceForPaymentId(payment.userId, payment.id);
      setPaymentInvoice(invoice);
    } catch (error) {
      showMessage(`Could not get invoice details for this payment. ${nestErrorMessage(error)}`, "error");
    }
  }), [payment.category, payment.type, payment.userId, payment.id, showMessage]);
  const getExpenseIfTypeOfPaymentIsExpense = useCallback(() => __async(void 0, null, function* () {
    if (!payment.userId || payment.category !== PaymentCategory.Expense) return;
    try {
      const expense = yield ExpenseAPI.getExpenseForPaymentId(payment.userId, payment.id);
      setPaymentExpense(expense);
    } catch (error) {
      showMessage(`Could not get expense details for this payment. ${nestErrorMessage(error)}`, "error");
    }
  }), [payment.category, payment.userId, payment.id, showMessage]);
  useEffect(() => {
    Promise.all([
      getPayeeBankDetails(),
      getPaymentInvoiceIfTypeOfPaymentIsInvoice(),
      getExpenseIfTypeOfPaymentIsExpense()
    ]);
  }, [getPaymentInvoiceIfTypeOfPaymentIsInvoice, getPayeeBankDetails, getExpenseIfTypeOfPaymentIsExpense]);
  const markAsPaid = useCallback(() => __async(void 0, null, function* () {
    try {
      yield PaymentsAPI.markAsPaid([payment.id]);
      showMessage("Marked as paid successfully", "success");
      yield refresh();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [showMessage, polyglot, payment, refresh]);
  const showPayButton = useMemo(() => {
    const alertsObj = paymentAlerts == null ? void 0 : paymentAlerts.entries.find((p) => p.id === payment.id);
    return Boolean(
      (!alertsObj || !(alertsObj.bankAccountMissing || alertsObj.userAddressMissing || alertsObj.referenceMissing)) && payment.status !== PaymentStatus.Paid && payment.status !== PaymentStatus.MarkPaid && !payment.markPaid
    );
  }, [paymentAlerts, payment]);
  return payment.status === PaymentStatus.NotPaid ? /* @__PURE__ */ jsx(
    NotPaidPaymentDetailsDrawerContent,
    {
      payment,
      payeeCurrentBankAccount: bankAccountDetails,
      refresh,
      showPayButton,
      setIsOpen,
      paymentInvoice,
      paymentExpense,
      markAsPaid
    }
  ) : /* @__PURE__ */ jsx(
    AttemptedPaymentDetailsDrawerContent,
    {
      payment,
      payeeCurrentBankAccount: bankAccountDetails,
      paymentInvoice,
      showPayButton,
      paymentExpense,
      refresh,
      markAsPaid
    }
  );
};
export const NotPaidPaymentDetailsDrawerContent = ({
  payment,
  payeeCurrentBankAccount,
  refresh,
  showPayButton,
  setIsOpen,
  paymentInvoice,
  paymentExpense,
  markAsPaid
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [, setState] = usePaymentContext();
  const routerHistory = useHistory();
  const getPaymentsAllActionsOptions = useMemo(() => {
    const finalOptions = [
      {
        icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
        handler: markAsPaid,
        label: "Mark as paid"
      }
    ];
    if (payment.category === PaymentCategory.Expense) {
      const rejectOption = {
        icon: /* @__PURE__ */ jsx(Fragment, {}),
        handler: () => __async(void 0, null, function* () {
          try {
            setLoading(true);
            yield PaymentsAPI.rejectUnderlyingExpense(payment.id);
            showMessage("Rejected related expense", "success");
            yield refresh();
            setIsOpen(false);
          } catch (error) {
            showMessage(
              polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }),
              "error"
            );
          } finally {
            setLoading(false);
          }
        }),
        label: "Reject",
        disabled: false
      };
      finalOptions.push(rejectOption);
    }
    if (payment.category === PaymentCategory.Invoice) {
      const voidOption = {
        icon: /* @__PURE__ */ jsx(Fragment, {}),
        handler: () => __async(void 0, null, function* () {
          try {
            setLoading(true);
            yield PaymentsAPI.voidUnderlyingInvoice(payment.id);
            showMessage("Voided related invoice", "success");
            yield refresh();
            setIsOpen(false);
          } catch (error) {
            showMessage(
              polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }),
              "error"
            );
          } finally {
            setLoading(false);
          }
        }),
        label: "Void",
        disabled: false
      };
      finalOptions.push(voidOption);
    }
    return finalOptions;
  }, [polyglot, markAsPaid, payment.category, payment.id, showMessage, refresh, setIsOpen]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(ViewerItem, { title: "Status", value: getPaymentStatus(polyglot, payment, __spreadValues({}, themeFonts.title4)) }),
    /* @__PURE__ */ jsx(ViewerItem, { title: "Category", value: payment.category }),
    /* @__PURE__ */ jsx(ViewerItem, { title: "Reference", value: payment.reference }),
    /* @__PURE__ */ jsx(ViewerItem, { title: "Amount", value: `${formatCurrency(payment.amount, void 0, payment.currency)}` }),
    payment.dueDate && /* @__PURE__ */ jsx(ViewerItem, { title: "Due date", value: getDateString(payment.dueDate) }),
    ((_a = payment.transaction) == null ? void 0 : _a.updatedAt) && /* @__PURE__ */ jsx(ViewerItem, { title: "Payment date", value: getDateString((_b = payment.transaction) == null ? void 0 : _b.updatedAt) }),
    paymentInvoice && /* @__PURE__ */ jsx(PaymentInvoicePreview, { invoice: paymentInvoice, titleSx: { my: "10px" } }),
    paymentExpense && /* @__PURE__ */ jsx(PaymentExpensePreview, { expense: paymentExpense, titleSx: { my: "10px" } }),
    !paymentPaidOrMarkedPaid(payment) && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mt: "10px", mb: "10px" }, children: "Current bank details" }),
      payeeCurrentBankAccount ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px" }, children: [
        payeeCurrentBankAccount.bankName && /* @__PURE__ */ jsx(ViewerItem, { title: "Bank name", value: payeeCurrentBankAccount.bankName }),
        /* @__PURE__ */ jsx(ViewerItem, { title: "Account name", value: payeeCurrentBankAccount.accountName }),
        /* @__PURE__ */ jsx(ViewerItem, { title: "Account number", value: payeeCurrentBankAccount.accountNumber }),
        /* @__PURE__ */ jsx(ViewerItem, { title: "Sort code", value: payeeCurrentBankAccount.sortCode })
      ] }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: spacing.mt40, children: "Bank account details not available" })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          disabled: loading,
          options: getPaymentsAllActionsOptions,
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "medium",
            title: "Actions",
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end",
            fullWidth: true
          }
        }
      ) }),
      showPayButton && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: loading,
          fullWidth: true,
          onClick: () => {
            setState(() => {
              return {
                payments: [payment],
                requests: [getRequestFromPayment(payment)]
              };
            });
            return routerHistory.push(PAYMENTS_SELECT_BANK_ROUTE);
          },
          children: "Pay"
        }
      )
    ] })
  ] });
};
export const AttemptedPaymentDetailsDrawerContent = ({
  payment,
  payeeCurrentBankAccount,
  paymentExpense,
  paymentInvoice,
  markAsPaid,
  showPayButton,
  refresh
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const { data: fullPaymentRequest, error } = useApiClient(
    payment.transactionId ? PaymentsEndpoints.getTransactionPaymentRequest(payment.transactionId) : { url: void 0 }
  );
  const [isPaymentsGroupOpen, setIsPaymentsGroupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setState] = usePaymentContext();
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = payment.userId ? getScopesContext({ userId: payment.userId }) : void 0;
  const isManagerOrAdmin = hasScopes(["payments:manager"], scopesContext);
  const paymentRequest = useMemo(() => {
    var _a2, _b2, _c;
    if (((_a2 = payment.transaction) == null ? void 0 : _a2.type) === TransactionType.BulkPayment && (fullPaymentRequest == null ? void 0 : fullPaymentRequest.payments)) {
      return (_b2 = fullPaymentRequest.payments.find(
        (paymentRequest2) => paymentRequest2.reference === payment.reference && paymentRequest2.amount.amount === payment.amount
      )) != null ? _b2 : null;
    }
    if (((_c = payment.transaction) == null ? void 0 : _c.type) === TransactionType.DomesticSinglePayment) {
      return fullPaymentRequest != null ? fullPaymentRequest : null;
    }
    return null;
  }, [fullPaymentRequest, payment]);
  useEffect(() => {
    var _a2;
    if (error) {
      showMessage(`Failed to fetch payment request. ${((_a2 = error.data) == null ? void 0 : _a2.message) || error.statusText}`, "error");
    }
  }, [error, showMessage]);
  const transactionAmount = useMemo(() => paymentRequest == null ? void 0 : paymentRequest.amount.amount, [paymentRequest]);
  const transactionCurrency = useMemo(() => paymentRequest == null ? void 0 : paymentRequest.amount.currency, [paymentRequest]);
  const transactionAccountName = useMemo(() => (paymentRequest == null ? void 0 : paymentRequest.payee.name) || payment.paidToAccountName, [
    paymentRequest,
    payment
  ]);
  const transactionAccountNumber = useMemo(
    () => {
      var _a2, _b2;
      return (_b2 = (_a2 = paymentRequest == null ? void 0 : paymentRequest.payee.accountIdentifications.find(
        (identificationObj) => identificationObj.type === "ACCOUNT_NUMBER"
      )) == null ? void 0 : _a2.identification) != null ? _b2 : payment.paidToAccountNumber;
    },
    [paymentRequest, payment]
  );
  const transactionSortCode = useMemo(
    () => {
      var _a2, _b2;
      return (_b2 = (_a2 = paymentRequest == null ? void 0 : paymentRequest.payee.accountIdentifications.find((identificationObj) => identificationObj.type === "SORT_CODE")) == null ? void 0 : _a2.identification) != null ? _b2 : payment.paidToSortCode;
    },
    [paymentRequest, payment]
  );
  const transactionReference = useMemo(() => paymentRequest == null ? void 0 : paymentRequest.reference, [paymentRequest]);
  const transactionAccountDetails = useMemo(() => {
    return transactionAccountName && transactionAccountNumber && transactionSortCode ? {
      accountName: transactionAccountName,
      accountNumber: transactionAccountNumber,
      sortCode: transactionSortCode
    } : void 0;
  }, [transactionAccountName, transactionAccountNumber, transactionSortCode]);
  const isDifferentAccountReason = useMemo(() => {
    if (payment.markPaid) return null;
    if (!transactionAccountDetails) {
      return "Missing transaction account details.";
    }
    if (!payeeCurrentBankAccount) {
      return "Missing current account details.";
    }
    if (transactionAccountDetails.accountName !== payeeCurrentBankAccount.accountName) {
      return "The transaction bank account name and the current bank account name are different.";
    }
    if (transactionAccountDetails.accountNumber !== payeeCurrentBankAccount.accountNumber) {
      return "The transaction bank account number and the current bank account number are different.";
    }
    if (transactionAccountDetails.sortCode !== payeeCurrentBankAccount.sortCode) {
      return "The transaction bank account sort code and the current bank account sort code are different.";
    }
    return null;
  }, [payment, payeeCurrentBankAccount, transactionAccountDetails]);
  const showTransactionRefresh = useMemo(
    () => {
      var _a2;
      return isManagerOrAdmin && payment.transaction && ((_a2 = payment.transaction) == null ? void 0 : _a2.status) === TransactionStatus.PENDING;
    },
    [payment, isManagerOrAdmin]
  );
  const refreshPaymentTransaction = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    if (!showTransactionRefresh) return;
    try {
      yield PaymentsAPI.refreshPaymentTransactionByPaymentId(payment.id, (_a2 = payment.userId) != null ? _a2 : null);
      yield refresh();
    } catch (error2) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error2) }), "error");
    }
  }), [polyglot, showMessage, payment, showTransactionRefresh, refresh]);
  const isTransactionOlderThan1Hour = useMemo(() => {
    var _a2;
    if (!((_a2 = payment == null ? void 0 : payment.transaction) == null ? void 0 : _a2.createdAt)) return false;
    const timeDiff = Date.now() - new Date(payment.transaction.createdAt).getTime();
    return timeDiff > 36e5;
  }, [payment]);
  const disablePayAndMarkAsPaid = useMemo(() => {
    return !isTransactionOlderThan1Hour && payment.transaction && payment.transaction.status === TransactionStatus.PENDING;
  }, [isTransactionOlderThan1Hour, payment.transaction]);
  const getPaymentsAllActionsOptions = useMemo(() => {
    return [
      {
        icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          setLoading(true);
          yield markAsPaid();
          setLoading(false);
        }),
        label: "Mark as paid",
        disabled: disablePayAndMarkAsPaid
      },
      {
        icon: /* @__PURE__ */ jsx(Reload, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          setLoading(true);
          yield refreshPaymentTransaction();
          setLoading(false);
        }),
        label: "Refresh transaction status",
        hidden: !showTransactionRefresh
      }
    ];
  }, [disablePayAndMarkAsPaid, markAsPaid, showTransactionRefresh, refreshPaymentTransaction]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(ViewerItem, { title: "Status", value: getPaymentStatus(polyglot, payment, themeFonts.title4, true) }),
    /* @__PURE__ */ jsx(ViewerItem, { title: "Category", value: payment.category }),
    /* @__PURE__ */ jsx(ViewerItem, { title: "Reference", value: payment.reference }),
    /* @__PURE__ */ jsx(ViewerItem, { title: "Amount", value: `${formatCurrency(payment.amount, void 0, payment.currency)}` }),
    payment.paidByUser && payment.paidOn && /* @__PURE__ */ jsx(ViewerItem, { title: "Paid by", value: /* @__PURE__ */ jsx(UserCell, { userId: payment.paidByUser.userId }) }),
    payment.paidOn && /* @__PURE__ */ jsx(ViewerItem, { title: "Paid on", value: new Date(payment.paidOn).toLocaleString() }),
    payment.transaction && /* @__PURE__ */ jsx(
      ViewerItem,
      {
        title: "Transaction type",
        value: payment.transaction.type ? transactionTypeToLabel(payment.transaction.type) : "N/A"
      }
    ),
    payment.transactionId && payment.isPartOfGroup && /* @__PURE__ */ jsx(
      ViewerItem,
      {
        title: "Transaction includes multiple payments",
        value: "See all payments",
        valueOnClick: () => {
          setIsPaymentsGroupOpen(true);
        }
      }
    ),
    payment.transactionId && payment.isPartOfGroup && /* @__PURE__ */ jsx(
      PaymentsGroupDrawer,
      {
        isOpen: isPaymentsGroupOpen,
        setIsOpen: setIsPaymentsGroupOpen,
        transactionId: payment.transactionId
      }
    ),
    payment.transaction && transactionAmount && transactionCurrency && transactionAmount !== payment.amount && /* @__PURE__ */ jsx(ViewerItem, { title: "Transaction amount", value: (_a = `${transactionCurrency} ${transactionAmount}`) != null ? _a : "N/A" }),
    payment.transaction && transactionReference && transactionReference !== payment.reference && /* @__PURE__ */ jsx(ViewerItem, { title: "Transaction reference", value: transactionReference != null ? transactionReference : "N/A" }),
    transactionAccountDetails && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mt: spacing.m10 }, children: "Payment was made to" }),
      payment.bankName && /* @__PURE__ */ jsx(ViewerItem, { title: "Bank name", value: payment.bankName }),
      /* @__PURE__ */ jsx(ViewerItem, { title: "Account name", value: transactionAccountName != null ? transactionAccountName : "N/A" }),
      /* @__PURE__ */ jsx(ViewerItem, { title: "Account number", value: transactionAccountNumber != null ? transactionAccountNumber : "N/A" }),
      /* @__PURE__ */ jsx(ViewerItem, { title: "Sort code", value: transactionSortCode != null ? transactionSortCode : "N/A" })
    ] }),
    !paymentPaidOrMarkedPaid(payment) && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mt: "10px" }, children: "Current bank details" }),
      payeeCurrentBankAccount ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g15 }, children: [
        payeeCurrentBankAccount.bankName && /* @__PURE__ */ jsx(ViewerItem, { title: "Bank name", value: payeeCurrentBankAccount.bankName }),
        /* @__PURE__ */ jsx(ViewerItem, { title: "Account name", value: payeeCurrentBankAccount.accountName }),
        /* @__PURE__ */ jsx(ViewerItem, { title: "Account number", value: payeeCurrentBankAccount.accountNumber }),
        /* @__PURE__ */ jsx(ViewerItem, { title: "Sort code", value: payeeCurrentBankAccount.sortCode })
      ] }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: "10px" }, children: "Bank account details not available" })
    ] }),
    paymentInvoice && /* @__PURE__ */ jsx(PaymentInvoicePreview, { invoice: paymentInvoice, titleSx: { my: "10px" } }),
    paymentExpense && /* @__PURE__ */ jsx(PaymentExpensePreview, { expense: paymentExpense, titleSx: { my: "10px" } }),
    isDifferentAccountReason && /* @__PURE__ */ jsxs(Box, { sx: spacing.mt40, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "flex-start", gap: spacing.g5, width: "100%" }, children: [
        /* @__PURE__ */ jsx(Box, { children: ((_b = payment.transaction) == null ? void 0 : _b.status) === TransactionStatus.COMPLETED ? /* @__PURE__ */ jsx(Info, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.ZeltYellow })) : /* @__PURE__ */ jsx(ErrorIcon, __spreadValues({}, iconSize)) }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "The current payee account details and the account details the transaction has been attempted for are missing or are not the same anymore." })
      ] }),
      /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mt: spacing.m10 }, children: [
        "Reason: ",
        isDifferentAccountReason
      ] })
    ] }),
    showPayButton && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          disabled: loading,
          options: getPaymentsAllActionsOptions,
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "medium",
            title: "Actions",
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end",
            fullWidth: true
          }
        }
      ) }),
      showPayButton && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: loading || disablePayAndMarkAsPaid,
          fullWidth: true,
          onClick: () => {
            setState(() => {
              return {
                payments: [payment],
                requests: [getRequestFromPayment(payment)]
              };
            });
            return routerHistory.push(PAYMENTS_SELECT_BANK_ROUTE);
          },
          children: "Pay"
        }
      )
    ] })
  ] });
};
