"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import { TemplateAPI } from "@/api-client/templates.api";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from "@/lib/routes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
export const SelectTemplateForContractModal = ({
  setOpen,
  open,
  templateList,
  setSelectedTemplate
}) => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const routerHistory = useHistory();
  const scopesContext = { userId: user.userId };
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const templateOptions = useMemo(() => {
    let options = [];
    if (templateList) {
      options = templateList.map((t) => ({
        label: t.name,
        value: t.id
      }));
    }
    return options;
  }, [templateList]);
  const useSmartTemplateForDocument = () => __async(void 0, null, function* () {
    const matchingTemplate = templateList.find((t) => t.id === selectedTemplateId);
    if (!matchingTemplate || !selectedTemplateId) {
      return;
    }
    setLoadingTemplate(true);
    const contractTemplate = yield TemplateAPI.getTemplateById({ templateId: selectedTemplateId });
    setLoadingTemplate(false);
    setSelectedTemplate(contractTemplate);
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen: open,
      setIsOpen: setOpen,
      onClose: () => {
        setSelectedTemplate(void 0);
      },
      children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ContractModal.template") }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ContractModal.documentFromSmartTemplate") }),
        /* @__PURE__ */ jsx(
          AutocompleteComponent,
          {
            name: "selectedTemplateId",
            label: polyglot.t("ContractModal.templateSelection"),
            options: templateOptions,
            fullWidth: true,
            value: templateOptions.find(({ value }) => value === selectedTemplateId) || null,
            compareValue: selectedTemplateId,
            onChange: (_, x) => {
              setSelectedTemplateId(x ? x.value : void 0);
            },
            editList: {
              handler: () => routerHistory.push(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE),
              isHidden: !hasScopes(["templates", "documents:all"], scopesContext)
            }
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsxs(
          ButtonComponent,
          {
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary",
            disabled: !selectedTemplateId,
            onClick: useSmartTemplateForDocument,
            loading: loadingTemplate,
            children: [
              polyglot.t("ContractModal.useTemplateForDocument"),
              " "
            ]
          }
        ) })
      ] })
    }
  );
};
