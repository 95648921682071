"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useReducer, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  documentFormModalOwnershipToDto,
  getDocumentParentCategory,
  getDocumentTypeListBasedOnAudience,
  initialiseState,
  OTHER_TYPE,
  reduceDocumentFormModal,
  uploadDocumentIfNeeded
} from "@v2/feature/documents/documents.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { constVoid, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  DocumentConfirmBulkEditDrawer
} from "./components/document-confirm-bulk-edit-modal.component";
import { TemplateAPI } from "@/api-client/templates.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/components/ArrowDown.svg";
import { ReactComponent as Edit } from "@/images/side-bar-icons/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DocumentConfirmBulkDeleteDrawer } from "@/v2/feature/documents/components/document-confirm-bulk-delete-modal.component";
import { DocumentEmptyOverview } from "@/v2/feature/documents/components/document-empty-overview.component";
import { DocumentFormModal } from "@/v2/feature/documents/components/document-form-modal.component";
import { DocumentTable } from "@/v2/feature/documents/components/document-table.component";
import { NewDocumentButton } from "@/v2/feature/documents/components/new-document-action-button.component";
import { PinnedDocument } from "@/v2/feature/documents/components/pinned-document.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import {
  DocumentStatus
} from "@/v2/feature/documents/documents.interface";
import { DocPreviewer } from "@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component";
import { SelectDeselectIdRows } from "@/v2/feature/task/components/task-table/select-deselect-string-id-rows.component";
import { ContractModal } from "@/v2/feature/templates/components/contract-modal.component";
import { MissingTemplateFieldModal } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import { SelectTemplateForContractModal } from "@/v2/feature/templates/components/select-template-for-contract-modal.component";
import { TemplatesScopes } from "@/v2/feature/templates/templates.scopes";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { getDisplayName } from "@/v2/util/string-format.util";
const TabFilter = (polyglot) => {
  return [
    { name: polyglot.t("DocumentStatus.all"), value: DocumentStatus.All },
    { name: polyglot.t("DocumentStatus.pending"), value: DocumentStatus.Pending }
  ];
};
const initialState = initialiseState([]);
export const DocumentsCompanyPage = ({ reach }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserIsDocumentAdmin = hasScopes(
    ["documents:all", "documents:manager"],
    getScopesContext({ userId: (_a = globalState.user) == null ? void 0 : _a.userId })
  );
  const currentUserIsTemplateAdmin = hasScopes(
    TemplatesScopes.LIST_CONTRACT_TEMPLATES,
    getScopesContext({ userId: (_b = globalState.user) == null ? void 0 : _b.userId })
  );
  const hasDocumentsManager = useMemo(() => {
    var _a2, _b2, _c2;
    const reporteeScopeContext = {
      userId: ((_b2 = (_a2 = globalState.user) == null ? void 0 : _a2.reports) == null ? void 0 : _b2.length) > 0 ? (_c2 = globalState.user) == null ? void 0 : _c2.reports[0] : 0
    };
    return checkScopes(globalState.user, ["documents:manager"], reporteeScopeContext);
  }, [globalState.user]);
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const [documents, setDocuments] = useState(void 0);
  const [pinnedDocuments, setPinnedDocuments] = useState([]);
  const [selectedDocBuffer, setSelectedDocBuffer] = useState();
  const [selectedDocName, setSelectedDocName] = useState("");
  const [selectedDocContentType, setSelectedDocContentType] = useState("");
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(void 0);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [missingFields, setMissingFields] = useState();
  const [contractRecipientUserId, setContractRecipientUserId] = useState();
  const [contractCompanySignatoryUserId, setContractCompanySignatoryUserId] = useState();
  const [confirmBulkDeleteDrawerOpen, setConfirmBulkDeleteDrawerOpen] = useState(false);
  const [confirmBulkEditDrawerOpen, setConfirmBulkEditDrawerOpen] = useState(false);
  const [isDocumentExist, setIsDocumentExist] = useState(false);
  const [filterString, setFilterString] = useState("");
  const [documentFormMode, setDocumentFormMode] = useState("add");
  const [open, setOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [statusFilterValue, setStatusFilterValue] = useState(DocumentStatus.All);
  const [selectionModel, setSelectionModel] = useState([]);
  function closeUploadModal() {
    setOpen(false);
  }
  const [loading, setLoading] = useState(true);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const { nonTerminatedCachedUsers, loaded } = useCachedUsers({ refresh: true });
  const allUsers = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => ({ value: u.userId, label: u.displayName }));
  }, [nonTerminatedCachedUsers]);
  const [users, setUsers] = useState();
  const [sorting, setSorting] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const fetchDocuments = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setLoadingDocuments(true);
        const { documents: documents2, pagination: paginationInfo, documentsExist } = yield reach === "team" ? DocumentAPI.listTeamDocuments(params) : DocumentAPI.listDocumentsV2(params);
        setIsDocumentExist(documentsExist);
        setDocuments([...documents2]);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
      } catch (error) {
        showMessage(
          polyglot.t("DocumentsCompanyPage.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoadingDocuments(false);
      }
    }),
    [polyglot, reach, showMessage]
  );
  useLayoutEffect(() => {
    const filteredUsers = {
      company: () => nonTerminatedCachedUsers,
      team() {
        const reportsAndMe = new Set(globalState.user.reports);
        reportsAndMe.add(globalState.user.userId);
        return nonTerminatedCachedUsers.filter((u) => reportsAndMe.has(u.userId));
      }
    }[reach]();
    setUsers(filteredUsers);
  }, [reach, globalState.user.reports, globalState.user.userId, nonTerminatedCachedUsers]);
  const getSortDirection = (sort) => {
    if (sort.length === 0) return void 0;
    const { id, desc } = sort[0];
    if (id !== "createdAt") return void 0;
    return desc ? "DESC" : "ASC";
  };
  useEffect(() => {
    let ignore = false;
    (() => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const typeData = yield DocumentAPI.listDocumentTypes();
        yield fetchDocuments({
          page: pageIndex,
          pageSize,
          searchQuery,
          filterString,
          statusFilter: statusFilterValue,
          shouldLimitResult: true,
          order: getSortDirection(sorting)
        });
        if (!ignore) {
          documentDispatch({ kind: "set_types", value: typeData });
        }
      } catch (error) {
        showMessage(
          polyglot.t("DocumentsCompanyPage.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }))();
    return () => {
      ignore = true;
    };
  }, [
    polyglot,
    reach,
    globalState.user.userId,
    globalDispatch,
    showMessage,
    fetchDocuments,
    sorting,
    pageIndex,
    pageSize,
    searchQuery,
    filterString,
    statusFilterValue
  ]);
  const getPinnedDocuments = useCallback(() => __async(void 0, null, function* () {
    const pinnedDocuments2 = yield DocumentAPI.getPinnedDocuments({ reach });
    setPinnedDocuments(pinnedDocuments2);
  }), [reach]);
  useEffect(() => {
    getPinnedDocuments();
  }, [getPinnedDocuments]);
  const completeSave = () => __async(void 0, null, function* () {
    var _a2, _b2, _c2, _d2, _e, _f, _g, _h;
    try {
      let dynamicFileUuid;
      if (documentState.documentLink && !documentState.fileUuid) {
        dynamicFileUuid = yield uploadDocumentIfNeeded(documentState.documentLink);
      }
      let documentObject = {
        name: documentState.name || "",
        type: (_b2 = (_a2 = documentState.type) == null ? void 0 : _a2.value) != null ? _b2 : OTHER_TYPE.value,
        belongsTo: documentFormModalOwnershipToDto(documentState),
        fileUuid: (_c2 = documentState.fileUuid) != null ? _c2 : dynamicFileUuid,
        note: documentState.note,
        expirySettings: (_d2 = documentState == null ? void 0 : documentState.expirySettings) != null ? _d2 : { expiryDate: "", documentExpires: false }
      };
      if (documentState.id && documentState.name) {
        if (((_e = documentState.type) == null ? void 0 : _e.category) === "Company Documents") {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { isPinned: (_f = documentState.pinned) != null ? _f : false });
        } else if (documentState.visibility) {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { visibilitySettings: documentState.visibility });
        }
        yield DocumentAPI.updateDocument(documentState.id, documentObject);
        showMessage(polyglot.t("DocumentsCompanyPage.successMessages.update"), "success");
      } else if (documentState.name) {
        if (((_g = documentState.type) == null ? void 0 : _g.category) === "Company Documents") {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { isPinned: (_h = documentState.pinned) != null ? _h : false });
        } else if (documentState.visibility) {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { visibilitySettings: documentState.visibility });
        }
        yield DocumentAPI.createDocument(documentObject);
        showMessage(
          documentFormMode === "request" ? polyglot.t("DocumentsCompanyPage.successMessages.request") : polyglot.t("DocumentsCompanyPage.successMessages.save"),
          "success"
        );
      }
      yield fetchDocuments({
        page: pageIndex,
        pageSize,
        searchQuery,
        filterString,
        statusFilter: statusFilterValue,
        shouldLimitResult: true
      });
      yield getPinnedDocuments();
    } catch (error) {
      showMessage(
        polyglot.t("DocumentsCompanyPage.errorMessages.save", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
    closeUploadModal();
  });
  const fetchTemplates = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const templates2 = yield TemplateAPI.getTemplates(params);
        setTemplates(templates2 || []);
        setLoading(false);
      } catch (error) {
        showMessage(
          polyglot.t("TemplatesPage.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setLoading(false);
      }
    }),
    [polyglot, showMessage]
  );
  const handleUploadDocument = () => {
    documentDispatch({ kind: "add_document" });
    setDocumentFormMode("add");
    setFilterValue(
      getDocumentParentCategory(
        reach,
        "add",
        void 0,
        currentUserIsDocumentAdmin,
        documentState.types
      )
    );
    setOpen(true);
  };
  const handleRequestDocument = () => {
    var _a2;
    documentDispatch({
      kind: "request_document",
      owner: { label: getDisplayName(globalState == null ? void 0 : globalState.user), value: (_a2 = globalState == null ? void 0 : globalState.user) == null ? void 0 : _a2.userId },
      reach: "company"
    });
    setDocumentFormMode("request");
    setFilterValue(
      getDocumentParentCategory(
        reach,
        "request",
        void 0,
        currentUserIsDocumentAdmin,
        documentState.types
      )
    );
    setOpen(true);
  };
  const handleBulkUploadDocument = () => {
    routerHistory.push(DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE);
  };
  const handleTemplateClick = () => {
    setOpenTemplateModal(true);
  };
  const handlePreviewClick = useCallback(
    (zeltDocument) => __async(void 0, null, function* () {
      var _a2, _b2;
      try {
        const fileUuidFromAttachment = zeltDocument.attachments && zeltDocument.attachments[0] && ((_a2 = zeltDocument.attachments[0]) == null ? void 0 : _a2.fileUuid);
        const finalUuid = (_b2 = zeltDocument.fileUuid) != null ? _b2 : fileUuidFromAttachment;
        if (!finalUuid) return;
        yield DocumentAPI.previewViaUuid(finalUuid).then((_0) => __async(void 0, [_0], function* ({ contentType, file }) {
          setSelectedDocName(zeltDocument.name);
          setSelectedDocBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        }));
      } catch (e) {
        console.error("::URL Download error", e);
        showMessage(
          polyglot.t("DocumentsCompanyPage.errorMessages.preview", { errorMessage: nestErrorMessage(e) }),
          "error"
        );
      }
    }),
    [polyglot, setOpenPreviewModal, setSelectedDocBuffer, setSelectedDocName, showMessage]
  );
  const debouncedFilter = useDebouncedCallback((filterString2) => __async(void 0, null, function* () {
    try {
      setFilterString(filterString2);
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage(polyglot.t("DocumentsCompanyPage.errorMessages.filter"), "error");
    }
  }), 500);
  const handleFilter = useCallback((event) => debouncedFilter.callback(event), [debouncedFilter]);
  const onMissingField = (params) => {
    const { contractRecipientUserId: contractRecipientUserId2, templateVerificationResult } = params;
    setContractRecipientUserId(contractRecipientUserId2);
    setContractCompanySignatoryUserId(templateVerificationResult.companySignatoryUserId);
    setMissingFields(templateVerificationResult.fields);
    setOpenMissingFieldContractModal(true);
  };
  useEffect(() => {
    trackPage(`Documents ${reach} page`);
    if (currentUserIsTemplateAdmin) fetchTemplates();
  }, []);
  const refreshMissingFieldsForTemplate = useCallback(
    (templateId, contractRecipientUserId2, companySignatoryUserId) => __async(void 0, null, function* () {
      try {
        const templateResult = yield TemplateAPI.verifyTemplateParameters({
          templateId,
          contractRecipientUserId: contractRecipientUserId2,
          companySignatoryUserId
        });
        setMissingFields(templateResult.fields);
      } catch (error) {
        showMessage(
          polyglot.t("TemplatesPage.errorMessages.verify", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, showMessage]
  );
  const refreshDocuments = () => __async(void 0, null, function* () {
    yield fetchDocuments({
      page: pageIndex,
      pageSize: DEFAULT_PAGE_SIZE,
      searchQuery,
      filterString,
      statusFilter: statusFilterValue,
      shouldLimitResult: true
    });
  });
  const bulkDeleteDocuments = () => __async(void 0, null, function* () {
    try {
      const { deletedCount } = yield DocumentAPI.bulkDeleteDocuments(selectionModel);
      setSelectionModel([]);
      refreshDocuments();
      showMessage(polyglot.t("DocumentFormModal.successMessages.bulkDelete", { deletedCount }), "success");
    } catch (error) {
      showMessage(
        polyglot.t("DocumentFormModal.errorMessages.bulkDelete", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const bulkEditDocuments = (updatedValues) => __async(void 0, null, function* () {
    try {
      yield DocumentAPI.bulkEditDocuments(selectionModel, updatedValues);
      setSelectionModel([]);
      refreshDocuments();
      showMessage(
        polyglot.t("DocumentFormModal.successMessages.bulkEdit", { updateCount: selectionModel.length }),
        "success"
      );
    } catch (error) {
      showMessage(
        polyglot.t("DocumentFormModal.errorMessages.bulkEdit", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const getDocumentBulkActionsOptions = () => {
    return [
      {
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => setConfirmBulkDeleteDrawerOpen(true),
        label: "Delete",
        disabled: selectionModel.length === 0
      },
      {
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => setConfirmBulkEditDrawerOpen(true),
        label: "Bulk Edit",
        disabled: selectionModel.length === 0
      }
    ];
  };
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("DocumentsCompanyPage.documents") }),
        showAction: isDocumentExist,
        actions: /* @__PURE__ */ jsx(
          NewDocumentButton,
          {
            onUploadClick: handleUploadDocument,
            onRequestClick: handleRequestDocument,
            onBulkUploadClick: currentUserIsDocumentAdmin ? handleBulkUploadDocument : void 0,
            onTemplateClick: currentUserIsTemplateAdmin ? handleTemplateClick : void 0,
            isAdmin: currentUserIsDocumentAdmin || reach === "team" && hasDocumentsManager,
            documentTypes: [...documentState.types]
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(
      ContentWrapper,
      {
        loading: !loaded,
        sx: __spreadProps(__spreadValues({}, pinnedDocuments.length > 0 ? spacing.pt40 : spacing.pt20), { overflow: "hidden" }),
        children: [
          !loading && !isDocumentExist && (documents == null ? void 0 : documents.length) === 0 && /* @__PURE__ */ jsx(
            DocumentEmptyOverview,
            {
              onUploadClick: handleUploadDocument,
              onRequestClick: handleRequestDocument,
              onBulkUploadClick: currentUserIsDocumentAdmin ? handleBulkUploadDocument : void 0,
              onTemplateClick: currentUserIsTemplateAdmin ? handleTemplateClick : void 0,
              documentTypes: [...documentState.types],
              isAdmin: currentUserIsDocumentAdmin
            }
          ),
          /* @__PURE__ */ jsxs(Fragment, { children: [
            pinnedDocuments.length > 0 && /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.pb40), children: /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  display: "flex",
                  alignItems: "center",
                  columnGap: spacing.g60,
                  rowGap: spacing.g20,
                  flexWrap: "wrap"
                },
                children: /* @__PURE__ */ jsx(
                  PinnedDocument,
                  {
                    pinnedDocuments,
                    onEditClick: (value) => {
                      documentDispatch({ kind: "edit_document", value, userList: allUsers });
                      setDocumentFormMode("edit");
                      setOpen(true);
                      setFilterValue(
                        getDocumentParentCategory(
                          reach,
                          "edit",
                          value,
                          currentUserIsDocumentAdmin,
                          documentState.types
                        )
                      );
                    },
                    documentTypes: [...documentState.types],
                    onPreviewClick: handlePreviewClick
                  }
                )
              }
            ) }),
            documentState.types.length > 0 && /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                },
                children: [
                  /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
                    /* @__PURE__ */ jsx(
                      TabFilterButtons,
                      {
                        filters: TabFilter(polyglot),
                        setFilterValue: setStatusFilterValue,
                        filterValue: statusFilterValue,
                        onFilterChange: ({ filterValue: filterValue2 }) => {
                          setSelectionModel([]);
                          setStatusFilterValue(filterValue2);
                        }
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      CategoryFilters,
                      {
                        filterTypes: {
                          type: getDocumentTypeListBasedOnAudience(documentState.types, currentUserIsDocumentAdmin).map(
                            (d) => {
                              return { value: d.value, label: d.label };
                            }
                          )
                        },
                        setFilterString: handleFilter,
                        filterString
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchQuery,
                        handleChange: (e) => {
                          var _a2, _b2;
                          setSearchQuery((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
                          setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
                        },
                        placeholder: polyglot.t("DocumentsCompanyPage.search")
                      }
                    )
                  ] }),
                  /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "end", gap: spacing.g5, marginLeft: spacing.ml10 }, children: [
                    /* @__PURE__ */ jsx(
                      SelectDeselectIdRows,
                      {
                        selectionModel,
                        setSelectionModel,
                        rows: documents != null ? documents : [],
                        hideSelectAll: true
                      }
                    ),
                    (selectionModel == null ? void 0 : selectionModel.length) > 0 ? /* @__PURE__ */ jsx(
                      StyledMenuComponent,
                      {
                        options: getDocumentBulkActionsOptions(),
                        actionButtonDetails: {
                          type: "button",
                          colorVariant: "secondary",
                          sizeVariant: "small",
                          title: "Actions",
                          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                          iconPosition: "end"
                        }
                      }
                    ) : /* @__PURE__ */ jsx(Fragment, {})
                  ] })
                ]
              }
            ),
            /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
              DocumentTable,
              {
                documents: documents ? [...documents] : [],
                loading: loading || loadingDocuments,
                documentTypes: [...documentState.types],
                onEditClick: (value) => {
                  documentDispatch({ kind: "edit_document", value, userList: allUsers });
                  setDocumentFormMode("edit");
                  setFilterValue(
                    getDocumentParentCategory(
                      reach,
                      "edit",
                      value,
                      currentUserIsDocumentAdmin,
                      documentState.types
                    )
                  );
                  setOpen(true);
                },
                onVerified: (doc, documentVerification) => {
                  pipe(
                    documents != null ? documents : [],
                    RA.findIndex((d) => d.id === doc.id),
                    O.fold(constVoid, (idx) => {
                      const verifiedDoc = __spreadProps(__spreadValues({}, doc), { documentVerification });
                      pipe(documents != null ? documents : [], RA.updateAt(idx, verifiedDoc), O.fold(constVoid, setDocuments));
                    })
                  );
                },
                sorting,
                setSorting,
                pagination,
                setPagination,
                totalPages,
                totalItems,
                onDelete: () => __async(void 0, null, function* () {
                  yield fetchDocuments({
                    page: pageIndex,
                    pageSize: DEFAULT_PAGE_SIZE,
                    searchQuery,
                    filterString,
                    statusFilter: statusFilterValue,
                    shouldLimitResult: true
                  });
                }),
                setSelectedDocBuffer,
                setSelectedDocContentType,
                setSelectedDocName,
                setOpenPreviewModal,
                setSelectionModel,
                selectionModel,
                stickyHeader: true
              }
            ) })
          ] })
        ]
      }
    ),
    users && /* @__PURE__ */ jsx(
      DocumentFormModal,
      {
        mode: documentFormMode,
        state: documentState,
        currentUserIsAdmin: currentUserIsDocumentAdmin,
        dispatch: documentDispatch,
        newDocumentTypes: documentState.types,
        onSubmit: completeSave,
        users,
        canSelectEveryone: documentFormMode === "request" ? false : reach === "company",
        canChangeOwnership: true,
        setOpen,
        open,
        setLoading,
        getDocuments: () => __async(void 0, null, function* () {
          yield fetchDocuments({
            page: pageIndex,
            pageSize: DEFAULT_PAGE_SIZE,
            searchQuery,
            filterString,
            statusFilter: statusFilterValue,
            shouldLimitResult: true
          });
        }),
        setFilterValue,
        filterValue
      }
    ),
    /* @__PURE__ */ jsx(
      DocumentConfirmBulkDeleteDrawer,
      {
        isOpen: confirmBulkDeleteDrawerOpen,
        setIsOpen: setConfirmBulkDeleteDrawerOpen,
        onActionConfirm: bulkDeleteDocuments,
        drawerTitle: "Delete documents",
        confirmationText: "Are you sure you want to delete selected documents?",
        documentCountForDeletion: (_c = selectionModel.length) != null ? _c : 0
      }
    ),
    /* @__PURE__ */ jsx(
      DocumentConfirmBulkEditDrawer,
      {
        isOpen: confirmBulkEditDrawerOpen,
        setIsOpen: setConfirmBulkEditDrawerOpen,
        onBulkEdit: bulkEditDocuments,
        drawerTitle: "Edit documents",
        confirmationText: `You are about to edit ${selectionModel == null ? void 0 : selectionModel.length} documents. Privacy settings change will only apply to personal documents and not company documents.`,
        documentCountForEdit: (_d = selectionModel.length) != null ? _d : 0
      }
    ),
    selectedDocBuffer && openPreviewModal && /* @__PURE__ */ jsx(
      DocPreviewer,
      {
        fileBuffer: selectedDocBuffer,
        contentType: selectedDocContentType,
        visible: true,
        onClose: () => setOpenPreviewModal(false),
        title: selectedDocName
      }
    ),
    selectedTemplate && openContractModal && /* @__PURE__ */ jsx(
      ContractModal,
      {
        open: openContractModal,
        setOpen: setOpenContractModal,
        contractTemplate: selectedTemplate,
        onMissingField
      }
    ),
    selectedTemplate && missingFields && contractRecipientUserId && openMissingFieldContractModal && /* @__PURE__ */ jsx(
      MissingTemplateFieldModal,
      {
        open: openMissingFieldContractModal,
        setOpen: setOpenMissingFieldContractModal,
        templateId: selectedTemplate.id,
        missingFields,
        contractRecipientId: contractRecipientUserId,
        companySignatoryUserId: contractCompanySignatoryUserId,
        refreshMissingFields: refreshMissingFieldsForTemplate
      }
    ),
    setSelectedTemplate && templates && openTemplateModal && /* @__PURE__ */ jsx(
      SelectTemplateForContractModal,
      {
        open: openTemplateModal,
        setOpen: setOpenTemplateModal,
        templateList: templates,
        setSelectedTemplate: (template) => {
          setSelectedTemplate(template != null ? template : void 0);
          setOpenContractModal(true);
        }
      }
    )
  ] });
};
