"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { ReviewCycleCreationTeamRouter } from "@v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-router/team/review-cycle-creation-team.router";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl, RouteScopesHas } from "@/component/widgets/Scopes";
import {
  REVIEWS_TEAM_ONGOING_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_ROUTE,
  REVIEWS_TEAM_ROUTE
} from "@/lib/routes";
import { ReviewCycleDetailTeamRouter } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-router/team/review-cycle-detail-team.router";
import { ReviewCycleOngoingListingPage } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-ongoing/review-cycle-ongoing-listing/review-cycle-ongoing-listing.page";
import { ReachType } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
export const ReviewsTeamRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reviews:manager"],
        path: REVIEWS_TEAM_ONGOING_SETUP_ROUTE,
        component: ReviewCycleCreationTeamRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reviews:manager"],
        path: REVIEWS_TEAM_ONGOING_DETAIL_ROUTE,
        component: ReviewCycleDetailTeamRouter
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["reviews:manager"], path: REVIEWS_TEAM_ONGOING_ROUTE, exact: true, children: /* @__PURE__ */ jsx(ReviewCycleOngoingListingPage, { reach: ReachType.Team }) }),
    /* @__PURE__ */ jsx(Redirect, { from: REVIEWS_TEAM_ROUTE, to: REVIEWS_TEAM_ONGOING_ROUTE })
  ] });
};
