"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DeviceEndpoints } from "@v2/feature/device/device.api";
import { AddExistingDeviceDrawer } from "@v2/feature/device/features/devices-company/components/add-existing-device-drawer.component";
import { ConfigurableDeviceListView } from "@v2/feature/device/features/devices-company/components/configurable-device-view.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { useLocation } from "react-router-dom";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DevicesActivePage = ({ loading }) => {
  const routerLocation = useLocation();
  const [isAddExistingOpen, setIsAddExistingOpen] = useState(false);
  const {
    data: configurableDevices,
    mutate: refreshConfigurableDevicesMutation,
    isLoading: devicesLoading
  } = useApiClient(DeviceEndpoints.getDirectoryDevices(), { suspense: false });
  const refresh = useCallback(() => __async(void 0, null, function* () {
    yield refreshConfigurableDevicesMutation == null ? void 0 : refreshConfigurableDevicesMutation();
    setIsAddExistingOpen(false);
  }), [refreshConfigurableDevicesMutation]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Devices" }),
        showBack: routerLocation.pathname.includes("/settings"),
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            onClick: () => {
              setIsAddExistingOpen(true);
            },
            children: "New device"
          }
        ),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: __spreadValues({}, spacing.pt20), children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "83%",
              height: "100%",
              sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
            }
          ),
          children: !devicesLoading ? /* @__PURE__ */ jsx(ConfigurableDeviceListView, { configurableDevices }) : /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "200px",
              height: "100%",
              sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
            }
          )
        }
      ) }),
      isAddExistingOpen && /* @__PURE__ */ jsx(AddExistingDeviceDrawer, { isOpen: isAddExistingOpen, setIsOpen: setIsAddExistingOpen, refresh })
    ] })
  ] });
};
