"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { tableWhiteIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { helperTextSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const SelectWorkOptions = ({
  logDate,
  attendanceSchedule,
  entries,
  setEntries,
  showErrorMessage
}) => {
  const { polyglot } = usePolyglot();
  const TypeOptions = useMemo(
    () => attendanceSchedule.attendanceTypesAllowed.map((type) => ({
      value: type.id,
      label: type.name
    })),
    [attendanceSchedule == null ? void 0 : attendanceSchedule.attendanceTypesAllowed]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: spacing.g15, mb: spacing.m10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "160px" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("AttendanceDomain.type") }) }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "80px" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("AttendanceDomain.from") }) }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "80px" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("AttendanceDomain.to") }) }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "30px" } })
    ] }, "labels"),
    entries && entries.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.m20, display: "flex", flexDirection: "column", gap: spacing.m30 }, children: entries.map((r, index) => /* @__PURE__ */ jsxs(
      Box,
      {
        sx: { display: "flex", justifyContent: "space-between", gap: spacing.g15, alignItems: "center" },
        children: [
          /* @__PURE__ */ jsx(Box, { sx: { width: "160px" }, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "type",
              options: TypeOptions,
              value: r.typeId,
              onChange: (e) => {
                const updatedEntries = [...entries];
                updatedEntries[index].typeId = e.target.value;
                setEntries(updatedEntries);
              }
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { width: "80px" }, children: /* @__PURE__ */ jsx(
            TimePickerComponent,
            {
              textFieldKey: "startHour",
              value: r.startHour,
              onChange: (event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${logDate}T${time}:00`);
                  const updatedEntries = [...entries];
                  updatedEntries[index].startHour = date.toFullString();
                  updatedEntries[index].startHourTimestamp = date.getDate();
                  setEntries(updatedEntries);
                }
              },
              fullWidth: true
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { width: "80px" }, children: /* @__PURE__ */ jsx(
            TimePickerComponent,
            {
              textFieldKey: "endHour",
              value: r.endHour,
              onChange: (event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${logDate}T${time}:00`);
                  const updatedEntries = [...entries];
                  updatedEntries[index].endHour = date.toFullString();
                  updatedEntries[index].endHourTimestamp = date.getDate();
                  setEntries(updatedEntries);
                }
              },
              fullWidth: true
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { width: "30px" }, children: /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableWhiteIconButtonSx,
              onClick: () => {
                setEntries(entries.filter((e, i) => i !== index));
              },
              children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
            }
          ) })
        ]
      },
      index
    )) }),
    showErrorMessage && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, helperTextSx), { color: themeColors.darkRed, mb: spacing.m10 }), children: polyglot.t("AttendanceDomain.errors.makeSureAllAreValid") }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(
      ButtonComponent,
      {
        colorVariant: "secondary",
        sizeVariant: "filter",
        onClick: () => {
          setEntries([
            ...entries.map((e, index) => __spreadProps(__spreadValues({}, e), { priority: index })),
            {
              typeId: "",
              startHour: "",
              endHour: "",
              startHourTimestamp: null,
              endHourTimestamp: null,
              priority: entries.length
            }
          ]);
        },
        children: [
          "+ ",
          polyglot.t("General.add")
        ]
      }
    ) })
  ] });
};
