"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class InsuranceAPI {
  static getInsuranceQuote() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/insurance-quotes")).data;
    });
  }
  static requestInsuranceQuote(benefitsQuoteData) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/insurance-quotes", benefitsQuoteData)).data;
    });
  }
  static updateQuoteMembers(quoteId, members) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/insurance-quotes/${quoteId}/members`, members)).data;
    });
  }
  static getAllInsuranceQuotesAsSuperAdmin() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/insurance-quotes/superadmin")).data;
    });
  }
  static getAllUserInsuranceRecordsAsSuperAdmin(insurancePolicyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/user-insurance/superadmin/policies/${insurancePolicyId}/users`)).data;
    });
  }
  static addUserToInsurancePolicyAsSuperAdmin(insurancePolicyId, userId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/user-insurance/superadmin/policies/${insurancePolicyId}/users/${userId}/opt-in`);
    });
  }
  static removeUserFromInsurancePolicyAsSuperAdmin(insurancePolicyId, userId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/user-insurance/superadmin/policies/${insurancePolicyId}/users/${userId}/opt-out`);
    });
  }
  static confirmUserDependantsAsSuperAdmin(insurancePolicyId, userId) {
    return __async(this, null, function* () {
      yield axios.post(
        `/apiv2/user-insurance/superadmin/policies/${insurancePolicyId}/users/${userId}/confirm-dependants`
      );
    });
  }
  static deleteInsuranceQuoteByIdAsSuperAdmin(quoteId) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/insurance-quotes/${quoteId}/superadmin`)).data;
    });
  }
  static createPolicyForInsuranceAsSuperAdmin(insuranceQuoteId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/insurance-policies/superadmin/quotes/${insuranceQuoteId}/create-policy`, data);
    });
  }
  static createEmptyUserInsuranceAsSuperAdmin(companyId, userId, policyId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/user-insurance/superadmin/companies/${companyId}/policies/${policyId}/users/${userId}`);
    });
  }
  static updateUserInsuranceAsSuperAdmin(policyId, userId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/user-insurance/superadmin/policies/${policyId}/users/${userId}`, update);
    });
  }
  static uploadPolicyDocumentsAsSuperAdmin(insurancePolicyId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/insurance-policies/superadmin/policies/${insurancePolicyId}/documents/upload`, data);
    });
  }
  static updatePolicyForInsuranceAsSuperAdmin(insurancePolicyId, data) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/insurance-policies/superadmin/${insurancePolicyId}/update-policy`, data);
    });
  }
  static updateHealthInsurancePolicyGeneral(data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/insurance-policies/general`, data);
    });
  }
  static deleteInsurancePolicyByIdAsSuperAdmin(policyId) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/insurance-policies/superadmin/${policyId}`)).data;
    });
  }
  static getAllInsurancePoliciesAsSuperAdmin() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/insurance-policies/superadmin")).data;
    });
  }
  static getInsurancePolicyDetailsAsSuperAdmin(policyId, includeDocuments = false) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/insurance-policies/superadmin/${policyId}?includeDocuments=${includeDocuments}`)).data;
    });
  }
  static getInsurancePolicyById(policyId, includeDocuments = false) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/insurance-policies/${policyId}?includeDocuments=${includeDocuments}`)).data;
    });
  }
  static getUserInsurancePolicyById(userId, includePolicyDetails = false) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/user-insurance/policies/users/${userId}?includePolicyDetails=${includePolicyDetails}`)).data;
    });
  }
  static getAllInsuranceByUserId(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/user-insurance/${userId}`)).data;
    });
  }
  static addUsersToPolicy(policyId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/user-insurance/policies/${policyId}/users`, data);
    });
  }
  static addUserToPolicy(userId, policyId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/user-insurance/policies/${policyId}/users/${userId}`, data);
    });
  }
  static optOutFromInsurancePolicy(userId, policyId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/user-insurance/policies/${policyId}/users/${userId}/opt-out`);
    });
  }
  static updateUserPolicyPremiums(userId, policyId, data) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/user-insurance/policies/${policyId}/users/${userId}`, data);
    });
  }
  static addDependantsToUserPolicy(userId, policyId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/user-insurance/policies/${policyId}/users/${userId}/dependants`, data);
    });
  }
  static removeUserFromPolicy(userId, policyId, endDate) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/user-insurance/policies/${policyId}/users/${userId}?endDate=${endDate}`);
    });
  }
  static createPMI(quoteId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/insurance-quotes/superadmin/${quoteId}/create-pmi`);
    });
  }
  static getInsuranceAddonsMultipliers() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/insurance-addons-multipliers/superadmin")).data;
    });
  }
  static getInsuranceAddonsMultipliersByPolicyId(policyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/insurance-addons-multipliers/policies/${policyId}`)).data;
    });
  }
  static createExistingInsurance(data) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/todo/create-existing`, data)).data;
    });
  }
}
export class InsuranceEndpoints {
  static getInsuranceQuote() {
    return {
      url: "/apiv2/insurance-quotes"
    };
  }
  static getAllUsersInsurancePolicyById(policyId) {
    return {
      url: `/apiv2/user-insurance/policies/${policyId}`
    };
  }
}
