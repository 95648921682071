"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useMemo, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Desc } from "@/images/side-bar-icons/ArrowDownSmall.svg";
import { ReactComponent as Asc } from "@/images/side-bar-icons/ArrowUpSmall.svg";
import { ReactComponent as SortIcon } from "@/images/side-bar-icons/SortIcon.svg";
import { PaginationDetail } from "@/v2/components/table/pagination-detail.component";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import "@/v2/components/table/styles/basic-table.scss";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import {
  iconSize,
  Table,
  TableBody,
  TableData,
  TableFootRow,
  TableHead,
  TableHeading,
  TableHeadRow,
  TableRow
} from "@/v2/styles/table.styles";
export const stickHeaderStyle = {
  overflowX: "auto",
  width: "100%",
  height: "calc(100vh - 200px)",
  overflowY: "auto",
  position: "relative"
};
export const BasicTable = ({
  rowData,
  columnData,
  loading,
  disabled,
  initialSort,
  rowClick,
  hidePagination = false,
  initialPageSize,
  noDataMessage,
  hiddenColumns,
  fixedLastColumn = true,
  fixedFirstColumn = true,
  style,
  customRowStyle,
  paginationReset = false,
  maxUnpaginatedRows = 100,
  setTable,
  showFooter = false,
  paginationSx,
  externalTdAndThPadding,
  stickyHeader,
  hideHover = false
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const usePagination = !hidePagination || maxUnpaginatedRows !== "unlimited" && rowData.length > maxUnpaginatedRows;
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: initialPageSize != null ? initialPageSize : DEFAULT_PAGE_SIZE
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const table = useReactTable({
    data: rowData,
    columns: columnData.filter((column) => column.id && !(hiddenColumns == null ? void 0 : hiddenColumns.includes(column.id))),
    initialState: {
      sorting: initialSort
    },
    state: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: usePagination ? getPaginationRowModel() : void 0,
    autoResetPageIndex: paginationReset,
    // should only be turned true when data reset. If otherwise, on each button click the pagination resets
    // we need to set manualPagination:true when we switch from pagination to unpaginated or the table
    // doesn't show all of the unpaginated data.
    manualPagination: !usePagination
  });
  if (setTable) setTable(table);
  useLayoutEffect(() => {
    table.setPageIndex(0);
  }, [rowData.length, table]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      "div",
      {
        style: stickyHeader ? __spreadValues({
          overflowX: "auto",
          width: "100%",
          height: "calc(100vh - 200px)",
          overflowY: "auto",
          position: "relative"
        }, style) : __spreadValues({ overflowX: "auto", maxHeight: "inherit", width: "100%" }, style),
        children: [
          /* @__PURE__ */ jsxs(Table, { children: [
            /* @__PURE__ */ jsx(TableHead, { style: stickyHeader ? { position: "sticky", top: 0, zIndex: 3 } : {}, children: (_a = table.getHeaderGroups()) == null ? void 0 : _a.map((headerGroup) => /* @__PURE__ */ jsx(TableHeadRow, { children: headerGroup.headers.map((header, idx, arr) => {
              var _a2;
              const cellStyle = {};
              return /* @__PURE__ */ jsx(
                TableHeading,
                {
                  maxWidth: header.column.columnDef.maxSize || 100,
                  minWidth: header.column.columnDef.minSize,
                  style: __spreadValues({}, cellStyle),
                  fixedFirstColumn: idx === 0 && fixedFirstColumn,
                  fixedLastColumn: idx === arr.length - 1 && fixedLastColumn,
                  className: "tableHeading",
                  externalTdAndThPadding,
                  children: header.isPlaceholder ? null : /* @__PURE__ */ jsxs(
                    "div",
                    {
                      style: { display: "flex", alignItems: "center", gap: spacing.g5 },
                      onClick: header.column.getToggleSortingHandler(),
                      children: [
                        /* @__PURE__ */ jsx("span", { style: __spreadValues({ color: themeColors.Grey }, themeFonts.caption), children: flexRender(header.column.columnDef.header, header.getContext()) }),
                        /* @__PURE__ */ jsx("div", { className: "sortIcon", children: (_a2 = header.column.columnDef.enableSorting && {
                          asc: /* @__PURE__ */ jsx(Asc, { width: 12, height: 12, style: { color: themeColors.DarkGrey } }),
                          desc: /* @__PURE__ */ jsx(Desc, { width: 12, height: 12, style: { color: themeColors.DarkGrey } })
                        }[header.column.getIsSorted()]) != null ? _a2 : /* @__PURE__ */ jsx(SortIcon, __spreadValues({}, iconSize)) })
                      ]
                    }
                  )
                },
                header.id
              );
            }) }, headerGroup.id)) }),
            loading && /* @__PURE__ */ jsx(TableBody, { style: { height: "100%", overflowY: "auto" }, children: [1, 2, 3, 4].map((row) => {
              const tableColumns = table.getAllColumns();
              return /* @__PURE__ */ jsx(TableRow, { children: (tableColumns.length > 0 ? tableColumns : [1, 2, 3, 4]).map((cell, i) => /* @__PURE__ */ jsx(TableData, { maxWidth: 150, minWidth: 100, children: /* @__PURE__ */ jsx(Skeleton, { variant: "text", sx: { backgroundColor: themeColors.Background } }) }, i)) }, row);
            }) }),
            rowData.length > 0 && !loading && /* @__PURE__ */ jsxs(TableBody, { children: [
              (_b = table.getRowModel()) == null ? void 0 : _b.rows.map((row) => /* @__PURE__ */ jsx(
                TableRow,
                {
                  hideHover,
                  onClick: () => !disabled && (rowClick == null ? void 0 : rowClick(row)),
                  style: {
                    cursor: !disabled && rowClick ? "pointer" : "default"
                  },
                  children: row.getVisibleCells().map((cell, idx, arr) => {
                    var _a2, _b2;
                    const cellStyle = {};
                    return /* @__PURE__ */ jsx(
                      TableData,
                      {
                        maxWidth: cell.column.columnDef.maxSize || 100,
                        minWidth: cell.column.columnDef.minSize,
                        cellStyle: __spreadValues(__spreadValues({}, cellStyle), customRowStyle == null ? void 0 : customRowStyle(row)),
                        meta: (_b2 = (_a2 = cell.column) == null ? void 0 : _a2.columnDef) == null ? void 0 : _b2.meta,
                        fixedFirstColumn: idx === 0 && fixedFirstColumn,
                        fixedLastColumn: idx === arr.length - 1 && fixedLastColumn,
                        externalTdAndThPadding,
                        children: flexRender(cell.column.columnDef.cell, cell.getContext())
                      },
                      idx
                    );
                  })
                },
                row.id
              )),
              showFooter && table.getFooterGroups().map((footerGroup) => /* @__PURE__ */ jsx(TableFootRow, { children: footerGroup.headers.map((header) => {
                const cellStyle = {};
                return /* @__PURE__ */ jsx(
                  TableData,
                  {
                    maxWidth: header.column.columnDef.maxSize || 100,
                    minWidth: header.column.columnDef.minSize,
                    cellStyle: __spreadValues(__spreadValues({}, themeFonts.title4), cellStyle),
                    children: flexRender(header.column.columnDef.footer, header.getContext())
                  },
                  header.id
                );
              }) }, footerGroup.id))
            ] })
          ] }),
          rowData.length < 1 && !loading && /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                width: "100%",
                display: "flex",
                marginTop: spacing.m10,
                height: "50px",
                alignItems: "center",
                justifyContent: "center"
              },
              children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: noDataMessage != null ? noDataMessage : polyglot.has("BasicServerTable.countNotFindAnything") ? polyglot.t("BasicServerTable.countNotFindAnything") : "Sorry, we couldn't find anything..." })
            }
          )
        ]
      }
    ),
    usePagination && /* @__PURE__ */ jsx(
      PaginationDetail,
      {
        totalPageCount: table.getPageCount(),
        totalRecordCount: rowData == null ? void 0 : rowData.length,
        current: Math.min(table.getPageCount(), table.getState().pagination.pageIndex + 1),
        onNextAction: () => {
          table.nextPage();
        },
        nextDisabled: !table.getCanNextPage(),
        onPreviousAction: () => {
          table.previousPage();
        },
        previousDisabled: !table.getCanPreviousPage(),
        paginationState: table.getState().pagination,
        setPaginationState: (pageSize2, pageIndex2) => {
          table.setPagination({ pageSize: pageSize2, pageIndex: pageIndex2 - 1 });
        },
        handleState: (page) => {
          const pageState = page ? page - 1 : 0;
          table.setPageIndex(pageState);
        },
        paginationSx
      }
    )
  ] });
};
