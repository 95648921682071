"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const AttendancePenaltyRuleCell = ({
  percent,
  width,
  variant = "caption",
  color
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Box, { sx: { width }, children: /* @__PURE__ */ jsx(Typography, { variant, color, children: percent === 0 ? polyglot.t("AttendanceModule.Penalties.warning") : polyglot.t("AttendanceModule.Penalties.dayRateTimesPercent", { percent }) }) });
};
