"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DocumentFormModal } from "@/v2/feature/documents/components/document-form-modal.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import {
  documentFormModalOwnershipToDto,
  getDocumentParentCategory,
  initialiseState,
  reduceDocumentFormModal
} from "@/v2/feature/documents/documents.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
const initialState = initialiseState([]);
export const DocumentActionItem = ({
  documentActionRow,
  refresh,
  afterClose
}) => {
  const { polyglot } = usePolyglot();
  const [documentFormMode, setDocumentFormMode] = useState("add");
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const [filterValue, setFilterValue] = useState("");
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserIsDocumentAdmin = hasScopes(["documents:all"], getScopesContext(globalState.user));
  const [open, setOpen] = useState(false);
  const [showMessage] = useMessage();
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const allUsers = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => ({ value: u.userId, label: u.displayName }));
  }, [nonTerminatedCachedUsers]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const types = yield DocumentAPI.listDocumentTypes();
        documentDispatch({ kind: "set_types", value: types });
      } catch (error) {
        console.error(`Something bad happened. ${nestErrorMessage(error)}`);
      }
    }))();
  }, []);
  const getDocumentById = useCallback(() => __async(void 0, null, function* () {
    try {
      const document = yield DocumentAPI.getDocumentById(Number(documentActionRow.id));
      documentDispatch({ kind: "edit_document", value: document, userList: allUsers });
      setDocumentFormMode("edit");
      setFilterValue(
        getDocumentParentCategory(
          "me",
          "edit",
          document,
          currentUserIsDocumentAdmin,
          documentState.types
        )
      );
      setOpen(true);
    } catch (error) {
      showMessage(
        polyglot.t("DocumentActionItem.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, documentActionRow.id, allUsers, currentUserIsDocumentAdmin, documentState.types, showMessage]);
  useEffect(() => {
    getDocumentById();
  }, [getDocumentById]);
  const completeSave = () => __async(void 0, null, function* () {
    var _a, _b, _c, _d, _e, _f, _g;
    try {
      let documentObject = {
        name: documentState.name || "",
        type: (_b = (_a = documentState.type) == null ? void 0 : _a.value) != null ? _b : "",
        belongsTo: documentFormModalOwnershipToDto(documentState),
        fileUuid: documentState.fileUuid,
        note: documentState.note,
        expirySettings: (_c = documentState == null ? void 0 : documentState.expirySettings) != null ? _c : { expiryDate: "", documentExpires: false }
      };
      if (documentState.id && documentState.name) {
        if (((_d = documentState.type) == null ? void 0 : _d.category) === "Company Documents") {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { isPinned: (_e = documentState.pinned) != null ? _e : false });
        } else if (documentState.visibility) {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { visibilitySettings: documentState.visibility });
        }
        yield DocumentAPI.updateDocument(documentState.id, documentObject);
        showMessage(polyglot.t("DocumentActionItem.successMessages.update"), "success");
      } else if (documentState.name) {
        if (((_f = documentState.type) == null ? void 0 : _f.category) === "Company Documents") {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { isPinned: (_g = documentState.pinned) != null ? _g : false });
        } else if (documentState.visibility) {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { visibilitySettings: documentState.visibility });
        }
        yield DocumentAPI.createDocument(documentObject);
        showMessage(polyglot.t("DocumentActionItem.successMessages.save"), "success");
      }
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("DocumentActionItem.errorMessages.save", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  function closeUploadModal() {
    setOpen(false);
  }
  return /* @__PURE__ */ jsx(
    DocumentFormModal,
    {
      mode: documentFormMode,
      state: documentState,
      currentUserIsAdmin: currentUserIsDocumentAdmin,
      dispatch: documentDispatch,
      newDocumentTypes: documentState.types,
      onClose: closeUploadModal,
      afterClose,
      onSubmit: completeSave,
      setOpen,
      open,
      setLoading: () => {
      },
      getDocuments: () => __async(void 0, null, function* () {
        yield refresh();
      }),
      setFilterValue,
      filterValue
    }
  );
};
