"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { LocalDate } from "@v2/util/local-date";
import { compareAsc, format, formatDistanceStrict, isValid, parseISO } from "date-fns";
export function removeTZ(d) {
  return d.replace("T", " ").replace("Z", "");
}
export function localFormat(dateFormat, d) {
  const fixedDate = new Date(removeTZ(d.toISOString()));
  return format(fixedDate, dateFormat);
}
export function formatShortMonthYear(date) {
  const d = typeof date === "string" ? parseISO(removeTZ(date)) : date;
  return format(d, "MMM yyyy");
}
export function formatLongMonthYear(date) {
  const d = typeof date === "string" ? parseISO(removeTZ(date)) : date;
  return format(d, "MMMM yyyy");
}
export function formatMediumDate(date, withDayOfWeek = false) {
  const d = typeof date === "string" ? parseISO(removeTZ(date)) : date;
  return format(d, withDayOfWeek ? "E d MMM yyyy" : "d MMM yyyy");
}
export function formatLongDate(date, withDayOfWeek = false) {
  const d = typeof date === "string" ? parseISO(removeTZ(date)) : date;
  return format(d, withDayOfWeek ? "E d MMMM yyyy" : "d MMMM yyyy");
}
export function timeAgo(dateStr) {
  if (!dateStr) return null;
  const timeString = formatDistanceStrict(parseISO(dateStr), /* @__PURE__ */ new Date(), { addSuffix: false });
  return compareAsc(parseISO(removeTZ(dateStr)), /* @__PURE__ */ new Date()) === 1 ? `in ${timeString}` : `${timeString} ago`;
}
export function dateAPItoDisplay(dateStr, withDayOfWeek = false) {
  if (typeof dateStr === "string")
    return dateStr ? format(parseISO(removeTZ(dateStr)), withDayOfWeek ? `E d LLL, yyyy` : "d LLL, yyyy") : null;
  return dateStr ? format(parseISO(removeTZ(dateStr.toISOString())), withDayOfWeek ? `E d LLL, yyyy` : "d LLL, yyyy") : null;
}
export function dateAPItoUILong(dateStr, withDayOfWeek = false) {
  return dateStr ? formatLongDate(parseISO(removeTZ(dateStr)), withDayOfWeek) : null;
}
export const createDateAsUTC = (dateString) => {
  const date = new Date(dateString);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  );
};
export const createShortDateAsUTC = (dateString) => {
  if (!isValid(dateString)) return dateString;
  const date = new Date(dateString);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  ).toISOString().substring(0, 10);
};
export const todaysDateShortISOString = () => new LocalDate().toDateString();
export const recentDateStrings = () => {
  const now = /* @__PURE__ */ new Date();
  const yesterday = new Date(now.getTime() - 24 * 60 * 6e4);
  const tomorrow = new Date(now.getTime() + 24 * 60 * 6e4);
  const twoDigit = (n) => `0${n}`.slice(-2);
  return {
    yesterday: `${yesterday.getFullYear()}-${twoDigit(yesterday.getMonth() + 1)}-${twoDigit(yesterday.getDate())}`,
    today: `${now.getFullYear()}-${twoDigit(now.getMonth() + 1)}-${twoDigit(now.getDate())}`,
    tomorrow: `${tomorrow.getFullYear()}-${twoDigit(tomorrow.getMonth() + 1)}-${twoDigit(tomorrow.getDate())}`
  };
};
export function convertDateFields(object, dateFields) {
  return __spreadValues(__spreadValues({}, object), Object.fromEntries(
    dateFields.filter((dateField) => object[dateField]).map((dateField) => [dateField, new Date(object[dateField])])
  ));
}
