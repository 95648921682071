"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useCallback } from "react";
import { generatePath, Redirect, Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  REVIEWS_COMPANY_ONGOING_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_FORM_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_GENERAL_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_LAUNCH_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_NOTIFICATION_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_PARTICIPANTS_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_TIMELINE_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_VISIBILITY_ROUTE
} from "@/lib/routes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { ReviewCycleEndpoints } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { RCUpsertGeneralPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-general/rc-upsert-general.page";
import { RCUpsertLaunchPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/rc-upsert-launch.page";
import { RCUpsertParticipantsPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/rc-upsert-participants.page";
import { RCUpsertTimelinePage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/rc-upsert-timeline.page";
import { ReviewCycleCreationNotificationsPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-notifications/review-cycle-creation-notifications.page";
import { ReviewCycleCreationQuestionPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/review-cycle-creation-question.page";
import { ReviewCycleCreationVisibilitySettingsPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-visibility-settings/review-cycle-creation-visibility-settings.page";
import { ReachType } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const getPageConfig = (cycleId, reviewCycle, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("CycleSettingsRouter.general"),
          stub: "general",
          path: generatePath(REVIEWS_COMPANY_ONGOING_SETUP_GENERAL_ROUTE, { cycleId })
        },
        {
          title: "Questions",
          stub: "question",
          path: generatePath(REVIEWS_COMPANY_ONGOING_SETUP_FORM_ROUTE, { cycleId })
        },
        {
          title: "Participants",
          stub: "participants",
          path: generatePath(REVIEWS_COMPANY_ONGOING_SETUP_PARTICIPANTS_ROUTE, { cycleId })
        },
        {
          title: "Visibility",
          stub: "visibility",
          path: generatePath(REVIEWS_COMPANY_ONGOING_SETUP_VISIBILITY_ROUTE, { cycleId })
        },
        {
          title: "Timeline",
          stub: "timeline",
          path: generatePath(REVIEWS_COMPANY_ONGOING_SETUP_TIMELINE_ROUTE, { cycleId })
        },
        {
          title: "Notifications",
          stub: "notifications",
          path: generatePath(REVIEWS_COMPANY_ONGOING_SETUP_NOTIFICATION_ROUTE, { cycleId })
        },
        ...(reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Draft || (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Scheduled ? [
          {
            title: "Start review cycle",
            stub: "launch",
            path: generatePath(REVIEWS_COMPANY_ONGOING_SETUP_LAUNCH_ROUTE, { cycleId })
          }
        ] : []
      ]
    }
  ];
};
export const ReviewCycleCreationCompanyRouter = () => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const params = useParams();
  const cycleId = params.cycleId;
  const { data: reviewCycle, mutate: refreshReviewCycle, isLoading: cycleLoading } = useApiClient(
    ReviewCycleEndpoints.getReviewCycleById(cycleId, ReachType.Company),
    {
      suspense: false
    }
  );
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      yield refreshReviewCycle == null ? void 0 : refreshReviewCycle();
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), "error");
    }
  }), [refreshReviewCycle, polyglot, showMessage]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "Reviews",
        pageConfig: getPageConfig(cycleId, reviewCycle, polyglot),
        backPath: REVIEWS_COMPANY_ONGOING_ROUTE,
        showBack: true
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:all"], path: REVIEWS_COMPANY_ONGOING_SETUP_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(
        RCUpsertGeneralPage,
        {
          reviewCycle,
          refresh,
          cycleLoading,
          reach: ReachType.Company
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:all"], path: REVIEWS_COMPANY_ONGOING_SETUP_FORM_ROUTE, children: /* @__PURE__ */ jsx(ReviewCycleCreationQuestionPage, { cycleId, reach: ReachType.Company }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:all"], path: REVIEWS_COMPANY_ONGOING_SETUP_PARTICIPANTS_ROUTE, children: /* @__PURE__ */ jsx(RCUpsertParticipantsPage, { cycleId, reach: ReachType.Company }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:all"], path: REVIEWS_COMPANY_ONGOING_SETUP_VISIBILITY_ROUTE, children: /* @__PURE__ */ jsx(ReviewCycleCreationVisibilitySettingsPage, { cycleId, reach: ReachType.Company }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:all"], path: REVIEWS_COMPANY_ONGOING_SETUP_TIMELINE_ROUTE, children: /* @__PURE__ */ jsx(RCUpsertTimelinePage, { cycleId, reach: ReachType.Company }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:all"], path: REVIEWS_COMPANY_ONGOING_SETUP_NOTIFICATION_ROUTE, children: /* @__PURE__ */ jsx(ReviewCycleCreationNotificationsPage, { reviewCycle, refresh, reach: ReachType.Company }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:all"], path: REVIEWS_COMPANY_ONGOING_SETUP_LAUNCH_ROUTE, children: /* @__PURE__ */ jsx(RCUpsertLaunchPage, { cycleId, reviewCycle, reach: ReachType.Company }) }),
      /* @__PURE__ */ jsx(Redirect, { to: REVIEWS_COMPANY_ONGOING_SETUP_GENERAL_ROUTE, exact: true })
    ] })
  ] });
};
