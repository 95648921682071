"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { PaymentStatus } from "@v2/feature/payments/payments.dto";
import {
  YapilyInstitutions
} from "@v2/feature/payments/payments.interface";
import { translatePaymentStatus } from "@v2/infrastructure/i18n/translate.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { capitalize } from "lodash";
import AIB from "@/images/payment/banks/aib.webp";
import Barclays from "@/images/payment/banks/barclays.webp";
import Hsbc from "@/images/payment/banks/hsbc.webp";
import Llyods from "@/images/payment/banks/llyods.webp";
import Modelo from "@/images/payment/banks/modelo.webp";
import Monzo from "@/images/payment/banks/monzo.webp";
import Natwest from "@/images/payment/banks/natwest.webp";
import NatwestBankline from "@/images/payment/banks/natwestBankline.webp";
import Revolut from "@/images/payment/banks/revolut.webp";
import RoyalBankScotland from "@/images/payment/banks/royalBankScotland.webp";
import Santanders from "@/images/payment/banks/santanders.webp";
import Starling from "@/images/payment/banks/starling.webp";
import Tide from "@/images/payment/banks/tide.webp";
import Wise from "@/images/payment/banks/wise.webp";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Question } from "@/images/side-bar-icons/Question.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { COUNTRY_ISO_CODE_MAPPING, CustomCountryEnum } from "@/v2/infrastructure/country/country.interface";
export const HMRC_ACCOUNT = {
  accountName: "HMRC Cumbernauld",
  accountNumber: "12001039",
  sortCode: "083210",
  currency: "GBP",
  country: "United Kingdom",
  firstName: "HMRC",
  lastName: "Cumbernauld"
};
export const getCodeForCountryName = (countryName) => {
  if (!countryName) return;
  return COUNTRY_ISO_CODE_MAPPING[countryName];
};
export const getCountryNameForCode = (countryCode) => {
  if (countryCode === CustomCountryEnum.code) return CustomCountryEnum.code;
  if (!countryCode) return "";
  if (/^gb$/i.test(countryCode)) countryCode = "UK";
  countryCode = countryCode.toUpperCase();
  return Object.keys(COUNTRY_ISO_CODE_MAPPING).find((key) => COUNTRY_ISO_CODE_MAPPING[key] === countryCode);
};
const mapPaymentToDefaultCSVFormat = (payment, payeeBankAccount, payee) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const firstName = payment.userId ? (_a = payee == null ? void 0 : payee.firstName) != null ? _a : "" : HMRC_ACCOUNT.firstName;
  const lastName = payment.userId ? (_b = payee == null ? void 0 : payee.lastName) != null ? _b : "" : HMRC_ACCOUNT.lastName;
  const beneficiary = lastName ? `${firstName} ${lastName}` : firstName;
  return {
    Beneficiary: beneficiary,
    AccountName: (_c = payeeBankAccount == null ? void 0 : payeeBankAccount.accountName) != null ? _c : "",
    AccountNumber: (_d = payeeBankAccount == null ? void 0 : payeeBankAccount.accountNumber) != null ? _d : "",
    SortCode: (_e = payeeBankAccount == null ? void 0 : payeeBankAccount.sortCode) != null ? _e : "",
    Reference: (_f = payment.reference) != null ? _f : "",
    Amount: payment.amount,
    Currency: (_h = (_g = payment.currency) != null ? _g : payeeBankAccount == null ? void 0 : payeeBankAccount.currency) != null ? _h : "GPB",
    DueDate: payment.dueDate ? new Date(payment.dueDate).toLocaleDateString() : void 0
  };
};
const mapPaymentToRevolutCSVFormat = (payment, payeeBankAccount, payee) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const country = (_a = getCodeForCountryName(payeeBankAccount == null ? void 0 : payeeBankAccount.country)) != null ? _a : "";
  return {
    Name: payee ? `${payee.firstName} ${payee.lastName}` : (_b = payeeBankAccount == null ? void 0 : payeeBankAccount.accountName) != null ? _b : "",
    "Recipient type": (payeeBankAccount == null ? void 0 : payeeBankAccount.accountNumber) === HMRC_ACCOUNT.accountNumber ? "Company" : "Individual",
    "Account number": (_c = payeeBankAccount == null ? void 0 : payeeBankAccount.accountNumber) != null ? _c : "",
    "Sort code": (_d = payeeBankAccount == null ? void 0 : payeeBankAccount.sortCode) != null ? _d : "",
    "Recipient bank country": country === "UK" ? "GB" : country,
    Currency: (_f = (_e = payment.currency) != null ? _e : payeeBankAccount == null ? void 0 : payeeBankAccount.currency) != null ? _f : "GPB",
    Amount: payment.amount,
    "Payment reference": (_g = payment.reference) != null ? _g : ""
  };
};
const mapPaymentToStarlingCSVFormat = (payment, payeeBankAccount, payee, companyName) => {
  var _a, _b, _c, _d, _e;
  const firstName = payment.userId ? (_a = payee == null ? void 0 : payee.firstName) != null ? _a : "" : "";
  const lastName = payment.userId ? (_b = payee == null ? void 0 : payee.lastName) != null ? _b : "" : "";
  return {
    "Sort Code": (_c = payeeBankAccount == null ? void 0 : payeeBankAccount.sortCode) != null ? _c : "",
    "Account Number": (_d = payeeBankAccount == null ? void 0 : payeeBankAccount.accountNumber) != null ? _d : "",
    "First Name": firstName,
    "Last Name": lastName,
    "Business Name": companyName != null ? companyName : "",
    Reference: (_e = payment.reference) != null ? _e : "",
    "Amount (GBP)": payment.amount
  };
};
const mapPaymentToWiseCSVFormat = (payment, payeeBankAccount, payee) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const firstName = payment.userId ? (_a = payee == null ? void 0 : payee.firstName) != null ? _a : "" : HMRC_ACCOUNT.firstName;
  const lastName = payment.userId ? (_b = payee == null ? void 0 : payee.lastName) != null ? _b : "" : HMRC_ACCOUNT.lastName;
  const name = lastName ? `${firstName} ${lastName}` : firstName;
  const recipientEmail = payment.userId && payee ? payee.emailAddress : "";
  const receiverType = payment.userId ? "PERSON" : "";
  return {
    name,
    recipientEmail,
    paymentReference: (_c = payment.reference) != null ? _c : "",
    receiverType,
    amountCurrency: (_e = (_d = payment.currency) != null ? _d : payeeBankAccount == null ? void 0 : payeeBankAccount.currency) != null ? _e : "GBP",
    amount: payment.amount,
    sourceCurrency: (_f = payment.currency) != null ? _f : "GBP",
    targetCurrency: (_g = payment.currency) != null ? _g : "GBP",
    sortCode: (_h = payeeBankAccount == null ? void 0 : payeeBankAccount.sortCode) != null ? _h : "",
    accountNumber: (_i = payeeBankAccount == null ? void 0 : payeeBankAccount.accountNumber) != null ? _i : "",
    IBAN: ""
  };
};
export const mapPaymentToCSVFormatByInstitution = (institutionId, payment, payeeBankAccount, payee, companyName) => {
  switch (institutionId) {
    case YapilyInstitutions.Revolut:
      return mapPaymentToRevolutCSVFormat(payment, payeeBankAccount, payee);
    case YapilyInstitutions.Starling:
      return mapPaymentToStarlingCSVFormat(payment, payeeBankAccount, payee, companyName);
    case YapilyInstitutions.Wise:
      return mapPaymentToWiseCSVFormat(payment, payeeBankAccount, payee);
    default:
      return mapPaymentToDefaultCSVFormat(payment, payeeBankAccount, payee);
  }
};
export const yappilyInstitutionToLogo = (institution) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I;
  const name = institution.id;
  switch (name) {
    case YapilyInstitutions.AIBROIBusiness:
      return /* @__PURE__ */ jsx("img", { src: (_b = AIB) != null ? _b : (_a = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _a.source, width: 40, height: 40, alt: "AIB ROI Business" });
    case YapilyInstitutions.BarclaysBusiness:
      return /* @__PURE__ */ jsx("img", { src: (_d = Barclays) != null ? _d : (_c = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _c.source, width: 40, height: 40, alt: "Barclays Business" });
    case YapilyInstitutions.BarclaysCorporate:
      return /* @__PURE__ */ jsx("img", { src: (_f = Barclays) != null ? _f : (_e = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _e.source, width: 40, height: 40, alt: "Barclays Corporate" });
    case YapilyInstitutions.HSBCBusiness:
      return /* @__PURE__ */ jsx("img", { src: (_h = Hsbc) != null ? _h : (_g = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _g.source, width: 40, height: 40, alt: "HSBC Business UK" });
    case YapilyInstitutions.HSBCNet:
      return /* @__PURE__ */ jsx("img", { src: (_j = Hsbc) != null ? _j : (_i = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _i.source, width: 40, height: 40, alt: "HSBC Net" });
    case YapilyInstitutions.LloydsBusiness:
      return /* @__PURE__ */ jsx("img", { src: (_l = Llyods) != null ? _l : (_k = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _k.source, width: 40, height: 40, alt: "Lloyds Business" });
    case YapilyInstitutions.ModeloSandbox:
      return /* @__PURE__ */ jsx("img", { src: (_n = Modelo) != null ? _n : (_m = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _m.source, width: 40, height: 40, alt: "Modelo Sandbox" });
    case YapilyInstitutions.Monzo:
      return /* @__PURE__ */ jsx("img", { src: (_p = Monzo) != null ? _p : (_o = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _o.source, width: 40, height: 40, alt: "Monzo" });
    case YapilyInstitutions.Natwest:
      return /* @__PURE__ */ jsx("img", { src: (_r = Natwest) != null ? _r : (_q = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _q.source, width: 40, height: 40, alt: "Natwest" });
    case YapilyInstitutions.NatwestBusiness:
      return /* @__PURE__ */ jsx("img", { src: (_t = NatwestBankline) != null ? _t : (_s = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _s.source, width: 40, height: 40, alt: "Natwest Business" });
    case YapilyInstitutions.RBS:
      return /* @__PURE__ */ jsx(
        "img",
        {
          src: (_v = RoyalBankScotland) != null ? _v : (_u = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _u.source,
          width: 40,
          height: 40,
          alt: "Royal Bank of Scotland"
        }
      );
    case YapilyInstitutions.RBSBusiness:
      return /* @__PURE__ */ jsx(
        "img",
        {
          src: (_x = RoyalBankScotland) != null ? _x : (_w = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _w.source,
          width: 40,
          height: 40,
          alt: "Royal Bank of Scotland Bankline"
        }
      );
    case YapilyInstitutions.Revolut:
      return /* @__PURE__ */ jsx("img", { src: (_z = Revolut) != null ? _z : (_y = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _y.source, width: 40, height: 40, alt: "Revolut" });
    case YapilyInstitutions.SantanderUk:
      return /* @__PURE__ */ jsx("img", { src: (_B = Santanders) != null ? _B : (_A = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _A.source, width: 40, height: 40, alt: "Santander UK" });
    case YapilyInstitutions.Starling:
      return /* @__PURE__ */ jsx("img", { src: (_D = Starling) != null ? _D : (_C = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _C.source, width: 40, height: 40, alt: "Starling" });
    case YapilyInstitutions.Tide:
      return /* @__PURE__ */ jsx("img", { src: (_F = Tide) != null ? _F : (_E = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _E.source, width: 40, height: 40, alt: "Tide" });
    case YapilyInstitutions.Wise:
      return /* @__PURE__ */ jsx("img", { src: (_H = Wise) != null ? _H : (_G = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _G.source, width: 40, height: 40, alt: "Wise" });
    default:
      return /* @__PURE__ */ jsx("img", { src: (_I = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _I.source, width: 40, height: 40, alt: "New-Bank" });
  }
};
export const BankingHoursBanks = [];
export const timeOptions = [
  { value: "00:00", label: "00:00" },
  { value: "00:30", label: "00:30" },
  { value: "01:00", label: "01:00" },
  { value: "01:30", label: "01:30" },
  { value: "02:00", label: "02:00" },
  { value: "02:30", label: "02:30" },
  { value: "03:00", label: "03:00" },
  { value: "03:30", label: "03:30" },
  { value: "04:00", label: "04:00" },
  { value: "04:30", label: "04:30" },
  { value: "05:00", label: "05:00" },
  { value: "05:30", label: "05:30" },
  { value: "06:00", label: "06:00" },
  { value: "06:30", label: "06:30" },
  { value: "07:00", label: "07:00" },
  { value: "07:30", label: "07:30" },
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },
  { value: "22:00", label: "22:00" },
  { value: "22:30", label: "22:30" },
  { value: "23:00", label: "23:00" },
  { value: "23:30", label: "23:30" }
];
export const allPaymentColumns = [
  { name: "Select", value: "select" },
  { name: "Beneficiary", value: "userId" },
  { name: "Type", value: "category" },
  { name: "Amount", value: "amount" },
  // { name: 'Bank name', value: 'bankName' },
  // { name: 'Account name', value: 'accountName' },
  // { name: 'Account number', value: 'accountNumber' },
  // { name: 'Sort code', value: 'sortCode' },
  { name: "Reference", value: "reference" },
  { name: "Due Date", value: "dueDate" },
  { name: "Payment Date", value: "createdAt" },
  { name: "Status", value: "status" },
  { name: "Actions", value: "actions" }
];
export function transactionTypeToLabel(type) {
  return type.toLowerCase().split("_").map(capitalize).join(" ");
}
export const paymentPaidOrMarkedPaid = (payment) => {
  return payment.markPaid || payment.status === PaymentStatus.Paid;
};
export const getPaymentStatus = (polyglot, payment, statusSx, includeTooltip = false) => {
  if (payment.markPaid)
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, statusSx), children: polyglot.t("PaymentStatus.markedaspaid") })
    ] });
  switch (payment.status) {
    case PaymentStatus.Processing:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } })),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ color: themeColors.Grey }, statusSx), children: translatePaymentStatus(payment.status, polyglot) })
      ] });
    case PaymentStatus.Unknown:
      return includeTooltip ? /* @__PURE__ */ jsx(StyledTooltip, { title: "Exited the flow or authorisation failed. Check with your bank before retrying or mark as paid.", children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", cursor: "pointer" }, children: [
        /* @__PURE__ */ jsx(Question, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.orange } })),
        " ",
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ color: themeColors.Grey }, statusSx), children: translatePaymentStatus(payment.status, polyglot) })
      ] }) }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Question, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.orange } })),
        " ",
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ color: themeColors.Grey }, statusSx), children: translatePaymentStatus(payment.status, polyglot) })
      ] });
    case PaymentStatus.Paid:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
        /* @__PURE__ */ jsx(Typography, { sx: statusSx, children: translatePaymentStatus(payment.status, polyglot) })
      ] });
    case PaymentStatus.Failed:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        /* @__PURE__ */ jsx(Typography, { sx: statusSx, children: translatePaymentStatus(payment.status, polyglot) })
      ] });
    case PaymentStatus.NotPaid:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } })),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ color: themeColors.Grey }, statusSx), children: translatePaymentStatus(payment.status, polyglot) })
      ] });
    default:
      return /* @__PURE__ */ jsx(Fragment, {});
  }
};
