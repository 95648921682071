"use strict";
export var AbsencePolicyTenureAllocation = /* @__PURE__ */ ((AbsencePolicyTenureAllocation2) => {
  AbsencePolicyTenureAllocation2["Anniversary"] = "Anniversary";
  AbsencePolicyTenureAllocation2["CycleStart"] = "CycleStart";
  AbsencePolicyTenureAllocation2["NextCycle"] = "NextCycle";
  return AbsencePolicyTenureAllocation2;
})(AbsencePolicyTenureAllocation || {});
export var AbsencePolicyAttachmentType = /* @__PURE__ */ ((AbsencePolicyAttachmentType2) => {
  AbsencePolicyAttachmentType2["Required"] = "Required";
  AbsencePolicyAttachmentType2["Optional"] = "Optional";
  return AbsencePolicyAttachmentType2;
})(AbsencePolicyAttachmentType || {});
