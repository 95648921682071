"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { GlobalContext } from "@/GlobalState";
import { canAccessScopes } from "@/lib/scopes";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { ReviewCycleEndpoints } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { CycleCreationVisibilitySettingsModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-visibility-settings/components/cycle-creation-visibility-settings-modal.component";
import { TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewCycleCreationVisibilitySettingsPage = ({
  cycleId,
  reach
}) => {
  var _a, _b, _c;
  const { data: reviewCycle, mutate: refreshCycle, isLoading: reviewCycleLoading } = useApiClient(
    ReviewCycleEndpoints.getReviewCycleById(cycleId, reach),
    {
      suspense: false
    }
  );
  const [isOpen, setIsOpen] = useState(false);
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const isAdmin = canAccessScopes(user, ["reviews:all"]);
  const isManager = canAccessScopes(user, ["reviews:manager"]);
  const showPeerSetting = !(reviewCycle == null ? void 0 : reviewCycle.cycleSettings.allowRevieweesToInvitePeers) && Boolean((_a = reviewCycle == null ? void 0 : reviewCycle.reviewerSelect) == null ? void 0 : _a.includes(ReviewerTypes.Peer));
  const isEditable = useMemo(
    () => Boolean(
      reviewCycle && (reviewCycle.state === CycleState.Draft || reviewCycle.state === CycleState.Scheduled) && Boolean(isAdmin || isManager && reviewCycle.owner === user.userId)
    ),
    [reviewCycle, isAdmin, isManager, user]
  );
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: reviewCycle ? /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle, reachType: reach }) : /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text" }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading: reviewCycleLoading,
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                title: "Visibility",
                onEdit: () => setIsOpen(true),
                hideEdit: !isEditable,
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: "Require manager to release feedback",
                    value: ((_b = reviewCycle == null ? void 0 : reviewCycle.visibilitySettings) == null ? void 0 : _b.allowManagerToReleaseFeedback) ? "Yes" : "No"
                  },
                  ...showPeerSetting ? [
                    {
                      type: SectionItemType.Pair,
                      label: "Hide peer's name",
                      value: ((_c = reviewCycle == null ? void 0 : reviewCycle.visibilitySettings) == null ? void 0 : _c.hidePeerAuthor) ? "Yes" : "No"
                    }
                  ] : []
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationVisibilitySettingsModal,
          {
            reviewCycle,
            isOpen,
            setIsOpen,
            onClose: () => setIsOpen(false),
            refresh: () => __async(void 0, null, function* () {
              yield refreshCycle == null ? void 0 : refreshCycle();
            }),
            showPeerSetting
          }
        )
      ]
    }
  );
};
