"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { useHistory } from "react-router-dom";
import { REVIEWS_COMPANY_ONGOING_ROUTE, REVIEWS_TEAM_ONGOING_ROUTE } from "@/lib/routes";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { ReviewCycleEndpoints } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { LaunchRolling } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/components/rolling/launch-rolling.component";
import { LaunchScheduled } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/components/scheduled/launch-scheduled.component";
import { TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { ReachType, ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleType } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const RCUpsertLaunchPage = ({
  cycleId,
  reviewCycle,
  reach
}) => {
  var _a, _b, _c, _d;
  const { data: reviewCycleLaunchDetails, isLoading: cycleLaunchLoading } = useApiClient(
    ReviewCycleEndpoints.getReviewLaunchDetailsByCycleId(cycleId),
    {
      suspense: false
    }
  );
  const routerHistory = useHistory();
  if (!reviewCycleLaunchDetails) return /* @__PURE__ */ jsx(Fragment, {});
  const showFlags = {
    showSelf: Boolean((_a = reviewCycleLaunchDetails.reviewerSelect) == null ? void 0 : _a.includes(ReviewerTypes.Self)),
    showUpward: Boolean((_b = reviewCycleLaunchDetails.reviewerSelect) == null ? void 0 : _b.includes(ReviewerTypes.Upward)),
    showPeer: Boolean((_c = reviewCycleLaunchDetails.reviewerSelect) == null ? void 0 : _c.includes(ReviewerTypes.Peer)),
    showManager: Boolean((_d = reviewCycleLaunchDetails.reviewerSelect) == null ? void 0 : _d.includes(ReviewerTypes.Manager))
  };
  const goBack = () => {
    const reachRoute = reach === ReachType.Company ? REVIEWS_COMPANY_ONGOING_ROUTE : REVIEWS_TEAM_ONGOING_ROUTE;
    routerHistory.push(reachRoute);
  };
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle, reachType: reach }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading: cycleLaunchLoading,
      children: reviewCycle.type === CycleType.Rolling ? /* @__PURE__ */ jsx(
        LaunchRolling,
        {
          reviewCycleLaunchDetails,
          cycleId,
          goBack,
          showFlags
        }
      ) : /* @__PURE__ */ jsx(
        LaunchScheduled,
        {
          reviewCycleLaunchDetails,
          cycleId,
          goBack,
          showFlags
        }
      )
    }
  );
};
