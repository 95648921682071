"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, FormControl, IconButton, Stack } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { Typography } from "@v2/components/typography/typography.component";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { DEBOUNCE_500_MS } from "@v2/feature/documents/documents.util";
import { DEFAULT_CURRENCY } from "@v2/feature/payments/payments.interface";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import { round2Digits } from "@v2/util/number.util";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { CompensationAPI, CompensationEndpoints } from "@/api-client/compensation.api";
import { UserContractEndpoints } from "@/api-client/contracts.api";
import { getUserCompensationSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/Mistake.svg";
import { displayRateLabel, PaySchedules, SalaryBasis } from "@/lib/employment";
import { nestErrorMessage } from "@/lib/errors";
import { Divider } from "@/v2/components/divider.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CurrencyTextField } from "@/v2/components/forms/currency-text-field.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { payScheduleUnit, salaryBasisQuantity } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import {
  PayScheduleEnum,
  SalaryBasisEnum
} from "@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import {
  drawerContentSx,
  editDeleteHeaderSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { toTitleCase } from "@/v2/util/string.util";
const deleteIconSize = { width: 14, height: 14 };
export const originalValueOrUndefined = (realVal) => {
  if (typeof realVal === "number" && realVal > 0) return realVal;
  else return void 0;
};
export const CompensationForm = ({
  initialValues,
  userId,
  getUserCompensation,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  usedForDataImport = false,
  importHandler = () => {
  },
  showEmployee = false
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  const { polyglot } = usePolyglot();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [rowForDeletion, setRowForDeletion] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMessage] = useMessage();
  const { data: userFtePercentage } = useApiClient(CompensationEndpoints.getFTEPercentageForUser(userId), {
    suspense: false
  });
  const { data: currentContract } = useApiClient(UserContractEndpoints.findCurrentByUserId(userId), {
    suspense: false
  });
  const [compensationBreakdown, setCompensationBreakdown] = useState(null);
  const availableUserFtePercentage = useMemo(() => {
    if (userFtePercentage && !isNaN(userFtePercentage)) return userFtePercentage;
    if ((initialValues == null ? void 0 : initialValues.userFteFromImport) && !isNaN(initialValues == null ? void 0 : initialValues.userFteFromImport))
      return initialValues.userFteFromImport / 100;
    return 0;
  }, [initialValues == null ? void 0 : initialValues.userFteFromImport, userFtePercentage]);
  const initialFormikValues = useMemo(
    () => {
      var _a2, _b2;
      return initialValues != null ? initialValues : {
        id: 0,
        effectiveDate: new LocalDate().toDateString(),
        paySchedule: PayScheduleEnum.Monthly,
        salaryBasis: SalaryBasisEnum.Annual,
        annualSalary: 0,
        salaryPerPaySchedule: 0,
        rate: null,
        units: 0,
        currency: (_b2 = (_a2 = currentContract == null ? void 0 : currentContract.entity) == null ? void 0 : _a2.currency) != null ? _b2 : "GBP",
        prorateSalaryByFte: false,
        proratedRate: 0,
        nonProratedRate: 0,
        userFteFromImport: 0,
        customUpdates: []
      };
    },
    [initialValues, currentContract]
  );
  const initialCompensationValues = __spreadProps(__spreadValues({}, initialFormikValues), {
    units: initialFormikValues.salaryBasis && [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(initialFormikValues.salaryBasis) ? initialFormikValues.units : 0,
    rate: initialFormikValues.prorateSalaryByFte ? (_a = initialFormikValues.nonProratedRate) != null ? _a : 0 : initialFormikValues.rate,
    currency: (_b = initialFormikValues.currency) != null ? _b : DEFAULT_CURRENCY
  });
  const formik = useFormik({
    initialValues: __spreadProps(__spreadValues({}, initialCompensationValues), {
      nonProratedRate: (_c = initialFormikValues.nonProratedRate) != null ? _c : void 0,
      proratedRate: ((_d = initialFormikValues.proratedRate) != null ? _d : initialFormikValues.prorateSalaryByFte) ? (_e = initialFormikValues.rate) != null ? _e : void 0 : void 0,
      annualSalary: 0,
      // this will be updated in the useEffect
      salaryPerPaySchedule: 0
      // this will be updated in the useEffect
    }),
    enableReinitialize: true,
    validationSchema: getUserCompensationSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2, _b2;
      try {
        setLoading(true);
        if (!usedForDataImport) {
          const updatedValues = __spreadProps(__spreadValues({}, values), {
            salaryPerPaySchedule: values.salaryPerPaySchedule ? Number(values.salaryPerPaySchedule) : 0,
            annualSalary: values.annualSalary ? Number(values.annualSalary) : 0,
            units: [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(values.salaryBasis) ? values.units : 0,
            rate: formik.values.prorateSalaryByFte ? (_a2 = values.proratedRate) != null ? _a2 : 0 : values.rate,
            nonProratedRate: (_b2 = values.rate) != null ? _b2 : void 0
          });
          delete updatedValues.proratedRate;
          if (rowModalMode === "edit" && updatedValues.id && updatedValues.id > 0) {
            yield CompensationAPI.updateById(userId, updatedValues);
          } else {
            yield CompensationAPI.create(userId, updatedValues);
          }
          showMessage(polyglot.t("CompensationForm.successMessages.save"), "success");
          yield getUserCompensation();
          setIsModalOpen(false);
        } else {
          importHandler == null ? void 0 : importHandler(values);
        }
      } catch (error) {
        showMessage(polyglot.t("CompensationForm.errorMessages.save", { msg: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
        setTimeout(() => formik.resetForm(), 1500);
      }
    })
  });
  const getCalculatedSalaryRates = useCallback(
    (compensation) => __async(void 0, null, function* () {
      var _a2;
      try {
        return CompensationAPI.calculateSalaryFixedRates(userId, __spreadProps(__spreadValues({}, compensation), {
          currency: (_a2 = formik.values.currency) != null ? _a2 : DEFAULT_CURRENCY
        }));
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
        return null;
      }
    }),
    [showMessage, polyglot, userId, formik.values.currency]
  );
  const debouncedCall = useCallback(() => __async(void 0, null, function* () {
    if (!getCalculatedSalaryRates || !formik.values.rate || !formik.values.paySchedule || !formik.values.salaryBasis)
      return;
    getCalculatedSalaryRates({
      rate: formik.values.rate,
      paySchedule: formik.values.paySchedule,
      salaryBasis: formik.values.salaryBasis,
      units: formik.values.units
    }).then((compBreakdown) => {
      var _a2, _b2;
      setCompensationBreakdown(compBreakdown);
      formik.setFieldValue("annualSalary", (_a2 = compBreakdown == null ? void 0 : compBreakdown.fixedRates.annual) != null ? _a2 : "");
      formik.setFieldValue("salaryPerPaySchedule", (_b2 = compBreakdown == null ? void 0 : compBreakdown.payScheduleRate) != null ? _b2 : "");
    }).catch((error) => {
      setCompensationBreakdown(null);
      showMessage(nestErrorMessage(error, polyglot), "error");
    });
  }), [
    showMessage,
    polyglot,
    getCalculatedSalaryRates,
    formik.values.rate,
    formik.values.paySchedule,
    formik.values.salaryBasis,
    formik.values.units
  ]);
  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedCall();
    }, DEBOUNCE_500_MS);
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedCall]);
  const handleDeleteRow = () => __async(void 0, null, function* () {
    try {
      if (rowForDeletion) yield CompensationAPI.deleteById(userId, rowForDeletion);
      showMessage(polyglot.t("CompensationForm.successMessages.delete"), "success");
    } catch (error) {
      showMessage(polyglot.t("CompensationForm.errorMessages.delete", { msg: nestErrorMessage(error) }), "error");
    } finally {
      yield getUserCompensation();
      handleSubmit(userId);
      setRowForDeletion(0);
      setFormCurrentlyEditing(false);
      formik.resetForm();
      setIsModalOpen(false);
      setIsRemovalModalOpen(false);
    }
  });
  const deleteCompensationRow = (rowId) => {
    setIsRemovalModalOpen(true);
    setRowForDeletion(rowId);
  };
  const onChangeRate = useCallback(
    (objToCheck) => {
      if (objToCheck) {
        const { rate, prorateSalaryByFte } = objToCheck;
        const rateValue = rate != null ? rate : 0;
        const proratedRate = prorateSalaryByFte && availableUserFtePercentage ? availableUserFtePercentage * rateValue : rateValue;
        formik.setFieldValue("rate", rate, true);
        formik.setFieldValue("proratedRate", originalValueOrUndefined(proratedRate), true);
      }
    },
    [availableUserFtePercentage, formik]
  );
  const proratedSuffix = useMemo(() => {
    return formik.values.prorateSalaryByFte && availableUserFtePercentage ? `- ${polyglot.t("CompensationCard.proRated")}`.concat(" (" + availableUserFtePercentage * 100 + "%)") : "";
  }, [formik.values.prorateSalaryByFte, availableUserFtePercentage, polyglot]);
  const createSalaryRateTooltip = useCallback(() => {
    if (!compensationBreakdown) return null;
    return /* @__PURE__ */ jsxs(Stack, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: compensationBreakdown.scheduleName ? `The rate is calculated based upon the employee's currently effective attendance schedule (${compensationBreakdown.scheduleName}).` : `The rate is calculated based upon the company default working schedule.` }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: `Days per week: ${round2Digits(
        compensationBreakdown.workingUnits.daysInWeek
      )}` }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: `Hours per day: ${round2Digits(
        compensationBreakdown.workingUnits.hoursInDay
      )}` })
    ] });
  }, [compensationBreakdown]);
  return /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
    /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
      rowModalMode === "add" ? /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("CompensationForm.new") }) : /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, editDeleteHeaderSx), { display: "flex", justifyContent: "space-between", width: "100%" }), children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("CompensationForm.edit") }),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: iconCTAButtonSx,
            onClick: (event) => {
              var _a2, _b2;
              setAnchorEl(event.currentTarget);
              setIsRemovalModalOpen(true);
              if ((_a2 = formik == null ? void 0 : formik.values) == null ? void 0 : _a2.id) deleteCompensationRow((_b2 = formik == null ? void 0 : formik.values) == null ? void 0 : _b2.id);
            },
            children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, deleteIconSize))
          }
        )
      ] }),
      showEmployee && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("CompensationForm.employee") }),
        /* @__PURE__ */ jsx(UserCell, { userId, nameVariant: "title4" })
      ] }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "compensation.effectiveDate", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_f = formik.values.effectiveDate) != null ? _f : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("effectiveDate", value);
            }
          },
          name: "effectiveDate",
          label: polyglot.t("CompensationForm.effectiveDate"),
          error: !!formik.errors.effectiveDate && formik.touched.effectiveDate,
          helperText: formik.errors.effectiveDate && formik.touched.effectiveDate
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "compensation.paySchedule", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "paySchedule",
          label: polyglot.t("CompensationForm.paySchedule"),
          options: PaySchedules(polyglot),
          value: formik.values.paySchedule,
          compareValue: formik.values.paySchedule,
          error: !!formik.errors.paySchedule && formik.touched.paySchedule,
          onChange: formik.handleChange,
          helperText: formik.errors.paySchedule && formik.touched.paySchedule
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "compensation.salaryBasis", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "salaryBasis",
          label: polyglot.t("CompensationForm.salaryBasis"),
          options: SalaryBasis(polyglot),
          value: formik.values.salaryBasis,
          compareValue: formik.values.salaryBasis,
          error: !!formik.errors.salaryBasis && formik.touched.salaryBasis,
          onChange: formik.handleChange,
          helperText: formik.errors.salaryBasis && formik.touched.salaryBasis
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "compensation.rate", children: /* @__PURE__ */ jsx(
        CurrencyTextField,
        {
          name: "rate",
          label: `${displayRateLabel(formik.values.salaryBasis, polyglot)} ${formik.values.prorateSalaryByFte ? "(100%)" : ""}`,
          value: formik.values.rate,
          onCurrencyChange: (value) => {
            formik.setFieldValue("currency", value);
          },
          selectedCurrency: formik.values.currency,
          onChange: (value) => {
            onChangeRate(__spreadProps(__spreadValues({}, formik.values), {
              rate: value ? Number(value) : null
            }));
          },
          error: formik.touched.rate && !!formik.errors.rate,
          helperText: formik.touched.rate && formik.errors.rate || ""
        }
      ) }),
      /* @__PURE__ */ jsxs(ProfileField, { fieldStub: "compensation.rate", sx: { minHeight: "unset" }, children: [
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("CompensationForm.prorateSalaryByFte"),
            name: "prorateSalaryByFte",
            checked: formik.values.prorateSalaryByFte,
            onChange: (_, value) => {
              formik.setFieldValue("prorateSalaryByFte", value);
              onChangeRate(__spreadProps(__spreadValues({}, formik.values), {
                prorateSalaryByFte: value
              }));
            },
            disabled: !formik.values.prorateSalaryByFte && !(currentContract == null ? void 0 : currentContract.attendanceSchedule)
          }
        ),
        formik.values.prorateSalaryByFte && (currentContract == null ? void 0 : currentContract.attendanceSchedule) ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("CompensationForm.prorateSalaryByFteDescription", {
          percentage: currentContract.attendanceSchedule.ftePercent,
          workHours: convertMinutesToClockHours(currentContract.attendanceSchedule.totalTime, polyglot),
          fteHours: convertMinutesToClockHours(currentContract.attendanceSchedule.fteEquivalent, polyglot)
        }) }) : formik.values.prorateSalaryByFte && !(currentContract == null ? void 0 : currentContract.attendanceSchedule) ? /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "RedDark", children: polyglot.t("CompensationForm.noScheduleMessage") }) : null
      ] }),
      /* @__PURE__ */ jsx(
        CustomFieldComponents,
        {
          values: formik.values.customUpdates,
          onChange: (values) => formik.setFieldValue("customUpdates", values),
          rowModalMode,
          formName: CustomProfileFormType.Salary
        }
      ),
      formik.values.paySchedule && ((_g = formik.values) == null ? void 0 : _g.salaryBasis) && [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes((_h = formik.values) == null ? void 0 : _h.salaryBasis) && /* @__PURE__ */ jsx(ProfileField, { fieldStub: "compensation.units", children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "units",
          label: `${toTitleCase(salaryBasisQuantity(formik.values.salaryBasis))} per ${payScheduleUnit(
            formik.values.paySchedule
          )}`,
          value: formik.values.units,
          type: "number",
          onChange: formik.handleChange,
          error: formik.touched.units && !!formik.errors.units,
          helperText: (_i = formik.touched.units && formik.errors.units) != null ? _i : " ",
          clearText: () => formik.setFieldValue("units", "")
        }
      ) }),
      /* @__PURE__ */ jsx(Divider, { style: { margin: "10px 0" } }),
      formik.values.paySchedule && /* @__PURE__ */ jsx(ProfileField, { fieldStub: "compensation.rate", children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "salaryPerPaySchedule",
          label: `${polyglot.t("CompensationForm.salaryPerPaySchedule1")} ${payScheduleUnit(
            formik.values.paySchedule
          )} ${polyglot.t("CompensationForm.salaryPerPaySchedule2")} ${proratedSuffix}`,
          value: round2Digits((_j = formik.values.salaryPerPaySchedule) != null ? _j : 0),
          type: "number",
          error: formik.touched.salaryPerPaySchedule && !!formik.errors.salaryPerPaySchedule,
          helperText: (_k = formik.touched.salaryPerPaySchedule && formik.errors.salaryPerPaySchedule) != null ? _k : " ",
          disabled: true
        }
      ) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "compensation.rate", children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "annualSalary",
          label: `${polyglot.t("CompensationForm.annualSalaryGross")} ${proratedSuffix}`,
          value: round2Digits(
            formik.values.annualSalary && !Number.isNaN(formik.values.annualSalary) ? formik.values.annualSalary : 0
          ),
          type: "number",
          disabled: true,
          error: formik.touched.annualSalary && !!formik.errors.annualSalary,
          helperText: (_l = formik.touched.annualSalary && formik.errors.annualSalary) != null ? _l : " "
        }
      ) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "compensation.rate", children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            "Daily rate (Gross)",
            /* @__PURE__ */ jsx(StyledTooltip, { title: createSalaryRateTooltip(), placement: "top", children: /* @__PURE__ */ jsx(MistakeIcon, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.GreyMiddle })) })
          ] }),
          value: round2Digits((_m = compensationBreakdown == null ? void 0 : compensationBreakdown.fixedRates.daily) != null ? _m : 0),
          type: "number",
          disabled: true
        }
      ) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "compensation.rate", children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            "Hourly rate (Gross)",
            /* @__PURE__ */ jsx(StyledTooltip, { title: createSalaryRateTooltip(), placement: "top", children: /* @__PURE__ */ jsx(MistakeIcon, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.GreyMiddle })) })
          ] }),
          value: round2Digits((_n = compensationBreakdown == null ? void 0 : compensationBreakdown.fixedRates.hourly) != null ? _n : 0),
          type: "number",
          disabled: true
        }
      ) }),
      /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "secondary",
            onClick: () => {
              formik.resetForm();
              onClose();
            },
            children: polyglot.t("General.cancel")
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => {
          setIsRemovalModalOpen(false);
          setRowForDeletion(0);
        },
        anchorEl,
        takeAction: handleDeleteRow,
        message: polyglot.t("CompensationForm.confirmDelete"),
        callToAction: polyglot.t("CompensationForm.callToAction")
      }
    )
  ] });
};
