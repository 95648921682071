"use strict";
import { getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import { isSameDay } from "date-fns";
export const getWeekRangeString = (weekStart) => {
  const mondayDate = new Date(weekStart);
  const sundayDate = new Date(weekStart);
  sundayDate.setDate(sundayDate.getDate() + 6);
  const monday = mondayDate.toLocaleDateString(void 0, { day: "2-digit", month: "short" });
  const sunday = sundayDate.toLocaleDateString(void 0, { day: "2-digit", month: "short" });
  return `${monday} - ${sunday}`;
};
export const getMondayOfCurrentWeek = () => {
  const date = /* @__PURE__ */ new Date();
  const day = date.getDay(), diff = date.getDate() - day + (day === 0 ? -6 : 1);
  date.setDate(diff);
  return date;
};
export const getWorkTimeFromScheduleByDay = (attendanceSchedule, day) => {
  const dayEntries = attendanceSchedule[day];
  if (!dayEntries || !dayEntries[0]) return null;
  const dayEntry = dayEntries[0];
  return `${dayEntry.from.slice(11, 16)} - ${dayEntry.to.slice(11, 16)}`;
};
export const getWorkTimeRangeFromScheduleByDay = (attendanceSchedule, day, date) => {
  const dayEntries = attendanceSchedule[day];
  const weekIndex = getIndexOfWeekInSchedule(
    attendanceSchedule.startDateOfFirstWeek,
    date,
    attendanceSchedule.noOfWeeks
  );
  if (!dayEntries || !dayEntries[weekIndex]) return null;
  const dayEntry = dayEntries[weekIndex];
  return [dayEntry.from.slice(11, 16), dayEntry.to.slice(11, 16)];
};
export const getBreakScheduleByDay = (attendanceSchedule, day, date, timeOnly = false) => {
  var _a;
  const dayEntries = attendanceSchedule[day];
  const weekIndex = getIndexOfWeekInSchedule(
    attendanceSchedule.startDateOfFirstWeek,
    date,
    attendanceSchedule.noOfWeeks
  );
  if (!dayEntries || !((_a = dayEntries[weekIndex]) == null ? void 0 : _a.break)) return null;
  const dayEntry = dayEntries[weekIndex];
  const [h, m] = dayEntry.break.slice(11, 16).split(":");
  const hours = Number(h);
  const minutes = Number(m);
  if (hours && !minutes) return timeOnly ? `${hours}h` : `${hours}h break`;
  if (!hours && minutes) return timeOnly ? `${minutes}min` : `${minutes}min break`;
  return timeOnly ? `${hours}h${minutes}min` : `${hours}h${minutes}min break`;
};
export const getAttendanceEntry = (currentWeekAttendance, currentDate) => {
  if (currentWeekAttendance && currentWeekAttendance.length === 0) return void 0;
  return currentWeekAttendance.find(
    (a) => isSameDay(currentDate, new Date(a.logDate)) && a.attendanceEntries.length > 0
  );
};
export const calculateLoggedHourByType = (attendanceEntriesLookup, attendanceTypes) => {
  var _a;
  const result = {};
  for (const type of attendanceTypes) {
    result[type.name] = 0;
  }
  for (const typeId in attendanceEntriesLookup) {
    if (Object.prototype.hasOwnProperty.call(attendanceEntriesLookup, typeId)) {
      const entries = attendanceEntriesLookup[typeId];
      const totalLength = entries.reduce((accumulator, current) => accumulator + current.length, 0);
      const typeName = (_a = attendanceTypes.find((type) => type.id === Number(typeId))) == null ? void 0 : _a.name;
      if (typeName) {
        result[typeName] = totalLength;
      }
    }
  }
  return result;
};
export const calculateSummary = (attendanceEntriesLookup, attendanceTypes, date) => {
  let result = {
    break: 0,
    regular: ["", ""],
    startIsTomorrow: false,
    // sort this for case when they are late to a shift
    endIsTomorrow: false
  };
  attendanceEntriesLookup.forEach((entry) => {
    const type = attendanceTypes.find((type2) => type2.id === entry.typeId);
    if (type && type.name.toLowerCase() === "break") {
      result.break += entry.length;
    }
  });
  const regularEntries = attendanceEntriesLookup.filter((entry) => {
    const type = attendanceTypes.find((type2) => type2.id === entry.typeId);
    return type && type.name.toLowerCase() === "regular";
  });
  if (regularEntries.length > 0 && date) {
    regularEntries.sort((a, b) => new Date(a.startHourTimestamp).getTime() - new Date(b.startHourTimestamp).getTime());
    const first = regularEntries[0];
    result.regular[0] = first.startHour.slice(11, 16);
    const last = regularEntries[regularEntries.length - 1];
    result.regular[1] = last.endHour.slice(11, 16);
    if ((first == null ? void 0 : first.startHour) && new Date(first.startHour).getDate() !== date.getDate()) result.startIsTomorrow = true;
    if ((last == null ? void 0 : last.endHour) && new Date(last.endHour).getDate() !== date.getDate()) result.endIsTomorrow = true;
  }
  return result;
};
export const getDayWithDate = (weekDate, day) => {
  const date = new Date(weekDate);
  switch (day) {
    case "monday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -6 : 1);
      date.setDate(diff);
      return `Mon ${date.getDate()}`;
    }
    case "tuesday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -5 : 2);
      date.setDate(diff);
      return `Tue ${date.getDate()}`;
    }
    case "wednesday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -4 : 3);
      date.setDate(diff);
      return `Wed ${date.getDate()}`;
    }
    case "thursday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -3 : 4);
      date.setDate(diff);
      return `Thu ${date.getDate()}`;
    }
    case "friday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -2 : 5);
      date.setDate(diff);
      return `Fri ${date.getDate()}`;
    }
    case "saturday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -1 : 6);
      date.setDate(diff);
      return `Sat ${date.getDate()}`;
    }
    case "sunday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? 0 : 7);
      date.setDate(diff);
      return `Sun ${date.getDate()}`;
    }
    default: {
      return day;
    }
  }
};
export const getDayWithDateArray = (weekDate, day) => {
  const date = new Date(weekDate);
  switch (day) {
    case "monday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -6 : 1);
      date.setDate(diff);
      return ["Mon", date.getDate()];
    }
    case "tuesday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -5 : 2);
      date.setDate(diff);
      return ["Tue", date.getDate()];
    }
    case "wednesday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -4 : 3);
      date.setDate(diff);
      return ["Wed", date.getDate()];
    }
    case "thursday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -3 : 4);
      date.setDate(diff);
      return ["Thu", date.getDate()];
    }
    case "friday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -2 : 5);
      date.setDate(diff);
      return ["Fri", date.getDate()];
    }
    case "saturday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -1 : 6);
      date.setDate(diff);
      return ["Sat", date.getDate()];
    }
    case "sunday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? 0 : 7);
      date.setDate(diff);
      return ["Sun", date.getDate()];
    }
    default: {
      return day;
    }
  }
};
const formatTime = (time) => {
  const hours = Math.floor(time);
  const minutes = (time - hours) * 60;
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = Math.round(minutes).toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};
export const calculateHourDifference = (start, end, breakTime) => {
  const startTime = /* @__PURE__ */ new Date(`2000-01-01T${start}:00`);
  const endTime = /* @__PURE__ */ new Date(`2000-01-01T${end}:00`);
  const timeDifferenceInMillis = endTime.getTime() - startTime.getTime();
  const hourDifference = timeDifferenceInMillis / (1e3 * 60 * 60);
  const totalHours = breakTime ? hourDifference - 1 : hourDifference;
  return formatTime(totalHours);
};
