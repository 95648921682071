"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { AttendanceAPI, AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { getScheduleValidationSchemaV2, getTheDateOfFirstMondayInYear } from "@v2/feature/attendance/attendance.util";
import { AttendancePatternScheduleStartDateDrawer } from "@v2/feature/attendance/settings/components/attendance-pattern-schedule-start-date-drawer.component";
import { AttendanceScheduleWeekPatternForm } from "@v2/feature/attendance/settings/components/attendance-schedule-week-pattern-form.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE } from "@/lib/routes";
import { RootStyle } from "@/v2/styles/root.styles";
export const AttendanceSettingsSchedulePatternPage = () => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const params = useParams();
  const scheduleId = Number(params.scheduleId);
  const [isSetDateOpen, setIsSetDateOpen] = useState(false);
  const {
    data: schedule,
    mutate: refreshAttendanceSchedule,
    isLoading
  } = useApiClient(AttendanceEndpoints.getAttendanceScheduleById(scheduleId), { suspense: false });
  const history = useHistory();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [patternError, setPatternError] = useState(false);
  const refreshSchedule = useCallback(() => __async(void 0, null, function* () {
    if (refreshAttendanceSchedule) yield refreshAttendanceSchedule();
  }), [refreshAttendanceSchedule]);
  const formik = useFormik({
    initialValues: {
      monday: (_a = schedule == null ? void 0 : schedule.monday) != null ? _a : [],
      tuesday: (_b = schedule == null ? void 0 : schedule.tuesday) != null ? _b : [],
      wednesday: (_c = schedule == null ? void 0 : schedule.wednesday) != null ? _c : [],
      thursday: (_d = schedule == null ? void 0 : schedule.thursday) != null ? _d : [],
      friday: (_e = schedule == null ? void 0 : schedule.friday) != null ? _e : [],
      saturday: (_f = schedule == null ? void 0 : schedule.saturday) != null ? _f : [],
      sunday: (_g = schedule == null ? void 0 : schedule.sunday) != null ? _g : [],
      noOfWeeks: (_h = schedule == null ? void 0 : schedule.noOfWeeks) != null ? _h : 0,
      startDateOfFirstWeek: (_i = schedule == null ? void 0 : schedule.startDateOfFirstWeek) != null ? _i : getTheDateOfFirstMondayInYear()
    },
    validationSchema: getScheduleValidationSchemaV2(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      if (!schedule) return;
      setLoading(true);
      try {
        yield AttendanceAPI.updateAttendanceSchedulePattern(schedule.id, values);
        yield refreshSchedule();
        history.push(generatePath(SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE, { scheduleId: schedule.id }));
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setLoading(false);
    })
  });
  const weeksNumbers = useMemo(() => {
    if (!formik.values.noOfWeeks) return [];
    const weeks = [];
    for (let i = 0; i < formik.values.noOfWeeks; i += 1) weeks.push(i);
    return weeks;
  }, [formik.values.noOfWeeks]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AttendanceModule.defineSchedulePatternTitle") }),
          /* @__PURE__ */ jsx(Typography, { variant: "bodyLarge", children: (_j = schedule == null ? void 0 : schedule.name) != null ? _j : "" })
        ] }),
        showBack: true,
        backPath: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE, { scheduleId })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, sx: spacing.pt20, children: schedule && /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: "40px", mb: "20px" }, children: weeksNumbers.map((weekIndex) => {
        return /* @__PURE__ */ jsx(
          AttendanceScheduleWeekPatternForm,
          {
            formik,
            weekNo: weekIndex,
            schedule,
            noOfWeeks: weeksNumbers.length
          }
        );
      }) }),
      patternError && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mb: "10px" }, color: "Red", children: polyglot.t("AttendanceCompanySettingsAttendanceSchedulesPage.invalidPatternMessage") }),
      /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "200px" }, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading,
          colorVariant: "primary",
          name: formik.values.noOfWeeks > 1 ? polyglot.t("General.continue") : polyglot.t("General.save"),
          fullWidth: true,
          onClick: () => {
            let invalidRecords = false;
            let allWeeksAreEmpty = false;
            if (!allRecordsAreValid(formik.values)) invalidRecords = true;
            const emptyWeeks = getEmptyWeeksIndexes(formik.values);
            if (emptyWeeks.length >= formik.values.noOfWeeks) allWeeksAreEmpty = true;
            if (allWeeksAreEmpty || invalidRecords) {
              setPatternError(true);
              return;
            } else {
              setPatternError(false);
            }
            if (formik.values.noOfWeeks > 1) setIsSetDateOpen(true);
            else formik.handleSubmit();
          }
        }
      ) }),
      /* @__PURE__ */ jsx(
        AttendancePatternScheduleStartDateDrawer,
        {
          isOpen: isSetDateOpen,
          setIsOpen: setIsSetDateOpen,
          formik,
          loading
        }
      )
    ] }) }) })
  ] });
};
const getEmptyWeeksIndexes = (data) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  const emptyWeeks = [];
  for (let i = 0; i < data.noOfWeeks; i += 1) {
    if ((!((_a = data.monday[i]) == null ? void 0 : _a.from) || !((_b = data.monday[i]) == null ? void 0 : _b.to)) && (!((_c = data.tuesday[i]) == null ? void 0 : _c.from) || !((_d = data.tuesday[i]) == null ? void 0 : _d.to)) && (!((_e = data.wednesday[i]) == null ? void 0 : _e.from) || !((_f = data.wednesday[i]) == null ? void 0 : _f.to)) && (!((_g = data.thursday[i]) == null ? void 0 : _g.from) || !((_h = data.thursday[i]) == null ? void 0 : _h.to)) && (!((_i = data.friday[i]) == null ? void 0 : _i.from) || !((_j = data.friday[i]) == null ? void 0 : _j.to)) && (!((_k = data.saturday[i]) == null ? void 0 : _k.from) || !((_l = data.saturday[i]) == null ? void 0 : _l.to)) && (!((_m = data.sunday[i]) == null ? void 0 : _m.from) || !((_n = data.sunday[i]) == null ? void 0 : _n.to))) {
      emptyWeeks.push(i);
    }
  }
  return emptyWeeks;
};
const allRecordsAreValid = (data) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  for (let i = 0; i < data.noOfWeeks; i += 1) {
    if (data.monday[i] && (!((_a = data.monday[i]) == null ? void 0 : _a.from) || !((_b = data.monday[i]) == null ? void 0 : _b.to)) || data.tuesday[i] && (!((_c = data.tuesday[i]) == null ? void 0 : _c.from) || !((_d = data.tuesday[i]) == null ? void 0 : _d.to)) || data.wednesday[i] && (!((_e = data.wednesday[i]) == null ? void 0 : _e.from) || !((_f = data.wednesday[i]) == null ? void 0 : _f.to)) || data.thursday[i] && (!((_g = data.thursday[i]) == null ? void 0 : _g.from) || !((_h = data.thursday[i]) == null ? void 0 : _h.to)) || data.friday[i] && (!((_i = data.friday[i]) == null ? void 0 : _i.from) || !((_j = data.friday[i]) == null ? void 0 : _j.to)) || data.saturday[i] && (!((_k = data.saturday[i]) == null ? void 0 : _k.from) || !((_l = data.saturday[i]) == null ? void 0 : _l.to)) || data.sunday[i] && (!((_m = data.sunday[i]) == null ? void 0 : _m.from) || !((_n = data.sunday[i]) == null ? void 0 : _n.to)))
      return false;
  }
  return true;
};
