"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { ContractorInvoiceModal } from "@v2/feature/payments/pages/components/contractor-invoice-modal.component";
import { iconCTAButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ContractorInvoiceAPI } from "@/api-client/contractor-invoice-api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as DownloadIcon } from "@/images/icons/download-icon.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Document.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { nestErrorMessage } from "@/lib/errors";
export const PaymentInvoicePreview = ({ invoice, titleSx }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMessage] = useMessage();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { getScopesContext, hasScopes } = useScopes();
  const currentUserIsAdmin = hasScopes(["invoices:all"], getScopesContext(user));
  const invoiceFileName = `Invoice_${invoice.fromUser.firstName}${invoice.fromUser.lastName}_${invoice.invoiceNumber}`;
  const handleDownloadClick = (invoiceId) => __async(void 0, null, function* () {
    try {
      const PDFBlob = yield ContractorInvoiceAPI.downloadInvoice({ invoiceId });
      const file = new Blob([PDFBlob], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      let link = document.createElement("a");
      link.download = `${invoiceFileName}.pdf`;
      link.href = fileURL;
      link.click();
    } catch (e) {
      console.error("::Download error", e);
      showMessage(`Failed to download contractor invoice. ${nestErrorMessage(e)}`, "error");
    }
  });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: titleSx, children: "Invoice" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", width: 1 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: invoiceFileName }) }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.gap5 }, children: [
        /* @__PURE__ */ jsx(IconButton, { sx: iconCTAButtonSx, onClick: () => handleDownloadClick(invoice.id), children: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize)) }),
        /* @__PURE__ */ jsxs(IconButton, { sx: iconCTAButtonSx, onClick: () => setIsModalOpen(true), children: [
          /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)),
          isModalOpen && /* @__PURE__ */ jsx(
            ContractorInvoiceModal,
            {
              isOpen: isModalOpen,
              setIsOpen: setIsModalOpen,
              selectedInvoice: invoice,
              onActionPerformed: () => __async(void 0, null, function* () {
              }),
              onClose: () => {
                setIsModalOpen(false);
              },
              currentUserIsAdmin
            }
          )
        ] })
      ] })
    ] })
  ] });
};
