"use strict";
export var EntityImportWizardData = /* @__PURE__ */ ((EntityImportWizardData2) => {
  EntityImportWizardData2["Users"] = "users";
  EntityImportWizardData2["Absences"] = "absences";
  EntityImportWizardData2["AbsenceAdjustments"] = "absenceAdjustments";
  EntityImportWizardData2["Payroll"] = "payroll";
  EntityImportWizardData2["Devices"] = "devices";
  EntityImportWizardData2["Attendances"] = "attendances";
  return EntityImportWizardData2;
})(EntityImportWizardData || {});
export var EntityImportWizardSource = /* @__PURE__ */ ((EntityImportWizardSource2) => {
  EntityImportWizardSource2["CSV"] = "csv";
  EntityImportWizardSource2["Google"] = "google";
  EntityImportWizardSource2["AzureAD"] = "azureAD";
  EntityImportWizardSource2["List"] = "list";
  EntityImportWizardSource2["Xero"] = "xero";
  EntityImportWizardSource2["Quickbooks"] = "quickbooks";
  return EntityImportWizardSource2;
})(EntityImportWizardSource || {});
const csvSourceOption = {
  label: "CSV Import",
  value: "csv" /* CSV */
};
const googleSourceOption = {
  label: "Google Workspace",
  value: "google" /* Google */
};
const azureADSourceOption = {
  label: "Azure AD",
  value: "azureAD" /* AzureAD */
};
export const EntityImportWizardSourceOptions = {
  ["users" /* Users */]: [azureADSourceOption, googleSourceOption, csvSourceOption],
  ["absences" /* Absences */]: [csvSourceOption],
  ["absenceAdjustments" /* AbsenceAdjustments */]: [csvSourceOption],
  ["payroll" /* Payroll */]: [csvSourceOption],
  ["devices" /* Devices */]: [csvSourceOption],
  ["attendances" /* Attendances */]: [csvSourceOption]
};
