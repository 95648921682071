"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { ScheduleTrackingType } from "@v2/feature/attendance/attendance.interface";
import { ScheduleSettingsForm } from "@v2/feature/attendance/company/components/form/schedule-settings-form.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const AttendanceScheduleEditSettingsDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  refresh,
  sites
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    AttendanceScheduleEditSettingsDrawerContent,
    {
      attendanceSchedule,
      refresh,
      setIsOpen,
      sites
    }
  ) });
};
const AttendanceScheduleEditSettingsDrawerContent = ({
  attendanceSchedule,
  refresh,
  setIsOpen,
  sites
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const attendanceTypesAllowedIds = useMemo(() => attendanceSchedule.attendanceTypesAllowed.map((r) => r.id), [
    attendanceSchedule
  ]);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const useGeolocation = values.trackingType === ScheduleTrackingType.ClockInClockOut ? Boolean(values.useGeolocation) : false;
        const restrictByGeolocation = Boolean(useGeolocation && values.restrictByGeolocation);
        const allowedWorkSites = restrictByGeolocation ? values.allowedWorkSites : [];
        const geolocationEmployeeSite = restrictByGeolocation ? values.geolocationEmployeeSite : false;
        const geolocationDistance = restrictByGeolocation ? values.geolocationDistance : null;
        const clockInEarlyCapMinutes = values.trackingType === ScheduleTrackingType.ClockInClockOut ? Number(values.clockInEarlyCapMinutes) : null;
        const update = {
          trackingType: values.trackingType,
          attendanceTypesAllowedIds: values.attendanceTypesAllowedIds,
          timesheetType: values.timesheetType,
          useGeolocation,
          restrictByGeolocation,
          geolocationDistance,
          geolocationEmployeeSite,
          allowedWorkSites,
          clockInEarlyCapMinutes
        };
        yield AttendanceAPI.updateAttendanceScheduleTrackingSettings(attendanceSchedule.id, update);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AttendanceScheduleEditSettingsDrawer.errorMessages.update", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [attendanceSchedule.id, showMessage, refresh, setIsOpen, polyglot]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "20px", height: "100%" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AttendanceSchedule.tracking") }),
    /* @__PURE__ */ jsx(
      ScheduleSettingsForm,
      {
        attendanceSchedule: __spreadProps(__spreadValues({}, attendanceSchedule), { attendanceTypesAllowedIds }),
        onSubmit,
        loading,
        buttonName: polyglot.t("General.save"),
        sites
      }
    )
  ] });
};
