"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ChipComponentWithIcon } from "@v2/components/chip/chip-with-icon.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DevicePossessionType } from "@v2/feature/device/device.interface";
import {
  getDeviceOwnerByDevicePossession,
  getDeviceOwnerByDevicePossessionTable
} from "@v2/feature/device/device.util";
import { DeviceActivityLogPage } from "@v2/feature/device/features/devices-company/pages/device-activity-log.page";
import { DeviceMdmDetailsPage } from "@v2/feature/device/features/devices-company/pages/device-mdm-details.page";
import { DeviceMdmSecurityPage } from "@v2/feature/device/features/devices-company/pages/device-mdm-security.page";
import { DeviceNotesPage } from "@v2/feature/device/features/devices-company/pages/device-notes.page";
import { NoAppliedPolicies } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { EnrollExistingDevice } from "@v2/feature/device/features/order-device/enroll-existing-device.page";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, Redirect, Switch, useParams } from "react-router-dom";
import { getModelImage } from "../../device.util";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE,
  DEVICES_ME_DEVICE_ACTIVITY_LOG_ROUTE,
  DEVICES_ME_DEVICE_DETAILS_ROUTE,
  DEVICES_ME_DEVICE_INSTALLED_APPS_ROUTE,
  DEVICES_ME_DEVICE_LOCATION_ROUTE,
  DEVICES_ME_DEVICE_NOTES_ROUTE,
  DEVICES_ME_DEVICE_POLICIES_ROUTE,
  DEVICES_ME_DEVICE_SECURITY_ROUTE,
  DEVICES_ME_DIRECTORY_ROUTE,
  DEVICES_ME_OVERVIEW_DEVICE_ENROLL_ROUTE,
  DEVICES_ME_OVERVIEW_DEVICE_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceActionsButton } from "@/v2/feature/device/components/device-details/device-actions-button.component";
import { DeviceInstalledAppsPage } from "@/v2/feature/device/features/devices-company/pages/device-installed-apps.page";
import { DeviceLocationPage } from "@/v2/feature/device/features/devices-company/pages/device-location.page";
import { DevicePoliciesPage } from "@/v2/feature/device/features/devices-company/pages/device-policies.page";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
const getDevicePageConfig = (devicePossessionId, device, currentUser, context) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: "Security",
          stub: "security",
          path: generatePath(DEVICES_ME_DEVICE_SECURITY_ROUTE, {
            devicePossessionId
          }),
          isHidden: context ? !checkScopes(currentUser, ["devices"], context) : true,
          hasChildren: false
        },
        {
          title: "Details",
          stub: "details",
          path: generatePath(DEVICES_ME_DEVICE_DETAILS_ROUTE, {
            devicePossessionId
          }),
          isHidden: context ? !checkScopes(currentUser, ["devices"], context) : true,
          hasChildren: false
        },
        {
          title: "Activity log",
          stub: "activity_log",
          path: generatePath(DEVICES_ME_DEVICE_ACTIVITY_LOG_ROUTE, {
            devicePossessionId
          }),
          isHidden: context ? !checkScopes(currentUser, ["devices"], context) : true,
          hasChildren: false
        },
        {
          title: "Apps",
          stub: "apps",
          path: generatePath(DEVICES_ME_DEVICE_INSTALLED_APPS_ROUTE, { devicePossessionId }),
          isHidden: (context ? checkScopes(currentUser, ["devices"], context) : true) && (!(device == null ? void 0 : device.applications) || device.applications.length === 0) && !((device == null ? void 0 : device.enrollmentStatus) === "enrolled" || (device == null ? void 0 : device.enrollmentStatus) === "ENROLMENT_FINISHED"),
          hasChildren: false
        },
        {
          title: "Notes",
          stub: "notes",
          path: generatePath(DEVICES_ME_DEVICE_NOTES_ROUTE, {
            devicePossessionId
          }),
          isHidden: context ? !checkScopes(currentUser, ["devices"], context) : true,
          hasChildren: false
        }
      ]
    }
  ];
};
export const MyDeviceDetailsRouter = ({ refreshDevicesAppData }) => {
  const params = useParams();
  const devicePossessionId = Number(params.devicePossessionId);
  const [devicePossession, setDevicePossession] = useState(void 0);
  const [appliedPolicies, setAppliedPolicies] = useState(NoAppliedPolicies);
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getCachedUserById } = useCachedUsers();
  const assignedUser = getCachedUserById(userId);
  const { getScopesContext } = useScopes();
  const [loading, setLoading] = useState(false);
  const [activeDeviceTransit, setActiveDeviceTransit] = useState(null);
  const [appliedZeltPolicies, setAppliedZeltPolicies] = useState(
    NoAppliedPolicies
  );
  const [
    appliedZeltPoliciesForMobile,
    setAppliedZeltPoliciesForMobile
  ] = useState(NoAppliedPolicies);
  const [configurableDevice, setConfigurableDevice] = useState(null);
  const scopesContext = (devicePossession == null ? void 0 : devicePossession.possessionType) === DevicePossessionType.User ? getScopesContext({ userId: devicePossession.possessionId }) : null;
  const { polyglot } = usePolyglot();
  const fetchDeviceDetails = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    try {
      const [
        configurableDevice2,
        retrievedDevicePossession,
        activeTransit,
        appliedPolicies2,
        zeltPolicies,
        zeltPoliciesForMobile
      ] = yield Promise.all([
        DeviceAPI.getDirectoryDeviceByPossessionId(devicePossessionId),
        DeviceAPI.getUserDevicePossessionDetails(userId, devicePossessionId),
        DeviceAPI.getActiveTransitForDevicePossession(devicePossessionId),
        DeviceAPI.getAppliedCompanyDevicePolicies(),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature(),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeatureForMobile()
      ]);
      setAppliedZeltPolicies(zeltPolicies);
      setAppliedZeltPoliciesForMobile(zeltPoliciesForMobile);
      setDevicePossession(retrievedDevicePossession);
      setActiveDeviceTransit(activeTransit);
      setAppliedPolicies(Boolean(appliedPolicies2) ? appliedPolicies2 : NoAppliedPolicies);
      setConfigurableDevice(configurableDevice2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage("Could not retrieve user device.", "error");
    }
  }), [devicePossessionId, userId]);
  useEffect(() => {
    fetchDeviceDetails();
  }, [fetchDeviceDetails]);
  const refreshDevice = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const configurableDevice2 = yield DeviceAPI.getDirectoryDeviceByPossessionId(devicePossessionId);
      setDevicePossession(configurableDevice2.devicePossession);
      setConfigurableDevice(configurableDevice2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
    }
  }), [devicePossessionId]);
  const customTitle = useMemo(() => {
    var _a, _b, _c, _d, _e, _f;
    const enrolled = ((_a = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _a.enrollmentStatus) === "enrolled" || ((_b = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _b.enrollmentStatus) === "ENROLMENT_FINISHED";
    const getUserName = () => {
      var _a2, _b2;
      return getDeviceOwnerByDevicePossession(
        devicePossession != null ? devicePossession : void 0,
        (_b2 = (_a2 = configurableDevice == null ? void 0 : configurableDevice.sites) == null ? void 0 : _a2.find((site) => site.id === (devicePossession == null ? void 0 : devicePossession.possessionId))) != null ? _b2 : null,
        getCachedUserById
      );
    };
    return /* @__PURE__ */ jsx(Box, { children: loading ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "120px",
        height: "70px",
        sx: { background: themeColors.Background, borderRadius: radius.br10 }
      }
    ) }) : /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: spacing.s2,
          boxSizing: "border-box"
        },
        children: [
          (_e = getModelImage((_c = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _c.type, (_d = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _d.modelName, {
            width: "120px",
            height: "120px"
          })) != null ? _e : /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "120px",
              height: "100%",
              sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
            }
          ),
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                gap: spacing.s1,
                boxSizing: "border-box"
              },
              children: [
                /* @__PURE__ */ jsx(
                  ChipComponentWithIcon,
                  {
                    name: enrolled ? "Enroled" : "Not enroled",
                    backgroundColor: "white",
                    textColor: "DarkGrey",
                    icon: enrolled ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : /* @__PURE__ */ jsx(Mistake, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
                    border: "light",
                    textVariant: "caption"
                  }
                ),
                devicePossession && devicePossession.possessionId && /* @__PURE__ */ jsx(Fragment, { children: devicePossession.possessionType === DevicePossessionType.User ? /* @__PURE__ */ jsx(
                  Box,
                  {
                    sx: {
                      width: "90%",
                      boxSizing: "border-box"
                    },
                    children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, justifyContent: "center" }, children: [
                      /* @__PURE__ */ jsx(
                        UserAvatar,
                        {
                          userId: devicePossession.possessionId,
                          size: "xxsmall"
                        },
                        devicePossession.possessionId
                      ),
                      /* @__PURE__ */ jsx(
                        Typography,
                        {
                          variant: "caption",
                          sx: {
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            width: "inherit"
                          },
                          children: getUserName()
                        }
                      )
                    ] })
                  }
                ) : getDeviceOwnerByDevicePossessionTable(
                  devicePossession,
                  (_f = configurableDevice == null ? void 0 : configurableDevice.sites) == null ? void 0 : _f.find((item) => item.id === devicePossession.possessionId),
                  polyglot,
                  assignedUser == null ? void 0 : assignedUser.displayName
                ) })
              ]
            }
          ),
          devicePossession && !devicePossession.endDate && /* @__PURE__ */ jsx(
            DeviceActionsButton,
            {
              configurableDevice,
              fetchDeviceDetails,
              refreshDevicesAppData,
              activeTransit: activeDeviceTransit,
              assignedUser
            }
          )
        ]
      }
    ) });
  }, [
    activeDeviceTransit,
    assignedUser,
    configurableDevice,
    devicePossession,
    fetchDeviceDetails,
    getCachedUserById,
    loading,
    polyglot,
    refreshDevicesAppData
  ]);
  const isUserPossession = !((devicePossession == null ? void 0 : devicePossession.possessionType) === DevicePossessionType.User);
  const isSecurityHidden = !(devicePossession == null ? void 0 : devicePossession.device) || !!activeDeviceTransit || isUserPossession;
  return /* @__PURE__ */ jsx(Fragment, { children: devicePossession && /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        pageConfig: getDevicePageConfig(
          devicePossessionId,
          devicePossession == null ? void 0 : devicePossession.device,
          globalState.user,
          scopesContext
        ),
        showBack: true,
        backPath: generatePath(DEVICES_ME_DIRECTORY_ROUTE, {
          devicePossessionId: devicePossession.id
        }),
        customTitle,
        type: "Domain"
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices"],
          path: DEVICES_ME_DEVICE_POLICIES_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(DevicePoliciesPage, { devicePossession, loading })
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices"],
          path: DEVICES_ME_DEVICE_LOCATION_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(DeviceLocationPage, { devicePossession, assignedUser, loading })
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices.enroll", "devices"],
          context: scopesContext,
          path: DEVICES_ME_OVERVIEW_DEVICE_ENROLL_ROUTE,
          children: /* @__PURE__ */ jsx(EnrollExistingDevice, { initialDevicePossession: devicePossession, refreshAppData: refreshDevicesAppData })
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices"],
          path: DEVICES_ME_DEVICE_SECURITY_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(
                DeviceMdmSecurityPage,
                {
                  devicePossession,
                  fetchDeviceDetails,
                  loading,
                  activeTransit: activeDeviceTransit,
                  appliedPolicies,
                  appliedZeltPolicies,
                  appliedZeltPoliciesForMobile
                }
              )
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices"],
          path: DEVICES_ME_DEVICE_DETAILS_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            DeviceMdmDetailsPage,
            {
              configurableDevice,
              ownerName: getDeviceOwnerByDevicePossession(
                devicePossession,
                configurableDevice == null ? void 0 : configurableDevice.sites.find((site) => site.id === devicePossession.possessionId),
                getCachedUserById
              ),
              loading,
              refresh: refreshDevice
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices"],
          path: DEVICES_ME_DEVICE_INSTALLED_APPS_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(DeviceInstalledAppsPage, { devicePossession, loading })
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(RouteScopesControl, { exact: true, scopes: ["devices"], path: DEVICES_ME_DEVICE_NOTES_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "31%",
              height: "100%",
              sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
            }
          ),
          children: /* @__PURE__ */ jsx(DeviceNotesPage, { devicePossession, loading, refresh: refreshDevice })
        }
      ) }),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices"],
          path: DEVICES_ME_DEVICE_ACTIVITY_LOG_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(DeviceActivityLogPage, { devicePossession, loading })
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices"],
          path: DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(
                DeviceMdmSecurityPage,
                {
                  devicePossession,
                  fetchDeviceDetails,
                  loading,
                  activeTransit: activeDeviceTransit,
                  appliedPolicies,
                  appliedZeltPolicies,
                  appliedZeltPoliciesForMobile
                }
              )
            }
          )
        }
      ),
      isSecurityHidden ? /* @__PURE__ */ jsx(Redirect, { exact: true, from: DEVICES_ME_OVERVIEW_DEVICE_ROUTE, to: DEVICES_ME_DEVICE_DETAILS_ROUTE }) : /* @__PURE__ */ jsx(Redirect, { exact: true, from: DEVICES_ME_OVERVIEW_DEVICE_ROUTE, to: DEVICES_ME_DEVICE_SECURITY_ROUTE })
    ] })
  ] }) });
};
