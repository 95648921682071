"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useEffect, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { EnrolmentType } from "@v2/feature/device/device.interface";
import { TechSpecsViewModal } from "@v2/feature/device/features/device-cards/components/tech-specs/tech-specs-view-modal.component";
import { InHouseMdmAPI } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { SimpleInHouseMdmDeviceDialog } from "@v2/feature/super-admin/features/super-admin-devices/components/simple-inhouse-mdm-device-dialog.component";
import { MdmMigratorAPI } from "@v2/feature/user/features/mdm-migration/mdm-migrator.api";
import { underlinedLinkDark } from "@v2/styles/buttons.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { themeColors } from "@/v2/styles/colors.styles";
export const SuperAdminOverviewInHouseMdmDeviceDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession,
  companyName
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(SuperAdminOverviewInHouseMdmDeviceDrawerContent, { devicePossession, companyName })
  }
) });
export const SuperAdminOverviewInHouseMdmDeviceDrawerContent = ({
  devicePossession,
  companyName
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t;
  const [, setLoading] = useState(false);
  const [, setSyncLoading] = useState(false);
  const [isDeviceDetailsOpen, setIsDeviceDetailsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [appliedPolicies, setAppliedPolicies] = useState();
  const [devicePossessionHelper, setDevicePossessionHelper] = useState(devicePossession);
  const refreshDevice = () => __async(void 0, null, function* () {
    let devicePossessionUpdated = yield DeviceAPI.getRefreshedDevicePossessionDetails(devicePossession.id);
    if (devicePossessionUpdated) {
      setDevicePossessionHelper(devicePossessionUpdated);
    }
  });
  const [showMessage] = useMessage();
  const initialValues = {
    deviceModel: (_b = (_a = devicePossession.device) == null ? void 0 : _a.modelName) != null ? _b : null,
    serialNumber: (_d = (_c = devicePossession.device) == null ? void 0 : _c.serialNumber) != null ? _d : null,
    enrolmentType: (_f = (_e = devicePossession.device) == null ? void 0 : _e.enrolmentType) != null ? _f : null,
    enrolmentStatus: (_h = (_g = devicePossession.device) == null ? void 0 : _g.enrollmentStatus) != null ? _h : null
  };
  const validationSchema = yup.object({
    deviceModel: yup.string().required("Device model is required"),
    serialNumber: yup.string().required("Serial number is required")
  });
  const patchDeviceEntity = () => __async(void 0, null, function* () {
    var _a2;
    try {
      setLoading(true);
      yield DeviceAPI.scanDeviceBySuperadmin(devicePossession.id, (_a2 = devicePossession.companyId) != null ? _a2 : 0);
      yield new Promise((resolve) => setTimeout(resolve, 1e3));
      yield refreshDevice();
      showMessage("Device successfully updated.", "success");
    } catch (error) {
      showMessage(`Device could not be updated. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => __async(void 0, null, function* () {
      return patchDeviceEntity();
    })
  });
  useEffect(() => {
    formik.validateForm();
  }, []);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleDeviceDetailsDialogOpen = () => __async(void 0, null, function* () {
    yield refreshDevice();
    setIsDeviceDetailsOpen(true);
  });
  const syncDeviceById = () => __async(void 0, null, function* () {
    var _a2, _b2;
    try {
      setSyncLoading(true);
      if (!devicePossession.device) {
        new Error("No device");
      }
      Promise.all([
        InHouseMdmAPI.syncDeviceAgainstDep({
          zeltDeviceId: devicePossession.deviceId,
          serialNumber: (_a2 = devicePossession.device) == null ? void 0 : _a2.serialNumber
        }),
        DeviceAPI.scanDeviceBySuperadmin(devicePossession.id, (_b2 = devicePossession.companyId) != null ? _b2 : 0)
      ]);
      showMessage("Start synchronizing device ", "success");
    } catch (error) {
      showMessage("Could not sync device. Something went wrong.", "error");
    } finally {
      setSyncLoading(false);
    }
  });
  const setCompanyPolicies = () => __async(void 0, null, function* () {
    try {
      setSyncLoading(true);
      yield InHouseMdmAPI.syncDevicePolicy({ zeltDeviceId: devicePossession.deviceId });
      showMessage("Start synchronizing device policy ", "success");
    } catch (error) {
      showMessage("Could not sync device. Something went wrong.", "error");
    } finally {
      setSyncLoading(false);
    }
  });
  const getDeviceAppliedPolicyPolicies = () => __async(void 0, null, function* () {
    var _a2, _b2, _c2;
    try {
      setSyncLoading(true);
      if (devicePossession.device && devicePossession.device.inHouseMdm) {
        if (["ipados", "ios"].includes((_a2 = devicePossession.device.os) != null ? _a2 : "")) {
          setAppliedPolicies(
            yield DeviceAPI.getSuperadminAppliedCompanyDevicePoliciesByZeltMobileMdm((_b2 = devicePossession.companyId) != null ? _b2 : 0)
          );
        } else {
          setAppliedPolicies(
            yield DeviceAPI.getSuperadminAppliedCompanyDevicePoliciesByZeltMdm((_c2 = devicePossession.companyId) != null ? _c2 : 0)
          );
        }
      }
      setOpenDialog(true);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showMessage(`Device's policies not found.`, "warning");
      } else {
        showMessage(`Could not show device's policies. Something went wrong.`, "error");
      }
    } finally {
      setSyncLoading(false);
    }
  });
  const addDepDeviceToInHouseMdm = () => __async(void 0, null, function* () {
    var _a2;
    try {
      setSyncLoading(true);
      yield MdmMigratorAPI.startMigration({
        companyId: (_a2 = devicePossession.companyId) != null ? _a2 : 0,
        deviceIds: [devicePossession.deviceId]
      });
      showMessage("Start synchronizing device ", "success");
    } catch (error) {
      showMessage("Could not sync device. Something went wrong.", "error");
    } finally {
      setSyncLoading(false);
    }
  });
  const syncEnrolmentStatus = () => __async(void 0, null, function* () {
    try {
      setSyncLoading(true);
      yield InHouseMdmAPI.syncEnrolmentStatus(devicePossession.deviceId);
      showMessage("Start synchronizing enrolment status ", "success");
    } catch (error) {
      showMessage("Could not sync enrolment status. Something went wrong.", "error");
    } finally {
      setSyncLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
      (_i = devicePossession.device) == null ? void 0 : _i.modelName,
      " ",
      (_j = devicePossession.device) == null ? void 0 : _j.id
    ] }),
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 2, alignItems: "left" }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Device Model",
          name: "deviceModel",
          value: (_k = devicePossession.device) == null ? void 0 : _k.modelName,
          size: "small",
          endAdornment: "none",
          disabled: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Serial number",
          name: "serialNumber",
          value: (_l = devicePossession.device) == null ? void 0 : _l.serialNumber,
          size: "small",
          endAdornment: "none",
          disabled: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Company name",
          name: "companyName",
          value: companyName,
          size: "small",
          endAdornment: "none",
          disabled: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Enrolment type",
          name: "enrolmentType",
          value: (_m = devicePossession.device) == null ? void 0 : _m.enrolmentType,
          size: "small",
          endAdornment: "none",
          disabled: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Enrolment status",
          name: "enrolmentStatus",
          value: (_n = devicePossession.device) == null ? void 0 : _n.enrollmentStatus,
          size: "small",
          endAdornment: "none",
          disabled: true
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            flexDirection: "column",
            gap: 3,
            mt: 3,
            justifyContent: "flex-start",
            alignItems: "flex-start"
          },
          children: [
            ((_o = devicePossession.device) == null ? void 0 : _o.enrollmentStatus) === "ENROLMENT_FINISHED" && /* @__PURE__ */ jsx(
              Link,
              {
                component: "button",
                sx: underlinedLinkDark,
                onClick: () => {
                  setCompanyPolicies();
                },
                children: "Migrate company policies"
              }
            ),
            ((_p = devicePossession.device) == null ? void 0 : _p.inHouseMdm) && ((_q = devicePossession.device) == null ? void 0 : _q.enrolmentType) !== EnrolmentType.OPEN_ENROLMENT && /* @__PURE__ */ jsx(
              Link,
              {
                component: "button",
                sx: underlinedLinkDark,
                onClick: () => {
                  addDepDeviceToInHouseMdm();
                },
                children: "Add to in-house MDM database - DEP"
              }
            ),
            ((_r = devicePossession.device) == null ? void 0 : _r.inHouseMdm) && /* @__PURE__ */ jsx(
              Link,
              {
                component: "button",
                sx: underlinedLinkDark,
                onClick: () => {
                  syncEnrolmentStatus();
                },
                children: "Sync enrolment status"
              }
            ),
            /* @__PURE__ */ jsx(
              Link,
              {
                component: "button",
                sx: underlinedLinkDark,
                onClick: () => {
                  getDeviceAppliedPolicyPolicies();
                },
                children: "See company policies"
              }
            ),
            ((_s = devicePossession.device) == null ? void 0 : _s.enrollmentStatus) === "ENROLMENT_FINISHED" && /* @__PURE__ */ jsx(
              Link,
              {
                component: "button",
                sx: underlinedLinkDark,
                onClick: () => {
                  handleDeviceDetailsDialogOpen();
                },
                children: "See devices details"
              }
            ),
            ((_t = devicePossession.device) == null ? void 0 : _t.enrollmentStatus) === "ENROLMENT_FINISHED" && /* @__PURE__ */ jsx(
              Link,
              {
                component: "button",
                sx: underlinedLinkDark,
                onClick: () => {
                  syncDeviceById();
                },
                children: "Sync device"
              }
            )
          ]
        }
      )
    ] }) }),
    /* @__PURE__ */ jsx(
      TechSpecsViewModal,
      {
        setIsOpen: setIsDeviceDetailsOpen,
        isOpen: isDeviceDetailsOpen,
        devicePossession: devicePossessionHelper,
        refresh: refreshDevice,
        activeTransit: null
      }
    ),
    /* @__PURE__ */ jsx(SimpleInHouseMdmDeviceDialog, { open: openDialog, onClose: handleCloseDialog, appliedPolicies })
  ] });
};
