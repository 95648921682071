"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FiltersDrawer } from "@v2/components/table/filters-drawer.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AbsencePolicyAllowanceType } from "@v2/feature/absence/absence.interface";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import {
  getCarryOverFromBreakdown,
  getExpiredCarryOver,
  getOneOffAdjustmentFromBreakdown
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { USER_ABSENCE_ROUTE } from "@/lib/routes";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
const getYearOptions = (polyglot) => {
  return [
    { label: polyglot.t("PageFilters.last"), value: "last", type: "radio" },
    { label: polyglot.t("PageFilters.current"), value: "current", type: "radio" },
    { label: polyglot.t("PageFilters.next"), value: "next", type: "radio" }
  ];
};
export const AbsenceBalancesTable = ({
  absencePolicies,
  view,
  stickyHeader
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const initialFilterValue = useMemo(() => {
    var _a2;
    return `Absence policy=${(_a2 = absencePolicies[0]) == null ? void 0 : _a2.id}&Calendar=current`;
  }, [
    absencePolicies
  ]);
  const [filterString, setFilterString] = useState(initialFilterValue);
  const [usersBalances, setUsersBalances] = useState([]);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState((_b = (_a = absencePolicies[0]) == null ? void 0 : _a.id) != null ? _b : null);
  const [yearValue, setYearValue] = useState("current");
  useEffect(() => {
    setFilterString(initialFilterValue);
  }, [initialFilterValue]);
  useEffect(() => {
    var _a2, _b2;
    const filters = filterString.split("&");
    const absenceIdString = (_a2 = filters.find((f) => f.startsWith("Absence policy"))) == null ? void 0 : _a2.split("=")[1];
    const absenceId = absenceIdString ? Number(absenceIdString) : null;
    setFilterValue((prev) => absenceId && prev === absenceId ? prev : absenceId);
    const year = (_b2 = filters.find((f) => f.startsWith("Calendar"))) == null ? void 0 : _b2.split("=")[1];
    setYearValue((prev) => year && prev === year ? prev : year);
  }, [filterString]);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const routerHistory = useHistory();
  const { getCachedUserById } = useCachedUsers();
  const [searchInput, setSearchInput] = useState("");
  const debouncedSearch = useDebouncedCallback((value) => {
    try {
      setSearchInput(!value ? "" : value);
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage(polyglot.t("AbsenceBalancesTable.errorMessages.handleSearch"), "error");
    }
  }, 300);
  const selectedPolicy = useMemo(() => {
    let defaultPolicy = absencePolicies[0];
    if (!filterValue) return defaultPolicy;
    const policyId = Number(filterValue);
    const newPolicy = absencePolicies.find((p) => p.id === policyId);
    return newPolicy ? newPolicy : defaultPolicy;
  }, [filterValue, absencePolicies]);
  useEffect(() => {
    (function() {
      return __async(this, null, function* () {
        if (!selectedPolicy) {
          setLoading(false);
          return;
        }
        try {
          setLoading(true);
          const usersBalancesResponse = view === "team" ? yield AbsenceAPI.fetchTeamUsersAbsenceBalanceBreakdown(
            selectedPolicy.id,
            yearValue,
            pagination.pageIndex,
            pagination.pageSize,
            searchInput ? searchInput : void 0
          ) : yield AbsenceAPI.fetchCompanyUsersAbsenceBalanceBreakdown(
            selectedPolicy.id,
            yearValue,
            pagination.pageIndex,
            pagination.pageSize,
            searchInput ? searchInput : void 0
          );
          setUsersBalances(usersBalancesResponse.items);
          setTotalPages(usersBalancesResponse.totalPages);
          setTotalItems(usersBalancesResponse.totalItems);
        } catch (error) {
          showMessage(
            polyglot.t("AbsenceBalancesTable.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        } finally {
          setLoading(false);
        }
      });
    })();
  }, [polyglot, showMessage, view, selectedPolicy, pagination.pageIndex, pagination.pageSize, searchInput, yearValue]);
  const rowData = useMemo(() => {
    return usersBalances.map((record) => {
      var _a2, _b2, _c, _d, _e;
      const user = getCachedUserById(record.userId);
      return __spreadProps(__spreadValues({}, record), {
        displayName: (_a2 = user == null ? void 0 : user.displayName) != null ? _a2 : "Unknown",
        userStatus: (_b2 = user == null ? void 0 : user.userEvent) == null ? void 0 : _b2.status,
        userStartDate: user == null ? void 0 : user.startDate,
        site: (_e = (_d = (_c = user == null ? void 0 : user.role) == null ? void 0 : _c.site) == null ? void 0 : _d.name) != null ? _e : null
      });
    });
  }, [usersBalances, getCachedUserById]);
  const tableColumns = useMemo(() => {
    var _a2;
    if (!selectedPolicy) return [];
    const balanceColumn = {
      header: () => polyglot.t("AbsenceBalancesTable.balanceHours"),
      accessorFn: (row) => row,
      id: "balance",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        var _a3;
        if (!selectedPolicy || !original[selectedPolicy.id]) return /* @__PURE__ */ jsx(EmptyCell, {});
        const value = (_a3 = original[selectedPolicy.id]) == null ? void 0 : _a3.currentBalance;
        const balance = value || value === 0 ? convertMinutesToClockHours(value, polyglot) : null;
        return balance ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: balance }) : /* @__PURE__ */ jsx(EmptyCell, {});
      }
    };
    const balanceDaysColumn = {
      header: () => polyglot.t("AbsenceBalancesTable.balanceDays"),
      accessorFn: (row) => row,
      id: "balanceInDays",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        var _a3;
        if (!selectedPolicy || !original[selectedPolicy.id]) return /* @__PURE__ */ jsx(EmptyCell, {});
        const value = (_a3 = original[selectedPolicy.id]) == null ? void 0 : _a3.currentBalanceInDays;
        const balance = value || value === 0 ? `${value} day${value === 1 ? "" : "s"}` : null;
        return balance ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: balance }) : /* @__PURE__ */ jsx(EmptyCell, {});
      }
    };
    const oneOffAdjustmentColumn = {
      header: () => polyglot.t("AbsenceBalancesTable.oneOffAdjustment"),
      accessorFn: (row) => row,
      id: "oneOffAdjustment",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        var _a3;
        if (!selectedPolicy) return /* @__PURE__ */ jsx(EmptyCell, {});
        const breakdown = original[selectedPolicy.id];
        if (!breakdown) return /* @__PURE__ */ jsx(EmptyCell, {});
        const value = (_a3 = original[selectedPolicy.id]) == null ? void 0 : _a3.oneOffAdjustment;
        if (value === null) return /* @__PURE__ */ jsx(EmptyCell, {});
        return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: getOneOffAdjustmentFromBreakdown(selectedPolicy, breakdown, polyglot) });
      }
    };
    const carryOverColumn = {
      header: () => polyglot.t("AbsenceBalancesTable.carryOver"),
      accessorFn: (row) => row,
      id: "ca",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        var _a3, _b2;
        if (!selectedPolicy) return /* @__PURE__ */ jsx(EmptyCell, {});
        const breakdown = original[selectedPolicy.id];
        if (!breakdown) return /* @__PURE__ */ jsx(EmptyCell, {});
        const value = (_b2 = (_a3 = original[selectedPolicy.id]) == null ? void 0 : _a3.carryOver) == null ? void 0 : _b2.allowedUnitsThisCycle;
        if (value === null || value === void 0) return /* @__PURE__ */ jsx(EmptyCell, {});
        const expiredCarryOver = breakdown.carryOver.isCarryOverExpired && breakdown.carryOver.expiredCarryOver && breakdown.carryOver.expiredCarryOver > 0 ? `(Expired: ${getExpiredCarryOver(selectedPolicy, breakdown, polyglot)})` : "";
        return /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.caption, children: [
          getCarryOverFromBreakdown(selectedPolicy, breakdown, polyglot),
          " ",
          expiredCarryOver
        ] });
      }
    };
    return [
      {
        header: () => polyglot.t("AbsenceBalancesTable.name"),
        accessorFn: (row) => row,
        id: "displayName",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const user = getCachedUserById(original.userId);
          return user ? /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(UserCell, { userId: original.userId }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      ...selectedPolicy.allowanceType === AbsencePolicyAllowanceType.Unlimited ? [] : [balanceColumn, balanceDaysColumn, oneOffAdjustmentColumn, carryOverColumn],
      {
        header: () => polyglot.t("AbsenceBalancesTable.hoursTaken"),
        accessorFn: (row) => row,
        id: `units-taken-${selectedPolicy.id}`,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a3;
          if (!selectedPolicy || !original[selectedPolicy.id]) return /* @__PURE__ */ jsx(EmptyCell, {});
          const value = (_a3 = original[selectedPolicy.id]) == null ? void 0 : _a3.unitsTaken.history;
          const unitsTaken = value || value === 0 ? convertMinutesToClockHours(value, polyglot) : null;
          return unitsTaken ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: unitsTaken }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("AbsenceBalancesTable.hoursBooked"),
        accessorFn: (row) => row,
        id: `units-booked-${(_a2 = selectedPolicy == null ? void 0 : selectedPolicy.id) != null ? _a2 : ""}`,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a3;
          if (!selectedPolicy || !original[selectedPolicy.id]) return /* @__PURE__ */ jsx(EmptyCell, {});
          const value = (_a3 = original[selectedPolicy.id]) == null ? void 0 : _a3.unitsTaken.upcoming;
          const unitsBooked = value || value === 0 ? convertMinutesToClockHours(value, polyglot) : null;
          return unitsBooked ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: unitsBooked }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("AbsenceBalancesTable.userStartDate"),
        accessorFn: (row) => row,
        id: "userStartDate",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.userStartDate ? getDateString(original.userStartDate) : polyglot.t("AbsenceBalancesTable.missingStartDate") }) })
      },
      {
        header: () => polyglot.t("AbsenceBalancesTable.reportsTo"),
        accessorFn: (row) => row,
        id: "reportsTo",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a3;
          const userM = getCachedUserById(original.userId);
          const managerM = ((_a3 = userM == null ? void 0 : userM.role) == null ? void 0 : _a3.managerId) ? getCachedUserById(userM.role.managerId) : void 0;
          return managerM ? /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(UserCell, { userId: managerM.userId }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("AbsenceBalancesTable.site"),
        accessorFn: (row) => row,
        id: "site",
        enableSorting: false,
        cell: ({ row: { original } }) => original.site ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t(original.site) }) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      }
    ];
  }, [polyglot, getCachedUserById, selectedPolicy]);
  const handleRowClick = useCallback(
    (row) => {
      var _a2, _b2;
      routerHistory.push(generatePath(USER_ABSENCE_ROUTE, { userId: (_b2 = (_a2 = row.original) == null ? void 0 : _a2.userId) != null ? _b2 : "" }));
    },
    [routerHistory]
  );
  const exportBalancesDataCSV = useCallback(() => __async(void 0, null, function* () {
    if (!selectedPolicy || view !== "company") return;
    try {
      window.location.href = AbsenceAPI.exportCompanyUsersBalancesAsCSV(selectedPolicy.id, yearValue);
    } catch (error) {
      showMessage(polyglot.t("AbsenceBalancesTable.errorMessages.badRequest"), "error");
    }
  }), [yearValue, polyglot, view, selectedPolicy, showMessage]);
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "space-between"
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-start", gap: "5px", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(
              FiltersDrawer,
              {
                name: polyglot.t("AbsenceTable.filters"),
                filtersOptions: [
                  {
                    filters: {
                      "Absence policy": absencePolicies.map((p) => {
                        var _a2;
                        return {
                          label: (_a2 = p.fullName) != null ? _a2 : p.name,
                          value: p.id,
                          type: "radio"
                        };
                      }),
                      Calendar: getYearOptions(polyglot)
                    }
                  }
                ],
                selectedFilters: filterString,
                setSelectedFilters: setFilterString,
                hideClearAll: true
              }
            ),
            /* @__PURE__ */ jsx(
              TableSearch,
              {
                query: searchInput,
                handleChange: (e) => {
                  debouncedSearch.callback(e.target.value);
                }
              }
            )
          ] }),
          view === "company" && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(LoaderButton, { loading: false, onClick: exportBalancesDataCSV, sizeVariant: "small", colorVariant: "secondary", children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("General.export") })
          ] }) }) })
        ]
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
      BasicServerTable,
      {
        rowData: [...rowData],
        columnData: tableColumns,
        loading,
        rowClick: handleRowClick,
        pagination,
        setPagination,
        totalPages,
        totalItems,
        stickyHeader
      }
    ) })
  ] });
};
