"use strict";
import { FilterTypeOption } from "@v2/feature/reports/reports.interface";
import { LocalDate } from "@v2/util/local-date";
export var ReportDisplayFormat = /* @__PURE__ */ ((ReportDisplayFormat2) => {
  ReportDisplayFormat2["none"] = "raw";
  ReportDisplayFormat2["number1"] = "number1";
  ReportDisplayFormat2["number2"] = "number2";
  ReportDisplayFormat2["number3"] = "number3";
  ReportDisplayFormat2["number4"] = "number4";
  ReportDisplayFormat2["number5"] = "number5";
  ReportDisplayFormat2["number6"] = "number6";
  ReportDisplayFormat2["dateonly1"] = "dateonly1";
  ReportDisplayFormat2["dateonly2"] = "dateonly2";
  ReportDisplayFormat2["dateonly3"] = "dateonly3";
  ReportDisplayFormat2["dateonly4"] = "dateonly4";
  ReportDisplayFormat2["dateonly5"] = "dateonly5";
  ReportDisplayFormat2["dateonly6"] = "dateonly6";
  ReportDisplayFormat2["dateonly7"] = "dateonly7";
  ReportDisplayFormat2["dateonly8"] = "dateonly8";
  ReportDisplayFormat2["dateonly9"] = "dateonly9";
  ReportDisplayFormat2["dateonly10"] = "dateonly10";
  ReportDisplayFormat2["dateonly11"] = "dateonly11";
  ReportDisplayFormat2["datetime1"] = "datetime1";
  ReportDisplayFormat2["datetime2"] = "datetime2";
  ReportDisplayFormat2["datetime3"] = "datetime3";
  return ReportDisplayFormat2;
})(ReportDisplayFormat || {});
export const ReportDisplayFormatNumberOptions = [
  { value: "raw" /* none */, label: "None" },
  {
    value: "number1" /* number1 */,
    label: "#,###.00",
    description: "Numbers with commas for thousands, 2 decimals"
  },
  {
    value: "number2" /* number2 */,
    label: "#,###.0",
    description: "Numbers with commas for thousands and 1 decimal"
  },
  { value: "number3" /* number3 */, label: "#,###", description: "Numbers with commas for thousands, no decimals" },
  { value: "number4" /* number4 */, label: "1234.00", description: "Numbers with 2 decimals, no commas" },
  { value: "number5" /* number5 */, label: "1234.0", description: "Numbers with 1 decimals, no commas" },
  { value: "number6" /* number6 */, label: "1234", description: "Numbers with decimals or commas" }
];
export const ReportDisplayFormatDateOptions = [
  { value: "raw" /* none */, label: "None" },
  { value: "dateonly1" /* dateonly1 */, label: "DD/MM/YYYY", description: "09/02/2024" },
  { value: "dateonly2" /* dateonly2 */, label: "D/MM/YYYY", description: "9/02/2024" },
  { value: "dateonly3" /* dateonly3 */, label: "YYYY-MM-DD", description: "2024-02-09" },
  { value: "dateonly4" /* dateonly4 */, label: "M/DD/YYYY", description: "2/09/2024" },
  { value: "dateonly5" /* dateonly5 */, label: "D MMM YYYY", description: "9 Feb 2024" },
  { value: "dateonly6" /* dateonly6 */, label: "MMMM YYYY", description: "February 2024" },
  { value: "dateonly7" /* dateonly7 */, label: "MMM YYYY", description: "Feb 2024" },
  { value: "dateonly8" /* dateonly8 */, label: "MM YYYY", description: "02 2024" },
  { value: "dateonly9" /* dateonly9 */, label: "MM/YYYY", description: "02/2024" },
  { value: "dateonly10" /* dateonly10 */, label: "MM", description: "02" },
  { value: "dateonly11" /* dateonly11 */, label: "YYYY", description: "2024" }
];
export const ReportDisplayFormatDateTimeOptions = [
  { value: "raw" /* none */, label: "None" },
  { value: "datetime1" /* datetime1 */, label: "DD/MM/YYYY HH:MM", description: "" },
  { value: "datetime2" /* datetime2 */, label: "YYYY-MM-DD HH:MM", description: "" },
  { value: "datetime3" /* datetime3 */, label: "DD/MM/YYYY HH:MM", description: "" }
];
export const FilterTypeOptionToDefaultReportFormat = {
  [FilterTypeOption.string]: "raw" /* none */,
  [FilterTypeOption.number]: "number1" /* number1 */,
  [FilterTypeOption.boolean]: "raw" /* none */,
  [FilterTypeOption.intOption]: "raw" /* none */,
  [FilterTypeOption.stringOption]: "raw" /* none */,
  [FilterTypeOption.dateonly]: "dateonly1" /* dateonly1 */,
  [FilterTypeOption.datetime]: "datetime1" /* datetime1 */,
  [FilterTypeOption.time]: "raw" /* none */
};
const ReportDisplayFormatHandler = {
  ["raw" /* none */]: (value) => value,
  ["number1" /* number1 */]: (value) => {
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value) * 100) / 100;
    return numberValue.toLocaleString("en-GB", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  },
  ["number2" /* number2 */]: (value) => {
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value) * 10) / 10;
    return numberValue.toLocaleString("en-GB", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    });
  },
  ["number3" /* number3 */]: (value) => {
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value));
    return numberValue.toLocaleString("en-GB");
  },
  ["number4" /* number4 */]: (value) => {
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value) * 100) / 100;
    return numberValue.toFixed(2);
  },
  ["number5" /* number5 */]: (value) => {
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value) * 10) / 10;
    return numberValue.toFixed(1);
  },
  ["number6" /* number6 */]: (value) => {
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value));
    return numberValue.toString();
  },
  // DATEONLY
  ["dateonly1" /* dateonly1 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-GB");
  },
  ["dateonly2" /* dateonly2 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-GB", { day: "numeric", month: "2-digit", year: "numeric" });
  },
  ["dateonly3" /* dateonly3 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.toDateString();
  },
  ["dateonly4" /* dateonly4 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-US");
  },
  ["dateonly5" /* dateonly5 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" });
  },
  ["dateonly6" /* dateonly6 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-GB", { month: "long", year: "numeric" });
  },
  ["dateonly7" /* dateonly7 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-GB", { month: "short", year: "numeric" });
  },
  ["dateonly8" /* dateonly8 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-GB", { month: "2-digit", year: "numeric" }).replace("/", " ");
  },
  ["dateonly9" /* dateonly9 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-GB", { month: "2-digit", year: "numeric" });
  },
  ["dateonly10" /* dateonly10 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-GB", { month: "2-digit" });
  },
  ["dateonly11" /* dateonly11 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString("en-GB", { year: "numeric" });
  },
  // DATE AND TIME
  ["datetime1" /* datetime1 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleString("en-GB", {
      day: "2-digit",
      year: "2-digit",
      month: "2-digit",
      hour: "numeric",
      minute: "numeric"
    });
  },
  ["datetime2" /* datetime2 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.toDateTimeString();
  },
  ["datetime3" /* datetime3 */]: (value) => {
    if (!value) return "";
    const date = new LocalDate(value);
    if (!date.isDate()) return value;
    return date.getDate().toLocaleString("en-US", {
      day: "numeric",
      year: "numeric",
      month: "numeric",
      hour: "numeric",
      minute: "numeric"
    });
  }
  // [ReportDisplayFormat.time1]: (value: string) => {
  //   if (!value) return '';
  //   const date = new LocalDate(value);
  //   if (!date.isDate()) return value;
  //
  //   return date.getDate().toLocaleString(undefined, {
  //     hour: 'numeric',
  //     minute: 'numeric',
  //   });
  // },
};
export function formatReportValue(value, format) {
  if (!value) return "";
  if (!format || !Object.values(ReportDisplayFormat).includes(format) || format === "raw" /* none */)
    return value;
  const handlerFunction = ReportDisplayFormatHandler[format];
  if (!handlerFunction) return value;
  return handlerFunction(value);
}
