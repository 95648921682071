"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Skeleton, Typography } from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Desc } from "@/images/side-bar-icons/ArrowDownSmall.svg";
import { ReactComponent as Asc } from "@/images/side-bar-icons/ArrowUpSmall.svg";
import { ReactComponent as SortIcon } from "@/images/side-bar-icons/SortIcon.svg";
import { PaginationDetail } from "@/v2/components/table/pagination-detail.component";
import "@/v2/components/table/styles/basic-table.scss";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import {
  iconSize,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableHeadRow,
  TableRow
} from "@/v2/styles/table.styles";
export const DEFAULT_PAGE_SIZE = 20;
export const BasicServerTable = ({
  rowData,
  columnData,
  sorting,
  setSorting,
  pagination,
  setPagination,
  loading,
  totalPages,
  rowClick,
  customRowStyle,
  hiddenColumns,
  totalItems,
  stickyHeader
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const paginatedData = rowData.slice(pagination.pageIndex * 10, pagination.pageIndex * 10 + 10);
  const table = useReactTable({
    data: totalPages ? rowData : paginatedData,
    columns: columnData.filter((column) => !column.id || !(hiddenColumns == null ? void 0 : hiddenColumns.includes(column.id))),
    pageCount: totalPages ? totalPages + 1 : Math.ceil(rowData.length / pagination.pageSize),
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    debugTable: process.env.REACT_APP_ENV !== "production",
    manualPagination: true
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      "div",
      {
        style: stickyHeader ? {
          overflowX: "auto",
          width: "100%",
          height: "calc(100vh - 200px)",
          overflowY: "auto",
          position: "relative"
        } : { overflowX: "auto", maxHeight: "inherit", width: "100%" },
        children: [
          /* @__PURE__ */ jsxs(Table, { children: [
            /* @__PURE__ */ jsx(TableHead, { style: stickyHeader ? { position: "sticky", top: 0, zIndex: 3 } : {}, children: (_a = table.getHeaderGroups()) == null ? void 0 : _a.map((headerGroup) => /* @__PURE__ */ jsx(TableHeadRow, { children: headerGroup.headers.map((header, idx, arr) => {
              var _a2;
              const cellStyle = {};
              return /* @__PURE__ */ jsx(
                TableHeading,
                {
                  maxWidth: header.column.columnDef.maxSize || 100,
                  minWidth: header.column.columnDef.minSize,
                  style: __spreadValues({}, cellStyle),
                  fixedFirstColumn: idx === 0,
                  fixedLastColumn: idx === arr.length - 1,
                  className: "tableHeading",
                  children: header.isPlaceholder ? null : /* @__PURE__ */ jsxs(
                    "div",
                    __spreadProps(__spreadValues({}, {
                      style: {
                        display: "flex",
                        alignItems: "center",
                        width: header.column.getSize()
                      },
                      onClick: header.column.getToggleSortingHandler()
                    }), {
                      children: [
                        /* @__PURE__ */ jsx(
                          "span",
                          {
                            style: __spreadValues({
                              color: themeColors.Grey
                            }, themeFonts.caption),
                            children: flexRender(header.column.columnDef.header, header.getContext())
                          }
                        ),
                        /* @__PURE__ */ jsx("div", { className: "sortIcon", children: (_a2 = header.column.columnDef.enableSorting && {
                          asc: /* @__PURE__ */ jsx(Asc, __spreadProps(__spreadValues({}, iconSize), { style: { color: themeColors.DarkGrey } })),
                          desc: /* @__PURE__ */ jsx(Desc, __spreadProps(__spreadValues({}, iconSize), { style: { color: themeColors.DarkGrey } }))
                        }[header.column.getIsSorted()]) != null ? _a2 : /* @__PURE__ */ jsx(SortIcon, __spreadValues({}, iconSize)) })
                      ]
                    })
                  )
                },
                header.id
              );
            }) }, headerGroup.id)) }),
            loading && /* @__PURE__ */ jsx(TableBody, { children: [1, 2, 3, 4].map((row) => /* @__PURE__ */ jsx(TableRow, { children: table.getAllColumns().map((cell, i) => /* @__PURE__ */ jsx(TableData, { maxWidth: 150, minWidth: 100, children: /* @__PURE__ */ jsx(Skeleton, { variant: "text", sx: { backgroundColor: themeColors.Background } }) }, i)) }, row)) }),
            (totalPages ? rowData : paginatedData).length > 0 && !loading && /* @__PURE__ */ jsx(TableBody, { children: (_b = table.getRowModel()) == null ? void 0 : _b.rows.map((row) => /* @__PURE__ */ jsx(
              TableRow,
              {
                onClick: () => rowClick && rowClick(row),
                style: { cursor: rowClick ? "pointer" : "default" },
                children: row.getVisibleCells().map((cell, idx, arr) => {
                  var _a2, _b2;
                  const cellStyle = {};
                  return /* @__PURE__ */ jsx(
                    TableData,
                    {
                      maxWidth: cell.column.columnDef.maxSize || 100,
                      minWidth: cell.column.columnDef.minSize,
                      cellStyle: __spreadValues(__spreadValues({}, cellStyle), !!customRowStyle && customRowStyle(row)),
                      meta: (_b2 = (_a2 = cell.column) == null ? void 0 : _a2.columnDef) == null ? void 0 : _b2.meta,
                      fixedFirstColumn: idx === 0,
                      fixedLastColumn: idx === arr.length - 1,
                      children: flexRender(cell.column.columnDef.cell, cell.getContext())
                    },
                    cell.id
                  );
                })
              },
              row.id
            )) })
          ] }),
          (totalPages ? rowData : paginatedData).length < 1 && !loading && /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                width: "100%",
                display: "flex",
                marginTop: spacing.m10,
                height: "50px",
                alignItems: "center",
                justifyContent: "center"
              },
              children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.has("BasicServerTable.countNotFindAnything") ? polyglot.t("BasicServerTable.countNotFindAnything") : "Sorry, we couldn't find anything..." })
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      PaginationDetail,
      {
        totalPageCount: totalPages ? totalPages : table.getPageCount(),
        totalRecordCount: totalItems != null ? totalItems : 0,
        current: table.getState().pagination.pageIndex,
        onNextAction: () => {
          table.nextPage();
        },
        nextDisabled: pagination.pageIndex === totalPages,
        onPreviousAction: () => {
          table.previousPage();
        },
        previousDisabled: pagination.pageIndex === 1,
        paginationState: table.getState().pagination,
        setPaginationState: (pageSize, pageIndex) => {
          table.setPagination({ pageSize, pageIndex });
        },
        handleState: (page) => {
          let pageState = page > 0 ? Number(page) : 1;
          if (pageState < 1) pageState = 1;
          if (totalPages && pageState > totalPages) pageState = totalPages;
          table.setPageIndex(pageState);
        }
      }
    )
  ] });
};
