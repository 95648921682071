"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { AbsenceStatus } from "@v2/feature/absence/absence.interface";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { AttendanceImportAPI } from "@v2/feature/attendance/subfeatures/attendance-import/attendance-import.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { round2Digits } from "@v2/util/number.util";
import pako from "pako";
import { useHistory } from "react-router-dom";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { useProfileFields } from "@/hooks/profile-fields.hook";
import { ReactComponent as Close } from "@/images/app-icons/Close.svg";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { nestErrorMessage } from "@/lib/errors";
import { StepperComponent } from "@/v2/components/stepper.component";
import { AbsenceImportAPI } from "@/v2/feature/absence/subfeatures/absence-import/absence-import.api";
import { DeviceImportAPI } from "@/v2/feature/device/features/devices-import/device-import.api";
import {
  EntityImportWizardData,
  EntityImportWizardSource
} from "@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface";
import { finalisePeopleDataForImport } from "@/v2/feature/entity-import/wizard/entity-import-mapper.util";
import { reValidatePeopleImportResult } from "@/v2/feature/entity-import/wizard/entity-import-validator.util";
import { InUseByTypes } from "@/v2/feature/entity-import/wizard/import-wizard.interface";
import { DataDomainChoiceSection } from "@/v2/feature/entity-import/wizard/sections/data-domain-choice.section";
import { DataSourceChoiceSection } from "@/v2/feature/entity-import/wizard/sections/data-source-choice.section";
import { ImportCompleteSection } from "@/v2/feature/entity-import/wizard/sections/import-complete.section";
import { ImportResultsSection } from "@/v2/feature/entity-import/wizard/sections/import-results.section";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserImportAPI } from "@/v2/feature/user/features/user-import/user-import.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { ddMMYYYYToIsoDateString } from "@/v2/infrastructure/date/date-format.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ImportWizardStepperSteps = ["Data", "Source", "Results", "Complete"];
export const iconSize = { width: 14, height: 14 };
const MAX_NON_BACKGROUND_PROCESSING = 10;
const MAX_NON_BACKGROUND_ATTENDANCES_PROCESSING = 100;
const MAX_NON_BACKGROUND_ABSENCES_PROCESSING = 100;
const MAX_NON_BACKGROUND_ABSENCE_ADJUSTMENTS_PROCESSING = 100;
function compressData(data) {
  const jsonStr = JSON.stringify(data);
  const input = new TextEncoder().encode(jsonStr);
  const compressed = pako.deflate(input);
  return compressed.buffer;
}
export const ImportWizardFlow = () => {
  const { polyglot } = usePolyglot();
  const [activeStep, setActiveStep] = useState("Data");
  const [importState, setImportState] = useState();
  const { refreshCachedUsers, getCachedUsersByEmail } = useCachedUsers();
  const [loading, setLoading] = useState(false);
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { data: companySettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const { data: attendanceSchedules } = useApiClient(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });
  const { data: departmentsForCompany } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false
  });
  const { data: sitesForCompany } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { getCustomFieldsForForm } = useProfileFields();
  const returnToImportSettingsPage = useCallback(() => {
    routerHistory.goBack();
  }, [routerHistory]);
  const isFirstStep = useCallback(() => {
    return ImportWizardStepperSteps.indexOf(activeStep) === 0;
  }, [activeStep]);
  const handleNext = useCallback(
    (importComplete) => {
      const currentStepIdx = ImportWizardStepperSteps.indexOf(activeStep);
      if (currentStepIdx >= ImportWizardStepperSteps.length - 1 || importComplete) {
        returnToImportSettingsPage();
        return;
      }
      setActiveStep(ImportWizardStepperSteps[currentStepIdx + 1]);
    },
    [activeStep, returnToImportSettingsPage]
  );
  const handleBack = useCallback(() => {
    setActiveStep((currentStep) => {
      const currentStepIdx = ImportWizardStepperSteps.indexOf(currentStep);
      return ImportWizardStepperSteps[Math.max(currentStepIdx - 1, 0)];
    });
  }, []);
  const importHasErrors = (result) => {
    const errorsFromImport = result.errors.flatMap((r) => r.errors);
    return errorsFromImport.length > 0;
  };
  const importResultHasOverlappingErrors = (result) => {
    const OVERLAPPING_RECORD_ERROR_MESSAGE = polyglot.t("importResultHasOverlappingErrors.errorMessages.overlap");
    return [...new Set(result.errors.flatMap((e) => e.errors).flatMap((record) => record.constraints.taken))].every(
      (errorMessage) => errorMessage && errorMessage.includes(OVERLAPPING_RECORD_ERROR_MESSAGE)
    );
  };
  const getUniqueErrorsFromImportResult = (importErrors) => {
    return Array.from(
      new Set(
        Object.entries(importErrors.flatMap((e) => e.constraints)).map((e) => Object.values(e[1])).flat()
      )
    );
  };
  const notifyImportResult = (importResult, overlappingAbsenceRecords = false) => {
    const importSuffix = [];
    if (importResult.importedCount > 0)
      importSuffix.push(
        `${importResult.importedCount} ${importState == null ? void 0 : importState.domain} ${polyglot.t("notifyImportResult.imported")}`
      );
    if (importResult.updatedCount > 0)
      importSuffix.push(
        `${importResult.updatedCount} ${importState == null ? void 0 : importState.domain} ${polyglot.t("notifyImportResult.updated")}`
      );
    const errorsFromImport = importResult.errors.flatMap((r) => r.errors).filter((e) => {
      var _a;
      return ((_a = Object.keys(e)) == null ? void 0 : _a.length) > 0;
    });
    if (errorsFromImport && errorsFromImport.length === 0) {
      showMessage(`${polyglot.t("notifyImportResult.successMessages.import")} - ${importSuffix.join(", ")}`, "success");
      return;
    }
    if (!overlappingAbsenceRecords) {
      const allUniqueErrors = getUniqueErrorsFromImportResult(errorsFromImport);
      const failureMessage = importResult.updatedCount > 0 || importResult.importedCount > 0 ? `${polyglot.t("notifyImportResult.errorMessages.partially")}  - ${importSuffix.join(", ")} - ${polyglot.t(
        "notifyImportResult.errorMessages.otherRecords"
      )}: ${allUniqueErrors.join(", ")}` : `${polyglot.t("notifyImportResult.errorMessages.errorList")} - ${allUniqueErrors.join(", ")}`;
      showMessage(
        failureMessage,
        importResult.updatedCount > 0 || importResult.importedCount > 0 ? "warning" : "error"
      );
    } else {
      const allUniqueErrors = getUniqueErrorsFromImportResult(errorsFromImport);
      const failureMessage = importResult.updatedCount > 0 || importResult.importedCount > 0 ? `${polyglot.t("notifyImportResult.errorMessages.partially")}  - ${importSuffix.join(", ")} - ${polyglot.t(
        "notifyImportResult.errorMessages.otherRecords"
      )}: ${allUniqueErrors.join(", ")}` : polyglot.t("notifyImportResult.errorMessages.overlappingRecords");
      showMessage(
        failureMessage,
        importResult.updatedCount > 0 || importResult.importedCount > 0 ? "warning" : "error"
      );
    }
  };
  const importPeopleCSV = (selectionModel, users) => __async(void 0, null, function* () {
    var _a;
    if (!(importState == null ? void 0 : importState.source)) return;
    try {
      setLoading(true);
      if (users == null ? void 0 : users.length) {
        const importResult = yield UserImportAPI.importViaCsvV4(users, importState.source);
        notifyImportResult(importResult);
        setImportState({
          domain: importState == null ? void 0 : importState.domain,
          source: importState == null ? void 0 : importState.source,
          result: importResult
        });
        yield refreshCachedUsers();
        handleNext();
        return;
      }
      const finalUserDataForImport = finalisePeopleDataForImport(
        importState,
        (_a = companySettings == null ? void 0 : companySettings.entities) != null ? _a : [],
        attendanceSchedules != null ? attendanceSchedules : [],
        selectionModel != null ? selectionModel : []
      );
      if (finalUserDataForImport.length > 0) {
        let importResult;
        if ((finalUserDataForImport == null ? void 0 : finalUserDataForImport.length) <= MAX_NON_BACKGROUND_PROCESSING) {
          importResult = yield UserImportAPI.importViaCsv(finalUserDataForImport);
        } else if ((finalUserDataForImport == null ? void 0 : finalUserDataForImport.length) > MAX_NON_BACKGROUND_PROCESSING && (importState == null ? void 0 : importState.source)) {
          try {
            const compressedData = compressData(finalUserDataForImport);
            importResult = yield UserImportAPI.importViaCsvV3(compressedData, importState == null ? void 0 : importState.source);
            if (!importResult) {
              showMessage(polyglot.t("importPeopleCSV.errorMessages.failure1"), "error");
              return;
            } else {
              refreshCachedUsers();
              handleNext(true);
              showMessage(`${polyglot.t("importPeopleCSV.importInitiated")}.`, "success");
            }
            return;
          } catch (error) {
            console.error(error);
            showMessage(polyglot.t("importPeopleCSV.errorMessages.failure2"), "error");
            return;
          }
        }
        if (!importResult) {
          showMessage(polyglot.t("importPeopleCSV.errorMessages.failure2"), "error");
          return;
        }
        notifyImportResult(importResult);
        if (!importHasErrors(importResult)) {
          setImportState({
            domain: importState == null ? void 0 : importState.domain,
            source: importState == null ? void 0 : importState.source,
            result: importResult
          });
          refreshCachedUsers();
          handleNext();
        } else {
          notifyImportResult(importResult);
          const departments = (departmentsForCompany != null ? departmentsForCompany : []).map((dept) => ({ label: dept.name, value: dept.id }));
          const sites = (sitesForCompany != null ? sitesForCompany : []).map((dept) => ({ label: dept.name, value: dept.id }));
          const revalidatedResult = yield reValidatePeopleImportResult(importResult, departments, sites, polyglot);
          setImportState({
            domain: importState == null ? void 0 : importState.domain,
            source: importState == null ? void 0 : importState.source,
            result: revalidatedResult
          });
        }
      } else {
        showMessage(polyglot.t("importPeopleCSV.warningMessages.noimport"), "warning");
      }
    } catch (error) {
      showMessage(`${polyglot.t("importPeopleCSV.errorMessages.import")}: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const importAbsenceCSV = () => __async(void 0, null, function* () {
    var _a, _b;
    try {
      setLoading(true);
      const finalAbsenceDataForImport = ((_a = importState == null ? void 0 : importState.result) == null ? void 0 : _a.errors.some((e) => e.entity)) ? ((_b = importState == null ? void 0 : importState.result) == null ? void 0 : _b.errors).flatMap(
        (record) => {
          const {
            firstName,
            lastName,
            workEmail,
            policyName,
            afternoonOnly,
            morningOnly,
            start,
            end,
            startHour,
            endHour,
            notes,
            status
          } = record.entity;
          return {
            firstName,
            lastName,
            workEmail,
            afternoonOnly: afternoonOnly === "Yes",
            morningOnly: morningOnly === "Yes",
            policyName,
            start: ddMMYYYYToIsoDateString(start),
            end: end ? ddMMYYYYToIsoDateString(end) : null,
            startHour,
            startHourTimestamp: startHour ? new Date(startHour) : null,
            endHour,
            endHourTimestamp: endHour ? new Date(endHour) : null,
            notes,
            status: (status ? status : AbsenceStatus.Pending).toLowerCase()
          };
        }
      ) : [];
      if (finalAbsenceDataForImport.length > 0 && finalAbsenceDataForImport.length <= MAX_NON_BACKGROUND_ABSENCES_PROCESSING) {
        const importResult = yield AbsenceImportAPI.importViaCsv(finalAbsenceDataForImport);
        if (!importHasErrors(importResult)) {
          notifyImportResult(importResult);
          handleNext();
        } else if (importResultHasOverlappingErrors(importResult)) {
          notifyImportResult(importResult, true);
          handleNext();
        } else {
          notifyImportResult(importResult);
        }
        setImportState({
          domain: importState == null ? void 0 : importState.domain,
          source: importState == null ? void 0 : importState.source,
          result: importResult
        });
      } else if (finalAbsenceDataForImport.length > MAX_NON_BACKGROUND_ABSENCES_PROCESSING) {
        const compressedData = compressData(finalAbsenceDataForImport);
        const importResult = yield AbsenceImportAPI.importViaCSVUsingQueue(compressedData);
        if (!importResult) {
          showMessage(polyglot.t("importAbsenceCSV.errorMessages.failure1"), "error");
          return;
        } else {
          handleNext(true);
          showMessage(`${polyglot.t("importAbsenceCSV.importInitiated")}.`, "success");
        }
      } else {
        showMessage(polyglot.t("importAbsenceCSV.warningMessages.noimport"), "warning");
      }
    } catch (error) {
      showMessage(`${polyglot.t("importAbsenceCSV.errorMessages.import")} import: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const importAbsenceAdjustmentsCSV = () => __async(void 0, null, function* () {
    var _a, _b;
    try {
      setLoading(true);
      const finalAbsenceAdjustmentsDataForImport = ((_a = importState == null ? void 0 : importState.result) == null ? void 0 : _a.errors.some((e) => e.entity)) ? ((_b = importState == null ? void 0 : importState.result) == null ? void 0 : _b.errors).flatMap(
        (record) => {
          const {
            id,
            firstName,
            lastName,
            workEmail,
            policyName,
            effectiveYear,
            adjustment,
            unit,
            note
          } = record.entity;
          return {
            id,
            firstName,
            lastName,
            workEmail: workEmail.toLowerCase(),
            policyName,
            effectiveYear: Math.round(Number(effectiveYear)),
            adjustment: round2Digits(Number(adjustment)),
            unit,
            note
          };
        }
      ) : [];
      if (finalAbsenceAdjustmentsDataForImport.length > 0 && finalAbsenceAdjustmentsDataForImport.length <= MAX_NON_BACKGROUND_ABSENCE_ADJUSTMENTS_PROCESSING) {
        const importResult = yield AbsenceImportAPI.importAdjustmentsViaCsv(finalAbsenceAdjustmentsDataForImport);
        if (!importHasErrors(importResult)) {
          notifyImportResult(importResult);
          handleNext();
        } else if (importResultHasOverlappingErrors(importResult)) {
          notifyImportResult(importResult, true);
          handleNext();
        } else {
          notifyImportResult(importResult);
        }
        setImportState({
          domain: importState == null ? void 0 : importState.domain,
          source: importState == null ? void 0 : importState.source,
          result: importResult
        });
      } else if (finalAbsenceAdjustmentsDataForImport.length > MAX_NON_BACKGROUND_ABSENCE_ADJUSTMENTS_PROCESSING) {
        const compressedData = compressData(finalAbsenceAdjustmentsDataForImport);
        const importResult = yield AbsenceImportAPI.importAdjustmentsViaCSVUsingQueue(compressedData);
        if (!importResult) {
          showMessage(polyglot.t("importAbsenceCSV.errorMessages.failure1"), "error");
          return;
        } else {
          handleNext(true);
          showMessage(`${polyglot.t("importAbsenceCSV.importInitiated")}.`, "success");
        }
      } else {
        showMessage(polyglot.t("importAbsenceCSV.warningMessages.noimport"), "warning");
      }
    } catch (error) {
      showMessage(`${polyglot.t("importAbsenceCSV.errorMessages.import")} import: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const getPossessionIdBasedOnInUseType = (record, currentUsers) => {
    var _a, _b;
    if (record.entity.inUseBy === InUseByTypes.Email && currentUsers.length > 0)
      return (_a = currentUsers.find((u) => u.emailAddress === record.entity.workEmail)) == null ? void 0 : _a.userId;
    if (record.entity.inUseBy === InUseByTypes.Site && sitesForCompany && sitesForCompany.length > 0 && record.entity.site)
      return (_b = sitesForCompany == null ? void 0 : sitesForCompany.find((s) => {
        var _a2, _b2;
        return ((_a2 = s.name) == null ? void 0 : _a2.toLowerCase()) === ((_b2 = record.entity.site) == null ? void 0 : _b2.toLowerCase());
      })) == null ? void 0 : _b.id;
    return void 0;
  };
  const importDeviceCSV = () => __async(void 0, null, function* () {
    var _a, _b;
    try {
      setLoading(true);
      const usersForAssignment = importState && importState.result && importState.result.errors ? importState.result.errors.filter((e) => e.entity.inUseBy === InUseByTypes.Email).map((e) => e.entity.workEmail).filter(Boolean) : [];
      const currentUsers = usersForAssignment && usersForAssignment.length > 0 ? getCachedUsersByEmail(usersForAssignment) : [];
      const finalDeviceDataForImport = ((_a = importState == null ? void 0 : importState.result) == null ? void 0 : _a.errors.some((e) => e.entity)) ? ((_b = importState == null ? void 0 : importState.result) == null ? void 0 : _b.errors).flatMap(
        (record) => {
          return __spreadProps(__spreadValues({}, record.entity), {
            possessionId: getPossessionIdBasedOnInUseType(record, currentUsers)
          });
        }
      ) : [];
      if (finalDeviceDataForImport.length > 0) {
        const importResult = yield DeviceImportAPI.importViaCsv(finalDeviceDataForImport);
        if (!importHasErrors(importResult)) {
          notifyImportResult(importResult);
          handleNext();
        } else {
          notifyImportResult(importResult);
        }
        setImportState({
          domain: importState == null ? void 0 : importState.domain,
          source: importState == null ? void 0 : importState.source,
          result: importResult
        });
      } else {
        showMessage(polyglot.t("importDeviceCSV.warningMessages.noimport"), "warning");
      }
    } catch (error) {
      showMessage(`${polyglot.t("importDeviceCSV.errorMessages.import")}: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const importAttendanceCSV = () => __async(void 0, null, function* () {
    var _a, _b;
    try {
      setLoading(true);
      const finalAttendanceDataForImport = ((_a = importState == null ? void 0 : importState.result) == null ? void 0 : _a.errors.some((e) => e.entity)) ? ((_b = importState == null ? void 0 : importState.result) == null ? void 0 : _b.errors).flatMap(
        (record) => {
          const { firstName, lastName, workEmail, attendanceType, logDate, start, end } = record.entity;
          return {
            firstName,
            lastName,
            workEmail,
            attendanceType,
            logDate,
            start,
            end
          };
        }
      ) : [];
      if (finalAttendanceDataForImport.length > 0 && finalAttendanceDataForImport.length < MAX_NON_BACKGROUND_ATTENDANCES_PROCESSING) {
        const importResult = yield AttendanceImportAPI.importViaCSVInstant(finalAttendanceDataForImport);
        if (!importHasErrors(importResult)) {
          notifyImportResult(importResult);
          handleNext();
        } else if (importResultHasOverlappingErrors(importResult)) {
          notifyImportResult(importResult, true);
          handleNext();
        } else {
          notifyImportResult(importResult);
        }
        setImportState({
          domain: importState == null ? void 0 : importState.domain,
          source: importState == null ? void 0 : importState.source,
          result: importResult
        });
      } else if (finalAttendanceDataForImport.length >= MAX_NON_BACKGROUND_ATTENDANCES_PROCESSING) {
        const compressedData = compressData(finalAttendanceDataForImport);
        const importResult = yield AttendanceImportAPI.importViaCSVUsingQueue(compressedData);
        if (!importResult) {
          showMessage(polyglot.t("importAttendanceCSV.errorMessages.failure1"), "error");
          return;
        } else {
          handleNext(true);
          showMessage(`${polyglot.t("importAttendanceCSV.importInitiated")}.`, "success");
        }
      } else {
        showMessage(polyglot.t("importAttendanceCSV.warningMessages.noimport"), "warning");
      }
    } catch (error) {
      showMessage(`${polyglot.t("importAttendanceCSV.errorMessages.import")}: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const completeImportHandler = (selectionModel, users) => __async(void 0, null, function* () {
    if ((importState == null ? void 0 : importState.domain) === EntityImportWizardData.Users && (importState == null ? void 0 : importState.source) === EntityImportWizardSource.CSV) {
      yield importPeopleCSV(selectionModel, users);
    } else if ((importState == null ? void 0 : importState.domain) === EntityImportWizardData.Absences && (importState == null ? void 0 : importState.source) === EntityImportWizardSource.CSV) {
      yield importAbsenceCSV();
    } else if ((importState == null ? void 0 : importState.domain) === EntityImportWizardData.AbsenceAdjustments && (importState == null ? void 0 : importState.source) === EntityImportWizardSource.CSV) {
      yield importAbsenceAdjustmentsCSV();
    } else if ((importState == null ? void 0 : importState.domain) === EntityImportWizardData.Devices && (importState == null ? void 0 : importState.source) === EntityImportWizardSource.CSV) {
      yield importDeviceCSV();
    } else if ((importState == null ? void 0 : importState.domain) === EntityImportWizardData.Attendances && (importState == null ? void 0 : importState.source) === EntityImportWizardSource.CSV) {
      yield importAttendanceCSV();
    } else if ((importState == null ? void 0 : importState.domain) === EntityImportWizardData.Users && (importState == null ? void 0 : importState.source) === EntityImportWizardSource.AzureAD) {
      yield importPeopleCSV(selectionModel);
    } else {
      showMessage(
        `${polyglot.t("completeImportHandler.warningMessages.domainNotConfigured")}: ${importState == null ? void 0 : importState.domain} - ${importState == null ? void 0 : importState.source} - ${polyglot.t("completeImportHandler.warningMessages.checkLater")}`,
        "warning"
      );
    }
  });
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.p30, position: "absolute", zIndex: 10, inset: 0, background: themeColors.white }, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", width: "70%", mx: "auto" }, children: [
      !isFirstStep() && /* @__PURE__ */ jsx(IconButton, { sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", left: spacing.m50 }), onClick: handleBack, children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize)) }),
      /* @__PURE__ */ jsx(StepperComponent, { sx: { flex: 1 }, steps: ImportWizardStepperSteps, activeStep }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", right: spacing.m50 }),
          onClick: () => returnToImportSettingsPage(),
          children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey }))
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Stack, { sx: { overflow: "hidden auto", pb: spacing.p30 }, children: {
      Data: () => /* @__PURE__ */ jsx(
        DataDomainChoiceSection,
        {
          importState,
          onNext: (domain) => {
            if (domain) {
              if ([
                EntityImportWizardData.Absences,
                EntityImportWizardData.AbsenceAdjustments,
                EntityImportWizardData.Devices,
                EntityImportWizardData.Attendances
              ].includes(domain)) {
                setImportState({ domain, source: EntityImportWizardSource.CSV });
              } else {
                setImportState({ domain });
              }
            }
            handleNext();
          },
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 }
        }
      ),
      Source: () => {
        var _a;
        return importState && /* @__PURE__ */ jsx(
          DataSourceChoiceSection,
          {
            sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
            importState,
            onNext: function(source, result) {
              setImportState({
                domain: importState.domain,
                source,
                result
              });
              handleNext();
            },
            entities: (_a = companySettings == null ? void 0 : companySettings.entities) != null ? _a : [],
            sites: sitesForCompany != null ? sitesForCompany : [],
            departments: departmentsForCompany != null ? departmentsForCompany : [],
            attendanceSchedules: attendanceSchedules != null ? attendanceSchedules : [],
            getCustomFieldsForForm
          }
        );
      },
      Results: () => importState && /* @__PURE__ */ jsx(
        ImportResultsSection,
        {
          sx: { width: "70%", mx: "auto", mt: spacing.mt40 },
          importState,
          onUpdateAndRevalidate: (result) => {
            setImportState({
              domain: importState.domain,
              source: importState.source,
              result
            });
            const remainingErrors = result.errors.flatMap((r) => r.errors);
            if (remainingErrors.length > 0)
              showMessage("Successfully updated record and re-validated", "success");
          },
          completeImport: completeImportHandler,
          loading,
          setLoading,
          attendanceSchedules: attendanceSchedules != null ? attendanceSchedules : []
        }
      ),
      Complete: () => importState && /* @__PURE__ */ jsx(
        ImportCompleteSection,
        {
          importState,
          onNext: handleNext,
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 }
        }
      )
    }[activeStep]() })
  ] });
};
