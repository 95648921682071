"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as Reject } from "@/images/side-bar-icons/Reject.svg";
import { nestErrorMessage } from "@/lib/errors";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortDate } from "@/v2/components/table/table-sorting.util";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { convertMinutesToHHMM } from "@/v2/feature/absence/absence.util";
import { AttendanceAPI } from "@/v2/feature/attendance/attendance.api";
import { AttendanceStatus } from "@/v2/feature/attendance/attendance.interface";
import { getAttendanceStatusIcon } from "@/v2/feature/attendance/attendance.util";
import { AttendanceEmptyState } from "@/v2/feature/attendance/company/components/empty-state/attendance-empty-state.component";
import { TrackTimeDrawer } from "@/v2/feature/attendance/company/components/track-time-drawer.component";
import { BadgeLoaderHeader } from "@/v2/feature/attendance/components/badge-loader-header.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { tableIconButtonSx, tablePrimaryIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const UserAttendancesDetail = ({
  selectedUserAttendances,
  selectedUser,
  loading,
  view,
  refresh,
  rangeType,
  selectedDate
}) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById, loaded } = useCachedUsers();
  const user = selectedUser ? getCachedUserById(selectedUser) : void 0;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [mode, setMode] = useState(void 0);
  const [showMessage] = useMessage();
  const generateDynamicColumns = (data) => {
    const uniqueNames = /* @__PURE__ */ new Set();
    data.forEach((userData) => {
      userData.loggedHoursByType.forEach((entry) => {
        uniqueNames.add(entry.name);
      });
    });
    return Array.from(uniqueNames).map((name) => ({
      id: name,
      header: () => name,
      accessorFn: (row) => row,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const entry = original.loggedHoursByType.find((e) => e.name === name);
        const isPositive = (entry == null ? void 0 : entry.differenceInLoggedAndScheduled) && (entry == null ? void 0 : entry.differenceInLoggedAndScheduled) > 0;
        const isMoreOrLess = Boolean(
          (entry == null ? void 0 : entry.differenceInLoggedAndScheduled) && (entry == null ? void 0 : entry.differenceInLoggedAndScheduled) !== 0
        );
        return /* @__PURE__ */ jsx(Fragment, { children: entry ? /* @__PURE__ */ jsxs("div", { style: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx("div", { children: convertMinutesToHHMM(entry.totalLength) }),
          isMoreOrLess && /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { color: isPositive ? themeColors.Green : themeColors.Red }, children: [
            isPositive ? "+ " : "",
            convertMinutesToHHMM(entry == null ? void 0 : entry.differenceInLoggedAndScheduled),
            "h"
          ] })
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {}) });
      },
      maxSize: 120,
      minSize: 100
    }));
  };
  const handleSelectedRequest = (request, mode2) => {
    setSelectedRequest(request);
    setMode(mode2);
    setIsDrawerOpen(true);
  };
  const handleRowClick = useCallback(({ original }) => {
    handleSelectedRequest(original, "view");
  }, []);
  const columns = useMemo(() => {
    const dateColumn = [
      {
        id: "logDate",
        header: () => polyglot.t("AttendanceDomain.date"),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.logDate),
        accessorFn: (row) => row,
        maxSize: 120,
        minSize: 80,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.logDate ? getDateString(original.logDate) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      }
    ];
    const staticColumns = [
      {
        header: () => polyglot.t("AttendanceDomain.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Fragment, { children: original.status ? getAttendanceStatusIcon(original.status, false, polyglot) : /* @__PURE__ */ jsx(EmptyCell, {}) }),
        maxSize: 120,
        minSize: 100
      },
      {
        header: () => polyglot.t("AttendanceDomain.schedule"),
        accessorFn: (row) => row,
        id: "schedule",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: ((_a = original == null ? void 0 : original.schedule) == null ? void 0 : _a.name) ? original == null ? void 0 : original.schedule.name : /* @__PURE__ */ jsx(EmptyCell, {}) });
        },
        maxSize: 120,
        minSize: 100
      },
      {
        header: () => polyglot.t("AttendanceDomain.notes"),
        accessorFn: (row) => row,
        id: "notes",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.notes ? original.notes : /* @__PURE__ */ jsx(EmptyCell, {}) }),
        maxSize: 120,
        minSize: 100
      },
      {
        header: () => " ",
        accessorFn: (row) => row,
        id: "actions",
        enableSorting: false,
        maxSize: 100,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(
            RowActions,
            {
              row: original,
              canApproveOrReject: original.canApproveOrReject,
              onEdit: () => handleSelectedRequest(original, "edit"),
              refresh
            }
          );
        }
      }
    ];
    return selectedUserAttendances ? [...dateColumn, ...generateDynamicColumns(selectedUserAttendances == null ? void 0 : selectedUserAttendances.data), ...staticColumns] : [...dateColumn, ...staticColumns];
  }, [polyglot, selectedUserAttendances, refresh]);
  const isLoading = useMemo(() => Boolean(loading || !loaded), [loaded, loading]);
  const isSubmittedAttendance = useMemo(
    () => {
      var _a;
      return Boolean(
        (_a = selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) == null ? void 0 : _a.some(
          (a) => a.status === AttendanceStatus.InProgress || a.status === AttendanceStatus.Submitted
        )
      );
    },
    [selectedUserAttendances == null ? void 0 : selectedUserAttendances.data]
  );
  const handleApproveAll = () => __async(void 0, null, function* () {
    try {
      if (selectedUser) {
        const update = { type: rangeType, date: new LocalDate(selectedDate).toDateString() };
        yield AttendanceAPI.approveUsersRequestByWeekOrMonth(selectedUser, update);
        yield refresh();
        showMessage(polyglot.t("AttendanceDomain.approvedAll"), "success");
      }
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotApproveAll", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const handleRejectAll = () => __async(void 0, null, function* () {
    try {
      if (selectedUser) {
        const update = { type: rangeType, date: new LocalDate(selectedDate).toDateString() };
        yield AttendanceAPI.rejectUsersRequestByWeekOrMonth(selectedUser, update);
        yield refresh();
        showMessage(polyglot.t("AttendanceDomain.rejectedAll"), "success");
      }
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotRejectAll", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  return /* @__PURE__ */ jsx(Box, { sx: spacing.pl20, children: /* @__PURE__ */ jsxs(Box, { sx: spacing.pt20, children: [
    selectedUserAttendances || isLoading ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        AttendanceDetailHeader,
        {
          loading: isLoading,
          userId: selectedUser,
          user,
          selectedUserAttendances,
          handleApproveAll,
          handleRejectAll,
          isSubmittedAttendance
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: (selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) ? [...selectedUserAttendances == null ? void 0 : selectedUserAttendances.data] : [],
          columnData: columns,
          hidePagination: true,
          loading: isLoading,
          rowClick: handleRowClick
        }
      ) })
    ] }) : /* @__PURE__ */ jsx(AttendanceEmptyState, {}),
    /* @__PURE__ */ jsx(
      TrackTimeDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        userId: selectedRequest == null ? void 0 : selectedRequest.userId,
        attendanceId: selectedRequest == null ? void 0 : selectedRequest.id,
        refresh,
        onClose: () => {
          setIsDrawerOpen(false);
        },
        afterClose: () => {
          setSelectedRequest(null);
          setMode(void 0);
        },
        mode,
        view
      }
    )
  ] }) });
};
const AttendanceDetailHeader = ({
  loading,
  userId,
  user,
  selectedUserAttendances,
  handleApproveAll,
  handleRejectAll,
  isSubmittedAttendance
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Box, { sx: spacing.pt20, children: loading ? /* @__PURE__ */ jsx(BadgeLoaderHeader, {}) : /* @__PURE__ */ jsxs("div", { style: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
    /* @__PURE__ */ jsxs("div", { style: { display: "flex", alignItems: "center", gap: spacing.g40 }, children: [
      userId && user && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(UserAvatar, { userId, size: "medium" }, userId),
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t(user.displayName) }),
          ((_b = (_a = user.role) == null ? void 0 : _a.jobPosition) == null ? void 0 : _b.title) && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t((_d = (_c = user.role) == null ? void 0 : _c.jobPosition) == null ? void 0 : _d.title) })
        ] })
      ] }),
      /* @__PURE__ */ jsx("div", { style: { display: "flex", alignItems: "center", gap: spacing.g40 }, children: selectedUserAttendances && (selectedUserAttendances == null ? void 0 : selectedUserAttendances.header) && selectedUserAttendances.header.map((badge) => /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: badge.type }),
        /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
          convertMinutesToHHMM(badge.totalLength),
          "h"
        ] })
      ] })) })
    ] }),
    isSubmittedAttendance && /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: [
          {
            icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
            handler: () => __async(void 0, null, function* () {
              return yield handleApproveAll();
            }),
            label: polyglot.t("AttendanceDomain.approveAll")
          },
          {
            icon: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize)),
            handler: () => __async(void 0, null, function* () {
              return yield handleRejectAll();
            }),
            label: polyglot.t("AttendanceDomain.rejectAll")
          }
        ],
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: polyglot.t("General.actions"),
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end"
        }
      }
    )
  ] }) });
};
const RowActions = ({ row, canApproveOrReject, onEdit, refresh }) => {
  const { polyglot } = usePolyglot();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mode, setMode] = useState(null);
  const [showMessage] = useMessage();
  const handleClose = () => {
    setAnchorEl(null);
    setMode(null);
  };
  const approveAttendanceRequest = useCallback(
    (requestId, userId, status) => __async(void 0, null, function* () {
      try {
        yield AttendanceAPI.approveAttendanceById(userId, requestId, status);
        if (status === AttendanceStatus.Approved)
          showMessage(polyglot.t("AttendanceDomain.requestApproved"), "success");
        else showMessage(polyglot.t("AttendanceDomain.requestRejected"), "success");
        yield refresh();
      } catch (error) {
        if (status === AttendanceStatus.Approved)
          showMessage(
            polyglot.t("AttendanceDomain.errors.couldNotApprove", { nestError: nestErrorMessage(error) }),
            "error"
          );
        else
          showMessage(
            polyglot.t("AttendanceDomain.errors.couldNotReject", { nestError: nestErrorMessage(error) }),
            "error"
          );
      }
    }),
    [polyglot, refresh, showMessage]
  );
  const deleteAttendanceRequest = useCallback(
    (requestId) => __async(void 0, null, function* () {
      try {
        yield AttendanceAPI.deleteAttendanceById(requestId);
        showMessage(polyglot.t("AttendanceDomain.requestDeleted"), "success");
        yield refresh();
      } catch (error) {
        showMessage(
          polyglot.t("AttendanceDomain.errors.couldNotDelete", { nestError: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, refresh, showMessage]
  );
  return /* @__PURE__ */ jsxs(Box, { onClick: (e) => e.stopPropagation(), sx: { display: "flex", gap: spacing.g5, maxWidth: "100px" }, children: [
    /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: [
          {
            icon: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, iconSize)),
            handler: onEdit,
            label: polyglot.t("General.edit")
          },
          {
            icon: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize)),
            handler: (e) => {
              if (e) {
                setAnchorEl(e.currentTarget);
                setMode("delete");
                e.stopPropagation();
              }
            },
            label: polyglot.t("General.delete")
          }
        ],
        actionButtonDetails: {
          type: "iconButton",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: polyglot.t("General.actions"),
          icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
        }
      }
    ),
    canApproveOrReject && row.status === AttendanceStatus.Submitted && /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tableIconButtonSx,
        onClick: (e) => {
          setAnchorEl(e.currentTarget);
          setMode("reject");
          e.stopPropagation();
        },
        children: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize))
      }
    ),
    canApproveOrReject && row.status === AttendanceStatus.Submitted && /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tablePrimaryIconButtonSx,
        onClick: (e) => __async(void 0, null, function* () {
          yield approveAttendanceRequest(row.id, row.userId, AttendanceStatus.Approved);
          e.stopPropagation();
        }),
        children: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize))
      }
    ),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: mode !== null,
        onClose: () => handleClose(),
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (mode === "reject") yield approveAttendanceRequest(row.id, row.userId, AttendanceStatus.Rejected);
          if (mode === "delete") yield deleteAttendanceRequest(row.id);
          setMode(null);
        }),
        message: mode === "reject" ? polyglot.t("AttendanceDomain.wantToReject") : mode === "delete" ? polyglot.t("AttendanceDomain.wantToDelete") : "",
        callToAction: polyglot.t("General.yes")
      }
    )
  ] });
};
