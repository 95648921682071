"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AttendancePenaltyRuleCell } from "@v2/feature/attendance/settings/components/attendance-penalty-rule-cell.component";
import { AttendancePenaltyAPI } from "@v2/feature/attendance/subfeatures/attendance-penalty/attendance-penalty.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const AttendancePayrollPenaltiesDrawer = ({
  isOpen,
  setIsOpen,
  schedule,
  allPenalties,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (!isOpen) return;
    setSelected(allPenalties.filter((r) => r.enabled).map((r) => r.id));
  }, [isOpen, allPenalties]);
  const [isUpdating, setIsUpdating] = useState(false);
  const enableAndDisable = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsUpdating(true);
      const toEnable = selected;
      const toDisable = allPenalties.filter((r) => !selected.includes(r.id)).map((r) => r.id);
      yield AttendancePenaltyAPI.enableAndDisableAttendancePenaltyRules(schedule.id, toEnable, toDisable);
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setIsUpdating(false);
    }
  }), [setIsOpen, schedule, selected, allPenalties, showMessage, polyglot, refresh]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, fixedWidthPx: 465, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { mb: "20px" }, children: polyglot.t("AttendanceModule.penalties") }),
    allPenalties.map((p) => {
      const isSelected = selected.includes(p.id);
      return /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            px: "5px",
            display: "flex",
            gap: "5px",
            alignItems: "center",
            mb: "5px",
            ":hover": { bgcolor: themeColors.Background, borderRadius: "10px" },
            cursor: "pointer"
          },
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setSelected((prev) => prev.includes(p.id) ? prev.filter((id) => id !== p.id) : [...prev, p.id]);
          },
          children: [
            /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: "allSelected",
                checked: isSelected,
                value: "allSelected"
              }
            ) }),
            /* @__PURE__ */ jsxs(Box, { children: [
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: p.name }),
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px" }, children: [
                /* @__PURE__ */ jsx(AttendancePenaltyRuleCell, { width: "90px", percent: p.multiplier1, variant: "captionSmall", color: "Grey" }),
                /* @__PURE__ */ jsx(AttendancePenaltyRuleCell, { width: "90px", percent: p.multiplier2, variant: "captionSmall", color: "Grey" }),
                /* @__PURE__ */ jsx(AttendancePenaltyRuleCell, { width: "90px", percent: p.multiplier3, variant: "captionSmall", color: "Grey" }),
                /* @__PURE__ */ jsx(AttendancePenaltyRuleCell, { width: "90px", percent: p.multiplier4, variant: "captionSmall", color: "Grey" })
              ] })
            ] })
          ]
        },
        p.id
      );
    }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "10px", mt: "40px" }, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          type: "button",
          sizeVariant: "medium",
          colorVariant: "primary",
          name: polyglot.t("General.update"),
          loading: isUpdating,
          onClick: enableAndDisable
        }
      )
    ] })
  ] }) });
};
