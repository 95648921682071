"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class PayItemAPI {
  static updateUpcomingOneOffPayItem(oneOffPaymentId, userId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/payroll/pay-items/users/${userId}/upcoming/one-off/${oneOffPaymentId}`, update);
    });
  }
  static createUpcomingPayItem(userId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/payroll/pay-items/users/${userId}/upcoming/one-off`, data);
    });
  }
  static deleteUpcomingPayItem(userId, oneOffPaymentId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/payroll/pay-items/users/${userId}/upcoming/one-off/${oneOffPaymentId}`);
    });
  }
}
export class PayItemEndpoints {
  static getUserOneOffPayments(userId) {
    return {
      url: `/apiv2/payroll/pay-items/users/${userId}/one-off-payments`
    };
  }
  static getUserOneOffPaymentsByAttendance(userId, attendanceId) {
    return {
      url: `/apiv2/payroll/pay-items/users/${userId}/attendances/${attendanceId}`
    };
  }
  static getUserOneOffPaymentsByAbsence(userId, absenceId) {
    return {
      url: `/apiv2/payroll/pay-items/users/${userId}/absences/${absenceId}`
    };
  }
}
