"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import { ContractAPI } from "@/api-client/contract.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Success } from "@/images/side-bar-icons/ok-green.svg";
import { SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE } from "@/lib/routes";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { MissingCompensation } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-compensation.component";
import { MissingContract } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-contract.component";
import { MissingEquity } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-equity.component";
import { MissingRole } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-role.component";
import { MissingAddressFormForPersonalContractSigning } from "@/v2/feature/templates/components/missing-address-form-contract-signing.component";
import { MissingPersonalFormForPersonalContractSigning } from "@/v2/feature/templates/components/missing-personal-form-contract-signing.component";
import {
  CONTRACT_FIELDS_CATEGORIES,
  defaultMissingFieldValues,
  FIELD_DISPLAY_NAME,
  PERSONAL_TYPE_FIELDS,
  WORK_TYPE_FIELDS
} from "@/v2/feature/templates/templates.interface";
import { getTemplateSchema } from "@/v2/feature/templates/validations/missing-field-form-validations";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { toBase64 } from "@/v2/util/base64.util";
import { getNonEmptyObjectFields } from "@/v2/util/object.util";
const returnValueOrEmptyString = (input, suffix = "") => {
  if (input || !isNaN(Number(input)) && Number(input) !== 0) {
    return `${input}${suffix}`;
  }
  return "";
};
export const getFormattedAddress = (address) => {
  const addressLine1 = returnValueOrEmptyString(address == null ? void 0 : address.address_line_1, ", ");
  const addressLine2 = returnValueOrEmptyString(address == null ? void 0 : address.address_line_2, ", ");
  const address_city = returnValueOrEmptyString(address == null ? void 0 : address.address_city, ", ");
  const postalCode = returnValueOrEmptyString(address == null ? void 0 : address.address_postalcode, ", ");
  const country = returnValueOrEmptyString(address == null ? void 0 : address.address_country);
  return `${addressLine1}${addressLine2}${address_city}${postalCode}${country}`;
};
export const getUnitTypeOptions = (polyglot) => {
  return [
    { value: "Months", label: polyglot.t("getUnitTypeOptions.months") },
    { value: "Weeks", label: polyglot.t("getUnitTypeOptions.weeks") },
    { value: "Days", label: polyglot.t("getUnitTypeOptions.days") }
  ];
};
const formatEquity = (equity) => {
  const dateText = equity.grantDate ? `granted on ${equity.grantDate}` : "";
  const typeText = equity.type ? `${equity.type}(s)` : "";
  const amount = equity.amount || "";
  return `${amount} ${typeText} ${dateText}`;
};
const RECIPIENT_SHOULD_POPULATE_PLACEHOLDER = "<recipient_populates_";
export const MissingFieldsDetail = (missingFieldsForCategory) => {
  return /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey }), children: (missingFieldsForCategory == null ? void 0 : missingFieldsForCategory.length) ? missingFieldsForCategory == null ? void 0 : missingFieldsForCategory.map((missingField) => FIELD_DISPLAY_NAME[missingField.fieldId]).filter(Boolean).join(", ") : "" });
};
export const MissingTemplateFieldModal = ({
  setOpen,
  open,
  templateId,
  missingFields,
  contractRecipientId,
  companySignatoryUserId,
  returnPath,
  refreshMissingFields
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [isSavingMissingFields, setIsSavingMissingFields] = useState(false);
  const [recipientShouldPopulate, setRecipientShouldPopulate] = useState([]);
  const [hasMissingWorkFields, setHasMissingWorkFields] = useState(false);
  const [hasMissingPersonalFields, setHasMissingPersonalFields] = useState(false);
  const [fillingMissingWorkFields, setFillingMissingWorkFields] = useState(false);
  const [fillingMissingPersonalFields, setFillingMissingPersonalFields] = useState(false);
  const [askRecipient, setAskRecipient] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const formik = useFormik({
    initialValues: defaultMissingFieldValues,
    enableReinitialize: true,
    validationSchema: getTemplateSchema(missingFields),
    onSubmit: handleSubmitAction
  });
  const redirectToSendContractRoutePage = useCallback(() => __async(void 0, null, function* () {
    const getFieldsToBePopulatedByRecipient = () => {
      const fieldsToBePopulatedByRecipient = {};
      for (const eachKey of recipientShouldPopulate) {
        if (PERSONAL_TYPE_FIELDS.includes(eachKey)) {
          fieldsToBePopulatedByRecipient[eachKey] = `${RECIPIENT_SHOULD_POPULATE_PLACEHOLDER}${eachKey}>`;
        }
      }
      return fieldsToBePopulatedByRecipient;
    };
    try {
      const templateFields = {
        employee_role: formik.values.role,
        employee_address: formik.values.address_line_1 ? getFormattedAddress({
          address_line_1: returnValueOrEmptyString(formik.values.address_line_1),
          address_line_2: returnValueOrEmptyString(formik.values.address_line_2),
          address_city: returnValueOrEmptyString(formik.values.address_city),
          address_country: returnValueOrEmptyString(formik.values.address_country),
          address_postalcode: returnValueOrEmptyString(formik.values.address_postalcode)
        }) : "",
        employee_probation_period: formik.values.probation_period_length ? `${returnValueOrEmptyString(formik.values.probation_period_length)} ${returnValueOrEmptyString(
          formik.values.probation_period_unit
        )}` : "",
        employee_notice_period: formik.values.notice_period_length ? `${returnValueOrEmptyString(formik.values.notice_period_length)} ${returnValueOrEmptyString(
          formik.values.notice_period_unit
        )}` : "",
        compensation_rate: returnValueOrEmptyString(formik.values.salary_rate),
        compensation_salary_basis: returnValueOrEmptyString(formik.values.salary_basis),
        compensation_equity: formik.values.equity_amount ? formatEquity({
          grantDate: dayjs(new LocalDate(formik.values.equity_grant_date).getDate()).format("DD/MM/YYYY"),
          type: formik.values.equity_type,
          amount: formik.values.equity_amount
        }) : ""
      };
      const fieldsForRecipient = getFieldsToBePopulatedByRecipient();
      const finalData = __spreadValues(__spreadValues(__spreadValues({}, formik.values), templateFields), fieldsForRecipient);
      setIsSavingMissingFields(true);
      yield ContractAPI.save({
        recipientId: contractRecipientId,
        fieldsToSave: finalData
      });
      showMessage(polyglot.t("MissingTemplateFieldModal.successMessages.update"), "success");
      setIsSavingMissingFields(false);
      let missingTemplateData = getNonEmptyObjectFields(finalData);
      const stateToPassThrough = {
        returnPath,
        companySignatoryUserId,
        contractTemplateData: toBase64({
          missingTemplateData
        })
      };
      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE, {
          templateId,
          userId: contractRecipientId
        }),
        stateToPassThrough
      );
    } catch (error) {
      console.error("Failed to save data", error.message);
      showMessage(polyglot.t("MissingTemplateFieldModal.errorMessages.save"), "error");
    }
  }), [
    polyglot,
    companySignatoryUserId,
    contractRecipientId,
    formik.values,
    recipientShouldPopulate,
    returnPath,
    routerHistory,
    showMessage,
    templateId
  ]);
  function handleSubmitAction() {
    if (!askRecipient && hasMissingPersonalFields && fillingMissingPersonalFields) {
      setAskRecipient(true);
      askRecipientToPopulatePersonalFields();
    } else if (fillingMissingPersonalFields && hasMissingWorkFields) {
      setFillingMissingPersonalFields(false);
      setFillingMissingWorkFields(true);
    } else if (!hasMissingPersonalFields && !hasMissingWorkFields || fillingMissingPersonalFields && !hasMissingWorkFields) {
      setFillingMissingPersonalFields(false);
      redirectToSendContractRoutePage();
    }
  }
  const disableMissingFields = useMemo(() => {
    let disable = false;
    const checkForMissingFieldsBasedOnType = (key, disable2) => {
      var _a, _b, _c;
      switch (key) {
        case "employee_first_name":
        case "employee_last_name":
        case "company_signature":
        case "employee_reports_to":
        case "employee_dob":
        case "employee_passport_number":
        case "employee_department":
        case "employee_site":
        case "compensation_currency":
        case "compensation_equity_type":
        case "compensation_equity_grant_date":
        case "compensation_equity_amount":
        case "compensation_equity_vesting_start":
        case "compensation_equity_vesting_period":
        case "compensation_equity_vesting_cliff":
        case "compensation_equity_exercise_price":
        case "employee_start_date": {
          disable2 = !formik.values[key];
          break;
        }
        case "employee_role": {
          disable2 = !formik.values["role"];
          break;
        }
        case "employee_attendance_schedule": {
          disable2 = formik.values["employee_attendance_schedule"] === 0 ? false : !!formik.values["employee_attendance_schedule"];
          break;
        }
        case "employee_address": {
          disable2 = !formik.values["address_line_1"] || !formik.values["address_city"] || !formik.values["address_country"] || !formik.values["address_postalcode"];
          break;
        }
        case "employee_notice_period":
        case "employee_probation_period": {
          disable2 = !formik.values["probation_period_unit"] || !formik.values["probation_period_length"] || !formik.values["notice_period_unit"] || !formik.values["notice_period_length"];
          break;
        }
        case "compensation_rate":
        case "compensation_salary_basis": {
          disable2 = !formik.values["salary_pay_schedule"] || !formik.values["salary_basis"] || !formik.values["salary_rate"] || !formik.values["salary_annual_amount"];
          if (["Daily", "Hourly"].includes((_a = formik.values) == null ? void 0 : _a.salary_basis)) {
            disable2 = !formik.values["salary_units"];
          }
          break;
        }
        case "compensation_units": {
          disable2 = !!(((_b = formik.values) == null ? void 0 : _b.compensation_salary_basis) && ["Daily", "Hourly"].includes((_c = formik.values) == null ? void 0 : _c.compensation_salary_basis) && !formik.values["compensation_units"]);
          break;
        }
        case "compensation_equity": {
          disable2 = !formik.values["equity_grant_date"] || !formik.values["equity_type"] || !formik.values["equity_amount"];
          break;
        }
      }
      return disable2;
    };
    if (fillingMissingPersonalFields && hasMissingPersonalFields) return false;
    for (const key of Object.keys(missingFields).filter((f) => Boolean(f))) {
      if (fillingMissingWorkFields && WORK_TYPE_FIELDS.includes(key) && !recipientShouldPopulate.includes(key)) {
        disable = checkForMissingFieldsBasedOnType(key, disable);
      } else if (fillingMissingPersonalFields && PERSONAL_TYPE_FIELDS.includes(key) && !recipientShouldPopulate.includes(key)) {
        disable = checkForMissingFieldsBasedOnType(key, disable);
      }
      if (disable) {
        break;
      }
    }
    return disable;
  }, [formik.values, missingFields, fillingMissingPersonalFields, fillingMissingWorkFields, recipientShouldPopulate]);
  const missingFieldsButtonText = useMemo(() => {
    if (!hasMissingPersonalFields && !hasMissingWorkFields) {
      return polyglot.t("MissingTemplateFieldModal.preview");
    }
    if (hasMissingWorkFields && fillingMissingWorkFields && hasMissingPersonalFields) {
      return polyglot.t("MissingTemplateFieldModal.updateFields");
    }
    if (hasMissingWorkFields && fillingMissingWorkFields && !hasMissingPersonalFields) {
      return polyglot.t("MissingTemplateFieldModal.continue");
    }
    if (hasMissingPersonalFields && fillingMissingPersonalFields) {
      return polyglot.t("MissingTemplateFieldModal.next");
    }
    return polyglot.t("MissingTemplateFieldModal.preview");
  }, [
    polyglot,
    fillingMissingPersonalFields,
    fillingMissingWorkFields,
    hasMissingPersonalFields,
    hasMissingWorkFields
  ]);
  const askRecipientToPopulatePersonalFields = () => {
    let recipientFields = [];
    for (const eachField of categorisedMissingFields) {
      if ([CustomProfileFormType.Details, CustomProfileFormType.Address, CustomProfileFormType.Basic].includes(
        eachField.category
      )) {
        recipientFields = [...recipientFields, eachField.fieldId];
      }
    }
    setRecipientShouldPopulate(recipientFields);
  };
  const handleRefresh = useCallback(() => __async(void 0, null, function* () {
    setIsRefreshing(true);
    yield refreshMissingFields == null ? void 0 : refreshMissingFields(templateId, contractRecipientId, companySignatoryUserId);
    setIsRefreshing(false);
  }), [companySignatoryUserId, contractRecipientId, refreshMissingFields, templateId]);
  const categorisedMissingFields = useMemo(() => {
    const missingFieldsForTemplate = [];
    for (const eachField of Object.keys(missingFields)) {
      missingFieldsForTemplate.push({
        category: CONTRACT_FIELDS_CATEGORIES[eachField],
        fieldId: eachField
      });
    }
    return missingFieldsForTemplate;
  }, [missingFields]);
  useEffect(() => {
    const hasMissingPersonal = categorisedMissingFields.some(
      (f) => [CustomProfileFormType.Details, CustomProfileFormType.Address, CustomProfileFormType.Basic].includes(f.category)
    );
    const hasMissingWork = categorisedMissingFields.some(
      (f) => [
        CustomProfileFormType.Salary,
        CustomProfileFormType.Equity,
        CustomProfileFormType.Contract,
        CustomProfileFormType.Role
      ].includes(f.category)
    );
    const shouldFillPersonal = hasMissingPersonal && !askRecipient;
    setHasMissingPersonalFields(shouldFillPersonal);
    setHasMissingWorkFields(hasMissingWork);
    setFillingMissingPersonalFields(shouldFillPersonal);
    setFillingMissingWorkFields(!shouldFillPersonal && hasMissingWork);
  }, [askRecipient, categorisedMissingFields]);
  const sections = useMemo(() => {
    const updateContractWithMissingDomainFields = (values) => __async(void 0, null, function* () {
      try {
        const addressValues = values;
        const personalValues = values;
        const templateFields = {
          employee_address: (addressValues == null ? void 0 : addressValues.addressLine1) ? getFormattedAddress({
            address_line_1: returnValueOrEmptyString(addressValues.addressLine1),
            address_line_2: returnValueOrEmptyString(addressValues.addressLine2),
            address_city: returnValueOrEmptyString(addressValues.city),
            address_country: returnValueOrEmptyString(addressValues.country),
            address_postalcode: returnValueOrEmptyString(addressValues.postcode)
          }) : "",
          employee_address_line1: addressValues == null ? void 0 : addressValues.addressLine1,
          employee_address_line2: addressValues == null ? void 0 : addressValues.addressLine2,
          employee_address_city: addressValues == null ? void 0 : addressValues.city,
          employee_address_country: addressValues == null ? void 0 : addressValues.country,
          employee_address_postcode: addressValues == null ? void 0 : addressValues.postcode,
          // Personal Information
          employee_dob: (personalValues == null ? void 0 : personalValues.dob) ? personalValues.dob : void 0,
          employee_passport_number: (personalValues == null ? void 0 : personalValues.passportNumber) ? personalValues.passportNumber : void 0,
          employee_personal_email: (personalValues == null ? void 0 : personalValues.personalEmail) ? personalValues.personalEmail : void 0
        };
        const finalData = __spreadValues(__spreadValues({}, values), templateFields);
        setIsSavingMissingFields(true);
        yield ContractAPI.save({
          recipientId: contractRecipientId,
          fieldsToSave: finalData
        });
        showMessage(polyglot.t("MissingTemplateFieldModal.successMessages.update"), "success");
      } catch (error) {
        console.error("Failed to save data", error.message);
        showMessage(polyglot.t("MissingTemplateFieldModal.errorMessages.save"), "error");
      } finally {
        setIsSavingMissingFields(false);
      }
    });
    const hasMissingField = (category) => categorisedMissingFields.some((f) => f.category === category);
    const listOfMissingFieldsForCategory = (category) => categorisedMissingFields.filter((f) => f.category === category);
    const result = [];
    if (hasMissingField(CustomProfileFormType.Details) && fillingMissingPersonalFields)
      result.push(
        /* @__PURE__ */ jsx(
          MissingPersonalFormForPersonalContractSigning,
          {
            userId: contractRecipientId,
            refreshData: handleRefresh,
            updateDomainForMissingFields: updateContractWithMissingDomainFields,
            missingFieldsForCategory: listOfMissingFieldsForCategory(CustomProfileFormType.Details)
          }
        )
      );
    if (hasMissingField(CustomProfileFormType.Address) && fillingMissingPersonalFields)
      result.push(
        /* @__PURE__ */ jsx(
          MissingAddressFormForPersonalContractSigning,
          {
            userId: contractRecipientId,
            refreshData: handleRefresh,
            updateDomainForMissingFields: updateContractWithMissingDomainFields,
            missingFieldsForCategory: listOfMissingFieldsForCategory(CustomProfileFormType.Address)
          }
        )
      );
    if (hasMissingField(CustomProfileFormType.Salary) && fillingMissingWorkFields)
      result.push(
        /* @__PURE__ */ jsx(
          MissingCompensation,
          {
            userId: contractRecipientId,
            refreshPayroll: handleRefresh,
            missingFieldsForCategory: listOfMissingFieldsForCategory(CustomProfileFormType.Salary)
          }
        )
      );
    if (hasMissingField(CustomProfileFormType.Contract) && fillingMissingWorkFields)
      result.push(
        /* @__PURE__ */ jsx(
          MissingContract,
          {
            onlyAdd: true,
            userId: contractRecipientId,
            refreshPayroll: handleRefresh,
            missingFieldsForCategory: listOfMissingFieldsForCategory(CustomProfileFormType.Contract)
          }
        )
      );
    if (hasMissingField(CustomProfileFormType.Equity) && fillingMissingWorkFields)
      result.push(
        /* @__PURE__ */ jsx(
          MissingEquity,
          {
            userId: contractRecipientId,
            refreshData: handleRefresh,
            missingFieldsForCategory: listOfMissingFieldsForCategory(CustomProfileFormType.Equity)
          }
        )
      );
    if (hasMissingField(CustomProfileFormType.Role) && fillingMissingWorkFields)
      result.push(
        /* @__PURE__ */ jsx(
          MissingRole,
          {
            userId: contractRecipientId,
            refreshData: handleRefresh,
            missingFieldsForCategory: listOfMissingFieldsForCategory(CustomProfileFormType.Role)
          }
        )
      );
    return result;
  }, [
    polyglot,
    categorisedMissingFields,
    contractRecipientId,
    fillingMissingPersonalFields,
    fillingMissingWorkFields,
    handleRefresh,
    showMessage
  ]);
  const mainDrawerCopy = useMemo(() => {
    return polyglot.t("MissingTemplateFieldModal.errorMessages.missingData", {
      type: fillingMissingPersonalFields ? "personal" : fillingMissingWorkFields ? "work" : "",
      complete: fillingMissingWorkFields ? "Please complete it before previewing the document." : ""
    });
  }, [polyglot, fillingMissingPersonalFields, fillingMissingWorkFields]);
  const additionaldrawerText = useMemo(() => {
    let copy = "";
    if (hasMissingPersonalFields && fillingMissingPersonalFields) {
      copy = polyglot.t("MissingTemplateFieldModal.copyMessage");
    }
    return copy;
  }, [polyglot, hasMissingPersonalFields, fillingMissingPersonalFields]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: polyglot.t("MissingTemplateFieldModal.missingInformation") }),
    /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.mt10 }), children: [
      mainDrawerCopy,
      /* @__PURE__ */ jsx("br", {}),
      additionaldrawerText
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20, gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("MissingTemplateFieldModal.recipient") }),
      /* @__PURE__ */ jsx(UserCell, { userId: contractRecipientId, nameSx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }) })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20 }, children: [
      isRefreshing && /* @__PURE__ */ jsx(LoadingSpinner, {}),
      !isRefreshing && sections.length === 0 && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10, mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Success, { height: "1em", style: { fill: themeColors.Green, flexShrink: 0 } }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("MissingTemplateFieldModal.allInfoPresent") })
      ] }),
      !isRefreshing && sections.length > 0 && sections.map((section, idx) => /* @__PURE__ */ jsx(
        Stack,
        {
          sx: { py: spacing.p10, borderTop: idx ? `1px solid ${themeColors.lightGrey}` : void 0 },
          children: section
        },
        idx
      ))
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", justifyContent: "space-between", width: "100%" }, spacing.mt40), children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        name: missingFieldsButtonText,
        loading: isSavingMissingFields,
        disabled: disableMissingFields
      }
    ) }) })
  ] }) }) }) }) });
};
