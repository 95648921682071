"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { FixedSizeList } from "react-window";
import { CompanyDepartmentAPI, SiteAPI } from "@/api-client/index.api";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { filterStringToObject } from "@/v2/feature/user/user.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const SpecificUserModal = ({
  isSpecificOpen,
  setSpecificIsOpen,
  label,
  value,
  onChange,
  filterValue,
  setLastValidFilterValue,
  onClose,
  userOptions,
  allowEmpty = false,
  excludeUsers
}) => {
  const { polyglot } = usePolyglot();
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [searchQuery, setSearchQuery] = useState("");
  const [filterString, setFilterString] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState(new Set(value));
  const userDetails = useMemo(() => {
    const exclude = excludeUsers ? new Set(excludeUsers) : null;
    if (!userOptions) {
      if (!exclude) return nonTerminatedCachedUsers;
      return nonTerminatedCachedUsers.filter((u) => !exclude.has(u.userId));
    }
    const userIds = new Set(userOptions.map(({ value: value2 }) => value2));
    const nonTerminatedUsers = nonTerminatedCachedUsers.length > userOptions.length ? nonTerminatedCachedUsers.filter((u) => userIds.has(u.userId)) : nonTerminatedCachedUsers;
    if (exclude) return nonTerminatedUsers.filter((u) => !exclude.has(u.userId));
    return nonTerminatedUsers;
  }, [nonTerminatedCachedUsers, userOptions, excludeUsers]);
  const filteredUsers = useMemo(() => {
    let filteredUserList = userDetails;
    if (searchQuery) {
      filteredUserList = filteredUserList.filter(
        (u) => u.displayName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (filterString) {
      const filterOptions = filterStringToObject(filterString);
      for (const key of Object.keys(filterOptions)) {
        switch (key) {
          case "Department": {
            filteredUserList = filteredUserList.filter(
              (user) => {
                var _a, _b;
                return ((_a = user.role) == null ? void 0 : _a.department) && filterOptions[key].includes((_b = user.role.department.id) == null ? void 0 : _b.toString());
              }
            );
            break;
          }
          case "Site": {
            filteredUserList = filteredUserList.filter(
              (user) => {
                var _a, _b;
                return ((_a = user.role) == null ? void 0 : _a.site) && filterOptions[key].includes((_b = user.role.site.id) == null ? void 0 : _b.toString());
              }
            );
            break;
          }
          default:
            break;
        }
      }
    }
    return filteredUserList;
  }, [filterString, searchQuery, userDetails]);
  const [departments, setDepartments] = useState([]);
  const [sites, setSites] = useState([]);
  const getFilters = useCallback(() => __async(void 0, null, function* () {
    const [sitesData, departmentsData] = yield Promise.all([
      SiteAPI.listSites(true),
      CompanyDepartmentAPI.getCompanyDepartments()
    ]);
    setDepartments(
      departmentsData.map((d) => ({
        value: d.id,
        label: polyglot.t(d.name)
      }))
    );
    setSites(
      sitesData.map((s) => ({
        value: s.id,
        label: polyglot.t(s.name)
      }))
    );
  }), [polyglot]);
  useEffect(() => {
    getFilters();
  }, [getFilters]);
  const FilterTypes = {
    Department: departments,
    Site: sites
  };
  const getFilteredUser = () => {
    onChange([...selectedUserIds], filterValue);
    setSpecificIsOpen(false);
    setLastValidFilterValue(filterValue);
  };
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: isSpecificOpen, setIsOpen: setSpecificIsOpen, onClose, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: label != null ? label : polyglot.t("General.users") }),
    /* @__PURE__ */ jsx(CategoryFilters, { filterTypes: FilterTypes, setFilterString, filterString }),
    /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.m10 }, children: /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchQuery,
        handleChange: (e) => {
          setSearchQuery(e.target.value);
        },
        style: { width: "90%" }
      }
    ) }),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        labelPlacement: "start",
        label: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Select all" }),
        checked: selectedUserIds.size > 0 && selectedUserIds.size === filteredUsers.length,
        disabled: filteredUsers.length === 0,
        onChange: (_, checked) => {
          if (checked) {
            setSelectedUserIds(new Set(filteredUsers.map(({ userId }) => userId)));
          } else {
            setSelectedUserIds(/* @__PURE__ */ new Set());
          }
        },
        checkboxSx: { ml: "auto" },
        sx: { width: "100%" }
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          maxHeight: "calc(100vh - 350px)",
          overflowY: "scroll",
          gap: spacing.g10,
          display: "flex",
          flexDirection: "column"
        },
        children: /* @__PURE__ */ jsx(FixedSizeList, { height: window.innerHeight - 400, itemCount: filteredUsers.length, itemSize: 35, width: "100%", children: ({ index, style }) => {
          const user = filteredUsers[index];
          const isChecked = selectedUserIds.has(user.userId);
          return /* @__PURE__ */ jsx("div", { style, children: /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: /* @__PURE__ */ jsx(UserCell, { userId: user.userId }, user.userId),
              labelPlacement: "start",
              checked: isChecked,
              onChange: (_, checked) => {
                const newSelectedUserIds = new Set(selectedUserIds);
                if (checked) {
                  newSelectedUserIds.add(user.userId);
                } else {
                  newSelectedUserIds.delete(user.userId);
                }
                setSelectedUserIds(newSelectedUserIds);
              },
              checkboxSx: { ml: "auto" },
              sx: { width: "100%" }
            }
          ) }, user.userId);
        } })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        onClick: () => getFilteredUser(),
        disabled: allowEmpty ? false : selectedUserIds.size === 0,
        children: "Done"
      }
    ) })
  ] }) });
};
