"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  WeekDayNames
} from "@v2/feature/attendance/attendance.interface";
import { SchedulePatternFlexibleRow } from "@v2/feature/attendance/settings/components/schedule-pattern-flexible-row.component";
import { SchedulePatternRow } from "@v2/feature/attendance/settings/components/schedule-pattern-row.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
export const AttendanceScheduleWeekPatternForm = ({ formik, weekNo, schedule, noOfWeeks }) => {
  const { polyglot } = usePolyglot();
  const [isAddingNew, setIsAddingNew] = useState(false);
  const renderRow = (day) => {
    const commonProps = {
      day,
      weekNo,
      values: formik.values,
      setDayForm: (value) => {
        const updatedValues = [...formik.values[day]];
        updatedValues[weekNo] = value;
        formik.setFieldValue(day, updatedValues);
      }
    };
    return !schedule.isFlexible ? /* @__PURE__ */ jsx(SchedulePatternRow, __spreadValues({}, commonProps)) : /* @__PURE__ */ jsx(SchedulePatternFlexibleRow, __spreadValues({}, commonProps));
  };
  const addWeek = () => __async(void 0, null, function* () {
    setIsAddingNew(true);
    yield Promise.all([
      formik.setFieldValue("noOfWeeks", formik.values.noOfWeeks + 1),
      formik.setFieldValue("monday", [...formik.values.monday, null]),
      formik.setFieldValue("tuesday", [...formik.values.tuesday, null]),
      formik.setFieldValue("wednesday", [...formik.values.wednesday, null]),
      formik.setFieldValue("thursday", [...formik.values.thursday, null]),
      formik.setFieldValue("friday", [...formik.values.friday, null]),
      formik.setFieldValue("saturday", [...formik.values.saturday, null]),
      formik.setFieldValue("sunday", [...formik.values.sunday, null])
    ]);
    setIsAddingNew(false);
  });
  const removeWeek = () => __async(void 0, null, function* () {
    yield Promise.all([
      formik.setFieldValue("noOfWeeks", formik.values.noOfWeeks - 1),
      formik.setFieldValue("monday", [...formik.values.monday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue("tuesday", [...formik.values.tuesday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue("wednesday", [...formik.values.wednesday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue("thursday", [...formik.values.thursday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue("friday", [...formik.values.friday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue("saturday", [...formik.values.saturday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue("sunday", [...formik.values.sunday.filter((_, index) => index !== weekNo)])
    ]);
  });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { my: "20px" }, children: noOfWeeks > 1 ? polyglot.t("AttendanceSchedule.weekNo", { weekNo: weekNo + 1 }) : "" }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px", alignItems: "center" }, children: [
        noOfWeeks > 1 && /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: tableIconButtonSx,
            onClick: removeWeek,
            title: polyglot.t("AttendanceCompanySettingsScheduleDetailsPage.remove"),
            children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
          }
        ),
        weekNo === noOfWeeks - 1 && !schedule.isFlexible && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "filter",
            colorVariant: "secondary",
            onClick: addWeek,
            endIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
            loading: isAddingNew,
            children: polyglot.t("AttendanceSchedulePattern.addWeek")
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g15 }, children: WeekDayNames.map((day) => renderRow(day)) })
  ] });
};
