"use strict";
import { jsx } from "react/jsx-runtime";
import { Fragment, useContext } from "react";
import { UserShiftHandler } from "@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler.component";
import { LocalDate } from "@v2/util/local-date";
import { GlobalContext } from "@/GlobalState";
import { ScheduleTrackingType } from "@/v2/feature/attendance/attendance.interface";
import { RegularAttendanceContent } from "@/v2/feature/dashboard/features/sections/user-attendance/components/regular-attendance-content.component";
export const UserAttendanceViewDrawer = ({
  selectedDate,
  attendanceSchedule,
  refreshWidgetData,
  setIsViewOpen,
  shiftWidgetData
}) => {
  var _a, _b, _c;
  const [globalState] = useContext(GlobalContext);
  const user = globalState.user;
  return /* @__PURE__ */ jsx(Fragment, { children: (attendanceSchedule == null ? void 0 : attendanceSchedule.trackingType) !== ScheduleTrackingType.ClockInClockOut ? /* @__PURE__ */ jsx(
    RegularAttendanceContent,
    {
      userId: user.userId,
      selectedDate,
      refreshWidgetData,
      setIsViewOpen
    }
  ) : /* @__PURE__ */ jsx(
    UserShiftHandler,
    {
      selectedDate: selectedDate != null ? selectedDate : new LocalDate().toDateString(),
      refresh: refreshWidgetData,
      shift: (_a = shiftWidgetData == null ? void 0 : shiftWidgetData.shift) == null ? void 0 : _a.todayShift,
      userSite: (_b = shiftWidgetData == null ? void 0 : shiftWidgetData.shift) == null ? void 0 : _b.userSite,
      currentWeekAttendance: (_c = shiftWidgetData == null ? void 0 : shiftWidgetData.shift.currentWeekAttendance) != null ? _c : [],
      attendanceSchedule,
      mode: "drawer"
    }
  ) });
};
