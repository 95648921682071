"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { keyBy } from "lodash";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { IconContentActionCard } from "@/v2/components/theme-components/icon-content-action-card.component";
import { getModelImage, getOrderStatus } from "@/v2/feature/device/device.util";
import { DeviceOrderDrawer } from "@/v2/feature/device/features/devices-company/components/device-order-drawer.component";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MyDevicesOrders = ({
  deviceOrders,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refreshDevicesData
}) => {
  const [sitesById, setSitesById] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDeviceOrder, setSelectedDeviceOrder] = useState(void 0);
  const [showMessage] = useMessage();
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const sites = yield SiteAPI.listSites();
        setSitesById(keyBy(sites, "id"));
      } catch (error) {
        showMessage(`Could not get sites list. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [showMessage]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Order" }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: deviceOrders.length > 0 ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
      deviceOrders.map((d, idx) => {
        var _a, _b;
        return /* @__PURE__ */ jsx(
          IconContentActionCard,
          {
            iconMedia: d.deviceModel ? (_a = getModelImage(d.deviceModel.type, d.deviceModel.modelName, {
              width: "150px",
              height: "auto"
            })) != null ? _a : void 0 : void 0,
            title: (_b = d.deviceModel) == null ? void 0 : _b.modelName,
            content: /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5 }, children: getOrderStatus(d.status) }),
            action: /* @__PURE__ */ jsx(
              Button,
              {
                sx: secondaryCTABtn,
                onClick: () => {
                  setSelectedDeviceOrder(d);
                  setIsDrawerOpen(true);
                },
                children: "Details"
              }
            ),
            showDivider: idx < deviceOrders.length - 1
          }
        );
      }),
      selectedDeviceOrder && /* @__PURE__ */ jsx(
        DeviceOrderDrawer,
        {
          setIsOpen: setIsDrawerOpen,
          isOpen: isDrawerOpen,
          onClose: () => {
            setIsDrawerOpen(false);
            setSelectedDeviceOrder(void 0);
          },
          deviceOrder: selectedDeviceOrder,
          refresh: () => __async(void 0, null, function* () {
            setIsDrawerOpen(false);
            setSelectedDeviceOrder(void 0);
            yield refreshDevicesData == null ? void 0 : refreshDevicesData();
          }),
          sitesById,
          reach: "me"
        }
      )
    ] }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "No orders to show" }) })
  ] });
};
