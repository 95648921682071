"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { DEFAULT_SCHEDULE_CAP, getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import { AttendanceShiftAPI } from "@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { convertMinutesToClockHours } from "@/v2/feature/absence/absence.util";
import {
  DayNoToWeekDay,
  weekDayToDayNo
} from "@/v2/feature/attendance/attendance.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { LocalDate } from "@/v2/util/local-date";
import { compareTimePartOnly } from "@/v2/util/time.util";
export const DEFAULT_MAXIMUM_ACCEPTED_PROXIMITY = 100;
export const DISTANCE_CANNOT_BE_CALCULATED = 999999;
export const MISSING_SITE_ADDRESS = 999998;
export const WeekdaysArray = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
export const nextScheduledDayDetails = (theFollowingScheduledDay, polyglot) => {
  const regularString = () => {
    if (!(theFollowingScheduledDay == null ? void 0 : theFollowingScheduledDay.daySchedule)) return [];
    const start = theFollowingScheduledDay == null ? void 0 : theFollowingScheduledDay.daySchedule.from.split("T")[1].slice(0, 5);
    const end = theFollowingScheduledDay == null ? void 0 : theFollowingScheduledDay.daySchedule.to.split("T")[1].slice(0, 5);
    return [start, end];
  };
  const breakString = () => {
    if (!(theFollowingScheduledDay == null ? void 0 : theFollowingScheduledDay.daySchedule) || !(theFollowingScheduledDay == null ? void 0 : theFollowingScheduledDay.daySchedule) || !(theFollowingScheduledDay == null ? void 0 : theFollowingScheduledDay.daySchedule.break))
      return "";
    const [h, m] = theFollowingScheduledDay.daySchedule.break.split("T")[1].split(":");
    const minutes = 60 * Number(h) + Number(m);
    return convertMinutesToClockHours(minutes, polyglot);
  };
  return [regularString()[0], regularString()[1], breakString()];
};
export const WidgetInfoMessage = ({
  isScheduledForToday,
  scheduleDay,
  isStarted,
  hasStartedBreak,
  time,
  isEnded,
  clockInEarlyCapMinutes
}) => {
  const { polyglot } = usePolyglot();
  const isRunningLate = useMemo(() => {
    if (!isScheduledForToday || !scheduleDay || !scheduleDay.daySchedule || isStarted) return false;
    const now = new LocalDate().toFullString().split("T")[1];
    const scheduledHour = scheduleDay.daySchedule.from.split("T")[1];
    return scheduledHour < now;
  }, [isScheduledForToday, scheduleDay, isStarted]);
  const startsSoon = useMemo(() => {
    var _a;
    if (!isScheduledForToday || !scheduleDay || !scheduleDay.daySchedule || isRunningLate || isStarted) return false;
    const now = new LocalDate().toFullString().split("T")[1];
    const scheduledHour = (_a = scheduleDay.daySchedule) == null ? void 0 : _a.from.split("T")[1];
    const [nH, nM] = now.split(":");
    const nowMinutes = Math.round(Number(nH) * 60 + Number(nM));
    const [sH, sM] = scheduledHour.split(":");
    const scheduledMinutes = Math.round(Number(sH) * 60 + Number(sM));
    const timeUntilStart = scheduledMinutes - nowMinutes;
    return timeUntilStart >= 0 && timeUntilStart <= clockInEarlyCapMinutes;
  }, [isScheduledForToday, scheduleDay, isRunningLate, isStarted, clockInEarlyCapMinutes]);
  const startsLater = useMemo(() => {
    if (isScheduledForToday && (scheduleDay == null ? void 0 : scheduleDay.daySchedule) && !isRunningLate && !isStarted && !startsSoon)
      return scheduleDay.daySchedule.from.split("T")[1].slice(0, 5);
    return null;
  }, [isScheduledForToday, scheduleDay, isRunningLate, isStarted, startsSoon]);
  if (isEnded)
    return /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: polyglot.t("AttendanceDomain.AttendanceShift.ShiftFinished") });
  if (isStarted && hasStartedBreak)
    return /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: polyglot.t("AttendanceDomain.AttendanceShift.BreakStarted", { time }) });
  if (isStarted)
    return /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: polyglot.t("AttendanceDomain.AttendanceShift.ShiftStarted", { time }) });
  if (isRunningLate)
    return /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.RedDark }, children: polyglot.t("AttendanceDomain.AttendanceShift.RunningLate") });
  if (startsSoon)
    return /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: polyglot.t("AttendanceDomain.AttendanceShift.StartsSoon") });
  if (startsLater)
    return /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: polyglot.t("AttendanceDomain.AttendanceShift.StartsLater", { startsLater }) });
  return /* @__PURE__ */ jsx(Box, {});
};
export const getDistanceBetweenCoordinates = (startPoint, endPoint, inMiles = false) => {
  const lon1 = startPoint.longitude * Math.PI / 180;
  const lon2 = endPoint.longitude * Math.PI / 180;
  const lat1 = startPoint.latitude * Math.PI / 180;
  const lat2 = endPoint.latitude * Math.PI / 180;
  const earthRadius = inMiles ? 3956 : 6371;
  const longitudeDiff = lon2 - lon1;
  const latitudeDiff = lat2 - lat1;
  const a = Math.pow(Math.sin(latitudeDiff / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(longitudeDiff / 2), 2);
  const c = 2 * Math.asin(Math.sqrt(a));
  return c * earthRadius;
};
export const isInAcceptedProximityUtil = (attendanceSchedule, distance) => {
  var _a;
  if (!attendanceSchedule) return false;
  if (!(attendanceSchedule == null ? void 0 : attendanceSchedule.restrictByGeolocation)) return true;
  if (!attendanceSchedule.geolocationDistance) return false;
  if (distance === null) return null;
  return distance < ((_a = attendanceSchedule.geolocationDistance) != null ? _a : DEFAULT_MAXIMUM_ACCEPTED_PROXIMITY);
};
export const getDateDayUtil = (date) => {
  const d = new LocalDate(date).getDate();
  return WeekdaysArray[d.getDay()];
};
export const getTheSelectedDaySchedule = (attendanceSchedule, selectedDay, selectedDate) => {
  const weekIndex = getIndexOfWeekInSchedule(
    attendanceSchedule.startDateOfFirstWeek,
    selectedDate,
    attendanceSchedule.noOfWeeks
  );
  if (weekIndex == null) return null;
  if (attendanceSchedule && attendanceSchedule[selectedDay] && attendanceSchedule[selectedDay][weekIndex])
    return { day: selectedDay, daySchedule: attendanceSchedule[selectedDay][weekIndex] };
  const dayIndex = WeekdaysArray.findIndex((day) => day === selectedDay);
  let nextIndex = dayIndex === 6 ? 0 : dayIndex + 1;
  while (nextIndex !== dayIndex) {
    const nextDay = WeekdaysArray[nextIndex];
    if (attendanceSchedule && attendanceSchedule[nextDay] && attendanceSchedule[nextDay][weekIndex])
      return { day: nextDay, daySchedule: attendanceSchedule && attendanceSchedule[nextDay][weekIndex] };
    nextIndex = (nextIndex + 1) % 7;
  }
  return null;
};
export const isLessThanEarlyCapMinutesUntilStart = (attendanceSchedule, isScheduledForToday, isStarted, relevantScheduledDay) => {
  var _a;
  const now = new LocalDate().toFullString();
  const nowDay = now.split("T")[0].split("-")[2];
  if (!isScheduledForToday || !relevantScheduledDay || !relevantScheduledDay.daySchedule || isStarted) return false;
  const scheduled = relevantScheduledDay.daySchedule.from;
  const scheduledDay = scheduled.split("T")[0].split("-")[2];
  if (Number(scheduledDay) < Number(nowDay)) return true;
  const nowTime = now.split("T")[1];
  const [hN, mN] = nowTime.split(":");
  const nowMinutes = Number(hN) * 60 + Number(mN);
  const scheduledTime = scheduled.split("T")[1];
  const [hS, mS] = scheduledTime.split(":");
  const scheduleMinutes = Number(hS) * 60 + Number(mS);
  return scheduleMinutes - nowMinutes <= ((_a = attendanceSchedule == null ? void 0 : attendanceSchedule.clockInEarlyCapMinutes) != null ? _a : DEFAULT_SCHEDULE_CAP);
};
export const getHasStartedBreak = (shift, breakType) => {
  if (!(shift == null ? void 0 : shift.shiftEntries)) return false;
  const lastShiftEntry = shift.shiftEntries[shift.shiftEntries.length - 1];
  return Boolean(breakType && lastShiftEntry.attendanceTypeId === breakType.id && !lastShiftEntry.endHour);
};
export const extractTimeFromShiftEntries = (shift) => {
  if (!(shift == null ? void 0 : shift.shiftEntries)) return "";
  const lastShiftEntry = shift.shiftEntries[shift.shiftEntries.length - 1];
  return lastShiftEntry.startHour.split("T")[1].slice(0, 5);
};
export const startAttendanceShift = (isInAcceptedProximity, distance, longitude, latitude, showMessage, refresh, polyglot, isYesterday) => __async(void 0, null, function* () {
  if (!isInAcceptedProximity || distance === DISTANCE_CANNOT_BE_CALCULATED || distance === MISSING_SITE_ADDRESS) {
    showMessage(
      isInAcceptedProximity === null ? polyglot.t("AttendanceDomain.AttendanceShift.Actions.LocationIsRequired") : distance === DISTANCE_CANNOT_BE_CALCULATED ? polyglot.t("AttendanceDomain.AttendanceShift.Actions.DistanceCouldNotBeCalculated") : distance === MISSING_SITE_ADDRESS ? polyglot.t("AttendanceDomain.AttendanceShift.Actions.MissingSiteAddress") : polyglot.t("AttendanceDomain.AttendanceShift.Actions.NotWithingProximity"),
      "error"
    );
    return;
  }
  try {
    yield AttendanceShiftAPI.startTodaysShift(longitude, latitude, isYesterday);
    showMessage(polyglot.t("AttendanceDomain.AttendanceShift.Actions.ShiftStarted"), "success");
    yield refresh();
  } catch (error) {
    showMessage(
      polyglot.t("AttendanceDomain.AttendanceShift.Actions.SomethingWentWrong", {
        errorMessage: nestErrorMessage(error)
      }),
      "error"
    );
  }
});
export const endAttendanceShift = (longitude, latitude, showMessage, refresh, polyglot, isYesterday) => __async(void 0, null, function* () {
  try {
    yield AttendanceShiftAPI.endTodaysShift(longitude, latitude, isYesterday);
    showMessage(polyglot.t("AttendanceDomain.AttendanceShift.Actions.ShiftEnded"), "success");
    if (refresh) yield refresh();
  } catch (error) {
    showMessage(
      polyglot.t("AttendanceDomain.AttendanceShift.Actions.SomethingWentWrong", {
        errorMessage: nestErrorMessage(error)
      }),
      "error"
    );
  }
});
export const startAttendanceBreak = (longitude, latitude, showMessage, refresh, polyglot) => __async(void 0, null, function* () {
  try {
    yield AttendanceShiftAPI.startBreak(longitude, latitude);
    showMessage(polyglot.t("AttendanceDomain.AttendanceShift.Actions.BreakStarted"), "success");
    yield refresh();
  } catch (error) {
    showMessage(
      polyglot.t("AttendanceDomain.AttendanceShift.Actions.SomethingWentWrong", {
        errorMessage: nestErrorMessage(error)
      }),
      "error"
    );
  }
});
export const endAttendanceBreak = (longitude, latitude, showMessage, refresh, polyglot) => __async(void 0, null, function* () {
  try {
    yield AttendanceShiftAPI.endBreak(longitude, latitude);
    showMessage(polyglot.t("AttendanceDomain.AttendanceShift.Actions.BreakEnded"), "success");
    if (refresh) yield refresh();
  } catch (error) {
    showMessage(
      polyglot.t("AttendanceDomain.AttendanceShift.Actions.SomethingWentWrong", {
        errorMessage: nestErrorMessage(error)
      }),
      "error"
    );
  }
});
export const getShiftLoggedHours = (scheduledDay, polyglot) => {
  if (!scheduledDay) return [];
  return nextScheduledDayDetails(scheduledDay, polyglot);
};
export const getDistance = (attendanceSchedule, currentPosition, userSite, sites) => {
  var _a, _b;
  if (!(currentPosition == null ? void 0 : currentPosition.coords)) return null;
  const distanceInKM = (attendanceSchedule == null ? void 0 : attendanceSchedule.geolocationEmployeeSite) && (userSite == null ? void 0 : userSite.coordinates) ? getDistanceBetweenCoordinates(currentPosition.coords, userSite.coordinates) : ((_a = userSite == null ? void 0 : userSite.coordinates) == null ? void 0 : _a.longitude) && ((_b = userSite.coordinates) == null ? void 0 : _b.latitude) ? DISTANCE_CANNOT_BE_CALCULATED : MISSING_SITE_ADDRESS;
  const userSiteDistance = Math.round(distanceInKM * 1e3);
  const otherSitesDistances = (attendanceSchedule == null ? void 0 : attendanceSchedule.allowedWorkSites) ? attendanceSchedule.allowedWorkSites.map((siteId) => {
    var _a2, _b2;
    const site = sites == null ? void 0 : sites.find((s) => s.id === siteId);
    if (!((_a2 = site == null ? void 0 : site.coordinates) == null ? void 0 : _a2.latitude) || !((_b2 = site == null ? void 0 : site.coordinates) == null ? void 0 : _b2.longitude)) return MISSING_SITE_ADDRESS;
    const distanceFromSite = getDistanceBetweenCoordinates(currentPosition.coords, site.coordinates);
    return Math.round(distanceFromSite * 1e3);
  }) : [];
  return Math.min(userSiteDistance, ...otherSitesDistances);
};
export const getCurrentPosition = (navigator, setIsFetchingLocation, setCurrentPosition) => {
  navigator.geolocation.getCurrentPosition((position) => {
    if (position) {
      setIsFetchingLocation(false);
      setCurrentPosition({
        coords: {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude
        }
      });
    } else {
      setCurrentPosition(null);
    }
  });
};
export const getRelevantShiftDayFromSchedule = (attendanceSchedule, todaysDay, currentWeekAttendance, shift) => {
  if (shift && !shift.isEnded)
    return {
      weekDay: DayNoToWeekDay[new Date(shift.logDate).getDay()],
      weekDate: shift.logDate
    };
  const todayWeekNoIndex = Object.entries(DayNoToWeekDay).findIndex(([, weekday]) => weekday === todaysDay);
  if (todayWeekNoIndex === -1) return { weekDay: todaysDay, weekDate: new LocalDate().toDateString() };
  const yesterdayToDayNoIndex = (todayWeekNoIndex - 1) % 7;
  const yesterdaysDay = DayNoToWeekDay[yesterdayToDayNoIndex];
  const yesterdayShiftLogDate = new LocalDate().getPreviousDateString();
  const yesterdaysWeekIndex = getIndexOfWeekInSchedule(
    attendanceSchedule.startDateOfFirstWeek,
    yesterdayShiftLogDate,
    attendanceSchedule.noOfWeeks
  );
  const yesterdayShift = attendanceSchedule[yesterdaysDay] ? attendanceSchedule[yesterdaysDay][yesterdaysWeekIndex] : null;
  if (
    // if yesterday's shift and shift is overnight
    yesterdayShift && new Date(yesterdayShift.to).getTime() < new Date(yesterdayShift.from).getTime() && compareTimePartOnly(new Date(yesterdayShift.to), /* @__PURE__ */ new Date()) === 1
  ) {
    if (currentWeekAttendance && !currentWeekAttendance.find((cwa) => cwa.logDate === yesterdayShiftLogDate))
      return { weekDay: yesterdaysDay, weekDate: yesterdayShiftLogDate };
  }
  return { weekDay: todaysDay, weekDate: new LocalDate().toDateString() };
};
export const getClosestDateForDay = (weekDay) => {
  const date = /* @__PURE__ */ new Date();
  const currentDayNo = date.getDay();
  if (DayNoToWeekDay[currentDayNo] === weekDay) return date;
  const weekDayNo = weekDayToDayNo(weekDay);
  date.setDate(date.getDate() - currentDayNo + weekDayNo);
  return date;
};
export const getDayPreviousFromDateString = (dateStr) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() - 1);
  const monthPart = date.getMonth() + 1;
  const datePart = date.getDate();
  return `${date.getFullYear()}-${monthPart < 10 ? "0" : ""}${monthPart}-${datePart < 10 ? "0" : ""}${datePart}`;
};
