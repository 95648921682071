"use strict";
import { jsx } from "react/jsx-runtime";
import { DeviceDetailsComponent } from "@v2/feature/device/features/device-cards/components/device-details.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceMdmDetailsPage = ({ configurableDevice, ownerName, loading, refresh }) => {
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: "Details",
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading,
      children: configurableDevice && /* @__PURE__ */ jsx(DeviceDetailsComponent, { configurableDevice, ownerName, refresh })
    }
  );
};
