"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Fragment as Fragment2, useContext, useMemo, useState } from "react";
import { keyBy } from "lodash";
import { Redirect, Switch, generatePath, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import {
  SURVEYS_TEAM_ONGOING_DETAIL_HEATMAP_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_INSIGHTS_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_RESULT_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_FORM_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE,
  SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE,
  SURVEYS_TEAM_ONGOING_ROUTE
} from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { FiltersEndpoints } from "@/v2/feature/filters/filters.api";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { ReachType } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { GrowthForbiddenAccess } from "@/v2/feature/growth/shared/components/growth-forbidden-access.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { SCUpsertGeneralPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/sc-upsert-general.page";
import { SCUpsertLaunchPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/sc-upsert-launch.page";
import { SurveyCycleCreationParticipantsPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/sc-upsert-participants.page";
import { SCUpsertTimelinePage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/sc-upsert-timeline.page";
import { SurveyCycleCreationNotificationsPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-notifications/survey-cycle-creation-notifications.page";
import { SurveyCycleCreationQuestionPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/survey-cycle-creation-question.page";
import { SurveyCycleDetailHeatmapPage } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-heatmap/survey-cycle-detail-heatmap.page";
import { SurveyCycleDetailInsightsPage } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-insights/survey-cycle-detail-insights.page";
import { SurveyCycleDetailResultsPage } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-cycle-detail-results.page";
import { SURVEYS_MANAGER_SCOPE } from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
const getPageConfig = (cycleId, surveyCycle, polyglot, user, isAdmin, isManager) => {
  return [
    ...(surveyCycle == null ? void 0 : surveyCycle.state) !== CycleState.Draft ? [
      {
        title: "General",
        stub: "",
        isHidden: false,
        hasChildren: true,
        subItems: [
          ...(surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Ongoing || (surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Completed || (surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Paused ? [
            {
              title: "Insights",
              stub: "insights",
              path: generatePath(SURVEYS_TEAM_ONGOING_DETAIL_INSIGHTS_ROUTE, { cycleId }),
              isHidden: false,
              hasChildren: false
            },
            {
              title: "Result",
              stub: "result",
              path: generatePath(SURVEYS_TEAM_ONGOING_DETAIL_RESULT_ROUTE, { cycleId }),
              isHidden: false,
              hasChildren: false
            },
            {
              title: "Heatmap",
              stub: "heatmap",
              path: generatePath(SURVEYS_TEAM_ONGOING_DETAIL_HEATMAP_ROUTE, { cycleId }),
              isHidden: false,
              hasChildren: false
            }
          ] : []
        ]
      }
    ] : [],
    ...isAdmin || isManager && user.userId === (surveyCycle == null ? void 0 : surveyCycle.owner) ? [
      {
        title: "Settings",
        stub: "settings",
        isHidden: false,
        hasChildren: true,
        subItems: [
          {
            title: polyglot.t("CycleSettingsRouter.general"),
            stub: "general",
            path: generatePath(SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE, { cycleId }),
            isHidden: false,
            hasChildren: false
          },
          {
            title: "Questions",
            stub: "question",
            path: generatePath(SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_FORM_ROUTE, { cycleId }),
            isHidden: false,
            hasChildren: false
          },
          {
            title: "Participants",
            stub: "participants",
            path: generatePath(SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE, { cycleId }),
            isHidden: false,
            hasChildren: false
          },
          {
            title: "Timeline",
            stub: "timeline",
            path: generatePath(SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE, { cycleId }),
            isHidden: false,
            hasChildren: false
          },
          {
            title: "Notifications",
            stub: "notifications",
            path: generatePath(SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE, { cycleId }),
            isHidden: false,
            hasChildren: false
          },
          ...(surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Draft || (surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Scheduled ? [
            {
              title: "Start survey cycle",
              stub: "launch",
              path: generatePath(SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE, { cycleId })
            }
          ] : []
        ]
      }
    ] : []
  ];
};
export const SurveyCycleDetailTeamRouter = () => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const isAdmin = canAccessScopes(user, ["surveys:all"]);
  const isManager = canAccessScopes(user, ["surveys:manager"]);
  const params = useParams();
  const cycleId = params.cycleId;
  const [filterString, setFilterString] = useState("");
  const { data: surveyCycle, mutate: refreshSurveyCycle, isLoading: cycleLoading } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleById(cycleId, ReachType.Team),
    {
      suspense: false
    }
  );
  const { data: surveyResult, isLoading: loadingResult } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleResult(cycleId, ReachType.Team, filterString),
    {
      suspense: false
    }
  );
  const { data: filtersAndTypesOptions } = useApiClient(FiltersEndpoints.getSurveyFilterOptions(), {
    suspense: false
  });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const growthFactors = useMemo(() => {
    return keyBy(allGrowthFactors, "name");
  }, [allGrowthFactors]);
  const refresh = () => __async(void 0, null, function* () {
    yield refreshSurveyCycle == null ? void 0 : refreshSurveyCycle();
  });
  const settingPathVisibility = useMemo(
    () => surveyCycle && Boolean(isAdmin || isManager && surveyCycle.owner === user.userId),
    [surveyCycle, isManager, isAdmin, user]
  );
  if (!surveyCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Fragment2, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "Surveys",
        pageConfig: getPageConfig(cycleId, surveyCycle, polyglot, user, isAdmin, isManager),
        backPath: generatePath(SURVEYS_TEAM_ONGOING_ROUTE, {
          cycleId
        }),
        showBack: true,
        type: "Domain"
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_DETAIL_INSIGHTS_ROUTE, children: /* @__PURE__ */ jsx(
        SurveyCycleDetailInsightsPage,
        {
          surveyCycle,
          cycleLoading: cycleLoading || loadingResult,
          growthFactors,
          surveyResult,
          reach: ReachType.Team,
          filtersAndTypesOptions: filtersAndTypesOptions || [],
          setFilterString,
          filterString
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_DETAIL_RESULT_ROUTE, children: /* @__PURE__ */ jsx(
        SurveyCycleDetailResultsPage,
        {
          surveyCycle,
          surveyResult,
          loadingResult,
          cycleLoading,
          growthFactors,
          filtersAndTypesOptions: filtersAndTypesOptions || [],
          setFilterString,
          filterString
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_DETAIL_HEATMAP_ROUTE, children: /* @__PURE__ */ jsx(
        SurveyCycleDetailHeatmapPage,
        {
          surveyCycle,
          cycleLoading,
          reach: ReachType.Team,
          growthFactors
        }
      ) }),
      settingPathVisibility && /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: [SURVEYS_MANAGER_SCOPE],
          path: SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE,
          children: /* @__PURE__ */ jsx(
            SCUpsertGeneralPage,
            {
              surveyCycle,
              refresh,
              cycleLoading,
              reach: ReachType.Team
            }
          )
        }
      ),
      settingPathVisibility && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_FORM_ROUTE, children: /* @__PURE__ */ jsx(SurveyCycleCreationQuestionPage, { cycleId }) }),
      settingPathVisibility && /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: [SURVEYS_MANAGER_SCOPE],
          path: SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE,
          children: /* @__PURE__ */ jsx(SurveyCycleCreationParticipantsPage, { cycleId, reach: ReachType.Team })
        }
      ),
      settingPathVisibility && /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: [SURVEYS_MANAGER_SCOPE],
          path: SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE,
          children: /* @__PURE__ */ jsx(SCUpsertTimelinePage, { cycleId, reach: ReachType.Team })
        }
      ),
      settingPathVisibility && /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: [SURVEYS_MANAGER_SCOPE],
          path: SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE,
          children: /* @__PURE__ */ jsx(SurveyCycleCreationNotificationsPage, { surveyCycle, refresh })
        }
      ),
      settingPathVisibility && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_MANAGER_SCOPE], path: SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE, children: /* @__PURE__ */ jsx(SCUpsertLaunchPage, { cycleId, reach: ReachType.Team }) }),
      !settingPathVisibility && (surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Draft ? /* @__PURE__ */ jsx("div", { style: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(GrowthForbiddenAccess, {}) }) : /* @__PURE__ */ jsx(
        Redirect,
        {
          to: (surveyCycle == null ? void 0 : surveyCycle.state) !== CycleState.Draft ? SURVEYS_TEAM_ONGOING_DETAIL_INSIGHTS_ROUTE : SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE,
          exact: true
        }
      )
    ] })
  ] });
};
