"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useState } from "react";
import { TrackTimeDrawer } from "@/v2/feature/attendance/company/components/track-time-drawer.component";
export const AttendanceActionItem = ({
  attendanceActionRow,
  refresh,
  onClose,
  afterClose
}) => {
  const [isOpen, setIsOpen] = useState(Boolean(attendanceActionRow));
  return /* @__PURE__ */ jsx(
    TrackTimeDrawer,
    {
      isOpen,
      setIsOpen,
      userId: attendanceActionRow.userId,
      attendanceId: attendanceActionRow.id,
      refresh: () => __async(void 0, null, function* () {
        return yield refresh();
      }),
      onClose,
      afterClose,
      mode: "view",
      view: "user"
    }
  );
};
