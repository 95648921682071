"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { AttendanceScheduleEditPayrollDrawer } from "@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-payroll-drawer.component";
import { AttendancePayrollPenaltiesSubSection } from "@v2/feature/attendance/settings/components/attendance-payroll-penalties-subsection.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
export const AttendanceSettingsPayrollSection = ({ schedule, refreshSchedule }) => {
  var _a;
  const { companyConfig } = useCompanyConfigState();
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      SettingsSectionContent,
      {
        title: polyglot.t("AttendanceSchedule.payroll"),
        onEdit: () => {
          setEdit(true);
        },
        children: [
          /* @__PURE__ */ jsx(
            SettingsSubsectionContent,
            {
              sections: [
                {
                  items: [
                    {
                      type: SectionItemType.Pair,
                      label: polyglot.t("PayrollModule.includedInPayroll"),
                      value: schedule.includedInPayroll ? polyglot.t("General.yes") : polyglot.t("General.no")
                    },
                    {
                      type: SectionItemType.Pair,
                      label: polyglot.t("PayItemModule.payCode"),
                      value: (_a = schedule.payCode) != null ? _a : polyglot.t("General.none")
                    }
                  ]
                }
              ]
            }
          ),
          /* @__PURE__ */ jsx(
            AttendanceScheduleEditPayrollDrawer,
            {
              isOpen: edit,
              setIsOpen: setEdit,
              attendanceSchedule: schedule,
              refresh: refreshSchedule
            }
          )
        ]
      }
    ),
    (companyConfig.inGlobalPayroll || companyConfig.inPayroll) && schedule.includedInPayroll && /* @__PURE__ */ jsx(AttendancePayrollPenaltiesSubSection, { schedule })
  ] });
};
