"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { LocalDate } from "@v2/util/local-date";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_DIRECTORY_ROUTE, DEVICES_ME_DIRECTORY_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { DevicePossessionType, DeviceTransitStatus } from "@/v2/feature/device/device.interface";
import { FieldValueComponent, getModelImage, getTransitStatus } from "@/v2/feature/device/device.util";
import { linkTextSx } from "@/v2/styles/buttons.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getDeviceSender = (deviceTransit) => {
  var _a, _b, _c, _d, _e;
  if (((_a = deviceTransit.sender) == null ? void 0 : _a.possessionType) === DevicePossessionType.User && ((_b = deviceTransit.sender) == null ? void 0 : _b.possessionId))
    return deviceTransit.sender.possessionId ? /* @__PURE__ */ jsx(UserCell, { userId: deviceTransit.sender.possessionId, nameSx: themeFonts.title4 }) : "Not assigned";
  if (((_c = deviceTransit.sender) == null ? void 0 : _c.possessionType) === DevicePossessionType.CompanySite)
    return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "Company Site" });
  if (((_d = deviceTransit.sender) == null ? void 0 : _d.possessionType) === DevicePossessionType.ZeltStorage)
    return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "Zelt Storage" });
  if (((_e = deviceTransit.sender) == null ? void 0 : _e.possessionType) === DevicePossessionType.ZeltStock)
    return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "Zelt Stock" });
  return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "Unknown" });
};
const getDeviceReceiver = (deviceTransit) => {
  var _a, _b, _c, _d, _e;
  if (((_a = deviceTransit.receiver) == null ? void 0 : _a.possessionType) === DevicePossessionType.User && ((_b = deviceTransit.receiver) == null ? void 0 : _b.possessionId))
    return deviceTransit.receiver.possessionId ? /* @__PURE__ */ jsx(UserCell, { userId: deviceTransit.receiver.possessionId, nameSx: themeFonts.title4 }) : "Not assigned";
  if (((_c = deviceTransit.receiver) == null ? void 0 : _c.possessionType) === DevicePossessionType.CompanySite)
    return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "Company Site" });
  if (((_d = deviceTransit.receiver) == null ? void 0 : _d.possessionType) === DevicePossessionType.ZeltStorage)
    return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "Zelt Storage" });
  if (((_e = deviceTransit.receiver) == null ? void 0 : _e.possessionType) === DevicePossessionType.ZeltStock)
    return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "Zelt Stock" });
  return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "Unknown" });
};
export const DeviceReturnDrawer = ({
  selectedDeviceTransit,
  refresh,
  reach
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const routerHistory = useHistory();
  const [globalState, dispatch] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const [isMarkAsShippedLoading, setIsMarkAsShippedLoading] = useState(false);
  const [isMarkAsDeliveredLoading, setIsMarkAsDeliveredLoading] = useState(false);
  const internalPossessions = [DevicePossessionType.User, DevicePossessionType.CompanySite];
  const isInternalTransit = selectedDeviceTransit.sender && selectedDeviceTransit.receiver && internalPossessions.includes((_a = selectedDeviceTransit.sender) == null ? void 0 : _a.possessionType) && internalPossessions.includes((_b = selectedDeviceTransit.receiver) == null ? void 0 : _b.possessionType);
  const isReturningOrSendToZeltStorageTransit = ((_c = selectedDeviceTransit.receiver) == null ? void 0 : _c.possessionType) && [DevicePossessionType.ZeltStorage, DevicePossessionType.ZeltStock].includes(
    selectedDeviceTransit.receiver.possessionType
  );
  const markInternalTransitAsShipped = () => __async(void 0, null, function* () {
    let isMarkedAsShipped = false;
    try {
      setIsMarkAsShippedLoading(true);
      yield DeviceAPI.changeDeviceOwnerSetTransitInShipping(selectedDeviceTransit.id);
      showMessage("Transit successfully marked as shipped", "success");
      isMarkedAsShipped = true;
      yield refresh();
      const alertsForDevices = yield DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
    } catch (error) {
      if (isMarkedAsShipped) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setIsMarkAsShippedLoading(false);
      routerHistory.push(
        generatePath(reach === "company" ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
      );
    }
  });
  const markReturningTransitAsShipped = () => __async(void 0, null, function* () {
    let isMarkedAsShipped = false;
    try {
      setIsMarkAsShippedLoading(true);
      yield DeviceAPI.markReturnTransitAsShipped(selectedDeviceTransit.id);
      isMarkedAsShipped = true;
      showMessage("Transit successfully marked as shipped", "success");
      yield refresh();
      const alertsForDevices = yield DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
    } catch (error) {
      if (isMarkedAsShipped) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setIsMarkAsShippedLoading(false);
      routerHistory.push(
        generatePath(reach === "company" ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
      );
    }
  });
  const cancelReturnDevice = () => __async(void 0, null, function* () {
    let isCancelled = false;
    try {
      if (selectedDeviceTransit) {
        yield DeviceAPI.cancelDeviceTransit(selectedDeviceTransit.id);
        isCancelled = true;
        showMessage("Device return has been cancelled.", "success");
        yield refresh();
        const alertsForDevices = yield DeviceAPI.getAlerts(globalState.user.userId);
        dispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { devices: alertsForDevices }
        });
      }
    } catch (error) {
      if (isCancelled) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Could not cancel device return. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      routerHistory.push(
        generatePath(reach === "company" ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
      );
    }
  });
  const markInternalTransitAsDelivered = () => __async(void 0, null, function* () {
    let isDeliveryConfirmed = false;
    setIsMarkAsDeliveredLoading(true);
    try {
      yield DeviceAPI.changeDeviceOwnerSetTransitAsDelivered(selectedDeviceTransit.id, new LocalDate().toDateString());
      showMessage("Transit successfully marked as delivered.", "success");
      isDeliveryConfirmed = true;
      const alertsForDevices = yield DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
    } catch (error) {
      if (isDeliveryConfirmed) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setIsMarkAsDeliveredLoading(false);
      routerHistory.push(
        generatePath(reach === "company" ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
      );
    }
  });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      Typography,
      {
        sx: __spreadValues(__spreadValues({}, themeFonts.title2), linkTextSx),
        onClick: () => {
          routerHistory.push(
            generatePath(reach === "company" ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
          );
        },
        children: [
          (_e = (_d = selectedDeviceTransit == null ? void 0 : selectedDeviceTransit.device) == null ? void 0 : _d.modelName) != null ? _e : "Return",
          " "
        ]
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m10, display: "flex", justifyContent: "center" }, children: getModelImage((_f = selectedDeviceTransit == null ? void 0 : selectedDeviceTransit.device) == null ? void 0 : _f.type, (_g = selectedDeviceTransit == null ? void 0 : selectedDeviceTransit.device) == null ? void 0 : _g.modelName, {
      width: "180px",
      height: "auto"
    }) }),
    /* @__PURE__ */ jsx(Box, { component: "section", sx: spacing.mt40, children: /* @__PURE__ */ jsxs(Box, { component: "dl", sx: { display: "flex", flexDirection: "column", margin: 0, padding: 0, gap: spacing.g15 }, children: [
      selectedDeviceTransit.status && /* @__PURE__ */ jsx(
        FieldValueComponent,
        {
          title: "Return status",
          value: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: getTransitStatus(selectedDeviceTransit.status) })
        }
      ),
      ((_h = selectedDeviceTransit == null ? void 0 : selectedDeviceTransit.sender) == null ? void 0 : _h.possessionType) && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Sender", value: getDeviceSender(selectedDeviceTransit) }),
      ((_i = selectedDeviceTransit == null ? void 0 : selectedDeviceTransit.receiver) == null ? void 0 : _i.possessionType) && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Receiver", value: getDeviceReceiver(selectedDeviceTransit) }),
      ((_j = selectedDeviceTransit == null ? void 0 : selectedDeviceTransit.receiver) == null ? void 0 : _j.deliveryAddress) && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Address", value: (_k = selectedDeviceTransit == null ? void 0 : selectedDeviceTransit.receiver) == null ? void 0 : _k.deliveryAddress }),
      ((_l = selectedDeviceTransit == null ? void 0 : selectedDeviceTransit.device) == null ? void 0 : _l.serialNumber) && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Serial number", value: (_m = selectedDeviceTransit == null ? void 0 : selectedDeviceTransit.device) == null ? void 0 : _m.serialNumber })
    ] }) }),
    selectedDeviceTransit.status === DeviceTransitStatus.Pending && /* @__PURE__ */ jsx(Box, { sx: spacing.mt40, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10 }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          fullWidth: true,
          onClick: () => cancelReturnDevice(),
          children: "Cancel return"
        }
      ),
      isInternalTransit && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Mark as shipped",
          loading: isMarkAsShippedLoading,
          colorVariant: "primary",
          sizeVariant: "medium",
          fullWidth: true,
          onClick: markInternalTransitAsShipped
        }
      ),
      isReturningOrSendToZeltStorageTransit && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Mark as shipped",
          loading: isMarkAsShippedLoading,
          colorVariant: "primary",
          sizeVariant: "medium",
          fullWidth: true,
          onClick: markReturningTransitAsShipped
        }
      )
    ] }) }),
    selectedDeviceTransit.status === DeviceTransitStatus.Shipping && isInternalTransit && /* @__PURE__ */ jsx(Box, { sx: spacing.mt40, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Confirm delivery",
        loading: isMarkAsDeliveredLoading,
        colorVariant: "primary",
        sizeVariant: "medium",
        fullWidth: true,
        onClick: markInternalTransitAsDelivered
      }
    ) })
  ] });
};
