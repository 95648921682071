"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { ignoredPayLines } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
const iconSize = { width: 14, height: 14 };
export const PayLineTypeMenu = ({ disabled, kind, payCodes, onMenuItemClick, sx }) => {
  const payrunMenuOptions = useMemo(() => {
    const isDeduction = kind === "deduction";
    return payCodes.filter(
      (payCode) => !payCode.isControlCode && payCode.isDeduction === isDeduction && !ignoredPayLines.has(payCode.code)
    ).sort((a, b) => a.code.localeCompare(b.code, void 0, { sensitivity: "base" })).map((item) => ({
      handler: () => onMenuItemClick(item),
      label: item.code
    }));
  }, [kind, payCodes, onMenuItemClick]);
  return /* @__PURE__ */ jsx(
    StyledMenuComponent,
    {
      options: payrunMenuOptions,
      actionButtonDetails: {
        type: "button",
        colorVariant: "secondary",
        sizeVariant: "small",
        title: { addition: "Add income", deduction: "Add deduction" }[kind],
        icon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize))
      },
      anchorOrigin: { horizontal: "left", vertical: "top" },
      transformOrigin: { horizontal: "left", vertical: "bottom" },
      disabled: disabled || payrunMenuOptions.length === 0,
      sx
    }
  );
};
