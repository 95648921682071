"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { DOCUMENTS_ME_ROUTE } from "@/lib/routes";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const CompanySignatorySignSignContractModal = ({
  setOpen,
  open,
  companySignatoryUserId,
  signAndSendContract,
  errorMessage,
  updateSignature
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isSigningContract, setIsSigningContract] = useState(false);
  const [companySignature, setCompanySignature] = useState("");
  const [contractSigned, setContractSigned] = useState(false);
  const routerLocation = useLocation();
  const inCompanySignMode = routerLocation == null ? void 0 : routerLocation.pathname.includes("company-sign");
  const routerHistory = useHistory();
  const signContract = useCallback(() => __async(void 0, null, function* () {
    setIsSigningContract(true);
    if (!companySignature) {
      showMessage(polyglot.t("CompanySignatorySignSignContractModal.errorMessages.signature"), "error");
    }
    try {
      yield signAndSendContract(companySignatoryUserId, companySignature);
      setContractSigned(true);
    } catch (error) {
      setIsSigningContract(false);
    }
  }), [companySignature, signAndSendContract, companySignatoryUserId, showMessage, polyglot]);
  const handleSignature = (text) => {
    updateSignature == null ? void 0 : updateSignature(text);
    setCompanySignature(text);
  };
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    !contractSigned && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("CompanySignatorySignSignContractModal.signTheContract") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("CompanySignatorySignSignContractModal.agreementMessage") }),
      /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "companySignature",
          label: polyglot.t("CompanySignatorySignSignContractModal.companySignature"),
          value: companySignature,
          type: "text",
          onChange: (e) => handleSignature(e.target.value),
          error: !companySignature,
          helperText: polyglot.t("CompanySignatorySignSignContractModal.helperSignature"),
          clearText: () => handleSignature("")
        }
      ) }),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            mt: "2em"
          },
          children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: __spreadValues({}, themeFonts.signature), children: companySignature })
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "secondary",
            onClick: () => {
              setOpen(false);
              setCompanySignature("");
            },
            children: polyglot.t("General.cancel")
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary",
            name: inCompanySignMode ? polyglot.t("CompanySignatorySignSignContractModal.sign") : polyglot.t("CompanySignatorySignSignContractModal.signAndSend"),
            loading: isSigningContract,
            disabled: isSigningContract || !companySignature,
            onClick: signContract
          }
        )
      ] })
    ] }),
    contractSigned && !Boolean(errorMessage) && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Thank you!" }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("CompanySignatorySignSignContractModal.youWillReceiveEmail") }),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "primary",
          sizeVariant: "medium",
          fullWidth: true,
          onClick: () => routerHistory.push(generatePath(DOCUMENTS_ME_ROUTE)),
          children: polyglot.t("CompanySignatorySignSignContractModal.close")
        }
      ) })
    ] }),
    Boolean(errorMessage) && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserSignContractModal.sorry") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: errorMessage }),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "primary",
          sizeVariant: "medium",
          fullWidth: true,
          onClick: () => routerHistory.push(generatePath(DOCUMENTS_ME_ROUTE)),
          children: polyglot.t("UserSignContractModal.close")
        }
      ) })
    ] })
  ] }) });
};
