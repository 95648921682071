"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { generatePath, useHistory } from "react-router-dom";
import { SETTINGS_BILLING_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export var PlanNames = /* @__PURE__ */ ((PlanNames2) => {
  PlanNames2["PEOPLE_PRO"] = "People Pro";
  PlanNames2["APPS_PRO"] = "Apps Pro";
  PlanNames2["DEVICES_PRO"] = "Devices Pro";
  PlanNames2["MONEY_PRO"] = "Money Pro";
  PlanNames2["TECH_PRO"] = "Tech Pro";
  PlanNames2["GROWTH_PRO"] = "Growth Pro";
  return PlanNames2;
})(PlanNames || {});
export const UPGRADE_REQUIRED_ERROR = "PLAN UPGRADE REQUIRED";
export const SEAT_LIMIT_ERROR = "SEATS LIMIT REACHED";
export const UpgradeToProModal = ({
  isOpen,
  setIsDrawerOpen,
  planName,
  messageSuffix,
  markup,
  seatsLimitError
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const SUPPORT_EMAIL = "support@zelt.app";
  const upgradeMessage = useMemo(() => {
    return {
      proApps: () => polyglot.t("UpgradeToProModal.proApps", { planName }),
      proPeople: () => polyglot.t("UpgradeToProModal.proPeople", { planName }),
      proMoney: () => polyglot.t("UpgradeToProModal.proMoney", { planName }),
      proPermissions: () => polyglot.t("UpgradeToProModal.proPermissions", { planName }),
      proForms: () => polyglot.t("UpgradeToProModal.proForms", { planName }),
      proTemplates: () => polyglot.t("UpgradeToProModal.proTemplates", { planName }),
      proCustomisation: () => polyglot.t("UpgradeToProModal.proCustomisation", { planName }),
      proGeneric: () => polyglot.t("UpgradeToProModal.proGeneric", { planName })
    }[messageSuffix]();
  }, [polyglot, messageSuffix, planName]);
  const seatLimitMessage = useMemo(() => {
    return seatsLimitError ? polyglot.t("UpgradeToProModal.seatsLimitError") : null;
  }, [seatsLimitError, polyglot]);
  const handleButtonClick = () => {
    if (seatsLimitError) {
      const subject = encodeURIComponent(`Contract Plan - Seats limit reached`);
      const body = encodeURIComponent(
        `Hello Support,

We have reached our fixed seats limit on the current plan. Please assist us with upgrading our plan or increasing the seats limit.

Thank you.`
      );
      window.location.href = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    } else {
      routerHistory.push(generatePath(SETTINGS_BILLING_ROUTE));
    }
  };
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen: setIsDrawerOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: seatsLimitError ? polyglot.t("UpgradeToProModal.seatsLimitTitle") : polyglot.t("UpgradeToProModal.toPro") }),
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { lineHeight: "24px" }, children: [
      seatsLimitError ? seatLimitMessage : polyglot.t("UpgradeToProModal.discoveredPro"),
      !seatsLimitError && upgradeMessage,
      markup
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "primary", onClick: handleButtonClick, children: seatsLimitError ? polyglot.t("UpgradeToProModal.contactSupport") : polyglot.t("UpgradeToProModal.upgrade") }) })
  ] }) });
};
