"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import { TrackTimeDrawer } from "@v2/feature/attendance/company/components/track-time-drawer.component";
import { format, isSameDay, isToday, isWithinInterval } from "date-fns";
import { getAbsenceEntries, getAttendanceEntries, getScheduledEntries } from "../attendance-week.util";
import { ScheduleTrackingType } from "@/v2/feature/attendance/attendance.interface";
import { getTimeFromDateString, getWeekDates } from "@/v2/feature/attendance/attendance.util";
import { TableCalendar } from "@/v2/feature/attendance/components/table-calendar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { LocalDate } from "@/v2/util/local-date";
export const ColoursByType = {
  Regular: themeColors.FreshGreen,
  Break: themeColors.ZeltYellow,
  Overtime: themeColors.Orange
};
export const WeekCalendar = ({
  userId,
  data,
  attendanceSchedule,
  year,
  weekNo,
  refreshRequests,
  userAbsences
}) => {
  var _a;
  const [isTrackTimeOpen, setIsTrackTimeOpen] = useState(false);
  const [selectedLogDate, setSelectedLogDate] = useState(new LocalDate().toDateString());
  const [selectedRequest, setSelectedRequest] = useState(void 0);
  const datesOfWeek = useMemo(() => {
    if (data) return getWeekDates(year, weekNo);
    return [];
  }, [data, weekNo, year]);
  const attendanceTypes = useMemo(
    () => {
      var _a2, _b;
      return (_b = ((_a2 = attendanceSchedule == null ? void 0 : attendanceSchedule.attendanceTypesAllowed) != null ? _a2 : []).reduce((result, type) => {
        result[type.id] = type;
        return result;
      }, {})) != null ? _b : {};
    },
    [attendanceSchedule]
  );
  const requestAndSchedulesPerDay = useMemo(() => {
    if (!attendanceSchedule) return [];
    return datesOfWeek.map(
      (dateObj) => {
        var _a2, _b, _c;
        const currentDate = new LocalDate(dateObj.value).getDate();
        const loggedEvents = data == null ? void 0 : data.find((d) => isSameDay(new LocalDate(d.logDate).getDate(), currentDate));
        const absences = (_a2 = userAbsences == null ? void 0 : userAbsences.filter((a) => {
          return isSameDay(new LocalDate(a.start).getDate(), currentDate) || a.end && isSameDay(new LocalDate(a.end).getDate(), currentDate) || a.end && isWithinInterval(currentDate, {
            start: new LocalDate(a.start).getDate(),
            end: new LocalDate(a.end).getDate()
          });
        })) != null ? _a2 : null;
        const day = format(currentDate, "eeee").toLowerCase();
        return {
          logDate: dateObj.value,
          attendanceEntries: (_b = loggedEvents && loggedEvents.attendanceEntries) != null ? _b : [],
          schedule: (_c = attendanceSchedule && attendanceSchedule[day]) != null ? _c : null,
          absences: absences != null ? absences : null
        };
      }
    );
  }, [attendanceSchedule, data, datesOfWeek, userAbsences]);
  const formatHour = (hour) => {
    return hour.toString().padStart(1, "0") + ":00";
  };
  const renderTimeSlots = useMemo(() => {
    const timeSlots = [];
    if (requestAndSchedulesPerDay.length > 0) {
      let earliestStartHour = 23;
      requestAndSchedulesPerDay.forEach(({ attendanceEntries, schedule }) => {
        attendanceEntries.forEach((entry) => {
          const startHour = parseInt(getTimeFromDateString(entry.startHour).split(":")[0]);
          if (startHour < earliestStartHour) {
            earliestStartHour = startHour;
          }
        });
        schedule && schedule.forEach((slot) => {
          if (slot) {
            const slotFrom = getTimeFromDateString(slot.from);
            const startHour = parseInt(slotFrom.split(":")[0]);
            if (startHour < earliestStartHour) {
              earliestStartHour = startHour;
            }
          }
        });
      });
      earliestStartHour = earliestStartHour > 1 ? earliestStartHour - 1 : 0;
      for (let i = 0; i < 24; i += 1) {
        const slots = datesOfWeek.map((dateObj, index) => {
          const { schedule, attendanceEntries, absences, logDate } = requestAndSchedulesPerDay[index];
          const weekIndex = attendanceSchedule ? getIndexOfWeekInSchedule(attendanceSchedule.startDateOfFirstWeek, logDate, attendanceSchedule.noOfWeeks) : null;
          const isScheduled = schedule && weekIndex !== null && schedule[weekIndex];
          const hasAttendanceEntries = attendanceEntries && attendanceEntries.length > 0;
          const hasAbsences = absences && absences.length > 0;
          const slot = schedule && weekIndex !== null && schedule[weekIndex] ? schedule[weekIndex] : null;
          const slotFrom = slot ? getTimeFromDateString(slot.from) : null;
          const slotTo = slot ? getTimeFromDateString(slot == null ? void 0 : slot.to) : null;
          let events = [];
          if (Boolean(hasAbsences) && absences !== null && isScheduled && slotFrom && slotTo && slot)
            getAbsenceEntries(absences, slotFrom, slotTo, i, events);
          else if (hasAttendanceEntries) getAttendanceEntries(attendanceEntries, i, events, attendanceTypes);
          else if (isScheduled && slotFrom && slotTo) getScheduledEntries(slotFrom, slotTo, i, events);
          return { dateObj, events };
        });
        const isFirstEntry = requestAndSchedulesPerDay[0].attendanceEntries.length > 0 && i === 0;
        const rowId = isFirstEntry ? "first-entry-row" : "";
        timeSlots.push({
          index: i,
          rowId,
          slots,
          earliestStartHour,
          firstColumnLabel: formatHour(i)
        });
      }
    }
    return timeSlots;
  }, [requestAndSchedulesPerDay, datesOfWeek, attendanceSchedule, attendanceTypes]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TableCalendar,
      {
        header: datesOfWeek,
        isActiveHeader: (value) => isToday(new LocalDate(value).getDate()),
        renderTimeSlots,
        onDayClick: (date) => {
          var _a2;
          const request = data.find((req) => req.logDate === date);
          if ((attendanceSchedule == null ? void 0 : attendanceSchedule.trackingType) === ScheduleTrackingType.ClockInClockOut && !request) return;
          setSelectedRequest(request != null ? request : void 0);
          setSelectedLogDate((_a2 = request == null ? void 0 : request.logDate) != null ? _a2 : date);
          setIsTrackTimeOpen(true);
        }
      }
    ),
    /* @__PURE__ */ jsx(
      TrackTimeDrawer,
      {
        isOpen: isTrackTimeOpen,
        setIsOpen: setIsTrackTimeOpen,
        refresh: refreshRequests,
        view: "user",
        userId,
        attendanceId: selectedRequest == null ? void 0 : selectedRequest.id,
        logDate: (_a = selectedRequest == null ? void 0 : selectedRequest.logDate) != null ? _a : selectedLogDate
      }
    )
  ] });
};
