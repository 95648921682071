"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, FormControl, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { TemplateAPI } from "@/api-client/templates.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE } from "@/lib/routes";
import { AccountStatus } from "@/lib/users";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import {
  ADDITIONAL_SIGNATORY_PLACEHOLDER,
  OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER
} from "@/v2/feature/templates/templates.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { JUNE_ACTIONS, useJune } from "@/v2/infrastructure/june/june.hook";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ContractModal = ({
  setOpen,
  open,
  contractTemplate,
  onMissingField,
  existingRecipient
}) => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const [showMessage] = useMessage();
  const [contractRecipientUserId, setContractRecipientUser] = useState();
  const [companySignatoryUserId, setCompanySignatoryUserId] = useState();
  const [isVerifyingContractTemplate, setIsVerifyingContractTemplate] = useState(false);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const routerHistory = useHistory();
  const { trackEvent } = useJune();
  const verifyTemplateParameters = useCallback(() => __async(void 0, null, function* () {
    try {
      trackEvent({
        userId: user.userId,
        event: JUNE_ACTIONS.USED_DOCUMENT_TEMPLATE,
        properties: {
          contractRecipientUserId,
          companySignatoryUserId,
          templateId: contractTemplate.id,
          companyId: contractTemplate.companyId
        }
      });
      if (!existingRecipient && !contractRecipientUserId) {
        showMessage(polyglot.t("ContractModal.errorMessages.select"), "error");
        return;
      }
      setIsVerifyingContractTemplate(true);
      const finalRecipient = existingRecipient ? existingRecipient : contractRecipientUserId;
      if (!finalRecipient) {
        showMessage(polyglot.t("ContractModal.errorMessages.select"), "error");
        return;
      }
      const templateResult = yield TemplateAPI.verifyTemplateParameters({
        templateId: contractTemplate.id,
        contractRecipientUserId: finalRecipient,
        companySignatoryUserId
      });
      const { hasMissingPersonalFields, hasMissingWorkFields } = templateResult;
      if (hasMissingPersonalFields || hasMissingWorkFields) {
        onMissingField({
          templateVerificationResult: templateResult,
          contractRecipientUserId: finalRecipient
        });
        setIsVerifyingContractTemplate(false);
        setOpen(false);
        return;
      }
      setIsVerifyingContractTemplate(false);
      const stateToPassThrough = { companySignatoryUserId };
      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE, {
          templateId: contractTemplate.id,
          userId: finalRecipient
        }),
        stateToPassThrough
      );
    } catch (error) {
      showMessage(
        polyglot.t("ContractModal.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
      setIsVerifyingContractTemplate(false);
    }
  }), [
    polyglot,
    trackEvent,
    user.userId,
    contractRecipientUserId,
    companySignatoryUserId,
    contractTemplate.id,
    contractTemplate.companyId,
    existingRecipient,
    routerHistory,
    showMessage,
    onMissingField,
    setOpen
  ]);
  const recipientOptionsExcludingCompanySignatory = useMemo(() => {
    return nonTerminatedCachedUsers.filter((user2) => user2.userId !== companySignatoryUserId && user2.accountStatus !== AccountStatus.Deactivated).map((u) => __spreadValues({ label: u.displayName, value: u.userId }, u));
  }, [companySignatoryUserId, nonTerminatedCachedUsers]);
  const companySignatoryOptionsExcludingContractRecipient = useMemo(() => {
    return nonTerminatedCachedUsers.filter((user2) => user2.userId !== contractRecipientUserId && user2.accountStatus !== AccountStatus.Deactivated).map((u) => __spreadValues({ label: u.displayName, value: u.userId }, u));
  }, [contractRecipientUserId, nonTerminatedCachedUsers]);
  const templateHasCompanySignatory = useMemo(() => {
    return contractTemplate.templateText.includes(OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER) || contractTemplate.templateText.includes(ADDITIONAL_SIGNATORY_PLACEHOLDER);
  }, [contractTemplate]);
  useEffect(() => {
    if (existingRecipient) {
      setContractRecipientUser(existingRecipient);
      if (companySignatoryUserId && templateHasCompanySignatory || !templateHasCompanySignatory)
        verifyTemplateParameters();
    }
  }, []);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          paddingBottom: spacing.p10
        },
        children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: polyglot.t("ContractModal.populate") })
      }
    ),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          paddingBottom: spacing.p10
        },
        children: [
          !existingRecipient && /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("ContractModal.pleaseAssign", {
            text: templateHasCompanySignatory ? polyglot.t("ContractModal.andAnyAdditionalSignatory") : " "
          }) }),
          existingRecipient && templateHasCompanySignatory && /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("ContractModal.additionalSignatoryAssign", {
            text: templateHasCompanySignatory ? polyglot.t("ContractModal.additionalSignatory") : " "
          }) })
        ]
      }
    ),
    !existingRecipient && /* @__PURE__ */ jsx(FormControl, { sx: { marginTop: spacing.m30 }, children: /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "contractRecipientUser",
        options: recipientOptionsExcludingCompanySignatory,
        onChange: (_, option) => {
          setContractRecipientUser(option == null ? void 0 : option.value);
        },
        value: contractRecipientUserId,
        label: polyglot.t("ContractModal.contractRecipientUser"),
        error: false,
        helperText: false
      }
    ) }),
    templateHasCompanySignatory && /* @__PURE__ */ jsx(FormControl, { sx: { marginTop: spacing.m30 }, children: /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "companySignatoryUser",
        options: companySignatoryOptionsExcludingContractRecipient,
        onChange: (_, option) => {
          setCompanySignatoryUserId(option == null ? void 0 : option.value);
        },
        value: companySignatoryUserId,
        label: polyglot.t("ContractModal.companySignatoryUser"),
        error: false,
        helperText: false
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", justifyContent: "space-between", width: "1s00%" }, spacing.mt40), children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "large",
        colorVariant: "primary",
        fullWidth: true,
        name: polyglot.t("ContractModal.createDraft"),
        loading: isVerifyingContractTemplate,
        disabled: isVerifyingContractTemplate || templateHasCompanySignatory && !companySignatoryUserId || !existingRecipient && !contractRecipientUserId,
        onClick: verifyTemplateParameters
      }
    ) }) })
  ] }) }) });
};
