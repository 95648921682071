"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { themeColors } from "@v2/styles/colors.styles";
import { differenceInHours } from "date-fns";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { iconSize } from "@/v2/styles/menu.styles";
export const SecurityDetailsInformation = ({ devicePossession }) => {
  var _a;
  const device = devicePossession.device;
  const isInactive = useMemo(() => {
    var _a2;
    if (!((_a2 = devicePossession.device) == null ? void 0 : _a2.lastCheckIn)) return false;
    const lastCheckInDate = new Date(devicePossession.device.lastCheckIn);
    const currentTime = /* @__PURE__ */ new Date();
    return differenceInHours(currentTime, lastCheckInDate) > 72;
  }, [(_a = devicePossession.device) == null ? void 0 : _a.lastCheckIn]);
  return /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          title: "Activity",
          items: [
            {
              type: SectionItemType.Pair,
              label: "Status",
              value: /* @__PURE__ */ jsx(Fragment, { children: isInactive ? /* @__PURE__ */ jsxs(Fragment, { children: [
                /* @__PURE__ */ jsx(Mistake, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
                " Inactive"
              ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
                /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
                " Active"
              ] }) }),
              hidden: !Boolean(device.lastCheckIn)
            },
            {
              type: SectionItemType.Pair,
              label: "Last check in",
              value: device.lastCheckIn ? device.inHouseMdm ? new Date(device.lastCheckIn).toUTCString() : new Date(device.lastCheckIn).toUTCString().split(" ").slice(0, 4).join(" ") : "NA",
              hidden: !Boolean(device.lastCheckIn)
            },
            {
              type: SectionItemType.TextLine,
              value: "No activity",
              hidden: Boolean(device.lastCheckIn)
            }
          ]
        }
      ]
    }
  );
};
