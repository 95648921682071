"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback, useState } from "react";
import { cloneDeep } from "lodash";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { CalendarAPI } from "@/v2/feature/calendar/calendar.api";
import {
  PaginationPartsEnum
} from "@/v2/feature/calendar/calendar.interface";
export const useCalendarData = (params, setCalendarAdvEventsTimeline, setLoading) => {
  const [calendarEventsTimeline, setCalendarEventsTimeline] = useState(void 0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [error, setError] = useState(false);
  const [usersLoading, setUsersLoading] = useState(true);
  const { filterString, selectedTypes, searchInput, view, page, pageSize } = params;
  const [showMessage] = useMessage();
  const fetchCalendarData = useCallback(
    (range) => __async(void 0, null, function* () {
      try {
        if (!range || error) return;
        setError(false);
        setLoading(true);
        setUsersLoading(true);
        setCalendarEventsTimeline(void 0);
        setCalendarAdvEventsTimeline(void 0);
        const data = yield CalendarAPI.loadInitialCalendarData(
          range,
          filterString,
          selectedTypes,
          searchInput,
          view,
          page,
          pageSize
        );
        setCalendarEventsTimeline(cloneDeep(data.data));
        const totalItems2 = data.pagination[PaginationPartsEnum.totalItems];
        const localPageSize = data.pagination.pageSize;
        const totalPages2 = Math.ceil(totalItems2 / Number(localPageSize) || DEFAULT_PAGE_SIZE);
        setTotalPages(totalPages2);
        setTotalItems(totalItems2);
      } catch (error2) {
        setLoading(false);
        setError(true);
        showMessage(`Something bad happened. ${nestErrorMessage(error2)}`, "error");
      } finally {
        setUsersLoading(false);
      }
    }),
    [
      filterString,
      selectedTypes,
      searchInput,
      view,
      page,
      pageSize,
      setCalendarAdvEventsTimeline,
      showMessage,
      error,
      setLoading
    ]
  );
  return {
    calendarEventsTimeline,
    fetchCalendarData,
    usersLoading,
    totalPages,
    totalItems
  };
};
