"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { ContractorInvoiceModal } from "@v2/feature/payments/pages/components/contractor-invoice-modal.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { themeColors } from "@v2/styles/colors.styles";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { ContractorInvoiceAPI } from "@/api-client/contractor-invoice-api";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { DisplayInvoiceUserAvatar } from "@/v2/feature/payments/pages/components/display-invoice-user-avatar.component";
import { ContractorInvoiceStatus } from "@/v2/feature/payments/payments.interface";
import { getContractorInvoiceStatusComponent } from "@/v2/feature/payments/utils/get-contractor-invoice-status.util";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { TableColumn } from "@/v2/feature/super-admin/components/helper/table-helper";
import { SelectDeselectIdRows } from "@/v2/feature/task/components/task-table/select-deselect-string-id-rows.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { truncateWithEllipses } from "@/v2/util/string.util";
const DATE_FORMAT = "DD MMM YYYY";
const TabFilter = [
  { name: "All", value: "All" },
  { name: "Draft", value: ContractorInvoiceStatus.Draft },
  { name: ContractorInvoiceStatus.Pending, value: ContractorInvoiceStatus.Pending },
  { name: "Paid", value: "Paid" },
  { name: "Other", value: "Other" }
];
export function ContractorInvoiceTable({
  invoices,
  onActionPerformed,
  reach,
  setSelectionModel,
  selectionModel,
  loadingInvoices
}) {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const location = useLocation();
  const queriedInvoiceId = new URLSearchParams(location.search).get("invoiceId");
  const queriedInvoice = invoices.find((invoice) => invoice.id === queriedInvoiceId);
  const { data: settingsAndAppConfig } = useApiClient(PaymentTypeSettingsEndpoints.getInvoiceTypesForCompanyId(), {
    suspense: false
  });
  const isAccountingAppConfigured = settingsAndAppConfig == null ? void 0 : settingsAndAppConfig.accountingAppConfigured;
  const [selectedInvoice, setSelectedInvoice] = useState(
    queriedInvoiceId ? queriedInvoice : void 0
  );
  const [filterValue, setFilterValue] = useState(
    (_d = (_c = (_b = (_a = state.user.features) == null ? void 0 : _a.invoice) == null ? void 0 : _b.table) == null ? void 0 : _c.selectedFilters) != null ? _d : ContractorInvoiceStatus.Pending
  );
  const [searchInput, setSearchInput] = useState("");
  const [showMessage] = useMessage();
  const [openInvoiceDetailsModal, setOpenInvoiceDetailsModal] = useState(!!queriedInvoice);
  const currentUserIsAdmin = hasScopes(["invoices:all"], getScopesContext(user));
  const { getCachedUserById } = useCachedUsers();
  const filteredInvoices = useMemo(() => {
    let filteredInvoices2 = [...invoices];
    if (filterValue === "Paid")
      filteredInvoices2 = invoices.filter(
        (i) => {
          var _a2;
          return i.status === ContractorInvoiceStatus.Approved && (!i.payment || ((_a2 = i.payment.transaction) == null ? void 0 : _a2.status) === "COMPLETED" || i.payment.markPaid);
        }
      );
    if (filterValue === "Awaiting payment")
      filteredInvoices2 = invoices.filter(
        (i) => {
          var _a2;
          return i.status === ContractorInvoiceStatus.Approved && i.payment && ((_a2 = i.payment.transaction) == null ? void 0 : _a2.status) !== "COMPLETED" && !i.payment.markPaid;
        }
      );
    if (filterValue === ContractorInvoiceStatus.Draft)
      filteredInvoices2 = invoices.filter((i) => [ContractorInvoiceStatus.Draft].includes(i.status));
    if (filterValue === "Pending")
      filteredInvoices2 = invoices.filter((i) => [ContractorInvoiceStatus.Pending].includes(i.status));
    if (filterValue === "Other")
      filteredInvoices2 = invoices.filter(
        (i) => [ContractorInvoiceStatus.Rejected, ContractorInvoiceStatus.Voided].includes(i.status)
      );
    if (searchInput) {
      const loweredCaseSearch = searchInput.toLowerCase();
      filteredInvoices2 = filteredInvoices2.filter((i) => {
        var _a2, _b2;
        const beneficiary = getCachedUserById(i.from);
        const name = beneficiary ? beneficiary.displayName : "";
        return ((_a2 = i.invoiceNumber) == null ? void 0 : _a2.toLowerCase().includes(loweredCaseSearch)) || name.toLowerCase().includes(loweredCaseSearch) || ((_b2 = i.totalAmount) == null ? void 0 : _b2.toString().includes(loweredCaseSearch));
      });
    }
    return filteredInvoices2;
  }, [invoices, filterValue, searchInput, getCachedUserById]);
  const bulkApproveInvoices = () => __async(this, null, function* () {
    try {
      const invoiceCount = yield ContractorInvoiceAPI.bulkApprove(selectionModel);
      setSelectionModel([]);
      yield onActionPerformed();
      showMessage(polyglot.t("ContractorInvoiceModal.successMessages.bulkApproval", { invoiceCount }), "success");
    } catch (error) {
      showMessage(
        polyglot.t("ContractorInvoiceModal.errorMessages.bulkApproval", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const invoicesColumn = useMemo(
    () => [
      {
        id: "select",
        enableSorting: false,
        minSize: 20,
        maxSize: 20,
        header: () => {
          const selectableInvoiceIds = new Set(filteredInvoices.map(({ id }) => id));
          const allSelected = selectionModel.length > 0 && selectionModel.length === selectableInvoiceIds.size && selectionModel.every((id) => selectableInvoiceIds.has(id));
          return /* @__PURE__ */ jsx(Box, { onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: "allSelected",
              checked: allSelected,
              value: "allSelected",
              onChange: (_, checked) => {
                setSelectionModel(checked ? [...selectableInvoiceIds] : []);
              }
            }
          ) });
        },
        cell: ({ row: { original } }) => {
          var _a2, _b2, _c2, _d2;
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m10 }, children: /* @__PURE__ */ jsx(Box, { onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: (_b2 = (_a2 = original.id) == null ? void 0 : _a2.toString()) != null ? _b2 : "",
              checked: selectionModel.includes(original.id),
              value: (_d2 = (_c2 = original.id) == null ? void 0 : _c2.toString()) != null ? _d2 : "",
              onChange: () => {
                let finalArray;
                if (selectionModel == null ? void 0 : selectionModel.includes(original.id)) {
                  finalArray = selectionModel.filter((sm) => sm !== original.id);
                } else finalArray = [...selectionModel, original.id];
                setSelectionModel(finalArray);
              }
            }
          ) }) });
        }
      },
      ...reach !== "me" ? [
        new TableColumn().define({
          header: polyglot.t("PaymentTableHeaders.beneficiary"),
          id: "from",
          fieldName: "from",
          enableSorting: true,
          sortingFn: (a, b) => sortString(a, b, (item) => polyglot.t(`${item.fromUser.firstName} ${item.fromUser.lastName}`)),
          parseRow: (row) => row.createdBy ? /* @__PURE__ */ jsx(DisplayInvoiceUserAvatar, { row, user: { idField: "from", userField: "fromUser" } }, row.id) : /* @__PURE__ */ jsx(EmptyCell, {})
        })
      ] : [],
      new TableColumn().define({
        header: polyglot.t("PaymentTableHeaders.description"),
        id: "notes",
        enableSorting: false,
        maxSize: 250,
        fieldName: "notes",
        parseRow: (row) => {
          const truncateLimit = 35;
          const notesTruncated = (row == null ? void 0 : row.notes) && row.notes.length > truncateLimit;
          return !!notesTruncated ? /* @__PURE__ */ jsx(StyledTooltip, { title: row.notes, placement: "right", children: /* @__PURE__ */ jsx("div", { style: { paddingRight: "10px" }, children: row.notes ? truncateWithEllipses(row.notes, truncateLimit) : "" }) }) : /* @__PURE__ */ jsx("div", { style: { paddingRight: "10px" }, children: row.notes });
        }
      }),
      new TableColumn().define({
        header: polyglot.t("PaymentTableHeaders.dueDate"),
        id: "dueDate",
        fieldName: "dueDate",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => new Date(item.dueDate).getTime()),
        formatter: (date) => {
          return /* @__PURE__ */ jsx(Box, { sx: { color: new Date(date).getTime() < Date.now() ? themeColors.RedDark : themeColors.black }, children: moment(date).format(DATE_FORMAT) });
        },
        parseRow: (row) => {
          const showAlert = [ContractorInvoiceStatus.Pending].includes(row.status) && new Date(row.dueDate).getTime() < Date.now();
          return /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                color: showAlert ? themeColors.RedDark : themeColors.black
              },
              children: moment(row.dueDate).format(DATE_FORMAT)
            }
          );
        }
      }),
      new TableColumn().define({
        header: polyglot.t("PaymentTableHeaders.status"),
        id: "status",
        fieldName: "status",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.status),
        parseRow: (row) => {
          return /* @__PURE__ */ jsx(Box, { children: getContractorInvoiceStatusComponent(
            row.status,
            __spreadValues({}, themeFonts.caption),
            !!isAccountingAppConfigured,
            !!row.externalId
          ) });
        }
      }),
      new TableColumn().define({
        header: polyglot.t("PaymentTableHeaders.amount"),
        id: "amount",
        fieldName: "lineItems",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalAmount),
        parseRow: (row) => {
          return /* @__PURE__ */ jsx("div", { style: { paddingRight: "10px" }, children: formatCurrency(row.totalAmount, void 0, row.currency) });
        }
      })
    ],
    [reach, polyglot, filteredInvoices, selectionModel, setSelectionModel, isAccountingAppConfigured]
  );
  const allSelectedInvoicesArePending = useMemo(() => {
    const selectedInvoices = filteredInvoices.filter((invoice) => selectionModel.includes(invoice.id));
    return selectedInvoices.every((eachInvoice) => eachInvoice.status === ContractorInvoiceStatus.Pending);
  }, [filteredInvoices, selectionModel]);
  const getInvoiceBulkActionsOptions = () => {
    return [
      {
        icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
        handler: () => bulkApproveInvoices(),
        label: "Approve selected",
        disabled: !allSelectedInvoicesArePending
      }
    ];
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { height: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }, children: [
      /* @__PURE__ */ jsx(
        TabFilterButtons,
        {
          filters: TabFilter,
          setFilterValue,
          filterValue,
          hasSearch: true,
          onFilterChange: (_0) => __async(this, [_0], function* ({ filterValue: filterValue2, searchInput: searchInput2 }) {
            try {
              setSelectionModel([]);
              setFilterValue(filterValue2);
              setSearchInput(searchInput2);
              const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
                "invoice",
                "table",
                "selectedFilters",
                filterValue2
              );
              dispatch({
                type: GlobalStateActions.UPDATE_USER,
                payload: updatedGlobalUser
              });
            } catch (error) {
              showMessage(
                polyglot.t("PaymentTableHeaders.errorMessages.filter", {
                  errorMessage: nestErrorMessage(error)
                }),
                "error"
              );
            }
          })
        }
      ),
      selectionModel.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "end", gap: spacing.g5, marginLeft: spacing.ml10 }, children: [
        /* @__PURE__ */ jsx(
          SelectDeselectIdRows,
          {
            selectionModel,
            setSelectionModel,
            rows: filteredInvoices,
            hideSelectAll: true
          }
        ),
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: getInvoiceBulkActionsOptions(),
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "Actions",
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            }
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: [...filteredInvoices],
        columnData: invoicesColumn,
        rowClick: (row) => {
          setSelectedInvoice(row.original);
          setOpenInvoiceDetailsModal(true);
        },
        initialSort: [{ id: "dueDate", desc: true }],
        loading: loadingInvoices
      }
    ) }),
    openInvoiceDetailsModal && selectedInvoice && /* @__PURE__ */ jsx(
      ContractorInvoiceModal,
      {
        isOpen: openInvoiceDetailsModal,
        setIsOpen: setOpenInvoiceDetailsModal,
        selectedInvoice,
        onClose: () => {
          setSelectedInvoice(void 0);
          setOpenInvoiceDetailsModal(false);
        },
        onActionPerformed,
        currentUserIsAdmin,
        reach
      }
    )
  ] });
}
