"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { ProfileTab } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { groupBy } from "lodash";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import * as Yup from "yup";
import { UploadInput } from "@/component/forms/UploadInput";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledRadioGroup } from "@/v2/components/theme-components/styled-radio-group.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { AppDetailsEndpoints } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { CustomProfileFormEndpoints } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import {
  EntityImportWizardData,
  EntityImportWizardSource,
  EntityImportWizardSourceOptions
} from "@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface";
import { PersistentNotification } from "@/v2/feature/entity-import/wizard/components/user/persistent-notification.component";
import {
  convertPeopleCSVToUserImport2,
  validateAbsenceAdjustmentsImport,
  validateAbsenceImport,
  validateAttendanceImport,
  validateDeviceImport,
  validatePeopleImport2
} from "@/v2/feature/entity-import/wizard/entity-import-validator.util";
import AttendanceImportCsvTemplate from "@/v2/feature/entity-import/wizard/template-samples/zelt-attendance-import-template.csv";
import DeviceImportCsvTemplate from "@/v2/feature/entity-import/wizard/template-samples/zelt-devices-import-template.csv";
import AbsenceAdjustmentsImportCsvTemplate from "@/v2/feature/entity-import/wizard/template-samples/zelt-timeadjustments-import.template.csv";
import AbsenceImportCsvTemplate from "@/v2/feature/entity-import/wizard/template-samples/zelt-timeaway-import-template.csv";
import { getCodeForCountryName } from "@/v2/feature/payments/payments.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { caseInsensitiveSort } from "@/v2/util/array.util";
import { parseCsvBuffer } from "@/v2/util/csv.util";
import { LocalDate } from "@/v2/util/local-date";
import { arrayBufferToString } from "@/v2/util/string.util";
export const DataSourceChoiceSection = ({
  importState,
  onNext,
  sx,
  entities,
  sites,
  departments,
  attendanceSchedules,
  getCustomFieldsForForm
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { data: absencePolicies } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), { suspense: false });
  const { data: attendanceTypes } = useApiClient(AttendanceEndpoints.getCompanyAttendanceTypes(), { suspense: false });
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const V1_PAYROLL_IMPORT_FLOW = "/settings/import/wizard/payroll/";
  const V1_USER_GOOGLE_IMPORT_FLOW = "/settings/import/wizard/users/google";
  const azureAppStub = "azure-ad";
  const googleAppStub = "google-workspace";
  const fallbackDomain = EntityImportWizardData.Users;
  const [azureAdLoading, setAzureAdLoading] = useState(false);
  const [newTemplateFile, setNewTemplateFile] = useState(false);
  const [selectedUserProfileFormIds, setSelectedUserProfileFormIds] = useState(/* @__PURE__ */ new Set());
  const { cachedUsers: allUsersIncludingTerminated } = useCachedUsers();
  const { data: azureAppDetails } = useApiClient(AppDetailsEndpoints.getAppDetails(azureAppStub), {
    suspense: false
  });
  const { data: googleAppDetails } = useApiClient(AppDetailsEndpoints.getAppDetails(googleAppStub), {
    suspense: false
  });
  const { data: customProfileForms } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false
  });
  const sortedProfileForms = useMemo(() => {
    const knownTabs = [ProfileTab.Personal, ProfileTab.Work, ProfileTab.Compensation, ProfileTab.Contact];
    return customProfileForms == null ? void 0 : customProfileForms.slice().sort((a, b) => {
      var _a2, _b;
      const knownTabIndexA = knownTabs.indexOf(a.formTab);
      const knownTabIndexB = knownTabs.indexOf(b.formTab);
      if (knownTabIndexA === -1 && knownTabIndexB === -1) {
        return caseInsensitiveSort(a, b, (x) => x.formTab);
      }
      return (
        // sort by tab order first
        knownTabIndexA - knownTabIndexB || // then by any display order values (null display orders are put last)
        ((_a2 = a.displayOrder) != null ? _a2 : 1e9) - ((_b = b.displayOrder) != null ? _b : 1e9) || // if the tabs and display orders are the same, order by form creation date
        a.createdAt.localeCompare(b.createdAt)
      );
    });
  }, [customProfileForms]);
  const DataSourceChoiceSchema = Yup.object().shape({
    dataSourceChoice: Yup.string().oneOf(EntityImportWizardSourceOptions[(_a = importState.domain) != null ? _a : fallbackDomain].map((o) => o.value)).required(polyglot.t("DataSourceChoiceSection.errorMessages.pleaseSelect")),
    dataSourceCsv: Yup.string().when("dataSourceChoice", {
      is: EntityImportWizardSource.CSV,
      then: Yup.string().required(polyglot.t("DataSourceChoiceSection.errorMessages.mustUploadCsv"))
    })
  });
  const handleAzureAdUserImportSourceSelection = () => __async(void 0, null, function* () {
    if (azureAppDetails == null ? void 0 : azureAppDetails.authorised) {
      try {
        setAzureAdLoading(true);
        const userListForImport = yield AppIntegrationAPI.getUserListForImport(azureAppStub);
        const mappedEntries = userListForImport.map((entry) => ({
          id: entry.id,
          entity: __spreadProps(__spreadValues({}, entry), {
            startDate: entry.startDate ? new LocalDate(entry.startDate).toDateString() : null,
            country: entry.country ? getCodeForCountryName(entry.country) : void 0
          }),
          errors: []
        }));
        if (userListForImport && formik.values.dataSourceChoice) {
          onNext(formik.values.dataSourceChoice, { errors: mappedEntries });
        }
      } catch (error) {
        showMessage(
          `${polyglot.t("DataSourceChoiceSection.errorMessages.fetchUsers")}. ${nestErrorMessage(error)}`,
          "error"
        );
      } finally {
        setAzureAdLoading(false);
      }
    }
  });
  const formik = useFormik({
    initialValues: {
      dataSourceChoice: importState == null ? void 0 : importState.source,
      dataSourceCsv: void 0
    },
    validateOnMount: true,
    validationSchema: DataSourceChoiceSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      if (values.dataSourceChoice) {
        setSaving(true);
        if (values.dataSourceChoice === EntityImportWizardSource.AzureAD) {
          yield handleAzureAdUserImportSourceSelection();
        }
        try {
        } catch (error) {
          showMessage(
            `${polyglot.t("DataSourceChoiceSection.errorMessages.importUsers")}. ${nestErrorMessage(error)}`,
            "error"
          );
          setSaving(false);
        }
      }
    })
  });
  const handleFileUpload = (result) => {
    result == null ? void 0 : result.errors.map((error) => error.id = v4());
    if (result && formik.values.dataSourceChoice) {
      onNext(formik.values.dataSourceChoice, result);
    }
  };
  const dataSourceChoiceOptions = useMemo(() => {
    var _a2;
    return EntityImportWizardSourceOptions[(_a2 = importState.domain) != null ? _a2 : fallbackDomain].filter((o) => {
      let appStatus;
      if (importState.domain === EntityImportWizardData.Users) {
        if (o.value === EntityImportWizardSource.AzureAD) appStatus = azureAppDetails == null ? void 0 : azureAppDetails.authorised;
        else if (o.value === EntityImportWizardSource.Google) appStatus = googleAppDetails == null ? void 0 : googleAppDetails.authorised;
        else if (o.value === EntityImportWizardSource.CSV) appStatus = true;
      } else {
        appStatus = true;
      }
      return appStatus === true;
    }).map((o) => {
      return __spreadValues({}, o);
    });
  }, [azureAppDetails == null ? void 0 : azureAppDetails.authorised, fallbackDomain, googleAppDetails == null ? void 0 : googleAppDetails.authorised, importState.domain]);
  useEffect(() => {
    if (!newTemplateFile) {
      setSelectedUserProfileFormIds(/* @__PURE__ */ new Set());
    }
  }, [newTemplateFile]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(
      PersistentNotification,
      {
        inUse: azureAdLoading,
        message: polyglot.t("handleAzureAdUserImportSourceSelection.message")
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("DataSourceChoiceSection.selectSource") }),
      /* @__PURE__ */ jsx(
        StyledRadioGroup,
        {
          name: "dataSourceChoice",
          options: dataSourceChoiceOptions,
          selectedValue: formik.values.dataSourceChoice,
          onChange: (o) => {
            const currentDataSourceChoice = o.currentTarget.value;
            formik.setFieldValue("dataSourceChoice", currentDataSourceChoice);
            if (importState.domain === EntityImportWizardData.Payroll && [
              EntityImportWizardSource.CSV,
              EntityImportWizardSource.Xero,
              EntityImportWizardSource.Quickbooks
            ].includes(currentDataSourceChoice)) {
              routerHistory.push(V1_PAYROLL_IMPORT_FLOW.concat(currentDataSourceChoice));
            } else if (importState.domain === EntityImportWizardData.Users && currentDataSourceChoice === EntityImportWizardSource.Google) {
              routerHistory.push(V1_USER_GOOGLE_IMPORT_FLOW);
            } else if (importState.domain === EntityImportWizardData.Users && currentDataSourceChoice === EntityImportWizardSource.AzureAD) {
              if (!(azureAppDetails == null ? void 0 : azureAppDetails.authorised))
                showMessage(polyglot.t("DataSourceChoiceSection.errorMessages.connectAzure"), "error");
            }
          }
        }
      ),
      formik.values.dataSourceChoice === EntityImportWizardSource.CSV && importState.domain === EntityImportWizardData.Absences && /* @__PURE__ */ jsxs(Box, { sx: { mt: "-40px" }, children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          polyglot.t("DataSourceChoiceSection.useTemplate1"),
          " ",
          /* @__PURE__ */ jsx("a", { href: AbsenceImportCsvTemplate, rel: "noreferrer", style: { color: themeColors.DarkGrey }, children: polyglot.t("DataSourceChoiceSection.useTemplate2") }),
          " ",
          polyglot.t("DataSourceChoiceSection.useTemplate3")
        ] }),
        /* @__PURE__ */ jsx(
          UploadInput,
          {
            skipUpload: true,
            onChange: (_, file) => __async(void 0, null, function* () {
              try {
                if (!file) return;
                const arrayBuffer = yield file.arrayBuffer();
                const csvBuffer = yield arrayBufferToString(arrayBuffer);
                const parsedCsv = parseCsvBuffer(csvBuffer);
                const validationResult = yield validateAbsenceImport(
                  parsedCsv,
                  allUsersIncludingTerminated,
                  absencePolicies != null ? absencePolicies : []
                );
                handleFileUpload(validationResult);
              } catch (error) {
                showMessage(polyglot.t("DataSourceChoiceSection.errorMessages.parsingCsv"), "error");
                console.error(":::: ERROR PARSING FILE :::::", error);
              }
            })
          }
        )
      ] }),
      formik.values.dataSourceChoice === EntityImportWizardSource.CSV && importState.domain === EntityImportWizardData.AbsenceAdjustments && /* @__PURE__ */ jsxs(Box, { sx: { mt: "-40px" }, children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          polyglot.t("DataSourceChoiceSection.useTemplate1"),
          " ",
          /* @__PURE__ */ jsx(
            "a",
            {
              href: AbsenceAdjustmentsImportCsvTemplate,
              rel: "noreferrer",
              style: { color: themeColors.DarkGrey },
              children: polyglot.t("DataSourceChoiceSection.useTemplate2")
            }
          ),
          " ",
          polyglot.t("DataSourceChoiceSection.useTemplate3")
        ] }),
        /* @__PURE__ */ jsx(
          UploadInput,
          {
            skipUpload: true,
            onChange: (_, file) => __async(void 0, null, function* () {
              try {
                if (!file) return;
                const arrayBuffer = yield file.arrayBuffer();
                const csvBuffer = yield arrayBufferToString(arrayBuffer);
                const parsedCsv = parseCsvBuffer(csvBuffer);
                const validationResult = yield validateAbsenceAdjustmentsImport(
                  parsedCsv,
                  allUsersIncludingTerminated,
                  absencePolicies != null ? absencePolicies : []
                );
                handleFileUpload(validationResult);
              } catch (error) {
                showMessage(polyglot.t("DataSourceChoiceSection.errorMessages.parsingCsv"), "error");
                console.error(":::: ERROR PARSING FILE :::::", error);
              }
            })
          }
        )
      ] }),
      formik.values.dataSourceChoice === EntityImportWizardSource.CSV && importState.domain === EntityImportWizardData.Attendances && /* @__PURE__ */ jsxs(Box, { sx: { mt: "-40px" }, children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          polyglot.t("DataSourceChoiceSection.useTemplate1"),
          " ",
          /* @__PURE__ */ jsx("a", { href: AttendanceImportCsvTemplate, rel: "noreferrer", style: { color: themeColors.DarkGrey }, children: polyglot.t("DataSourceChoiceSection.useTemplate2") }),
          " ",
          polyglot.t("DataSourceChoiceSection.useTemplate3")
        ] }),
        /* @__PURE__ */ jsx(
          UploadInput,
          {
            skipUpload: true,
            onChange: (_, file) => __async(void 0, null, function* () {
              try {
                if (!file) return;
                const arrayBuffer = yield file.arrayBuffer();
                const csvBuffer = yield arrayBufferToString(arrayBuffer);
                const parsedCsv = parseCsvBuffer(csvBuffer);
                const validationResult = yield validateAttendanceImport(
                  parsedCsv,
                  allUsersIncludingTerminated,
                  attendanceTypes != null ? attendanceTypes : []
                );
                handleFileUpload(validationResult);
              } catch (error) {
                showMessage(polyglot.t("DataSourceChoiceSection.errorMessages.parsingCsv"), "warning");
                console.error(":::: ERROR PARSING FILE :::::", error);
              }
            })
          }
        )
      ] }),
      formik.values.dataSourceChoice === EntityImportWizardSource.CSV && importState.domain === EntityImportWizardData.Users && sortedProfileForms && /* @__PURE__ */ jsxs(Stack, { sx: { mt: "-40px", gap: spacing.g15 }, children: [
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Create a new CSV template file",
            name: "existing-file",
            checked: newTemplateFile,
            onChange: (e, checked) => setNewTemplateFile(checked)
          }
        ),
        newTemplateFile && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Choose the data you would like to include in the import" }),
          /* @__PURE__ */ jsx(Stack, { sx: { gap: spacing.g15 }, children: Object.entries(groupBy(sortedProfileForms, "formTab")).map(([tab, forms]) => /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: tab,
                name: tab,
                checked: forms.some(({ formId }) => selectedUserProfileFormIds.has(formId)),
                onChange: (e, checked) => {
                  const ids = new Set(selectedUserProfileFormIds);
                  forms.forEach(({ formId }) => ids[checked ? "add" : "delete"](formId));
                  setSelectedUserProfileFormIds(ids);
                },
                labelSx: { textTransform: "capitalize", fontWeight: "bold" }
              }
            ),
            /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.m5, gap: spacing.g5 }, children: forms.map(({ formName, formId }) => /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: formName,
                name: formId,
                checked: selectedUserProfileFormIds.has(formId),
                onChange: (e, checked) => {
                  const ids = new Set(selectedUserProfileFormIds);
                  ids[checked ? "add" : "delete"](formId);
                  setSelectedUserProfileFormIds(ids);
                },
                labelSx: { textTransform: "capitalize" },
                sx: { marginLeft: spacing.ml10 }
              },
              formId
            )) })
          ] }, tab)) }),
          selectedUserProfileFormIds.size > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt10 }, children: [
            /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
              "1. Download",
              " ",
              /* @__PURE__ */ jsx(
                "a",
                {
                  href: `/apiv2/users/import/zelt-user-import-template.csv?sections=${[
                    ...selectedUserProfileFormIds
                  ].join(",")}`,
                  rel: "noreferrer",
                  style: { color: themeColors.DarkGrey },
                  children: "this CSV template"
                }
              ),
              " ",
              "file."
            ] }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "2. Edit the file and enter the data to be imported." }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "3. Save the file and upload it here:" })
          ] })
        ] }),
        (!newTemplateFile || selectedUserProfileFormIds.size > 0) && /* @__PURE__ */ jsx(
          UploadInput,
          {
            skipUpload: true,
            onChange: (_, file) => __async(void 0, null, function* () {
              try {
                if (!file) return;
                const arrayBuffer = yield file.arrayBuffer();
                const csvBuffer = yield arrayBufferToString(arrayBuffer);
                const parsedCsv = parseCsvBuffer(csvBuffer);
                const userImport = convertPeopleCSVToUserImport2(
                  parsedCsv,
                  allUsersIncludingTerminated,
                  departments,
                  sites,
                  entities,
                  attendanceSchedules,
                  sortedProfileForms,
                  getCustomFieldsForForm,
                  polyglot
                );
                const validationResult = yield validatePeopleImport2(userImport, polyglot);
                handleFileUpload(validationResult);
              } catch (error) {
                showMessage(polyglot.t("DataSourceChoiceSection.errorMessages.parsingCsv"), "error");
                console.error(":::: ERROR PARSING FILE :::::", error);
              }
            })
          }
        )
      ] }),
      formik.values.dataSourceChoice === EntityImportWizardSource.CSV && importState.domain === EntityImportWizardData.Devices && /* @__PURE__ */ jsxs(Box, { sx: { mt: "-40px" }, children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          polyglot.t("DataSourceChoiceSection.useTemplate1"),
          " ",
          /* @__PURE__ */ jsx("a", { href: DeviceImportCsvTemplate, rel: "noreferrer", style: { color: themeColors.DarkGrey }, children: polyglot.t("DataSourceChoiceSection.useTemplate2") }),
          " ",
          polyglot.t("DataSourceChoiceSection.useTemplate3")
        ] }),
        /* @__PURE__ */ jsx(
          UploadInput,
          {
            skipUpload: true,
            onChange: (_, file) => __async(void 0, null, function* () {
              try {
                if (!file) return;
                const arrayBuffer = yield file.arrayBuffer();
                const csvBuffer = yield arrayBufferToString(arrayBuffer);
                const parsedCsv = parseCsvBuffer(csvBuffer);
                const validationResult = yield validateDeviceImport(parsedCsv);
                handleFileUpload(validationResult);
              } catch (error) {
                showMessage(polyglot.t("DataSourceChoiceSection.errorMessages.parsingCsv"), "error");
                console.error(":::: ERROR PARSING FILE :::::", error);
              }
            })
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("DataSourceChoiceSection.continue"),
          loading: saving,
          colorVariant: "primary",
          sizeVariant: "large",
          disabled: !formik.isValid,
          fullWidth: true
        }
      )
    ] })
  ] }) });
};
