"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { SettingsReadOnlyLine } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component";
import { WeekDayNames } from "@v2/feature/attendance/attendance.interface";
import { getAllTimeFromScheduleByWeekIndex } from "@v2/feature/attendance/attendance.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
export const AttendanceSchedulePatternSubSection = ({ schedule }) => {
  const { polyglot } = usePolyglot();
  const weeksArray = useMemo(() => {
    const arr = [];
    for (let w = 0; w < schedule.noOfWeeks; w += 1) {
      arr.push(w);
    }
    return arr;
  }, [schedule.noOfWeeks]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    weeksArray.length > 1 && /* @__PURE__ */ jsx(Box, { sx: { mb: "20px" }, children: /* @__PURE__ */ jsx(
      SettingsReadOnlyLine,
      {
        field: polyglot.t("ScheduleSectionReadonly.startDateOfFirstWeek"),
        value: new LocalDate(schedule.startDateOfFirstWeek).toLocaleDateString()
      },
      "schedule_start_week"
    ) }),
    weeksArray.map((weekNo) => {
      return /* @__PURE__ */ jsxs(Box, { sx: { mt: "10px", mb: "10px" }, children: [
        schedule.noOfWeeks > 1 && /* @__PURE__ */ jsxs(Typography, { variant: "title4", sx: { mb: "10px" }, children: [
          polyglot.t("AttendanceSchedule.weekNo", { weekNo: weekNo + 1 }),
          ":"
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px" }, children: WeekDayNames.map((day) => {
          var _a, _b, _c;
          if (!schedule[day] || !schedule[day][weekNo] || !schedule[day][weekNo].to || !schedule[day][weekNo].from)
            return /* @__PURE__ */ jsx(
              SettingsReadOnlyLine,
              {
                field: polyglot.t(`Days.${day.toLowerCase()}`),
                value: polyglot.t("AttendanceDomain.notWorking")
              },
              day
            );
          if (schedule.isFlexible) {
            const totalHoursValue = (_b = (_a = schedule[day][weekNo]) == null ? void 0 : _a.totalHours) == null ? void 0 : _b.slice(11, 16);
            const [tH, tM] = totalHoursValue ? totalHoursValue.split(":") : [null, null];
            const totalHoursToHour = tH && Number(tH) ? Number(tH) : "";
            const totalHoursToMinute = tM && Number(tM) ? Number(tM) : "";
            const breakString = totalHoursValue ? `${Number(totalHoursToHour) > 0 ? `${totalHoursToHour}h` : ""} ${Number(totalHoursToMinute) > 0 ? `${totalHoursToMinute}m` : ""}`.trim() : "";
            return /* @__PURE__ */ jsx(
              SettingsReadOnlyLine,
              {
                field: polyglot.t(`Days.${day.toLowerCase()}`),
                value: breakString
              },
              day
            );
          } else {
            const from = schedule[day][weekNo].from.slice(11, 16);
            const to = schedule[day][weekNo].to.slice(11, 16);
            const breakValue = (_c = schedule[day][weekNo].break) == null ? void 0 : _c.slice(11, 16);
            const [bH, bM] = breakValue ? breakValue.split(":") : [null, null];
            const breakToHour = bH && Number(bH) ? polyglot.t("ScheduleSectionReadonly.breakToHour", { number: Number(bH) }) : "";
            const breakToMinute = bM && Number(bM) ? polyglot.t("ScheduleSectionReadonly.breakToMinute", {
              breakToHour: breakToHour ? " " : "",
              number: Number(bM)
            }) : "";
            const breakString = breakValue && breakValue !== "00:00" ? polyglot.t("ScheduleSectionReadonly.breakString", {
              breakToHour,
              breakToMinute
            }) : "";
            const period = polyglot.t("ScheduleSectionReadonly.period", {
              from,
              to,
              breakString
            });
            return /* @__PURE__ */ jsx(SettingsReadOnlyLine, { field: polyglot.t(`Days.${day.toLowerCase()}`), value: period }, day);
          }
        }) }),
        /* @__PURE__ */ jsx(Box, { sx: { mt: "10px", mb: "20px" }, children: /* @__PURE__ */ jsx(
          SettingsReadOnlyLine,
          {
            field: polyglot.t("ScheduleSectionReadonly.totalHours"),
            value: convertMinutesToClockHours(getAllTimeFromScheduleByWeekIndex(schedule, weekNo), polyglot)
          },
          "week_total"
        ) })
      ] }, weekNo);
    })
  ] });
};
