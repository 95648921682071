"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class AttendancePenaltyAPI {
  static enableAndDisableAttendancePenaltyRules(scheduleId, toEnable, toDisable) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-penalties/schedules/${scheduleId}`, { toEnable, toDisable });
    });
  }
}
export class AttendancePenaltyEndpoints {
  static getAttendanceSchedulePenaltyRules(scheduleId) {
    return {
      url: `/apiv2/attendance-penalties/schedules/${scheduleId}`
    };
  }
}
