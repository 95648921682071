"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { generatePath, useHistory } from "react-router-dom";
import { ContractAPI } from "@/api-client/contract.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DOCUMENTS_ME_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const UserSignContractModal = ({
  setOpen,
  open,
  contractId,
  returnPath,
  updateSignature,
  witnessPending = false
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isSigningContract, setIsSigningContract] = useState(false);
  const [errorMessage, setErrorMessage] = useState(void 0);
  const [employeeSignature, setEmployeeSignature] = useState("");
  const [contractSigned, setContractSigned] = useState(false);
  const [witnessSigning, setWitnessSigning] = useState(false);
  const [witnessSigned, setWitnessSigned] = useState(false);
  const [witnessDetails, setWitnessDetails] = useState(null);
  const [witnessSignature, setWitnessSignature] = useState("");
  const [witnessFullName, setWitnessFullName] = useState("");
  const [witnessFullAddress, setWitnessFullAddress] = useState("");
  const routerHistory = useHistory();
  const getWitnessDetails = useCallback(() => {
    return witnessSignature && witnessFullName && witnessFullAddress ? {
      witnessSignature,
      witnessFullName,
      witnessFullAddress
    } : null;
  }, [witnessFullAddress, witnessFullName, witnessSignature]);
  const signContract = useCallback(
    (signingWitnessDetailsNow = false) => __async(void 0, null, function* () {
      var _a, _b;
      setErrorMessage(void 0);
      try {
        setIsSigningContract(true);
        if (!employeeSignature) {
          showMessage(polyglot.t("UserSignContractModal.errorMessages.signature"), "error");
        }
        let detailsForWitness;
        if (signingWitnessDetailsNow) {
          detailsForWitness = getWitnessDetails();
          setWitnessDetails(detailsForWitness);
          setWitnessSigned(!!detailsForWitness);
        }
        if (witnessPending && !!detailsForWitness) {
          yield ContractAPI.sign({ contractId, signature: employeeSignature, witnessDetails: detailsForWitness });
          showMessage(polyglot.t("UserSignContractModal.successMessages.signed"), "success");
        } else if (!witnessPending) {
          yield ContractAPI.sign({ contractId, signature: employeeSignature });
          showMessage(polyglot.t("UserSignContractModal.successMessages.signed"), "success");
        }
        setContractSigned(true);
        if (witnessPending) setWitnessSigning(true);
      } catch (error) {
        setErrorMessage(
          ((_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.message) || (error == null ? void 0 : error.message) || "Issues encountered in creating this document, please try again"
        );
        showMessage(
          polyglot.t("UserSignContractModal.errorMessages.sign", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setIsSigningContract(false);
      }
    }),
    [employeeSignature, witnessPending, showMessage, polyglot, getWitnessDetails, contractId]
  );
  const handlePostUserSignAction = () => {
    routerHistory.push(returnPath ? returnPath : generatePath(DOCUMENTS_ME_ROUTE));
    return;
  };
  const witnessDone = !witnessPending || witnessPending && witnessSigned && witnessDetails !== null;
  const handleSignature = (text) => {
    updateSignature == null ? void 0 : updateSignature(text);
    setEmployeeSignature(text);
  };
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    !contractSigned && !witnessSigning && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserSignContractModal.signTheContract") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("UserSignContractModal.userAgreementMessage") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("UserSignContractModal.userAgreementMessagePart2") }),
      /* @__PURE__ */ jsx(FormControl, { children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "employeeSignature",
          label: polyglot.t("UserSignContractModal.employeeSignature"),
          value: employeeSignature,
          type: "text",
          onChange: (e) => handleSignature(e.target.value),
          error: !employeeSignature,
          helperText: polyglot.t("UserSignContractModal.helperSignature"),
          clearText: () => handleSignature("")
        }
      ) }),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            mt: "2em"
          },
          children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: __spreadValues({}, themeFonts.signature), children: employeeSignature })
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          name: polyglot.t("UserSignContractModal.sign"),
          loading: isSigningContract,
          disabled: isSigningContract || !employeeSignature,
          onClick: () => signContract(),
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ) })
    ] }),
    !witnessDone && contractSigned && witnessSigning && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserSignContractModal.witnessDetails") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("UserSignContractModal.userWitnessMessagePart1") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("UserSignContractModal.userAgreementMessage") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("UserSignContractModal.userWitnessMessagePart2") }),
      /* @__PURE__ */ jsx(FormControl, { children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "witnessFullName",
          label: polyglot.t("UserSignContractModal.witnessFullName"),
          value: witnessFullName,
          type: "text",
          onChange: (e) => setWitnessFullName(e.target.value),
          error: !witnessFullName,
          clearText: () => setWitnessFullName("")
        }
      ) }),
      /* @__PURE__ */ jsx(FormControl, { children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "witnessFullAddress",
          label: polyglot.t("UserSignContractModal.witnessFullAddress"),
          value: witnessFullAddress,
          type: "text",
          onChange: (e) => setWitnessFullAddress(e.target.value),
          error: !witnessFullAddress,
          clearText: () => setWitnessFullAddress("")
        }
      ) }),
      /* @__PURE__ */ jsx(FormControl, { children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "witnessSignature",
          label: polyglot.t("UserSignContractModal.witnessSignature"),
          value: witnessSignature,
          type: "text",
          onChange: (e) => setWitnessSignature(e.target.value),
          error: !witnessSignature,
          helperText: polyglot.t("UserSignContractModal.helperSignature"),
          clearText: () => setWitnessSignature("")
        }
      ) }),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            mt: "2em"
          },
          children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: __spreadValues({}, themeFonts.signature), children: witnessSignature })
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          name: polyglot.t("UserSignContractModal.sign"),
          loading: isSigningContract,
          disabled: isSigningContract || !witnessSignature || !witnessFullAddress || !witnessFullName,
          onClick: () => signContract(true),
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ) })
    ] }),
    contractSigned && witnessDone && !Boolean(errorMessage) && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserSignContractModal.thankYou") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("UserSignContractModal.youWillReceiveEmail") }),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "primary",
          sizeVariant: "medium",
          fullWidth: true,
          onClick: () => handlePostUserSignAction(),
          children: polyglot.t("UserSignContractModal.close")
        }
      ) })
    ] }),
    Boolean(errorMessage) && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserSignContractModal.sorry") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: errorMessage }),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "primary",
          sizeVariant: "medium",
          fullWidth: true,
          onClick: () => handlePostUserSignAction(),
          children: polyglot.t("UserSignContractModal.close")
        }
      ) })
    ] })
  ] }) });
};
