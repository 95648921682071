"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { FieldCard } from "@v2/components/field-card.component";
import { Typography } from "@v2/components/typography/typography.component";
import { isUnlimitedPolicy } from "@v2/feature/absence/absence.util";
import {
  getAccrualBreakdownValue,
  getAccrualRemainingValue,
  getBookedFromBreakdown,
  getCurrentBalanceFromBreakdown,
  getTotalAllowanceBreakdown,
  getUsedFromBreakdown
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { AllowanceDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/allowance-drawer.component";
import { BookedAllowanceDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/booked-allowance-drawer.component";
import { UsedAllowanceDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/used-allowance-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
export const PolicyBreakdown = ({
  isCurrentYear,
  userId,
  refresh,
  absencePolicy,
  userBalanceDetailedStats
}) => {
  const { polyglot } = usePolyglot();
  const [isAllowanceDrawerOpen, setIsAllowanceDrawerOpen] = useState(false);
  const [isUsedDrawerOpen, setIsUsedDrawerOpen] = useState(false);
  const [isBookedDrawerOpen, setIsBookedDrawerOpen] = useState(false);
  const policyBreakdown = userBalanceDetailedStats[absencePolicy.id];
  const accruedSoFar = isCurrentYear ? getAccrualBreakdownValue(absencePolicy, policyBreakdown, "total", polyglot) : null;
  const accruedRemaining = isCurrentYear ? getAccrualRemainingValue(absencePolicy, policyBreakdown, polyglot) : null;
  const totalAllowance = getTotalAllowanceBreakdown(absencePolicy, policyBreakdown, polyglot);
  const used = getUsedFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const booked = getBookedFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const balance = getCurrentBalanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const isUnlimited = isUnlimitedPolicy(absencePolicy);
  const boxSx = accruedSoFar ? { height: "88px" } : {};
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mb: spacing.m10 }, children: absencePolicy.name }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(
        FieldCard,
        {
          name: polyglot.t("AllowanceDrawer.allowance"),
          value: totalAllowance != null ? totalAllowance : polyglot.t("AllowanceDrawer.unlimited"),
          subValue: accruedSoFar ? polyglot.t("AllowanceDrawer.valueAccrued", { value: accruedSoFar }) : void 0,
          action: isUnlimited ? void 0 : () => setIsAllowanceDrawerOpen(true),
          color: "secondary",
          sx: boxSx
        }
      ),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "\u2013" }),
      /* @__PURE__ */ jsx(
        FieldCard,
        {
          name: polyglot.t("AllowanceDrawer.used"),
          value: used,
          action: isUnlimited ? void 0 : () => setIsUsedDrawerOpen(true),
          color: "warning",
          sx: boxSx
        }
      ),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "\u2013" }),
      /* @__PURE__ */ jsx(
        FieldCard,
        {
          name: polyglot.t("AllowanceDrawer.booked"),
          value: booked,
          action: isUnlimited ? void 0 : () => setIsBookedDrawerOpen(true),
          color: "warning",
          sx: boxSx
        }
      ),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "=" }),
      /* @__PURE__ */ jsx(
        FieldCard,
        {
          name: polyglot.t("AllowanceDrawer.remaining"),
          value: balance != null ? balance : polyglot.t("AllowanceDrawer.unlimited"),
          subValue: accruedRemaining ? polyglot.t("AllowanceDrawer.valueAvailable", { value: accruedRemaining }) : void 0,
          color: "success",
          sx: boxSx
        }
      )
    ] }),
    isAllowanceDrawerOpen && /* @__PURE__ */ jsx(
      AllowanceDrawer,
      {
        isOpen: isAllowanceDrawerOpen,
        setIsOpen: setIsAllowanceDrawerOpen,
        absencePolicy,
        userBalanceDetailedStats,
        refresh,
        userId
      }
    ),
    isUsedDrawerOpen && /* @__PURE__ */ jsx(
      UsedAllowanceDrawer,
      {
        isOpen: isUsedDrawerOpen,
        setIsOpen: setIsUsedDrawerOpen,
        absencePolicy,
        userBalanceDetailedStats
      }
    ),
    isBookedDrawerOpen && /* @__PURE__ */ jsx(
      BookedAllowanceDrawer,
      {
        isOpen: isBookedDrawerOpen,
        setIsOpen: setIsBookedDrawerOpen,
        absencePolicy,
        userBalanceDetailedStats
      }
    )
  ] });
};
