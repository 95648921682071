"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box, FormControl, FormControlLabel } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { OauthClientAPI, OauthClientEndpoints } from "@/api-client/oauth-client.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeveloperHubPermissionsEditDrawer = ({
  isOpen,
  oauthApp,
  setIsOpen,
  onClose,
  onUpdate
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { data: ALL_AVAILABLE_SCOPES } = useApiClient(OauthClientEndpoints.getAvailableScopes(), {
    suspense: false
  });
  const formik = useFormik({
    initialValues: {
      scopes: (oauthApp == null ? void 0 : oauthApp.scopes) || [],
      scopeReasons: (oauthApp == null ? void 0 : oauthApp.scopeReasons) || {}
    },
    validationSchema: Yup.object().shape({
      scopes: Yup.array().of(Yup.string()).min(1, "At least one scope must be selected"),
      scopeReasons: Yup.object().test(
        "scope-reasons",
        "Some selected scopes are missing reasons",
        function(scopeReasons, context) {
          const scopes = context.parent.scopes;
          const missingReasons = scopes.filter((scope) => {
            const reason = scopeReasons == null ? void 0 : scopeReasons[scope];
            return !reason || reason.trim() === "";
          });
          if (missingReasons.length > 0) {
            return this.createError({
              message: `Missing reasons for: ${missingReasons.join(", ")}`
            });
          }
          return true;
        }
      )
    }),
    onSubmit: (values) => {
      updateScopesForOauthAppClient(values);
    }
  });
  useEffect(() => {
    if (oauthApp) {
      formik.setValues({
        scopes: oauthApp.scopes || [],
        scopeReasons: oauthApp.scopeReasons || {}
      });
    }
  }, [oauthApp]);
  const updateScopesForOauthAppClient = (values) => __async(void 0, null, function* () {
    if (!oauthApp || oauthApp.published) {
      showMessage(polyglot.t("DeveloperHub.cannotUpdatePublishedApp"), "error");
      return;
    }
    try {
      yield OauthClientAPI.updateScopeAndScopeReasonsForExistingClient(
        oauthApp.clientId,
        oauthApp.id,
        values.scopes,
        values.scopeReasons
      );
      showMessage(polyglot.t("DeveloperHubClientGenerateDrawer.successMessages.update"), "success");
      onClose();
      onUpdate();
    } catch (error) {
      showMessage(
        polyglot.t("DeveloperHubClientGenerateDrawer.errorMessages.update", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const handleScopeChange = (scope, checked) => {
    formik.setValues((prevValues) => {
      const newScopes = checked ? [...prevValues.scopes, scope] : prevValues.scopes.filter((s) => s !== scope);
      const newScopeReasons = __spreadValues({}, prevValues.scopeReasons);
      if (!checked) {
        delete newScopeReasons[scope];
      }
      return __spreadProps(__spreadValues({}, prevValues), {
        scopes: newScopes,
        scopeReasons: newScopeReasons
      });
    }, true);
    setTimeout(() => formik.validateForm(), 0);
  };
  useEffect(() => {
    console.log("Current formik values:", formik.values);
    console.log("Current formik errors:", formik.errors);
  }, [formik.values, formik.errors]);
  const handleScopeReasonChange = (scope, reason) => {
    formik.setFieldValue("scopeReasons", __spreadProps(__spreadValues({}, formik.values.scopeReasons), {
      [scope]: reason
    }));
  };
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, sx: { overflowY: "hidden", px: spacing.s2 }, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: __spreadValues({}, drawerContentSx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("DeveloperHub.permissions") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("DeveloperHub.permissionsExplainer") }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          overflowY: "auto",
          maxHeight: "50%",
          mt: spacing.mt10,
          ml: spacing.ml10,
          px: spacing.px16,
          pb: spacing.pb20
        },
        children: (ALL_AVAILABLE_SCOPES != null ? ALL_AVAILABLE_SCOPES : []).map((scope, index) => /* @__PURE__ */ jsxs(Box, { sx: { mb: spacing.mb10 }, children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              control: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  name: `scopeValue-${index}`,
                  checked: formik.values.scopes.includes(scope.value),
                  onChange: (_, checked) => handleScopeChange(scope.value, checked)
                }
              ),
              label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: scope.value })
            }
          ),
          formik.values.scopes.includes(scope.value) && /* @__PURE__ */ jsx(FormControl, { sx: { marginTop: 1 }, size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: `scopeReasons[${scope.value}]`,
              label: polyglot.t("DeveloperHub.reasonFor", { scope: scope.label }),
              value: formik.values.scopeReasons[scope.value] || "",
              onChange: (e) => handleScopeReasonChange(scope.value, e.target.value),
              error: formik.touched.scopeReasons && !!formik.errors.scopeReasons,
              helperText: formik.touched.scopeReasons && typeof formik.errors.scopeReasons === "string" && formik.errors.scopeReasons,
              fullWidth: true
            }
          ) })
        ] }, scope.value))
      }
    ),
    formik.touched.scopes && formik.errors.scopes && /* @__PURE__ */ jsx(Typography, { color: "red", variant: "caption", children: formik.errors.scopes }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        fullWidth: true,
        colorVariant: "primary",
        sizeVariant: "medium",
        onClick: (e) => {
          e.preventDefault();
          formik.handleSubmit();
        },
        disabled: !formik.isValid || (oauthApp == null ? void 0 : oauthApp.published),
        children: polyglot.t("DeveloperHub.updateScopes")
      }
    ) })
  ] }) }) });
};
