"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DownloadIcon } from "@/images/icons/download-icon.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Document.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { downloadFileByUrl } from "@/v2/feature/documents/documents.util";
import { ViewerItem } from "@/v2/feature/payments/components/payment-details-drawer.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import {
  ID_CHECK_COMPLETED_STATES,
  MANUAL_RTW_CHECK
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
import { toTitleCase } from "@/v2/util/string.util";
export const ViewResultsForIdChecksModal = ({
  isOpen,
  setIsOpen,
  idCheckState,
  idCheckDocumentToDisplay
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const params = useParams();
  const userId = Number(params.userId);
  const { getCachedUserById } = useCachedUsers();
  const [isDownloading, setIsDownloading] = useState(false);
  const currentProfileUser = userId ? getCachedUserById(userId) : void 0;
  const fallbackDocumentName = currentProfileUser ? `${currentProfileUser == null ? void 0 : currentProfileUser.firstName} ${currentProfileUser == null ? void 0 : currentProfileUser.lastName} - Identity Check` : "Identity Check";
  const idCheckDocumentName = idCheckDocumentToDisplay != null ? idCheckDocumentToDisplay : fallbackDocumentName;
  const downloadReportForIdCheck = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2;
    setIsDownloading(true);
    const { requestId } = idCheckState;
    if (!requestId) return;
    const reportResponse = yield OnboardingAPI.downloadIdCheckReport(idCheckState);
    try {
      const pdfBytes = new Uint8Array((_a2 = reportResponse == null ? void 0 : reportResponse.file) == null ? void 0 : _a2.data);
      const file = new Blob([pdfBytes], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      let link = document.createElement("a");
      const reportName = (_b2 = reportResponse == null ? void 0 : reportResponse.name) != null ? _b2 : "IdCheck-report.pdf";
      link.download = reportName;
      link.href = fileURL;
      link.click();
    } catch (error) {
      showMessage(
        polyglot.t("IdCheckPackage.errorMessages.failedToDownloadReport", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsDownloading(false);
    }
  }), [idCheckState, polyglot, showMessage]);
  const isExternalIdCheck = ((_a = idCheckState == null ? void 0 : idCheckState.requestId) == null ? void 0 : _a.length) > 0 && idCheckState.requestId !== MANUAL_RTW_CHECK;
  const downloadExistingManualIdCheck = (fileUuid, originalFilename) => __async(void 0, null, function* () {
    try {
      setIsDownloading(true);
      if (fileUuid && typeof fileUuid === "string") {
        yield DocumentAPI.downloadViaUuid(fileUuid).then((url) => {
          downloadFileByUrl(url, originalFilename);
        });
      }
    } catch (error) {
      showMessage(
        polyglot.t("IdCheckPackage.errorMessages.failedToDownloadReport", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsDownloading(false);
    }
  });
  return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t(
      isExternalIdCheck ? "UserProfileIdentityChecks.results" : "UserProfileIdentityChecks.manualIdentityCheck"
    ) }),
    /* @__PURE__ */ jsx(
      ViewerItem,
      {
        title: polyglot.t(isExternalIdCheck ? "UserProfileIdentityChecks.completionDate" : "IdCheck.dateOfCheck"),
        value: idCheckState.completedAt ? (_b = dateAPItoDisplay(idCheckState.completedAt)) != null ? _b : "" : "None"
      }
    ),
    (idCheckState == null ? void 0 : idCheckState.status) && /* @__PURE__ */ jsx(ViewerItem, { title: "Status", value: toTitleCase(idCheckState == null ? void 0 : idCheckState.status) }),
    isExternalIdCheck && /* @__PURE__ */ jsx(ViewerItem, { title: "Result", value: (idCheckState == null ? void 0 : idCheckState.result) ? toTitleCase(idCheckState == null ? void 0 : idCheckState.result) : "None" }),
    isExternalIdCheck && (idCheckState == null ? void 0 : idCheckState.updatedAt) && /* @__PURE__ */ jsx(
      ViewerItem,
      {
        title: "Last updated",
        value: idCheckState.updatedAt ? (_c = dateAPItoDisplay(idCheckState.updatedAt)) != null ? _c : "" : "None"
      }
    ),
    isExternalIdCheck && /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("UserProfileIdentityChecks.summaryOfTheChecks") }),
    isExternalIdCheck && ((_d = idCheckState.checks) == null ? void 0 : _d.length) > 0 && idCheckState.checks.map((eachCheck) => /* @__PURE__ */ jsx(ViewerItem, { title: eachCheck.name, value: toTitleCase(eachCheck.status) })),
    isExternalIdCheck && ((_e = idCheckState.checks) == null ? void 0 : _e.length) === 0 && /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "Grey", children: [
      "We have not received any results yet.",
      " "
    ] }),
    isExternalIdCheck && /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("UserProfileIdentityChecks.downloadReportPDF") }),
    !isExternalIdCheck && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", width: 1 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: idCheckDocumentName }) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.gap5 }, children: /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: iconCTAButtonSx,
          disabled: isDownloading,
          onClick: () => downloadExistingManualIdCheck(idCheckState.reportUrl, idCheckDocumentName),
          children: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize))
        }
      ) })
    ] }),
    isExternalIdCheck && idCheckState.completedAt && idCheckState.status && ID_CHECK_COMPLETED_STATES.has(idCheckState.status) && idCheckState.requestId && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", width: 1 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: idCheckDocumentName }) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.gap5 }, children: /* @__PURE__ */ jsx(IconButton, { sx: iconCTAButtonSx, disabled: isDownloading, onClick: () => downloadReportForIdCheck(), children: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize)) }) })
    ] }),
    isExternalIdCheck && !idCheckState.completedAt && idCheckState.status && !ID_CHECK_COMPLETED_STATES.has(idCheckState.status) && idCheckState.requestId && /* @__PURE__ */ jsxs(Stack, { direction: "column", sx: { gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "Grey", children: [
        "You can request Preliminary Result from Zinc to check if there is any progress for this employee.",
        /* @__PURE__ */ jsx("br", {}),
        /* @__PURE__ */ jsx("br", {}),
        " Once all results are in, Zelt will notify you."
      ] }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          loading: isDownloading,
          disabled: isDownloading,
          onClick: () => {
            downloadReportForIdCheck();
          },
          children: "Download Preliminary Report"
        }
      )
    ] })
  ] }) });
};
