"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { capitalize } from "lodash";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-heatmap/components/chart-heatmap/chart-heatmap-table.scss";
import { SurveyQuestionDrawer } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-question-drawer.component";
import {
  MeasureBy,
  ShowBy
} from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
const COLOR_RANGE = 5;
export const ChartHeatmapTable = ({
  series,
  categories,
  filter,
  loadingHeatmap,
  growthFactors,
  measureByFilter,
  surveyHeatmap,
  questionMap,
  showByFilter
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const getMinMaxValues = useCallback((series2) => {
    if (series2.length === 0) {
      return [];
    }
    const numberOfItems = series2[0].data.length;
    const minMaxValues = new Array(numberOfItems);
    for (let idx = 0; idx < numberOfItems; idx++) {
      const rowData = series2.map((item) => item.data[idx]).filter((item) => item !== null);
      minMaxValues[idx] = {
        min: Math.min(...rowData),
        max: Math.max(...rowData)
      };
    }
    return minMaxValues;
  }, []);
  const getMinMaxByRow = useMemo(() => {
    if (!series) return [{ min: 0, max: 100 }];
    return getMinMaxValues(series);
  }, [getMinMaxValues, series]);
  const getColorRange = useCallback(
    (value, categoryIndex) => {
      const { min, max } = getMinMaxByRow[categoryIndex];
      const stepSize = Math.round((max - min) / COLOR_RANGE);
      const thresholds = [min, ...[1, 2, 3, 4].map((factor) => min + factor * stepSize)];
      const colors = ["#F5ABA3", "#FCEFED", "#F0FAF2", "#B3E5C0", "#74CF8C"];
      if (value === null) return themeColors.Background;
      let colorIndex = thresholds.findIndex((threshold, index) => {
        return value >= threshold && (thresholds[index + 1] === void 0 || value < thresholds[index + 1]);
      });
      if (colorIndex === -1) {
        colorIndex = colors.length - 1;
      }
      return colors[colorIndex];
    },
    [getMinMaxByRow]
  );
  const renderTableHeaders = useMemo(() => {
    if (loadingHeatmap) {
      return /* @__PURE__ */ jsxs("tr", { style: { textAlign: "left", borderBottom: borders.background, boxSizing: "border-box" }, children: [
        /* @__PURE__ */ jsx(
          "th",
          {
            style: {
              textAlign: "left",
              height: "60px",
              width: "550px",
              verticalAlign: "bottom",
              paddingBottom: "15px",
              paddingRight: "16px",
              paddingLeft: "16px"
            },
            children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: capitalize(filter) })
          }
        ),
        new Array(11).fill(0).map((_, idx) => /* @__PURE__ */ jsx(
          "th",
          {
            style: {
              textAlign: "left",
              height: "60px",
              width: "60px",
              verticalAlign: "bottom",
              paddingBottom: "28px"
            },
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                color: "DarkGrey",
                sx: {
                  display: "block",
                  maxWidth: "118px",
                  maxHeight: "60px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  transform: "rotate(-45deg)",
                  textAlign: "left"
                }
              }
            )
          },
          idx
        ))
      ] });
    }
    return /* @__PURE__ */ jsxs("tr", { style: { textAlign: "left", borderBottom: borders.background, boxSizing: "border-box" }, children: [
      /* @__PURE__ */ jsx(
        "th",
        {
          style: {
            textAlign: "left",
            height: "60px",
            width: "550px",
            maxWidth: "550px",
            minWidth: "550px",
            verticalAlign: "bottom",
            paddingBottom: "15px",
            paddingRight: "16px",
            paddingLeft: "16px"
          },
          children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: capitalize(filter) })
        }
      ),
      series.map((item) => /* @__PURE__ */ jsx(
        "th",
        {
          style: {
            textAlign: "left",
            height: "60px",
            width: "60px",
            verticalAlign: "bottom",
            paddingBottom: "28px"
          },
          children: /* @__PURE__ */ jsx(StyledTooltip, { title: capitalize(item.name), placement: "top", children: /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              color: "DarkGrey",
              sx: {
                display: "block",
                maxWidth: "118px",
                maxHeight: "60px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                transform: "rotate(-45deg)",
                textAlign: "left"
              },
              children: capitalize(item.name)
            }
          ) }) })
        },
        item.name
      ))
    ] });
  }, [loadingHeatmap, series, filter]);
  const renderTableBody = useMemo(() => {
    if (loadingHeatmap) {
      return new Array(11).fill(0).map((_) => /* @__PURE__ */ jsxs("tr", { style: { borderBottom: borders.background }, children: [
        /* @__PURE__ */ jsx(
          "td",
          {
            style: {
              height: "60px",
              width: "550px",
              maxWidth: "550px",
              minWidth: "550px",
              borderBottom: borders.background,
              paddingRight: "16px",
              paddingLeft: "16px"
            },
            children: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", height: 60, width: "100%" })
          }
        ),
        new Array(11).fill(0).map((_2, idx) => /* @__PURE__ */ jsx(
          "td",
          {
            style: {
              height: "60px",
              width: "60px",
              maxWidth: "60px",
              boxSizing: "border-box",
              textAlign: "center",
              verticalAlign: "middle"
            },
            children: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", height: 60, width: 60 })
          },
          idx
        ))
      ] }));
    }
    return categories.map((category, index) => /* @__PURE__ */ jsxs("tr", { children: [
      /* @__PURE__ */ jsx(
        "td",
        {
          style: {
            height: "60px",
            width: "550px",
            maxWidth: "550px",
            minWidth: "550px",
            paddingRight: "40px",
            paddingLeft: "16px",
            borderBottom: borders.background
          },
          children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: showByFilter === ShowBy.Questions ? questionMap[category] : category })
        }
      ),
      series.map((item) => {
        const value = item.data[index];
        const color = getColorRange(value, index);
        const isClickable = showByFilter === ShowBy.Questions && item.data[index] !== null;
        return /* @__PURE__ */ jsx(
          "td",
          {
            className: isClickable ? "heatmap-td" : "heatmap-noevent-td",
            style: {
              background: color,
              cursor: isClickable ? "pointer" : "default"
            },
            onClick: () => {
              if (isClickable) {
                const selectedCategory = surveyHeatmap == null ? void 0 : surveyHeatmap.find((cat) => cat.name === item.name);
                const selectedQuestion2 = (selectedCategory == null ? void 0 : selectedCategory.result.resultByQuestion.find((obj) => obj.question.id === category)) || null;
                setIsOpen(true);
                setSelectedQuestion(selectedQuestion2);
              }
            },
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "title4",
                sx: {
                  display: "inline-block",
                  maxWidth: "60px",
                  maxHeight: "60px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  verticalAlign: "middle"
                },
                children: item.data[index] === null ? "\u2013" : item.data[index]
              }
            )
          },
          item.name
        );
      })
    ] }, category));
  }, [categories, series, getColorRange, loadingHeatmap, questionMap, showByFilter, surveyHeatmap]);
  const getTableWidth = useCallback(() => {
    const baseWidth = 610;
    const totalWidth = series.length > 1 ? series.length * 60 + baseWidth : 3 * 60 + baseWidth;
    const maxWidth = window.innerWidth - 300;
    return totalWidth >= maxWidth ? "100%" : `${totalWidth}px`;
  }, [series.length]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx("div", { style: { overflowX: "scroll", maxWidth: getTableWidth(), width: "100%" }, children: /* @__PURE__ */ jsxs("table", { style: { tableLayout: "fixed", width: "100%", borderCollapse: "collapse" }, children: [
      /* @__PURE__ */ jsx("thead", { children: renderTableHeaders }),
      /* @__PURE__ */ jsx("tbody", { children: renderTableBody })
    ] }) }),
    /* @__PURE__ */ jsx(
      SurveyQuestionDrawer,
      {
        isOpen,
        setIsOpen,
        onClose: () => {
          setSelectedQuestion(null);
        },
        growthFactors,
        questionItem: selectedQuestion,
        impactResult: void 0,
        impactFilter: "",
        measureByFilter: measureByFilter === MeasureBy.Average ? "avg" : measureByFilter.toLowerCase(),
        gridsXs: [6, 4]
      }
    )
  ] });
};
