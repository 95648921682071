"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { absurd, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { ContractAPI } from "@/api-client/contract.api";
import { TemplateAPI } from "@/api-client/templates.api";
import { BELONGS_TO_EVERYONE } from "@/lib/documents";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import {
  CATEGORIES_FOR_PRIVATE_DOCUMENTS,
  PREVIEWABLE_FILE_FORMATS
} from "@/v2/feature/documents/documents.interface";
import {
  SignatoryType
} from "@/v2/feature/templates/templates.interface";
import { filterStringToObject } from "@/v2/feature/user/user.util";
export const DEFAULT_DOCUMENT_TYPE_NAME = "other";
export const REDUCER_NAME = "reduceDocumentFormModal";
export const OTHER_TYPE = {
  value: DEFAULT_DOCUMENT_TYPE_NAME,
  label: "Other",
  category: "Other",
  icon: "file",
  bgColor: "bg-red-500",
  canUserAdd: true,
  onePersonOnly: false,
  canBelongToEveryone: true,
  onboardingDocument: true,
  needsVerification: false
};
export var DocumentsDomain = /* @__PURE__ */ ((DocumentsDomain2) => {
  DocumentsDomain2["company"] = "company";
  DocumentsDomain2["employee"] = "employee";
  return DocumentsDomain2;
})(DocumentsDomain || {});
export function documentCanBeSeenByEveryoneFromDto(d) {
  var _a;
  return d.canAllEmployeesSee && ((_a = d.belongsTo) != null ? _a : []).length === 0;
}
export function documentFormModalOwnershipToDto(o) {
  return o.multiownership ? o.canBelongToEveryone ? o.belongsToEveryone ? [BELONGS_TO_EVERYONE] : o.owners : o.owners : o.owner ? [o.owner] : [];
}
export const uploadDocumentIfNeeded = (documentLink) => __async(void 0, null, function* () {
  if (!documentLink) return void 0;
  return DocumentAPI.uploadFileViaURL(documentLink);
});
export function documentBelongsToEveryone(o) {
  return o.multiownership && o.canBelongToEveryone && o.belongsToEveryone;
}
export function documentHasOwner(o) {
  return o.multiownership ? o.canBelongToEveryone ? o.belongsToEveryone || o.owners.length > 0 : o.owners.length > 0 : !!o.owner;
}
export function getOwnersUserIds(o) {
  var _a;
  return new Set(
    (o.multiownership ? o.canBelongToEveryone && o.belongsToEveryone ? [] : (_a = o.owners) != null ? _a : [] : o.owner ? [o.owner] : []).map((x) => x.value)
  );
}
export function initialiseState(types, owner) {
  const type = OTHER_TYPE;
  return {
    types,
    // TODO take from type list
    type: void 0,
    id: void 0,
    name: void 0,
    fileUuid: void 0,
    note: void 0,
    canBelongToEveryone: type.canBelongToEveryone,
    multiownership: !type.onePersonOnly,
    belongsToEveryone: false,
    owners: owner ? [owner] : [],
    uploadedBy: void 0,
    currentlyUploading: false
  };
}
export function initialiseStateForDocumentRequest(types, owner, reach) {
  return {
    types,
    // TODO take from type list
    type: void 0,
    id: void 0,
    name: void 0,
    fileUuid: void 0,
    note: void 0,
    canBelongToEveryone: false,
    multiownership: false,
    belongsToEveryone: false,
    owners: owner && reach !== "company" ? [owner] : [],
    owner: owner && reach !== "company" ? owner : void 0,
    uploadedBy: void 0,
    currentlyUploading: false
  };
}
export function initialiseStateFromDocument(types, doc, userList) {
  var _a, _b, _c, _d, _e;
  const belongsTo = pipe(
    (_a = doc.belongsTo) != null ? _a : [],
    RA.filterMap((b) => {
      var _a2, _b2, _c2, _d2, _e2;
      const userId = Number((_a2 = b.User) == null ? void 0 : _a2.userId);
      return isNaN(userId) ? O.none : O.some({
        value: userId,
        label: (_e2 = (_b2 = b.User) == null ? void 0 : _b2.displayName) != null ? _e2 : `${(_c2 = b.User) == null ? void 0 : _c2.firstName} ${(_d2 = b.User) == null ? void 0 : _d2.lastName}`
      });
    })
  );
  const type = (_b = types.find((x) => x.value === doc.type)) != null ? _b : OTHER_TYPE;
  const ownership = type.onePersonOnly ? {
    multiownership: !type.onePersonOnly,
    owner: ((_c = belongsTo[0]) != null ? _c : doc.belongsToUserId && userList) ? userList == null ? void 0 : userList.find((x) => {
      var _a2;
      return x.value === doc.belongsToUserId || x.value === ((_a2 = belongsTo[0]) == null ? void 0 : _a2.value);
    }) : void 0
  } : type.canBelongToEveryone ? {
    multiownership: !type.onePersonOnly,
    canBelongToEveryone: type.canBelongToEveryone,
    belongsToEveryone: documentCanBeSeenByEveryoneFromDto(doc),
    owners: belongsTo
  } : {
    multiownership: !type.onePersonOnly,
    canBelongToEveryone: type.canBelongToEveryone,
    owners: belongsTo
  };
  const attachment = doc.attachments && doc.attachments.length > 0 ? doc.attachments[0] : void 0;
  return __spreadProps(__spreadValues({
    types,
    type: type != null ? type : OTHER_TYPE,
    id: doc.id,
    name: doc.name,
    note: doc.note,
    fileUuid: (_d = attachment == null ? void 0 : attachment.fileUuid) != null ? _d : doc == null ? void 0 : doc.fileUuid,
    fileName: (_e = attachment == null ? void 0 : attachment.fileName) != null ? _e : doc == null ? void 0 : doc.fileName
  }, ownership), {
    uploadedBy: doc.uploadedBy,
    pinned: doc.isPinned,
    visibility: doc.visibilitySettings,
    expirySettings: doc.expirySettings
  });
}
export function initialiseStateForBulkUploadDocument(types, doc, userList) {
  var _a, _b, _c, _d;
  const belongsTo = pipe(
    (_a = doc.belongsTo) != null ? _a : [],
    RA.filterMap((b) => {
      var _a2, _b2, _c2, _d2, _e;
      const userId = Number((_a2 = b.User) == null ? void 0 : _a2.userId);
      return isNaN(userId) ? O.none : O.some({
        value: userId,
        label: (_e = (_b2 = b.User) == null ? void 0 : _b2.displayName) != null ? _e : `${(_c2 = b.User) == null ? void 0 : _c2.firstName} ${(_d2 = b.User) == null ? void 0 : _d2.lastName}`
      });
    })
  );
  const ownership = {
    multiownership: false,
    owner: ((_b = belongsTo[0]) != null ? _b : doc.belongsToUserId && userList) ? userList == null ? void 0 : userList.find((x) => {
      var _a2;
      return x.value === doc.belongsToUserId || x.value === ((_a2 = belongsTo[0]) == null ? void 0 : _a2.value);
    }) : void 0
  };
  const attachment = doc.attachments && doc.attachments.length > 0 ? doc.attachments[0] : void 0;
  return __spreadProps(__spreadValues({
    types,
    type: OTHER_TYPE,
    id: doc.id,
    name: doc.name,
    note: doc.note,
    fileUuid: (_c = attachment == null ? void 0 : attachment.fileUuid) != null ? _c : doc == null ? void 0 : doc.fileUuid,
    fileName: (_d = attachment == null ? void 0 : attachment.fileName) != null ? _d : doc == null ? void 0 : doc.fileName
  }, ownership), {
    uploadedBy: doc.uploadedBy,
    pinned: doc.isPinned,
    visibility: doc.visibilitySettings,
    expirySettings: doc.expirySettings
  });
}
export function reduceDocumentFormModal(state, action) {
  var _a, _b, _c, _d;
  switch (action.kind) {
    case "set_types": {
      return __spreadProps(__spreadValues({}, state), { types: action.value });
    }
    case "change_name": {
      return __spreadProps(__spreadValues({}, state), { name: action.value });
    }
    case "set_visibility": {
      return __spreadProps(__spreadValues({}, state), { visibility: action.value });
    }
    case "set_expiry": {
      return __spreadProps(__spreadValues({}, state), { expirySettings: action.value });
    }
    case "set_pinned": {
      return __spreadProps(__spreadValues({}, state), { pinned: action.value });
    }
    case "change_type": {
      return __spreadValues(__spreadValues({}, state), action.type ? { type: action.type } : {});
    }
    case "change_note": {
      return __spreadProps(__spreadValues({}, state), { note: action.value });
    }
    case "upload_file": {
      return __spreadValues(__spreadValues({}, state), action.value);
    }
    case "prepare_file_for_upload": {
      return __spreadValues(__spreadValues({}, state), action.value);
    }
    case "clear_file": {
      return __spreadProps(__spreadValues({}, state), { fileUuid: void 0 });
    }
    case "add_document_link": {
      return __spreadProps(__spreadValues({}, state), { documentLink: action.value });
    }
    case "select_everyone": {
      if (state.type && (state.type.onePersonOnly || !state.type.canBelongToEveryone)) {
        throw new Error(
          `${REDUCER_NAME}/${action.kind}: document of type '${state.type.value}' can't belong to everyone.`
        );
      }
      const { type, types, fileName, fileUuid, id, name, note } = state;
      return {
        canBelongToEveryone: true,
        multiownership: true,
        belongsToEveryone: true,
        type,
        types,
        fileName,
        fileUuid,
        id,
        name,
        note
      };
    }
    case "select_owner": {
      if (state.multiownership) {
        throw new Error(
          `${REDUCER_NAME}/${action.kind}: document of type '${(_b = (_a = state.type) == null ? void 0 : _a.value) != null ? _b : "Unknown"}' must be owned by one or more.`
        );
      }
      return __spreadProps(__spreadValues({}, state), { owner: action.value });
    }
    case "select_owners": {
      if (!state.multiownership) {
        throw new Error(
          `${REDUCER_NAME}/${action.kind}: document of type '${(_d = (_c = state.type) == null ? void 0 : _c.value) != null ? _d : "Unknown"}' can't be owned by more than one person`
        );
      }
      return state.canBelongToEveryone ? __spreadProps(__spreadValues({}, state), { belongsToEveryone: false, owners: action.value }) : __spreadProps(__spreadValues({}, state), { owners: action.value });
    }
    case "unselect_owners": {
      return state.multiownership ? state.canBelongToEveryone ? __spreadProps(__spreadValues({}, state), { belongsToEveryone: false, owners: [] }) : __spreadProps(__spreadValues({}, state), { owners: [] }) : __spreadProps(__spreadValues({}, state), { owner: void 0 });
    }
    case "add_document": {
      return initialiseState(state.types, action.owner);
    }
    case "edit_document": {
      return initialiseStateFromDocument(state.types, action.value, action.userList);
    }
    case "bulk_upload_edit_document": {
      return initialiseStateForBulkUploadDocument(state.types, action.value, action.userList);
    }
    case "request_document": {
      return initialiseStateForDocumentRequest(state.types, action.owner, action.reach);
    }
    case "currently_uploading": {
      return __spreadProps(__spreadValues({}, state), { currentlyUploading: action.value });
    }
    default: {
      return absurd(action);
    }
  }
}
export const DEBOUNCE_300_MS = 300;
export const DEBOUNCE_500_MS = 500;
export const DEBOUNCE_1000_MS = 1e3;
const filterByType = (documents, types) => {
  return documents.filter((document2) => types.includes(document2.type));
};
export const filterDocuments = (searchInput) => (documents) => (filterString) => {
  let filteredDocuments = documents;
  if (filterString) {
    const filterOptions = filterStringToObject(filterString);
    if (filterOptions) {
      for (const key of Object.keys(filterOptions)) {
        switch (key) {
          case "type": {
            filteredDocuments = filterByType(filteredDocuments, filterOptions[key]);
            break;
          }
          default:
            break;
        }
      }
    }
  }
  if (searchInput) {
    const search = searchInput.toLowerCase().trim().replaceAll(" ", "");
    filteredDocuments = filteredDocuments.filter(
      (document2) => document2.name.toLowerCase().trim().replaceAll(" ", "").includes(search) || document2.type.toLowerCase().trim().replaceAll(" ", "").includes(search) || document2.belongsTo && document2.belongsTo.map((user) => {
        return `${user.User.displayName.toLowerCase().trim().replaceAll(" ", "")},${user.User.firstName.toLowerCase().trim().replaceAll(" ", "")},${user.User.lastName.toLowerCase().trim().replaceAll(" ", "")}`;
      }).toString().includes(search)
    );
  }
  return filteredDocuments;
};
export const getPinnedDocuments = (documents) => {
  return documents.filter((doc) => doc.isPinned);
};
const filterDocumentsByCategory = (viewType, allDocuments) => {
  return viewType === "company" ? allDocuments.filter((document2) => document2.category === "Company Documents") : allDocuments.filter((document2) => document2.category !== "Company Documents");
};
export const getDocumentTypeListBasedOnAudience = (baseTypeList, currentUserIsAdmin = false, viewType) => {
  if (currentUserIsAdmin) {
    if (!viewType) return baseTypeList;
    else return filterDocumentsByCategory(viewType, baseTypeList);
  }
  const privateDocuments = baseTypeList == null ? void 0 : baseTypeList.filter((t) => CATEGORIES_FOR_PRIVATE_DOCUMENTS.has(t == null ? void 0 : t.category));
  return !viewType ? privateDocuments : filterDocumentsByCategory(viewType, privateDocuments);
};
export const getDefaultNameForSingleUserDocument = (state, subjectToChange) => {
  var _a, _b;
  if ((!state.name || state.name.length === 0) && state.type && (state.owners && state.owners.length === 1 || state.owner)) {
    const defaultName = `${state.type.label} - ${(state == null ? void 0 : state.owners) && ((_a = state == null ? void 0 : state.owners) == null ? void 0 : _a.length) ? state.owners[0].label : (_b = state == null ? void 0 : state.owner) == null ? void 0 : _b.label}`;
    subjectToChange.next(defaultName);
    return defaultName;
  }
};
export const getTypeState = (state, subjectToChange, filterValue) => {
  if (filterValue) {
    if (filterValue === "company") {
      if (state.type) {
        if (state.type.category === "Company Documents") {
          subjectToChange.next(state.type);
          return state.type;
        }
        const defaultType = state.types.find((t) => t.category === "Company Documents");
        subjectToChange.next(defaultType);
        return defaultType;
      }
    } else {
      if (state.type) {
        if (state.type.category !== "Company Documents") {
          subjectToChange.next(state.type);
          return state.type;
        }
        const defaultType = state.types.find((t) => t.category === "Other");
        subjectToChange.next(defaultType);
        return defaultType;
      }
    }
  }
};
function extractFileName(url) {
  const lastSlashIndex = url.lastIndexOf("/");
  if (lastSlashIndex === -1) return url;
  return url.slice(lastSlashIndex + 1);
}
export function downloadFileByUrl(fileUrl, fileName = "document") {
  if (!fileUrl) return;
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = fileUrl;
  const finalFileName = extractFileName(fileName);
  link.download = finalFileName;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    var _a;
    URL.revokeObjectURL(link.href);
    (_a = link == null ? void 0 : link.parentNode) == null ? void 0 : _a.removeChild(link);
  }, 0);
}
export const downloadTemplatesByID = (templateName, recipientUser, params) => __async(void 0, null, function* () {
  const response = yield TemplateAPI.downloadContractTemplatePreview(params);
  stringToDownloadPDF(response.data, templateName, recipientUser);
});
export const downloadIncompleteContractsByID = (templateName, recipientUser, params) => __async(void 0, null, function* () {
  const response = yield ContractAPI.downloadIncompleteContractPreview(params);
  stringToDownloadPDF(response.data, templateName, recipientUser);
});
const stringToDownloadPDF = (pdf, templateName, recipientUser) => {
  const pdfBytes = new Uint8Array(pdf);
  const file = new Blob([pdfBytes], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  let link = document.createElement("a");
  link.download = `${templateName}${recipientUser ? "-" + recipientUser : ""}-Draft.pdf`;
  link.href = fileURL;
  link.click();
};
export function previewFileInNewTab(fileUrl) {
  if (!fileUrl) return;
  window.open(fileUrl, "_blank");
}
export function getDocumentParentCategory(reach, mode, state, currentUserIsAdmin, documentTypes) {
  var _a;
  if (!currentUserIsAdmin) {
    return "personal";
  }
  if (mode === "edit" && state) {
    const documentTypeName = (_a = documentTypes.find((d) => d.value === state.type)) == null ? void 0 : _a.category;
    return documentTypeName === "Company Documents" ? "company" : "personal";
  }
  if (mode === "request") return "personal";
  return reach === "me" ? "personal" : "company";
}
export const isPreviewableFile = (doc) => {
  var _a, _b;
  const fileNameFromAttachments = doc.attachments && doc.attachments[0] && ((_a = doc.attachments[0]) == null ? void 0 : _a.fileName);
  const finalFilename = (_b = doc == null ? void 0 : doc.fileName) != null ? _b : fileNameFromAttachments;
  if (!finalFilename) return false;
  const fileExtension = finalFilename.substring(finalFilename.lastIndexOf("."));
  return PREVIEWABLE_FILE_FORMATS.includes(fileExtension.toLowerCase());
};
export const companySignatureRequired = (row) => {
  var _a, _b, _c, _d;
  return ((_a = row.contract) == null ? void 0 : _a.signatoriesRequired) && ((_c = (_b = row.contract) == null ? void 0 : _b.signatoriesRequired) == null ? void 0 : _c.includes(SignatoryType.additional)) && ((_d = row.contract) == null ? void 0 : _d.companySignatory);
};
export const recipientSignatureRequired = (row) => {
  var _a, _b, _c, _d;
  return ((_a = row.contract) == null ? void 0 : _a.signatoriesRequired) && ((_c = (_b = row.contract) == null ? void 0 : _b.signatoriesRequired) == null ? void 0 : _c.includes(SignatoryType.recipient)) && ((_d = row.contract) == null ? void 0 : _d.recipient);
};
export const allPartiesHaveSignedContract = (row) => {
  const { contractId, contract } = row;
  const companySignatureExpected = companySignatureRequired(row);
  const recipientSignatureExpected = recipientSignatureRequired(row);
  if (!contractId || !contract) {
    return false;
  }
  if (!companySignatureExpected && !recipientSignatureExpected || !Array.isArray(contract.signatoriesRequired) || contract.signatoriesRequired.length === 0) {
    return true;
  }
  if (companySignatureExpected && !contract.companySignatureTimestamp) {
    return false;
  }
  if (recipientSignatureExpected && !contract.recipientSignatureTimestamp) {
    return false;
  }
  return true;
};
