"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { AppIntegrationEndpoints } from "@v2/feature/app-integration/app-integration.api";
import { AppsWithLogins } from "@v2/feature/app-integration/app-integration.interface";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { tsFormat } from "@v2/feature/monitoring/monitoring.util";
import { OffboardingAPI } from "@v2/feature/offboarding/offboarding.api";
import { getUserAppDtoForInstalledAppViaScheduledAction } from "@v2/feature/offboarding/offboarding.util";
import { UserAPI } from "@v2/feature/user/user.api";
import { MANUALLY_EXCLUDED_BOARDING_APPS } from "@v2/feature/user-onboarding/by-admin/interface/onboarding.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { format } from "date-fns";
import { generatePath } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as RejectedGrey } from "@/images/side-bar-icons/Rejected-grey.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { nestErrorMessage } from "@/lib/errors";
import { USER_OFFBOARDING_ROUTE } from "@/lib/routes";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { DeactivateAppsDrawer } from "@/v2/feature/offboarding/components/deactivate-apps-drawer.component";
import { RootStyle } from "@/v2/styles/root.styles";
const OffboardingAppIcon = ({ stub, size = 60 }) => {
  return /* @__PURE__ */ jsx(Box, { sx: { width: `${size}px`, height: `${size}px` }, children: /* @__PURE__ */ jsx("img", { src: `/app-icons-v2/images/${stub}.png`, width: `${size}px`, alt: stub, style: { borderRadius: "50%" } }) });
};
const getStatusIcon = (status, polyglot) => {
  switch (status) {
    case polyglot.t("OffboardingApps.active"):
      return /* @__PURE__ */ jsx(OkGreen, { style: { fill: themeColors.Green } });
    case polyglot.t("OffboardingApps.noAccess"):
      return /* @__PURE__ */ jsx(RejectedGrey, {});
    case polyglot.t("OffboardingApps.suspended"):
      return /* @__PURE__ */ jsx(RejectedGrey, {});
    case polyglot.t("OffboardingApps.invited"):
      return /* @__PURE__ */ jsx(Waiting, { style: { fill: themeColors.DarkGrey } });
  }
};
export const OffboardingAppsPage = ({ userId, offboardingState, refresh, loading }) => {
  const { polyglot } = usePolyglot();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyApps, setCompanyApps] = useState([]);
  const [userApps, setUserApps] = useState([]);
  const [selectedUserApp, setSelectedUserApp] = useState(void 0);
  const [existingDeactivationDate, setExistingDeactivationDate] = useState(void 0);
  const {
    data: delayedActions,
    mutate: refreshDelayedActions
  } = useApiClient(AppIntegrationEndpoints.getDelayedAppActionsByUserId(userId), { suspense: false });
  const [showMessage] = useMessage();
  const getUserApps = useCallback(() => __async(void 0, null, function* () {
    var _a;
    try {
      setIsLoading(true);
      const [apps, allUserApps, userAccount] = yield Promise.all([
        OffboardingAPI.getOffboardingCompanyApps(),
        OffboardingAPI.getOffboardingAppsForUser(userId),
        UserAPI.getUserAccountInfo(userId)
      ]);
      const zeltApp = {
        stub: "zelt",
        userId,
        userStatus: (userAccount == null ? void 0 : userAccount.deactivationDate) ? `Account will be deactivated on ${format(
          new LocalDate(userAccount.deactivationDate).getDate(),
          tsFormat
        )} UTC` : userAccount == null ? void 0 : userAccount.accountStatus
      };
      const filteredUserApps = allUserApps.filter((app) => !MANUALLY_EXCLUDED_BOARDING_APPS.includes(app.stub));
      const companyAppStubs = apps.map((a) => a.stub);
      const userAppStubsWithDelayedActions = [];
      const delayedAppActions = (_a = delayedActions == null ? void 0 : delayedActions.delayed) != null ? _a : [];
      for (const eachDelayedAction of delayedAppActions) {
        if (companyAppStubs.includes(eachDelayedAction.data.appStub)) {
          const installedAppWithDelayedActionForUser = apps.find((app) => app.stub === eachDelayedAction.data.appStub);
          if (installedAppWithDelayedActionForUser) {
            userAppStubsWithDelayedActions.push(
              getUserAppDtoForInstalledAppViaScheduledAction(installedAppWithDelayedActionForUser, eachDelayedAction)
            );
          }
        }
      }
      const delayedActionStubs = new Set(userAppStubsWithDelayedActions.map((app) => app.stub));
      const uniqueFilteredUserApps = filteredUserApps.filter((app) => !delayedActionStubs.has(app.stub));
      setCompanyApps(apps);
      setUserApps([...uniqueFilteredUserApps, ...userAppStubsWithDelayedActions, zeltApp]);
      if (userAccount == null ? void 0 : userAccount.deactivationDate) setExistingDeactivationDate(userAccount == null ? void 0 : userAccount.deactivationDate);
      setIsLoading(false);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  }), [userId, delayedActions, showMessage]);
  useEffect(() => {
    getUserApps();
  }, [getUserApps]);
  const delayedActionsByStubForCurrentUser = useMemo(() => {
    var _a;
    const appStubsForUser = userApps.map((a) => a.stub);
    const relevantDelayedActions = (_a = delayedActions == null ? void 0 : delayedActions.delayed) == null ? void 0 : _a.filter((d) => appStubsForUser.includes(d.data.appStub));
    return relevantDelayedActions ? new Map(relevantDelayedActions.map((delayedAction) => [delayedAction.data.appStub, delayedAction])) : void 0;
  }, [delayedActions == null ? void 0 : delayedActions.delayed, userApps]);
  const selectedAppIsZelt = useMemo(() => {
    return (selectedUserApp == null ? void 0 : selectedUserApp.stub) === "zelt";
  }, [selectedUserApp == null ? void 0 : selectedUserApp.stub]);
  const columnData = useMemo(
    () => [
      {
        header: () => polyglot.t("OffboardingApps.apps"),
        id: "appName",
        size: 120,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const app = companyApps == null ? void 0 : companyApps.find((companyApp) => companyApp.stub === original.stub);
          return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            /* @__PURE__ */ jsx(OffboardingAppIcon, { stub: original.stub, size: 20 }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.stub === "zelt" ? "Zelt" : app == null ? void 0 : app.name })
          ] });
        }
      },
      {
        header: () => polyglot.t("OffboardingApps.userStatus"),
        id: "userStatus",
        size: 120,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return original.stub === "zelt" ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            getStatusIcon(original.userStatus, polyglot),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.userStatus })
          ] }) : /* @__PURE__ */ jsx("div", { children: AppsWithLogins.includes(original.stub) ? /* @__PURE__ */ jsx(Box, { children: (original == null ? void 0 : original.emails) && (original == null ? void 0 : original.emails.length) > 0 ? (_a = original == null ? void 0 : original.emails) == null ? void 0 : _a.map((emailObj) => {
            return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
              getStatusIcon(emailObj.status, polyglot),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: emailObj.status })
            ] });
          }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            getStatusIcon(original.userStatus, polyglot),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.userStatus })
          ] }) }) : /* @__PURE__ */ jsx(Box, { children: (original == null ? void 0 : original.emails) ? (_b = original == null ? void 0 : original.emails) == null ? void 0 : _b.map((emailObj) => {
            return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
              getStatusIcon(emailObj.status, polyglot),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: emailObj.status })
            ] });
          }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            getStatusIcon(original.userStatus, polyglot),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.userStatus })
          ] }) }) });
        }
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "actions",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return !(original == null ? void 0 : original.scheduledAction) ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableIconButtonSx,
              onClick: () => {
                setSelectedUserApp(original);
                setIsOpen(true);
              },
              children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
            }
          ) }) : /* @__PURE__ */ jsx(Fragment, {});
        },
        maxSize: 150,
        minSize: 100
      }
    ],
    [polyglot, companyApps]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("OffboardingPage.apps") }),
        showBack: true,
        backPath: generatePath(USER_OFFBOARDING_ROUTE, { userId })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "600px", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OffboardingApps.deactivateDesc") }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.s2 }, children: /* @__PURE__ */ jsx(BasicTable, { rowData: userApps, columnData, hidePagination: true, loading: isLoading }) }),
      selectedUserApp && /* @__PURE__ */ jsx(
        DeactivateAppsDrawer,
        {
          isOpen,
          setIsOpen,
          userId,
          userApp: selectedUserApp,
          userLeaveDate: offboardingState.leaveDate,
          onClose: () => setIsOpen(false),
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([refresh(), getUserApps()]);
            yield refreshDelayedActions == null ? void 0 : refreshDelayedActions();
            setIsOpen(false);
          }),
          existingDelayedAction: !selectedAppIsZelt && selectedUserApp && delayedActionsByStubForCurrentUser ? delayedActionsByStubForCurrentUser.get(selectedUserApp.stub) : void 0,
          existingDeactivationDate: selectedAppIsZelt && existingDeactivationDate ? existingDeactivationDate : void 0
        }
      )
    ] }) })
  ] });
};
