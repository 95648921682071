"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { PolicyPayrollEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-payroll-edit-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const AbsencePolicyPayrollSection = ({ absencePolicy, refresh }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const isPayrollConnected = useMemo(() => {
    if (!absencePolicy) return false;
    return absencePolicy.inPayrollRequests || absencePolicy.inPayrollOffboarding;
  }, [absencePolicy]);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AbsencePolicyNotificationsSection.payroll"),
      onEdit: () => setIsEditDrawerOpen(true),
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("PayrollModule.connectedToPayroll"),
                    value: isPayrollConnected ? polyglot.t("General.yes") : polyglot.t("General.no")
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("PayItemModule.payCode"),
                    value: (_a = absencePolicy.payCode) != null ? _a : polyglot.t("General.none"),
                    hidden: !isPayrollConnected
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyPayrollSection.approvedRequests"),
                    value: absencePolicy.inPayrollRequests ? polyglot.t("General.enabled") : polyglot.t("General.disabled"),
                    hidden: !isPayrollConnected
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("PayItemModule.payCodeMultiplier"),
                    value: polyglot.t("AttendanceModule.Penalties.dayRateTimesMultiplier", {
                      multiplier: absencePolicy.payCodeMultiplier
                    }),
                    hidden: !isPayrollConnected || !absencePolicy.inPayrollRequests || absencePolicy.payCodeMultiplier === null
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyPayrollSection.accruedHoliday"),
                    value: absencePolicy.inPayrollOffboarding ? polyglot.t("General.enabled") : polyglot.t("General.disabled"),
                    hidden: !isPayrollConnected
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyPayrollEditDrawer,
          {
            isOpen: isEditDrawerOpen,
            setIsOpen: setIsEditDrawerOpen,
            absencePolicy,
            refresh
          }
        )
      ]
    }
  );
};
