"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import Box from "@mui/material/Box";
import { StickyStyleTD, StyleTD, StyleTR } from "@/v2/feature/calendar/calendar-table.interface";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { TableBody } from "@/v2/styles/table.styles";
export const CalendarTableBodyLoadingState = React.memo(
  ({ timelineDays, activeView }) => {
    return /* @__PURE__ */ jsx(TableBody, { children: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((user, index) => {
      return /* @__PURE__ */ jsxs(StyleTR, { children: [
        /* @__PURE__ */ jsx(
          StickyStyleTD,
          {
            className: "data-row-user",
            style: {
              minWidth: activeView === "Month" ? "48px" : "130px",
              maxWidth: activeView === "Month" ? "48px" : "130px",
              width: activeView === "Month" ? "48px" : "130px"
            },
            children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", height: "100%" }, children: /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                height: "48px",
                width: "130px",
                sx: { background: themeColors.Background, display: "flex" }
              }
            ) })
          }
        ),
        timelineDays == null ? void 0 : timelineDays.map((entry, idx) => {
          return /* @__PURE__ */ jsx(
            StyleTD,
            {
              className: "data-row-entry",
              style: {
                minWidth: activeView === "Month" ? "48px" : "130px",
                maxWidth: activeView === "Month" ? "48px" : "130px",
                width: activeView === "Month" ? "48px" : "130px"
              },
              children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", height: "100%" }, children: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  height: "48px",
                  width: activeView === "Month" ? "40px" : "130px",
                  sx: { background: themeColors.Background, display: "flex" }
                }
              ) })
            },
            `${idx}-entry`
          );
        })
      ] }, index);
    }) });
  }
);
