"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import styled from "@emotion/styled";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const tdAndThPadding = "0 16px";
export const iconSize = { width: 14, height: 14 };
export const actionIconSize = { width: 14, height: 14 };
export const Table = styled("table")(() => ({
  width: "100%",
  borderCollapse: "collapse",
  borderSpacing: "2px"
}));
export const TableHead = styled("thead")(() => ({
  position: "sticky",
  overflowX: "auto",
  whiteSpace: "nowrap",
  ":hover": {
    backgroundColor: "transparent"
  }
}));
export const TableHeadRow = styled("tr")(() => ({
  // borderBottom: `6px solid transparent`,
  backgroundColor: themeColors.white
}));
export const TableHeading = styled("th")(
  ({
    maxWidth,
    minWidth,
    fixedFirstColumn,
    fixedLastColumn,
    externalTdAndThPadding
  }) => __spreadValues(__spreadValues(__spreadProps(__spreadValues({}, themeFonts.captionSmall), {
    color: themeColors.Grey,
    height: "16px",
    paddingBottom: spacing.p5,
    backgroundColor: "inherit",
    textAlign: "left",
    maxWidth,
    minWidth,
    padding: externalTdAndThPadding != null ? externalTdAndThPadding : tdAndThPadding
  }), fixedFirstColumn && {
    position: "sticky",
    left: 0,
    zIndex: 1
  }), fixedLastColumn && {
    position: "sticky",
    right: 0,
    zIndex: 1
  })
);
export const Th = styled("th")(() => __spreadProps(__spreadValues({}, themeFonts.captionSmall), {
  color: themeColors.Grey,
  height: "16px",
  paddingBottom: spacing.p16,
  textAlign: "left"
}));
export const TableBody = styled("tbody")(() => ({
  backgroundColor: themeColors.white
}));
export const TableData = styled("td")(
  ({
    maxWidth,
    cellStyle,
    meta,
    fixedFirstColumn,
    fixedLastColumn,
    minWidth,
    externalTdAndThPadding
  }) => __spreadValues(__spreadValues(__spreadValues(__spreadProps(__spreadValues(__spreadProps(__spreadValues({
    position: "relative"
  }, themeFonts.caption), {
    color: themeColors.DarkGrey,
    backgroundColor: "inherit",
    textAlign: "left",
    height: "39px",
    // = 40px - 1px row dividing line
    maxWidth,
    minWidth,
    padding: externalTdAndThPadding != null ? externalTdAndThPadding : tdAndThPadding
  }), cellStyle), {
    "& div:last-child": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  }), meta === "onclick" && {
    ":hover": {
      color: themeColors.ZeltYellow
    }
  }), fixedFirstColumn && {
    position: "sticky",
    left: 0,
    zIndex: 1
  }), fixedLastColumn && {
    position: "sticky",
    right: 0,
    zIndex: 1
  })
);
export const TableRow = styled("tr")(({ hideHover = false }) => ({
  // borderBottom: borders.background,
  backgroundColor: themeColors.white,
  ".show-on-hover": { display: "none" },
  ":hover": {
    backgroundColor: hideHover ? "none" : themeColors.TableHover,
    ".show-on-hover": { display: "block" }
  }
}));
export const TableFootRow = styled("tr")(() => ({
  backgroundColor: themeColors.white
}));
