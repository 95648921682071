"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { AttendancePayrollPenaltiesDrawer } from "@v2/feature/attendance/settings/components/attendance-payroll-penalties-drawer.component";
import { AttendancePenaltyRuleCell } from "@v2/feature/attendance/settings/components/attendance-penalty-rule-cell.component";
import { AttendancePenaltyEndpoints } from "@v2/feature/attendance/subfeatures/attendance-penalty/attendance-penalty.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const AttendancePayrollPenaltiesSubSection = ({ schedule }) => {
  const {
    data: attendancePenaltyRules,
    mutate: refreshRules,
    isLoading: isLoadingPenalties
  } = useApiClient(AttendancePenaltyEndpoints.getAttendanceSchedulePenaltyRules(schedule.id), { suspense: false });
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);
  const refreshPenalties = useCallback(() => __async(void 0, null, function* () {
    if (refreshRules) yield refreshRules();
  }), [refreshRules]);
  const filteredPenaltyRules = useMemo(() => {
    if (!attendancePenaltyRules) return [];
    if (schedule.isFlexible) {
      return attendancePenaltyRules.filter((f) => f.name.includes("General"));
    }
    return attendancePenaltyRules;
  }, [attendancePenaltyRules, schedule.isFlexible]);
  const enabledPenalties = useMemo(() => {
    var _a;
    return (_a = filteredPenaltyRules == null ? void 0 : filteredPenaltyRules.filter((p) => p.enabled)) != null ? _a : [];
  }, [filteredPenaltyRules]);
  return /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          title: polyglot.t("AttendanceModule.penalties"),
          onEdit: () => {
            setEdit(true);
          },
          loading: isLoadingPenalties,
          headerWidth: "720px",
          contentWidth: "720px",
          items: [
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsx(
                PayrollPenaltiesTable,
                {
                  enabledPenalties,
                  edit,
                  setEdit,
                  schedule,
                  attendancePenaltyRules: attendancePenaltyRules != null ? attendancePenaltyRules : [],
                  refreshPenalties
                }
              )
            }
          ]
        }
      ]
    }
  );
};
const PayrollPenaltiesTable = ({
  enabledPenalties,
  edit,
  setEdit,
  schedule,
  attendancePenaltyRules,
  refreshPenalties
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", sx: { width: "200px" }, children: polyglot.t("AttendanceModule.penalty") }),
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", sx: { width: "125px" }, children: polyglot.t("AttendanceModule.Penalties.penalty1") }),
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", sx: { width: "125px" }, children: polyglot.t("AttendanceModule.Penalties.penalty2") }),
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", sx: { width: "125px" }, children: polyglot.t("AttendanceModule.Penalties.penalty3") }),
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", sx: { width: "125px" }, children: polyglot.t("AttendanceModule.Penalties.penalty4") })
    ] }, "titles"),
    enabledPenalties.map((p) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { width: "200px" }, children: p.name }),
      /* @__PURE__ */ jsx(AttendancePenaltyRuleCell, { width: "125px", percent: p.multiplier1 }),
      /* @__PURE__ */ jsx(AttendancePenaltyRuleCell, { width: "125px", percent: p.multiplier2 }),
      /* @__PURE__ */ jsx(AttendancePenaltyRuleCell, { width: "125px", percent: p.multiplier3 }),
      /* @__PURE__ */ jsx(AttendancePenaltyRuleCell, { width: "125px", percent: p.multiplier4 })
    ] }, p.id)),
    /* @__PURE__ */ jsx(
      AttendancePayrollPenaltiesDrawer,
      {
        isOpen: edit,
        setIsOpen: setEdit,
        schedule,
        allPenalties: attendancePenaltyRules,
        refresh: refreshPenalties
      }
    )
  ] });
};
